import Icon from "../../../components/Icon/Icon";
import { ModalCard } from "../../../components/Modal/Modal";
import { FormikProvider, useFormik } from "formik";
import { NavModalType } from "./EntryDetailNav";
import { useTheme } from "styled-components";
import TextField from "../../../components/FormFields/TextField";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import Button from "../../../components/Button/Button";
import { useState, useEffect, useContext } from "react";
import { EntryNote, EntryNoteType } from "./EntryDetail";
import {
  createEntryNote,
  getEntryNotesByType,
} from "../../Entries/manageEntry";
import { useAlert } from "../../../components/Alert/Alerts";
import { getLocalTime } from "../../../utils/timeUtilities";

export const JurorNotes = (props: {
  notes: Array<EntryNote>;
  selectedJuror?: string;
}) => {
  const theme = useTheme();

  return (
    <div className="mt-4 w-full">
      {props.notes &&
        props.notes.length > 0 &&
        props.notes.flatMap((data, index) => {
          return (props.selectedJuror &&
            props.selectedJuror === data.nameCreatedBy) ||
            !props.selectedJuror ? (
            <div key={index} className="w-full mb-4 pb-4 border-b-2">
              <div className="flex justify-center w-full">
                <div className="flex items-center gap-4 w-full">
                  <h3>{data.nameCreatedBy}</h3>
                  <div className="flex gap-1 items-center">
                    <Icon
                      icon={data.isPrivate ? "lock" : "eye"}
                      color={theme.colorPrimary}
                      width="20px"
                      height="20px"
                    />
                    <p className="italic">
                      {data.isPrivate ? "Private" : "Public"}
                    </p>
                  </div>
                </div>
                {data.createdDate && (
                  <p className="font-semibold">
                    {getLocalTime(data.createdDate, false)}
                  </p>
                )}
              </div>
              <p className="mt-2">{data.note}</p>
            </div>
          ) : (
            []
          );
        })}
    </div>
  );
};

const EntryNotesForm = (props: EntryNotesFormProps) => {
  const theme = useTheme();
  const { addNewAlert } = useAlert();

  const blankNote = {
    newNote: "",
    isNewNotePublic: false,
  };

  const [entryNotes, setEntryNotes] = useState<Array<EntryNote>>([]);

  const handleSubmitNotes = () => {
    // call api to save note
    const payloadForApi: EntryNote = {
      entryId: Number(props.entryId),
      id: 0,
      note: formikProps.values.newNote,
      isPrivate: !formikProps.values.isNewNotePublic,
      isDeleted: false,
      type: EntryNoteType.Juror,
    };

    createEntryNote(Number(props.entryId), payloadForApi).then((response) => {
      if (response.status === 200) {
        // on note save success
        setEntryNotes((prev) => [response.data, ...prev]);
        formikProps.resetForm();
        addNewAlert({ type: "success", message: "Note saved" });
        // props.showModal(false);
      }
    });

    console.log("payloadForApi", payloadForApi);
  };

  useEffect(() => {
    getEntryNotesByType(Number(props.entryId), EntryNoteType.Juror)
      .then((data) => {
        if (data) {
          setEntryNotes(data);
        }
      })
      .catch((error) => {});
  }, [props.entryId, props.juryId]);

  const formikProps = useFormik({
    initialValues: blankNote,
    enableReinitialize: true,
    onSubmit: async (value) => {},
  });

  const isSaveNoteEnabled = formikProps.values.newNote?.length > 0;

  return (
    <FormikProvider value={formikProps}>
      <ModalCard
        className=" !min-h-fit !max-h-fit"
        title="Notes"
        headerIcons={
          <Icon
            icon="close"
            color={theme.colorPrimary}
            onClick={() => props.showModal(false)}
            width="35px"
            height="35px"
          />
        }
        iconColor={theme.colorPrimary}
      >
        <div className="flex flex-col gap-[2rem] p-[2rem]">
          <TextField
            name="newNote"
            height="100px"
            component="textarea"
            placeholder="Add Notes"
            // value={formikProps.values.newNote}
          />
          <div className="flex justify-between items-center">
            <CheckboxField
              className="checkbox-field"
              name="isNewNotePublic"
              //   checked={formikProps.values.isNewNotePrivate}
              placeholder="Save as a public note that will be visible to all jurors."
            />

            <Button
              onClick={() => handleSubmitNotes()}
              className="mt-auto"
              icon="check"
              disabled={!isSaveNoteEnabled}
            >
              Save
            </Button>
          </div>
          <JurorNotes notes={entryNotes} />
        </div>
      </ModalCard>
    </FormikProvider>
  );
};
export default EntryNotesForm;

interface EntryNotesFormProps {
  showModal(type: false | NavModalType): void;
  entryId: string;
  juryId: string;
}
