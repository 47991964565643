import { ProgramModel } from "../Program/ProgramInterfaces";
import { bool } from "yup";
import { EntrySortOptions, ViewType } from "./LiveTools/EntryView";
import { BatchSelectionCard } from "../../../hooks/useAdminLiveToolsHub";
import { AwardModel } from "../Awards/Awards";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model";

export interface RootProgramWithJuryCards {
	id: number;
	name: string;
	juries: JuryCardModel[];
}

export interface JuryCardModel {
	id: number;
	programId: number;
	name: string;
	welcomeVideo?: string;
	welcomeImage?: string;
	isEntrySetFinal: boolean;
	isJuryPoolFinal: boolean;
	isRoundConfigurationFinal: boolean;
	isRoundAssignmentFinal: boolean;
	numberOfJuryGroups: number;
	juryResource?: string;
}

export interface JuryCardAdmin {
	id: number;
	name: string;
	activeEntries: number;
	activeJurors: number;
	votesCast: number;
	votesPossible: number;
	entrySetIds: number[];
}

export interface JurorCardConfig {
	id: number;
	userId: string;
	fullName: string;
	companyName: string;
	companyCity?: string;
	email: string;
	juryId: number;
	jurorType?: string;
	juryGroup?: number;
	juryName: string;
	isActive: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
}

export interface Juror {
	id: number;
	userId: string;
	juryId: number;
	jurorType?: string;
	juryGroup?: number;
	isActive: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
}

export interface JurorEntrySetSelectionCard {
	id: number;
	juryId: number;
	name: string;
	description?: string;
	roundType: string;
	votesPossible: number;
	votesCast: number;
	entriesLocked: boolean;
	deadline: number | null;
	status: JudgingRoundStatus;
	totalVideoMinutes: number;
	videoMinutesWatched: number;
}

export interface JuryConfig extends JuryCardModel {
	juryPool: JurorCardConfig[];
	roundStructures: RoundStructureConfig[];
	createdDate?: Date;
	updatedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
}

export interface CreateJuryModel {
	programId: number;
	name: string;
}

export interface CreateEntrySetModel {
	juryId: number;
	name: string;
}

export interface EntrySetConfig {
	id: number;
	juryId: number;
	name: string;
	description: string;
	entrySetDefinitions?: EntrySetDefinitionConfig[];
	isSelected: boolean;
	order: number;
}

export interface EntrySetDefinitionConfig {
	id: number;
	rootProgramId: number;
	entrySetId?: number;
	entryableProgramId: number;
	entryType: string;
	createdDate?: Date;
	updatedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
	filterString?: string;
	isCheckedForDrag: boolean;
}

export interface RoundType {
	id: number;
	name: string;
	definition: string;
	defaultJurorInstructions: string;
	voteOptions: VoteOption[];
	isAwardable: boolean;
	isSelected: boolean;
}

export interface VoteOption {
	id: number;
	roundTypeId: number;
	name: string;
	value?: number;
	type: string;
	isAward: boolean;
}

export interface RoundStructureConfig {
	id: number;
	name: string;
	juryId: number;
	roundTypeId: number;
	roundType?: RoundType;
	order?: number;
	jurorInstructions?: string;
	juryRoundPool?: JurorRoundJoin[];
	isFeedbackRequired: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	createdBy?: string;
	modifiedBy?: string;
	isCollapsed: boolean;
}

export interface JurorRoundJoin {
	id: number;
	jurorId: number;
	roundStructureId: number;
}

export interface BatchLabelGroup {
	label: string;
	batchLabelGroupEntries: BatchLabelGroupEntry[];
}

export interface BatchLabelGroupEntry {
	id: number;
	companyName: string;
	brand: string;
	title: string;
	juryGroup: number;
}

export interface BatchLabelJury {
	name: string;
	juryGroup: number;
}

export interface JurorViewCard {
	id: number;
	name: string;
	companyName: string;
	totalEntries: number;
	votesCast: number;
	entriesLocked: boolean;
	entrySets: JurorViewEntrySetCard[];
	temporaryPassword?: string;
}

export interface JurorViewEntrySetCard {
	id: number;
	name: string;
	totalEntries: number;
	votesCast: number;
}

export interface EntrySetAdminEntrySetCard extends EntrySetAdminCard {
	threshold: number;
	entrySetAdminEntrySetCardBatches: EntrySetAdminEntrySetCardBatches[];
}

export interface EntrySetAdminCard {
	id: number;
	juryId: number;
	name: string;
	description?: string;
	activeRoundOrder: number;
	activeRoundId: number;
	totalRounds: number;
	roundType: string;
	countAbstainAndNoVotes: boolean;
	isFeedbackRequired: boolean;
	activeEntries: number;
	activeJurors: number;
	votesCast: number;
	votesPossible: number;
	deadline?: number;
	jurorInstructions?: string;
	entriesLocked: boolean;
	roundStatus: JudgingRoundStatus;
	voteOptions: VoteOption[];
	showControls: boolean;
	threshold?: number;
	isSingleChoiceVoting: boolean;
}

export interface EntrySetAdminEntrySetCardBatches {
	label: string;
	threshold: string;
	entrySetAdminRoundEntryCard: EntrySetAdminRoundEntryCard[];
}

export interface EntrySetAdminRoundEntryCard {
	id: number;
	entrySetId: number;
	entryId: number;
	isLocked: boolean;
	juryGroup?: number;
	title?: string;
	brand?: string;
	coverImage?: string;
	votesCast: number;
	advance?: boolean;
	score: number;
	companyName: string;
	judgingNote: string;
	hasPhysicalComponent: boolean;
}

export interface GalleryBatchCard {
	id: number;
	juryId: number;
	entrySetId: number;
	rootProgramId: number;
	roundStructureId: number;
	roundTypeId: number;
	roundId: number;
	label: string;
	threshold?: number;
	roundEntries: GalleryEntryCard[];
	batchHierarchyDefinitions: BatchHierarchyDefinition[];
}

export interface BatchHierarchyDefinition {
	label?: string;
	name: string;
	definition?: string;
	entityId?: number;
	type: HierarchyType;
}

export enum HierarchyType {
	Program,
	EntryType,
	Other,
}

export interface GalleryEntryCard {
	id: number;
	juryId: number;
	entrySetId: number;
	roundId: number;
	rootProgramId: number;
	roundStructureId: number;
	roundTypeId: number;
	batchId: number;
	entryId: number;
	isLocked: boolean;
	juryGroup?: number;
	title?: string;
	brand?: string;
	coverImage?: string;
	votes: GalleryQuickVote[];
	score?: number;
	PreviousRoundScore?: number;
	advance?: boolean;
	companyName: string;
	judgingNote?: string;
	hasPhysicalComponent: boolean;
	synopsis?: string;
	isHidden?: boolean;
	awardLevel?: string;
	batchHierarchyDefinitions?: BatchHierarchyDefinition[];
}

export interface GalleryQuickVote {
	id: number;
	juryId: number;
	entrySetId: number;
	roundId: number;
	batchId: number;
	roundEntryId: number;
	rootProgramId: number;
	roundStructureId: number;
	roundTypeId: number;
	userId: string;
	entryId: number;
	jurorId: number;
	jurorName?: string;
	jurorCompany?: string;
	voteOptionId?: number | null;
	voteOption?: VoteOption;
	isSingleChoiceVoting: boolean;
}

export enum JudgingRoundStatus {
	Pending,
	Ready,
	Started,
	Pause,
	Completed,
}

export interface LiveToolsSettings {
	id: number;
	juryId: number;
	juryName: string;
	entryDisplayTitlePreview: string;
	isActive: boolean;
	entriesLocked: boolean;
	selectedRoundStructureId: number;
	roundStructure?: RoundStructureConfig;
	displayConfiguration: ViewType;
	sort: EntrySortOptions;
	includeBatchDivisions: boolean;
	showProgressIndicators: boolean;
	showPhysicalComponents: boolean;
	selectedBatches: BatchSelectionCard[];
	jurorControl: boolean;
	revealWinner: boolean;
	highlightedRoundEntryId?: number;
	awardId: number;
	award?: AwardModel;
}

export enum LiveToolsMediaType {
	PauseSlide,
	Image,
	Audio,
	Video,
	EntryTitleSlide,
	DefinitionSlide,
	Link,
	Document,
}

export interface LiveToolsMediaItem {
	id?: number;
	juryId: number;
	entryId?: number;
	mediaType: LiveToolsMediaType;
	filePath?: string;
	brand?: string;
	title?: string;
	text?: string;
	awardLevel?: string;
	volume?: number;
	timestamp?: number;
	isPlaying?: boolean;
	zoomLevel?: number;
	xLocation?: number;
	yLocation?: number;
	coverImage?: string;
	audioCount?: number;
	documentCount?: number;
	imageCount?: number;
	videoCount?: number;
}

export interface JurorJuryCard {
	id: number;
	name: string;
	welcomeVideo: string;
	welcomeImage: string;
	juryResource?: string;
	votesPossible: number;
	votesCast: number;
	totalVideoMinutes: number;
	videoMinutesWatched: number;
	entriesLocked: boolean;
	entrySets: JurorEntrySetSelectionCard[];
}
