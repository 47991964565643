import React, { useEffect, useState, useContext } from "react";
import styled, { useTheme, css } from "styled-components";
import Breadcrumbs, {
	VerticalDivider,
} from "../../components/Breadcrumbs/Breadcrumbs";
import Button, { ButtonLink } from "../../components/Button/Button";
import Icon from "../../components/Icon/Icon";
import { WarningModal } from "../../components/Modal/Modal";
import StickyCard, {
	StickyCardBody,
	StickyContainer,
	StickyCardHeader,
} from "../../components/StickyCard/StickyCard";
import Collapsible from "../../components/Collapsible/Collapsible";
import {
	BatchHierarchyDefinition,
	GalleryBatchCard,
	JudgingRoundStatus,
} from "../Admin/Judging/JudgingInterfaces";
import { PageContainer } from "../EntrantProgram/EntrantProgram";
import JudgingGalleryNav from "./JudgingGalleryNav";
import BatchDefModal, { ProgramLevelDefinition } from "./BatchDefModal";
import GalleryCard, { StyledGalleryCard } from "./GalleryCard";
import JudgingGallerySideBar from "./JudgingGallerySideBar";
import { useHistory } from "react-router-dom";
import InfoModal from "./InfoModal";
import Loading from "../../components/Loading/Loading";
import { PatternBG } from "../../globalStyles";
import { useJudgingGalleryHub } from "../../hooks/useJudgingGalleryHub";
import { ModalContext } from "../../App";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import assetsConfig from "../../assetsConfig";

export const InnerContainer = styled.div<{ height: string }>`
	position: relative;
	display: flex;
	height: ${(p) => p.height};
`;

const EntriesContainerCSS = `
  width: 100%;
  padding: 0 max(2rem, calc((100vw - 1800px) / 2));
  padding-top: 2rem;
  margin-bottom: 200px;
`;

// used for NON grand rounds where there ARE batches
const BatchContainer = styled(StickyContainer)`
	position: absolute !important;
	top: 0 !important;
	left: 50%;
	transform: translateX(-50%);
	${PatternBG};
	${EntriesContainerCSS};
`;

// used for the grand round where there are NO batches
const EntryCardContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 350px);
	justify-content: center;
	gap: 1.5rem;
	${EntriesContainerCSS};
`;

const EntriesContainer = (props: {
	isGrandOrWinnerRound: boolean;
	children: React.ReactNode;
}) => {
	return props.isGrandOrWinnerRound ? (
		<EntryCardContainer>{props.children}</EntryCardContainer>
	) : (
		<BatchContainer>{props.children}</BatchContainer>
	);
};

const WelcomeBanner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 1px solid ${({ theme }) => theme.colorCopyLight}; */
	box-shadow: 0 4px 4px -4px ${({ theme }) => theme.colorBoxShadow};
	padding: 1rem;
	background-color: ${({ theme }) => theme.colorBackgroundLight};
`;

export const GalleryContainer = styled.div`
	display: flex;
	flex-direction: column;
	/* grid-template-columns: repeat(auto-fill, 250px); */
	flex-wrap: wrap;
	justify-content: center;
	gap: 1rem;

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		display: grid;
		grid-template-columns: repeat(auto-fill, 350px);
	}
`;

const StyledStickyCard = styled(StickyCard)`
	min-height: 0 !important;
	margin-bottom: 2rem;
	max-height: auto !important;

	${StickyCardHeader} {
		flex-wrap: wrap;
	}

	.right-title {
		font-size: ${({ theme }) => theme.xxSmallSize};
		cursor: default;
	}

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		.right-title {
			font-size: ${({ theme }) => theme.xSmallSize};
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.xl}) {
		.right-title {
			font-size: ${({ theme }) => theme.pSize};
		}
	}
`;

export const StyledVerticalDivider = styled(VerticalDivider)`
	width: 0.5rem;
	height: 0.5rem;
	margin-right: 0.5rem;

	@media only screen and (min-width: ${({ theme }) => theme.sm}) {
		width: 0.75rem;
		height: 0.75rem;
		margin-right: 0.5rem;
	}

	@media only screen and (min-width: ${({ theme }) => theme.xl}) {
		width: 1rem;
		height: 1rem;
	}
`;

// split a string batch label by ">" or "-"
export const splitBatchLabels = (batchLabelString: string) => {
	let batchLabels = batchLabelString.split(" > ");
	// hierarchy levels should always be greater than 1
	if (batchLabels.length === 1) {
		batchLabels = batchLabels[0].split(" - "); // try splitting by dash
	}

	batchLabels.length > 1 && batchLabels.shift(); // remove highest level program name
	return batchLabels;
};

const JudgingGallery = () => {
	const theme = useTheme();
	const { modal, setModal } = useContext(ModalContext);
	const history = useHistory();
	const {
		juror,
		entrySetId,
		juryId,
		entrySetCard,
		galleryBatchCards,
		jurorJuryCard: jurorViewCard,
		updateVote,
		settings,
		highlightedEntryUrl,
	} = useJudgingGalleryHub();
	// const { setEntriesCompleted, setTotalEntries, setEntrySetTitle } =
	//   useContext(EntryProgressContext);

	const [entriesCollapsed, setEntriesCollapsed] = useState<{
		[title: string]: boolean;
	}>({});

	const [filterOption, setFilterOption] = useState("all");
	const [filteredBatches, setFilteredBatches] =
		useState<Array<GalleryBatchCard>>();

	const [showTip, setShowTip] = useState(false); // appears on first visit
	const [showInfoModal, setShowInfoModal] = useState(false); // round info, appears when info icon on the bottom right of the screen is clicked
	const [batchDefModal, setBatchDefModal] = useState<
		BatchHierarchyDefinition[] | null
	>(null);
	const [liveJudgingJurorActive, setLiveJudgingJurorActive] = useState(false);

	// for the grand/winner round:
	// no batch containers
	// no directory
	// voting grand on one entry should mark the other entries with a gray X
	const [isGrandOrWinnerRound, setIsGrandOrWinnerRound] = useState(false);

	// whether all votes are for "No Grand" option
	const [isNoGrandSelected, setIsNoGrandSelected] = useState(false);

	//   const [totalEntriesCompleted, setTotalEntriesCompleted] = useState(0);
	//   const [totalEntries, setTotalEntries] = useState(0);

	const handleAccordion = (clickedElement: string) => {
		// const obj: any = {};
		// Object.entries(entriesCollapsed).forEach(
		//   ([cardName, isCollapsed]) =>
		//     (obj[cardName] = clickedElement === cardName ? !isCollapsed : true)
		// );
		//   setEntriesCollapsed(obj);

		// toggle expand for clicked accordion
		setEntriesCollapsed({
			...entriesCollapsed,
			[clickedElement]: !entriesCollapsed[clickedElement],
		});
	};

	const handleFilter = () => {
		const getVotedOrUnvotedEntries = (voted: boolean) => {
			return galleryBatchCards.map((batch) => {
				return {
					...batch,
					roundEntries: batch.roundEntries.flatMap((roundEntry) => {
						let hasJurorVoted = roundEntry.votes.find(
							(v) => v.jurorId === juror?.id && v.voteOption && v.voteOptionId
						);

						// return round entry if it matches search criteria
						if ((voted && hasJurorVoted) || (!voted && !hasJurorVoted)) {
							return roundEntry;
						} else return [];
					}),
				};
			});
		};

		if (filterOption === "all") {
			setFilteredBatches(galleryBatchCards);
		} else if (filterOption === "voted") {
			setFilteredBatches(getVotedOrUnvotedEntries(true));
		} else if (filterOption === "notVoted") {
			setFilteredBatches(getVotedOrUnvotedEntries(false));
		}
	};

	const handleRoundInfoModal = () => {
		if (showInfoModal) {
			setModal({
				title: `${entrySetCard.roundType} Round`,
				message: (
					<div className="flex flex-col gap-[1rem] text-center max-w-[500px]">
						{entrySetCard.jurorInstructions || ""}
						{jurorViewCard?.juryResource && (
							<a
								className="text-colorActivation"
								href={
									process.env.REACT_APP_S3_HOST + jurorViewCard.juryResource
								}
								target="_blank"
							>
								View Resources
							</a>
						)}
					</div>
				),
				headerIcon: "info",
				onClickClose: () => setShowInfoModal(false),
			});
		}
	};

	const handleTipModal = () => {
		if (showTip) {
			setModal({
				title: `Judging Information`,
				message: (
					<div className="flex items-center gap-[.25rem]">
						<p>For judging criteria, tips, instructions & how-tos, click the</p>
						{
							<Icon
								icon="info"
								color={theme.colorPrimary}
								width="16px"
								height="16px"
							/>
						}
						<p> icon</p>
					</div>
				),
				headerIcon: "info",
				onClickClose: () => setShowTip(false),
			});
		}
	};

	useEffect(() => {
		setIsGrandOrWinnerRound(
			entrySetCard.roundType === "Grand" || entrySetCard.roundType === "Winner"
		);
	}, [entrySetCard.roundType]);

	useEffect(() => {
		// seems to be a race condition with the galleryBatchCards
		// when updateVote is called
		setTimeout(() => {
			handleFilter();
			setIsNoGrandSelected(
				isGrandOrWinnerRound &&
					galleryBatchCards
						.flatMap((x) => x.roundEntries)
						.flatMap((x) => x.votes)
						.every((x) => x.voteOption && x.voteOption.value === 0)
			);
		}, 100);
	}, [filterOption, galleryBatchCards, isGrandOrWinnerRound]);

	useEffect(() => {
		if (!localStorage.getItem("was_visited")) {
			localStorage.setItem("was_visited", "true");
			setShowTip(true);
		}
	}, []);

	useEffect(() => {
		handleTipModal();
	}, [showTip]);

	useEffect(() => {
		if (entrySetCard.id !== 0 && juryId) {
			switch (entrySetCard.roundStatus) {
				case JudgingRoundStatus.Completed:
				case JudgingRoundStatus.Pending:
				case JudgingRoundStatus.Pause:
				case JudgingRoundStatus.Ready:
					history.push(`/judge/${juryId}`);
					break;
				default:
					break;
			}
		}
	}, [juryId, entrySetCard.roundStatus, entrySetCard.id]);

	const [votesCast, setVotesCast] = useState(
		jurorViewCard?.entrySets.find((x) => x.id === Number(entrySetId))
			?.votesCast || 0
	);
	const [totalEntries, setTotalEntries] = useState(
		jurorViewCard?.entrySets.find((x) => x.id === Number(entrySetId))
			?.votesPossible || 0
	);
	const [entryProgress, setEntryProgress] = useState(0);

	useEffect(() => {
		setVotesCast(
			jurorViewCard?.entrySets.find((x) => x.id === Number(entrySetId))
				?.votesCast || 0
		);
		setTotalEntries(
			jurorViewCard?.entrySets.find((x) => x.id === Number(entrySetId))
				?.votesPossible || 0
		);
	}, [jurorViewCard]);

	useEffect(() => {
		setEntryProgress((votesCast / totalEntries) * 100);
	}, [votesCast, totalEntries]);

	useEffect(() => {
		setEntriesCollapsed(
			galleryBatchCards.reduce((acc: { [key: string]: boolean }, obj) => {
				acc[obj.label] = false;
				return acc;
			}, {})
		);
		setFilteredBatches(galleryBatchCards);
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		handleRoundInfoModal();
	}, [showInfoModal]);

	useEffect(() => {
		if (settings) {
			if (settings.isActive === true && settings.jurorControl === true) {
				setLiveJudgingJurorActive(true);
			} else {
				setLiveJudgingJurorActive(false);
			}
		}
	}, [settings.isActive, settings.jurorControl]);

	const renderNoGrandCard = () => {
		let card = <></>;

		// search for no grand vote properties and render 1 card for the no grand vote
		filteredBatches &&
			filteredBatches.some((batch) =>
				batch.roundEntries.some((roundEntryCard) => {
					let vote = roundEntryCard.votes.find((v) => v.jurorId === juror?.id);

					if (vote) {
						const voteOption =
							entrySetCard &&
							entrySetCard.voteOptions.find((vote) => vote.name === "No Grand");

						if (voteOption && voteOption.id) {
							card = (
								<StyledGalleryCard tint={false}>
									<div className="flex items-center justify-center w-full h-full">
										<Button
											className="w-[170px] button-dark-gray"
											disabled={
												entrySetCard.entriesLocked ||
												jurorViewCard?.entriesLocked
											}
											onClick={() =>
												updateVote(
													vote!.id,
													roundEntryCard.entrySetId,
													roundEntryCard.batchId,
													roundEntryCard.roundId,
													voteOption.id,
													entrySetCard.roundType
												)
											}
											iconRight={
												isNoGrandSelected &&
												!(
													entrySetCard.entriesLocked ||
													(jurorViewCard && jurorViewCard.entriesLocked)
												)
											}
											icon={
												entrySetCard.entriesLocked ||
												(jurorViewCard && jurorViewCard.entriesLocked)
													? "lock"
													: isNoGrandSelected
													? "check"
													: undefined
											}
										>
											{entrySetCard.entriesLocked ||
											(jurorViewCard && jurorViewCard.entriesLocked)
												? "Voting Locked"
												: isNoGrandSelected
												? "No Grand"
												: "Vote No Grand"}
										</Button>
									</div>
								</StyledGalleryCard>
							);

							return true;
						} else return false;
					} else return false;
				})
			);

		return card;
	};
	const getJudgingWelcomeText = (juryName?: string) => {
		return assetsConfig.copy.judgingWelcomeText.replace(
			"[JURY NAME]",
			juryName || ""
		);
	};

	return (
		<PageContainer>
			<BrowserHeader title={`Gallery: ${entrySetCard.name}`} />
			<JudgingGalleryNav
				onSelectSort={setFilterOption}
				roundName={entrySetCard.roundType}
				roundDeadline={entrySetCard.deadline!}
				entrySetId={entrySetId}
				entrySetCard={entrySetCard}
				jurorJuryCard={jurorViewCard}
				juryId={juryId}
				highlightedEntryUrl={highlightedEntryUrl}
				liveJudgingJurorActive={liveJudgingJurorActive}
				award={settings.award}
			/>

			<span
				className="fixed z-10 flex items-center justify-center bg-colorActivation bottom-[1.5rem] right-[1.5rem]  w-[50px] h-[50px] rounded-full cursor-pointer"
				onClick={() => setShowInfoModal(true)}
			>
				<Icon
					icon="info"
					color={theme.colorCopyLightLight}
					width="30px"
					height="30px"
				/>
			</span>

			<BatchDefModal
				programLevels={batchDefModal}
				onHide={() => setBatchDefModal(null)}
			/>

			<WelcomeBanner
				dangerouslySetInnerHTML={{
					__html: getJudgingWelcomeText(entrySetCard.name),
				}}
			></WelcomeBanner>
			<InnerContainer
				height={
					isGrandOrWinnerRound
						? "auto"
						: filteredBatches
						? String(
								// get rows (4 cards per row) to determine height
								filteredBatches.reduce(
									(acc, val) => (acc += Math.ceil(val.roundEntries.length / 4)),
									0
								) * 600
						  ) + "px"
						: "200vh"
				}
			>
				<JudgingGallerySideBar
					navLinks={
						filteredBatches && filteredBatches.length > 0
							? filteredBatches?.flatMap((batchCard) => {
									if (batchCard.label) {
										let shortenedLabel = batchCard.label.split(" > ");

										if (shortenedLabel && shortenedLabel.length > 1) {
											shortenedLabel.shift();

											return {
												label: shortenedLabel.join(" > "),
												path: `#batch${String(batchCard.id)}`,
											};
										} else return [];
									} else return [];
							  })
							: undefined
					}
					show={!isGrandOrWinnerRound}
				/>
				<EntriesContainer isGrandOrWinnerRound={isGrandOrWinnerRound}>
					{galleryBatchCards.length === 0 && (
						<Loading
							showLogo={false}
							fullScreen={false}
							text="Loading gallery"
						/>
					)}
					{filteredBatches &&
						filteredBatches.length > 0 &&
						filteredBatches.map((batchCard) => {
							if (batchCard.roundEntries.length > 0) {
								const batchLabels = splitBatchLabels(batchCard.label);

								const batchEntriesCompleted = batchCard.roundEntries.reduce(
									(roundsVoted, entry) => {
										let hasJurorVoted = entry.votes.find(
											(v) =>
												v.jurorId === juror?.id &&
												v.voteOption &&
												v.voteOptionId
										);

										return hasJurorVoted ? roundsVoted + 1 : roundsVoted;
									},
									0
								);
								const batchTotalEntries = batchCard.roundEntries.length;

								return !isGrandOrWinnerRound ? (
									<StyledStickyCard
										id={"batch" + String(batchCard.id)}
										key={`batch.${String(batchCard.id)}`}
										className="!max-h-fit"
										titleElement={
											<div
												className="flex items-center flex-wrap gap-[.5rem] cursor-pointer w-fit"
												onClick={() =>
													// slice program level
													setBatchDefModal(
														batchCard.batchHierarchyDefinitions.length >= 2
															? batchCard.batchHierarchyDefinitions.slice(1)
															: batchCard.batchHierarchyDefinitions
													)
												}
											>
												{batchLabels.map((label, i) => (
													<>
														<h2
															key={`batch.${String(batchCard.id)}.label.${i}`}
															className="whitespace-nowrap text-xSmallSize xl:text-pSize xxl:text-h2Size"
														>
															{label}
														</h2>
														{i !== batchLabels.length - 1 && (
															<StyledVerticalDivider
																key={`batch.${String(
																	batchCard.id
																)}.divider.${i}`}
															/>
														)}
													</>
												))}
											</div>
										}
										rightTitle={`${batchEntriesCompleted}/${batchTotalEntries} Entries Complete`}
										headerIcons={[
											<Icon
												icon="caret"
												color={theme.colorCopyLightLight}
												height="21px"
												width="21px"
												className="caret"
												rotation={
													entriesCollapsed[batchCard.label] ? "180deg" : "0"
												}
												onClick={() => handleAccordion(batchCard.label)}
											/>,
										]}
										maxHeight="initial"
									>
										<Collapsible
											isCollapsed={entriesCollapsed[batchCard.label]}
										>
											<StickyCardBody className="flex flex-col gap-[1.875rem] !p-[.75rem_1rem] sm:!p-[2.5rem_3rem]">
												<GalleryContainer>
													{batchCard.roundEntries &&
														batchCard.roundEntries.length > 0 &&
														batchCard.roundEntries.flatMap((roundEntryCard) => {
															let vote = roundEntryCard.votes.find(
																(v) => v.jurorId === juror?.id
															);

															if (vote) {
																return (
																	<GalleryCard
																		key={`batch.${String(
																			batchCard.id
																		)}.roundEntryCard.${roundEntryCard.id}`}
																		{...roundEntryCard}
																		entrySetCard={entrySetCard}
																		voteOptions={entrySetCard.voteOptions}
																		roundType={entrySetCard.roundType}
																		isFeedbackRequired={
																			entrySetCard.isFeedbackRequired
																		}
																		updateVote={updateVote}
																		galleryCards={galleryBatchCards}
																		vote={vote}
																		juryLocked={
																			jurorViewCard
																				? jurorViewCard.entriesLocked
																				: false
																		}
																		showPhysicalComponents={
																			settings.showPhysicalComponents
																		}
																	/>
																);
															} else return [];
														})}
												</GalleryContainer>
											</StickyCardBody>
										</Collapsible>
									</StyledStickyCard>
								) : (
									<>
										{batchCard.roundEntries &&
											batchCard.roundEntries.length > 0 &&
											batchCard.roundEntries.flatMap((roundEntryCard) => {
												let vote = roundEntryCard.votes.find(
													(v) => v.jurorId === juror?.id
												);

												if (vote) {
													return (
														<GalleryCard
															key={`roundEntryCard.${roundEntryCard.id}`}
															{...roundEntryCard}
															entrySetCard={entrySetCard}
															voteOptions={entrySetCard.voteOptions}
															roundType={entrySetCard.roundType}
															isFeedbackRequired={
																entrySetCard.isFeedbackRequired
															}
															updateVote={updateVote}
															galleryCards={galleryBatchCards}
															vote={vote}
															juryLocked={
																jurorViewCard
																	? jurorViewCard.entriesLocked
																	: false
															}
															showPhysicalComponents={
																settings.showPhysicalComponents
															}
														/>
													);
												} else return [];
											})}
									</>
								);
							} else return <></>;
						})}
					{renderNoGrandCard()}
				</EntriesContainer>
			</InnerContainer>
		</PageContainer>
	);
};

export interface GalleryCardData {
	company: string;
	description: string;
	image: string;
	isEntryLocked?: boolean;
	voteOption?: InOutVoteOptions;
	hasJurorVoted?: boolean;
}

interface GalleryContainerData {
	title: string;
	card: GalleryCardData[];
	programLevels: ProgramLevelDefinition[];
	showBreadcrumbs: boolean;
}

export enum InOutVoteOptions {
	In,
	Out,
	Abstain,
}

export default JudgingGallery;
