import {
  EntryMediaField,
  EntryTextField,
  EntryDateField,
  EntryListField,
  EntryLinkField,
  EntryIndividualCreditField,
  EntryCompanyCreditField,
  EntryPhysicalComponentField,
  ProgramModel,
} from "../Admin/Program/ProgramInterfaces";
import { MediaItem } from "../MediaLibrary/mediaLibrary.model.d";
import { CompanyModel } from "../Company/company.model";
import { DropdownOptions } from "../../components/FormFields/DropdownField";

export interface EntryModel {
  id: number;
  userId: string;
  companyId: number;
  programId: number;
  program: ProgramModel;
  paymentStatusId: number;
  reviewStatusId: number;
  title: string;
  brand: string;
  coverImage: CoverImageMediaJoin;
  executions: ExecutionModel[];
  isCampaign: boolean;
  awardLevel?: string;
  termsAndConditions: boolean;
  isValid: boolean;
  campaignFields: (
    | EntryMediaField
    | EntryTextField
    | EntryDateField
    | EntryListField
    | EntryLinkField
    | EntryPhysicalComponentField
  )[];
  creditFields: (EntryCompanyCreditField | EntryIndividualCreditField)[];
  createdDate?: string;
  createdBy?: string;
  updatedDate?: string;
  modifiedBy?: string;
  modifiedByName?: string;
  orderDate?: string;
  orderId?: number;
  company?: CompanyModel;
  revisionNote?: string;
  rootProgramId: number;
  parentEntry?: BasicEntryModel;
  childEntries?: BasicEntryModel[];
  hasPhysicalComponent: boolean;
  physicalItemDescription?: string;
  physicalAssetStatus: PhysicalAssetStatus;
  reviewerId?: string;
  reviewer?: string;
  isChatLocked: boolean;
}

export interface BasicEntryModel {
  id: number;
  title?: string;
}

export interface Audit {
  fieldChanged: string;
  oldValues?: string;
  newValues: string;
  revisedBy: string;
  nameRevisedBy: string;
  createdDate: Date;
  action: string;
}

export interface CoverImageMediaJoin {
  entryId: number;
  mediaId?: string;
  mediaItem?: MediaItem;
}

export interface ExecutionModel {
  id: number;
  programId: number;
  entryModelId: number;
  title: string;
  index: number;
  program: ProgramModel;
  executionFields: (
    | EntryMediaField
    | EntryTextField
    | EntryDateField
    | EntryListField
    | EntryLinkField
    | EntryPhysicalComponentField
  )[];
}
export enum PhysicalAssetStatus {
  NoPhysical,
  NotReceived,
  InReview,
  OnHold,
  Failed,
  Withdrawn,
  Approved,
}

export const PhysicalAssetStatusOptions: DropdownOptions[] = [
  { value: PhysicalAssetStatus.NoPhysical, label: "No Physical" },
  { value: PhysicalAssetStatus.NotReceived, label: "Not Received" },
  { value: PhysicalAssetStatus.InReview, label: "In Review" },
  {
    value: PhysicalAssetStatus.OnHold,
    label: "On Hold",
  },
  { value: PhysicalAssetStatus.Failed, label: "Failed" },
  { value: PhysicalAssetStatus.Withdrawn, label: "Withdrawn" },
  { value: PhysicalAssetStatus.Approved, label: "Approved" },
];
