import styled, { useTheme } from "styled-components";
import Icon from "../../../components/Icon/Icon";
import Modal, { ModalCard } from "../../../components/Modal/Modal";
import TextField from "../../../components/FormFields/TextField";
import assetsConfig from "../../../assetsConfig";
import Button from "../../../components/Button/Button";
import {
  EntryNote,
  EntryNoteType,
} from "../../Judging/EntryDetail/EntryDetail";
import { NemoUser } from "../../../hooks/useCurrentUser";
import { css } from "styled-components";
import { useAlert } from "../../../components/Alert/Alerts";
import { useEffect, useMemo, useRef, useState } from "react";
import EditMenu from "../../../components/EditMenu/EditMenu";
import EditReviewNote from "./EditReviewNote";
import { useFormikContext } from "formik";
import { AdminEntryModel } from "../../Entries/EditEntry";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import {
  UpdatePropertyRequest,
  updateEntryProperty,
  markNotificationsAsRead,
} from "../../Entries/manageEntry";
import { DateTime } from "luxon";
import { CSVLink } from "react-csv";

const MessageBubble = styled.div<{
  alignRight: boolean;
  isOwnMessage?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .note {
    padding: 1rem;
    background: ${({ theme }) => theme.colorBackgroundLight};
    width: fit-content;
    white-space: pre-wrap;
  }

  .meta {
    margin-left: 0.5rem;
    color: ${({ theme }) => theme.colorCopyLight};
    font-style: italic;
    font-size: ${({ theme }) => theme.xSmallSize};
  }

  ${(p) =>
    p.alignRight &&
    css`
      align-items: flex-end;

      .note {
        background: ${({ theme }) => theme.colorBorderLight};
      }
    `}

  ${(p) =>
    p.isOwnMessage &&
    css`
      align-items: flex-end;

      .note {
        background: ${({ theme }) => theme.colorActivationDark};
        color: ${({ theme }) => theme.colorCopyLightLight};
      }
    `}
`;

const ScrollableMessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1.875rem;
  display: flex;
  flex-direction: column;
  gap: 1.5625rem;
  min-height: 300px;
  position: relative;
`;

const StickySection = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colorBackgroundLightLight};
  border-top: solid 2px ${({ theme }) => theme.colorBorderLight};
  border-bottom: solid 2px ${({ theme }) => theme.colorBorderLight};
`;

const StyledModalCard = styled(ModalCard)<{ disabled?: boolean }>`
  ${(p) =>
    p.disabled &&
    css`
      &,
      ${StickySection} {
        background: ${({ theme }) => theme.colorFieldReadOnly};
      }
    `}
`;

// generic modal used for messages and reviewer notes
const MessagingModal = (props: MessagingModalProps) => {
  const theme = useTheme();
  const { addNewAlert } = useAlert();
  const [editNote, setEditNote] = useState<null | EntryNote>(null);
  const { values } = useFormikContext<AdminEntryModel>();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const getLocalTime = (date: string) => {
    // Treat the input string as UTC
    const utcDate = DateTime.fromISO(date, { zone: "utc" });

    // Convert to the local time zone
    return utcDate
      .setZone(DateTime.local().zoneName ?? undefined)
      .toLocaleString(DateTime.DATETIME_SHORT);
  };

  // Scroll to bottom whenever messages change
  // useEffect(() => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [props.messages, props.showModal]);

  // Trigger scroll to bottom with a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 200); // Adjust delay as needed
    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [props.messages, props.showModal]); // No dependencies, runs only on mount

  useEffect(() => {
    if (
      props.showModal &&
      props.modalType == EntryNoteType.ReviewerToSubmitter
    ) {
      const markNotifications = async () => {
        if (values.id) {
          await markNotificationsAsRead(values.id);
        }
      };

      markNotifications();
    }
  }, [props.showModal, props.modalType]);

  const noNotesText = useMemo(() => {
    switch (props.modalType) {
      case EntryNoteType.Juror:
        return "There are no juror notes available.";
      case EntryNoteType.Reviewer:
        return "There are no reviewer notes available.";
      case EntryNoteType.ReviewerToSubmitter:
        return "Please preview your message. It will be sent to all submitters when you click Send below.";
      case EntryNoteType.Feedback:
        return "There are no feedback notes available.";
      default:
        return "";
    }
  }, [props.modalType]);

  const titleText = useMemo(() => {
    switch (props.modalType) {
      case EntryNoteType.Juror:
        return "Juror Notes";
      case EntryNoteType.Reviewer:
        return "Reviewer Notes";
      case EntryNoteType.ReviewerToSubmitter:
        return props.disabled ? "Resolved Messages" : "Messages";
      case EntryNoteType.Feedback:
        return assetsConfig.labels.jurorFeedback.singular;
      default:
        return "Entry Notes";
    }
  }, [props.modalType, props.disabled]);

  const handleSaveReviewNote = () => {
    if (
      props.draftMessage &&
      props.draftMessage.note &&
      props.draftMessage.note != ""
    ) {
      props
        .sendDraftMessage(props.draftMessage)
        .then((res: any) => {
          addNewAlert({
            type: "success",
            message: "Successfully saved draft",
          });
          props.setShowModal(false);
          props.onClose();
        })
        .catch((e: any) =>
          addNewAlert({
            type: "error",
            message: e.message,
          })
        );
    } else {
      props.setShowModal(false);
      props.onClose();
    }
  };

  return (
    <Modal
      show={props.showModal && props.isConnected}
      className="overflow-visible"
    >
      <StyledModalCard
        className="overflow-visible"
        title={titleText}
        headerIcons={
          <Icon
            icon="close"
            color={theme.colorPrimary}
            onClick={() => {
              handleSaveReviewNote();
            }}
            width="30px"
            height="30px"
          />
        }
        disabled={props.disabled}
      >
        {props.currentUser &&
          (props.currentUser.isAdmin || props.currentUser.isEntryReviewer) && (
            <StickySection className="flex items-center gap-[1.875rem] flex-wrap p-[.5rem]">
              <>
                <CSVLink
                  className="no-underline text-colorCopyLightLight"
                  data={props.messages.map((note) => {
                    return {
                      Name: note.nameCreatedBy,
                      Message: note.note,
                      Timestamp: note.createdDate,
                    };
                  })}
                  filename={`Chat-${values.id}.csv`}
                >
                  <Button
                    disabled={!props.messages || props.messages.length == 0}
                    className="button-light h-[20px] w-[75px]"
                  >
                    Export to CSV
                  </Button>
                </CSVLink>
                {props.modalType === EntryNoteType.ReviewerToSubmitter && (
                  <ToggleSwitchField
                    name="isChatLocked"
                    className="ml-auto"
                    label="Messages Resolved"
                    checked={values.isChatLocked}
                    small
                    id={"isChatLocked"}
                    onChange={(e) => {
                      if (e.target.checked !== values.isChatLocked) {
                        const request: UpdatePropertyRequest = {
                          id: values.id,
                          propertyName: "isChatLocked",
                          propertyValue: e.target.checked,
                        };
                        const response = updateEntryProperty(request);

                        response
                          .then(() =>
                            addNewAlert({
                              type: "success",
                              message: "Successfully saved resolved status",
                            })
                          )
                          .catch(() =>
                            addNewAlert({
                              type: "error",
                              message: "Autosave failed",
                            })
                          );
                      }
                    }}
                  />
                )}
              </>
            </StickySection>
          )}
        <ScrollableMessagesContainer className="flex flex-col py-[2.625rem] px-[1.875rem] gap-[1.5625rem] min-h-[300px]">
          {props.messages && props.messages.length > 0 ? (
            <>
              {props.messages.map((reviewerNote) => {
                return (
                  (!reviewerNote.isPrivate ||
                    reviewerNote.createdBy !== props.currentUser.id) && (
                    <MessageBubble
                      key={reviewerNote.id}
                      alignRight={
                        props.currentUser.isEntryReviewer &&
                        reviewerNote.type === EntryNoteType.Reviewer
                      }
                      isOwnMessage={
                        props.currentUser.id === reviewerNote.createdBy
                      }
                    >
                      <div className="flex items-center">
                        {reviewerNote.createdBy === props.currentUser.id && (
                          <EditMenu
                            options={[
                              {
                                label: "Edit",
                                action: () => {
                                  reviewerNote.note &&
                                    setEditNote(reviewerNote);
                                },
                              },
                              {
                                label: "Delete",
                                action: () => {
                                  props.deleteMessage(reviewerNote.id);
                                },
                              },
                            ]}
                            disabled={props.disabled}
                          />
                        )}

                        <div className="note">{reviewerNote.note}</div>
                      </div>

                      <div className="meta">
                        {reviewerNote.nameCreatedBy},{" "}
                        {reviewerNote.updatedDate &&
                        reviewerNote.updatedDate != reviewerNote.createdDate ? (
                          "Edited " + getLocalTime(reviewerNote.updatedDate)
                        ) : reviewerNote.createdDate ? (
                          getLocalTime(reviewerNote.createdDate)
                        ) : (
                          <></>
                        )}
                      </div>
                    </MessageBubble>
                  )
                );
              })}
              {/* Add a ref to this empty div to scroll to it */}
              <div ref={messagesEndRef} />
            </>
          ) : (
            <p>{noNotesText}</p>
          )}
        </ScrollableMessagesContainer>
        <StickySection className="flex items-center gap-[1.875rem] flex-wrap p-[1.875rem]">
          {props.disabled ? (
            <p
              dangerouslySetInnerHTML={{
                __html: assetsConfig.copy.disabledMessageModalText,
              }}
            ></p>
          ) : editNote ? (
            <>
              <div className="flex-1 min-w-[320px]">
                <EditReviewNote note={editNote} setEditNote={setEditNote} />
              </div>
              <div className="flex flex-col gap-[.5rem] ml-auto">
                <Button
                  className="button-light"
                  onClick={() => setEditNote(null)}
                  icon="close"
                  iconColor={theme.colorCopyDarkDark}
                  iconSize="15px"
                >
                  Discard Changes
                </Button>
                <Button
                  onClick={() => {
                    props.updateMessage(editNote);
                    setEditNote(null);
                  }}
                  iconLeft="check"
                  iconColor={theme.colorCopyLightLight}
                  iconSize="12px"
                >
                  Save Changes
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="flex-1 min-w-[320px]">
                <TextField
                  className="flex-1"
                  name="entryReviewNote"
                  placeholder={
                    props.modalType === EntryNoteType.ReviewerToSubmitter
                      ? "Send Message"
                      : `Add ${
                          props.modalType
                            ? EntryNoteType[props.modalType]
                            : "Entry"
                        } Note`
                  }
                  component="textarea"
                  height="100px"
                  value={
                    props.draftMessage &&
                    props.draftMessage.note &&
                    props.draftMessage.isPrivate
                      ? props.draftMessage.note
                      : undefined
                  }
                  onChange={(e) => {
                    props.setDraftMessage({
                      ...props.draftMessage,
                      note: e.target.value,
                    });
                  }}
                />
              </div>
              <Button
                className="ml-auto"
                onClick={() => {
                  props.sendDraftMessage({
                    ...props.draftMessage,
                    isPrivate: false,
                  });
                  props.setDraftMessage(props.blankReviewNote);
                }}
                disabled={
                  !props.draftMessage ||
                  (props.draftMessage && !props.draftMessage.note)
                }
              >
                Send
              </Button>
            </>
          )}
        </StickySection>
      </StyledModalCard>
    </Modal>
  );
};

export default MessagingModal;

interface MessagingModalProps {
  modalType: EntryNoteType | null;
  currentUser: NemoUser;
  showModal: boolean;
  onClose(): void;
  setShowModal(show: boolean): void;
  disabled?: boolean;
  messages: Array<EntryNote>;
  draftMessage: EntryNote;
  blankReviewNote: EntryNote;
  setDraftMessage: React.Dispatch<React.SetStateAction<EntryNote>>;
  sendDraftMessage: (newEntryNote: EntryNote) => Promise<void>;
  updateMessage: (updatedNote: Partial<EntryNote>) => Promise<void>;
  deleteMessage: (entryNoteId: number) => Promise<void>;
  isConnected: boolean;
}
