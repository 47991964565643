import {
	NavTab,
	NavTabList,
	NavTabPanel,
	NavTabs,
} from "../../../components/NavTabs/NavTabs";
import { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import Button from "../../../components/Button/Button";
import StickyCard, {
	StickyCardBody,
} from "../../../components/StickyCard/StickyCard";
import { useTheme } from "styled-components";
import SearchBar from "../../../components/SearchBar/SearchBar";
import { IndividualCredit, CompanyCredit } from "../Credits/CreditInterfaces";
import { useFormik, FormikProvider } from "formik";
import { CreditType } from "../Program/ProgramInterfaces";
import { AdminEntryModel } from "../../Entries/EditEntry";
import { getMediaSrc } from "../../MediaLibrary/manageMediaLibrary";
import {
	getActiveCompanyCredits,
	getActiveIndividualCredits,
} from "../Credits/manageCreditLibrary";
import CreditFields from "../../Entries/CreditFields";
import { AxiosResponse } from "axios";

import {
	CreditCheckContext,
	ToggleMobileCreditLibContext,
} from "../../Entries/EntryForm";
import { AdminEditPageContainer, getAdminHierarchy } from "./AdminEntryEdit";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import { getClaims } from "../../../components/Auth/handleJWT";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { sortEntryFields } from "../../Entries/manageEntry";
import NewCreditLibrarySticky from "../Credits/CreditLibrary/NewCreditLibrarySticky";
import assetsConfig from "../../../assetsConfig";
import { useAlert } from "../../../components/Alert/Alerts";

const AdminCreditEdit = (props: AdminEntryModel) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { companyId } = props;
	const claims = getClaims();
	const isAdmin = claims.some(
		(claim) => claim.name === "role" && claim.value === "admin"
	);
	const [tabIndex, setTabIndex] = useState(0);

	const formikProps = useFormik({
		initialValues: props,
		onSubmit: () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const [companyCreditsArray, setCompanyCreditsArray] = useState<
		CompanyCredit[]
	>([]);

	const [individualCreditsArray, setIndividualCreditsArray] = useState<
		IndividualCredit[]
	>([]);
	const [creditSearchVal, setCreditSearchval] = useState("");
	const [resetCreditCheck, setResetCreditCheck] = useState(false);
	const [creditSelect, setCreditSelect] = useState(null);
	const [hierarchy, setHierarchy] = useState<string[]>([]);

	useEffect(() => {
		setHierarchy(
			getAdminHierarchy(
				props.executions!,
				props.program.ancestry!,
				props.isCampaign!
			)
		);
	}, []);

	useEffect(() => {
		getActiveCompanyCredits(companyId).then(
			(response: AxiosResponse<CompanyCredit[], any>) => {
				if (response.status === 200) {
					setCompanyCreditsArray(response.data);
				}
			}
		);

		getActiveIndividualCredits(companyId).then(
			(response: AxiosResponse<IndividualCredit[], any>) => {
				if (response.status === 200) {
					setIndividualCreditsArray(response.data);
				}
			}
		);
	}, []);

	return (
		<CreditCheckContext.Provider
			value={{ resetCreditCheck, setResetCreditCheck }}
		>
			<ToggleMobileCreditLibContext.Provider
				value={{ creditSelect, setCreditSelect }}
			>
				<BrowserHeader
					title={`Edit Credits: [${props.id}] ${
						props.title ? props.title : ""
					}`}
				/>
				<FormikProvider value={formikProps}>
					<AdminEditPageContainer
						entryDetails={{
							eid: formikProps.values.id,
							coverImgSrc: formikProps.values.coverImage.mediaItem
								? getMediaSrc(formikProps.values.coverImage.mediaItem)
								: ImagePlaceholder,
							title: formikProps.values.title,
							brand: formikProps.values.brand,
							author: formikProps.values.company?.name || "",
							awardLevel: formikProps.values.awardLevel,
							ancestry: hierarchy,
							reviewStatusId: formikProps.values.reviewStatusId,
							paymentStatusId: formikProps.values.paymentStatusId,
						}}
					>
						<StickyCard
							className="!relative !top-0 !max-h-full"
							title="Credits"
						>
							<StickyCardBody className="flex flex-col gap-[2rem]">
								<CreditFields
									name={"creditFields"}
									isAdmin={true}
									fieldArray={sortEntryFields(formikProps.values.creditFields)}
								/>
							</StickyCardBody>
						</StickyCard>

						<NewCreditLibrarySticky
							className="!top-[4rem] side-sticky xl:!min-w-[500px] xxl:!min-w-[600px]"
							companyId={companyId}
							companyCreditArr={companyCreditsArray}
							indCreditArr={individualCreditsArray}
							setCompanyCreditsArray={setCompanyCreditsArray}
							setIndividualCreditsArray={setIndividualCreditsArray}
							allowMultiselect
						/>
					</AdminEditPageContainer>
				</FormikProvider>
			</ToggleMobileCreditLibContext.Provider>
		</CreditCheckContext.Provider>
	);
};

export default AdminCreditEdit;
