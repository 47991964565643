import {
	EntryLinkField,
	EntryListField,
	EntryMediaField,
	EntryPhysicalComponentField,
	EntryTextField,
	EntryDateField,
	ProgramTextField,
	TextType,
	ListType,
	DateType,
	EntryMediaJoin,
} from "../Program/ProgramInterfaces";
import {
	isAProgramDateField,
	isAProgramListField,
	isAProgramLinkField,
	isAProgramMediaField,
	isAProgramPhysicalComponentField,
	isAProgramTextField,
} from "../../Entries/DynamicFields";
import styled, { useTheme } from "styled-components";
import React, { useState, useContext, memo } from "react";
import Icon from "../../../components/Icon/Icon";
import TextField from "../../../components/FormFields/TextField";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import Button from "../../../components/Button/Button";
import RadioField, {
	ListContainer,
} from "../../../components/FormFields/RadioField";
import EntryListFieldForm from "../../Entries/Fields/EntryListFieldForm";
import DateFieldForm from "../Program/FieldTemplateForms/DateFieldForm";
import EntryDateFieldForm from "../../Entries/Fields/EntryDateFieldForm";
import { useEffect } from "react";
import EntryTextFieldForm from "../../Entries/Fields/EntryTextFieldForm";
import EntryLinkFieldForm from "../../Entries/Fields/EntryLinkFieldForm";
import { RenderLinkField } from "../../Judging/EntryDetail/EntryDetail";
import EntryPhysicalComponentFieldForm from "../../Entries/Fields/EntryPhysicalComponentFieldForm";
import InfoPopup, {
	ThumbnailPopup,
} from "../../../components/InfoPopup/InfoPopup";
import EntryMediaFieldForm from "../../Entries/Fields/EntryMediaFieldForm";
import { useLocation } from "react-router-dom";
import { MediaItem, MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import {
	getMediaSrc,
	getThumbnailSrc,
	getThumbnailSrcNested,
} from "../../MediaLibrary/manageMediaLibrary";
import MediaLightBox from "../../Judging/EntryDetail/MediaLightbox";
import { updateEntryField } from "../../Entries/manageEntry";
import { AxiosResponse } from "axios";

import VideoPlaceholder from "../../../assets/placeholders/video-landscape.png";
import { getClaims } from "../../../components/Auth/handleJWT";
import useDownloadMedia from "../../../utils/useDownloadMedia";
import { DownloadMediaButton } from "../../../components/DownloadMedia/DownloadMedia";
import { createPortal } from "react-dom";
import HideMediaButton from "../../../components/HideMediaButton/HideMediaButton";
import { approveFlagMedia } from "../../../views/Entries/manageEntry";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import DraggableMediaList, {
	DragMediaItem,
} from "../../../components/Draggable/DraggableMedia";
import { useAlert } from "../../../components/Alert/Alerts";
import { getLocalTime } from "../../../utils/timeUtilities";
import Authorized from "../../../components/Auth/Authorized";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import parse from "html-react-parser";
import useTextWrap from "../../../hooks/useTextWrap";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import ErrorWrapper from "./ErrorWrapper";

const ToggleField = styled.div<{ show?: boolean }>`
	${({ show }) => (show ? "" : "display: none;")}
`;

export const ErrorText = styled.span`
	color: ${({ theme }) => theme.colorDanger};
`;

const StyledEntryFieldDisplay = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const IconContainers = styled.div`
	display: grid;
	grid-template-columns: 30px 30px 30px 30px;
	gap: 0.5rem;
	justify-content: flex-start;
	align-items: center;
	margin-top: 1rem;
`;

const EntryFieldDisplay = (props: {
	onSave(): Promise<AxiosResponse<any, any>>;
	onDiscard(): void;
	field:
		| EntryTextField
		| EntryDateField
		| EntryListField
		| EntryLinkField
		| EntryPhysicalComponentField
		| EntryMediaField;
	form: React.ReactNode;
	display: React.ReactNode;
	name: string;
}) => {
	const theme = useTheme();
	const location = useLocation();
	const { errors, setFieldValue } = useFormikContext<any>();
	const [isEditing, setIsEditing] = useState(false);
	const isMediaField = props.field.hasOwnProperty("mediaItems");
	const params = new URLSearchParams(location.search);
	const entryId = params.get("entryId");

	const isRequired = () => {
		// all fields except media field, which doesn't have "isRequired" prop
		if (props.field.programField.hasOwnProperty("isRequired")) {
			// @ts-ignore
			return props.field.programField.isRequired;
		}
		// list field
		else if (props.field.programField.hasOwnProperty("minQuantity")) {
			return (props.field as EntryListField).programField.minQuantity > 0;
		} else return false;
	};

	const asterisk = isRequired() ? "*" : "";
	const { addNewAlert } = useAlert();

	return (
		<>
			<StyledEntryFieldDisplay>
				<div className="flex items-center gap-[.5rem]">
					<h3 className="mr-[1rem]">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<InfoPopup alt="Admin Help Text" iconSize="20px">
						{props.field.programField.adminHelpText}
					</InfoPopup>
					<InfoPopup icon="info2" alt="Entrant Help Text" iconSize="21px">
						{props.field.programField.entrantHelpText}
					</InfoPopup>

					<Icon
						icon="edit"
						color={theme.colorPrimary}
						width="20px"
						height="20px"
						id={`editIcon-${props.name}`}
						onClick={() => {
							isMediaField
								? window.open(
										`/admin/edit-entry/media?entryId=${entryId}`,
										"_blank"
								  )
								: setIsEditing(!isEditing);
						}}
					/>
				</div>
				<ToggleField show={isEditing}>
					{props.form}

					<div
						//               className={`flex gap-[1rem]
						//   ${errors && Object.values(errors).length > 0 ? "mt-[3rem]" : ""}
						//   `}
						className={`flex gap-[1rem] mt-[1.5rem]`}
					>
						<Button
							className="button-light w-[150px]"
							icon="close"
							iconSize="18px"
							iconColor={theme.colorCopyDarkDark}
							onClick={() => {
								// formikProps.resetForm();
								props.onDiscard();
								setIsEditing(false);
							}}
						>
							Discard
						</Button>
						<Button
							className="w-[150px]"
							icon="check"
							onClick={() =>
								props
									.onSave()
									.then((resp) => {
										if (resp.status === 200) {
											addNewAlert({
												type: "success",
												message: "saved successfully",
											});
											setFieldValue(props.name, resp.data);
											setIsEditing(false);
										} else {
											addNewAlert({
												type: "error",
												message: "Failed to save.",
											});
										}
									})
									.catch((error: any) => {
										console.log("Failed to save Field: ", error);

										addNewAlert({
											type: "error",
											message: "Failed to save.",
										});
									})
							}
							//   disabled={formikProps.isSubmitting}
						>
							Save
						</Button>
					</div>
				</ToggleField>
				{!isEditing && (
					<div className="ml-[1rem] whitespace-pre-wrap">{props.display}</div>
				)}
			</StyledEntryFieldDisplay>
		</>
	);
};

const MediaFieldContainer = styled.div`
	display: flex;
	gap: 1rem;
	.img-container {
		flex: 0 0 200px;
		height: 112.5px;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.img-tint {
			filter: brightness(0.2);
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.md}) {
		flex-wrap: wrap;
	}
`;

const ThumbnailCard = memo(
	(props: {
		entryId: string;
		media: MediaItem;
		setMediaModal: React.Dispatch<React.SetStateAction<MediaItem | null>>;
		toggleApproveFlagMedia(
			entryId: number,
			mediaId: string,
			approved: boolean
		): void;
		fieldId: string;
	}) => {
		const { settings, loading } = useSelector(
			(state: RootState) => state.settings
		);

		const theme = useTheme();
		const { media, setMediaModal, toggleApproveFlagMedia, entryId } = props;
		const [mediaSrc, setMediaSrc] = useState<string | undefined>();
		const [isConversionError, setIsConversionError] = useState(false);

		const handleMediaSrc = async () => {
			if (media) {
				const result = await getThumbnailSrcNested(media);
				setMediaSrc(result);
			}
		};

		useEffect(() => {
			handleMediaSrc();
		}, [media]);

		useEffect(() => {
			if (mediaSrc) {
				setIsConversionError(mediaSrc === VideoPlaceholder);
			}
		}, [mediaSrc]);

		return (
			<MediaFieldContainer>
				<ThumbnailPopup alt={isConversionError ? "Conversion Error" : ""}>
					<div className="img-container relative">
						{isConversionError && (
							<Icon
								className="absolute  top-1/2 left-1/2 transform z-10 -translate-x-1/2 -translate-y-1/2"
								icon="invalid"
								width="55px"
								height="55px"
								color={theme.colorPrimary}
							/>
						)}

						<img
							className={`!w-[200px] max-w-[initial] ${
								isConversionError ? "img-tint" : ""
							}`}
							src={mediaSrc}
							alt={media.fileName}
						/>
					</div>
				</ThumbnailPopup>

				<div className="flex flex-col flex-start">
					<b className="mb-[1rem] break-all">{media.fileName}</b>
					<Authorized
						settings={settings}
						loading={loading}
						feature="Display Hide Media"
						authorized={
							<HideMediaButton
								id={`field.${props.fieldId}.media.${media.id!}`}
								hideForWinnersGallery={media.hideForWinnersGallery}
								mediaId={media.id!}
								useToggleSwitch
							/>
						}
					/>
					<IconContainers>
						<Icon
							icon="expand-arrows"
							color={theme.colorPrimary}
							onClick={() => setMediaModal(media)}
						/>
						<DownloadMediaButton fileName={media.fileName} path={media.path} />

						<Icon
							icon={media.isApproved === true ? "liked" : "like"}
							color={theme.colorPrimary}
							height="18px"
							width="18px"
							alt="Approve"
							onClick={() => toggleApproveFlagMedia(+entryId!, media.id!, true)}
						/>
						<Icon
							icon={media.isApproved === false ? "flagged" : "flag"}
							color={theme.colorPrimary}
							height="18px"
							width="18px"
							alt="Flag"
							onClick={() =>
								toggleApproveFlagMedia(+entryId!, media.id!, false)
							}
						/>
						<span
							hidden={media.approvedFlaggedByUser == undefined}
							className="text-xSmallSize flex items-center gap-[.5rem] text-colorCopyLight w-max"
						>
							<Icon
								icon="edit-user"
								color={theme.colorCopyLight}
								defaultCursor
							/>
							{media.approvedFlaggedByUser}
							<p className="text-xSmallSize">{" - Eid:"}</p>
							<a
								href={`/admin/edit-entry?entryId=${media.approvedFlaggedOnEntryId}`}
								target="_blank"
								rel="noreferrer"
								className="text-xSmallSize"
							>
								{media.approvedFlaggedOnEntryId}
							</a>
						</span>
					</IconContainers>
				</div>
			</MediaFieldContainer>
		);
	}
);

const MediaFieldDisplay = (props: MediaFieldDisplayProps) => {
	const theme = useTheme();
	const claims = getClaims();
	const [mediaModal, setMediaModal] = useState<MediaItem | null>(null);
	const params = new URLSearchParams(window.location.search);
	const entryId = params.get("entryId");
	const { addNewAlert } = useAlert();
	const [propsMediaItems, setPropsMediaItems] = useState<MediaItem[]>(
		props.mediaItems
	);
	const { user } = useCurrentUser();

	const toggleApproveFlagMedia = (
		entryId: number,
		mediaId: string,
		approved: boolean
	) => {
		approveFlagMedia(entryId, mediaId, approved)
			.then((res) => {
				if (res.status === 200) {
					addNewAlert({
						type: "success",
						message: `Media ${approved ? "Approved" : "Flagged"} successfully!`,
					});

					const mediaToUpdate = propsMediaItems.find((i) => i.id === mediaId);
					mediaToUpdate!.isApproved = approved;
					mediaToUpdate!.approvedFlaggedOnEntryId = entryId;
					mediaToUpdate!.approvedFlaggedByUser = user.fullName;

					const updatedItems = propsMediaItems.map((item) =>
						item.id === mediaId ? { ...item, ...mediaToUpdate } : item
					);

					setPropsMediaItems(updatedItems);
				} else {
					addNewAlert({
						type: "error",
						message: "Problem Approving or Flagging  Media",
					});
				}
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: "Problem Approving or Flagging Media",
				});
			});
	};

	const minError =
		props.min > 0 && props.mediaItems.length === 0
			? `At least ${props.min} file${props.min > 1 ? "s are" : " is"} required.`
			: null;

	const maxError =
		props.max && props.mediaItems.length > props.max
			? `This field is not valid.`
			: null;

	return (
		<div className="relative">
			{createPortal(
				<MediaLightBox
					show={mediaModal !== null}
					selectedMediaPreview={mediaModal}
					onClickHide={() => setMediaModal(null)}
					isZoomEnabled
					hideCarousel
				/>,
				document.body
			)}

			<div className="flex items-center gap-[.5rem] mb-[1rem]">
				<h3 className="mr-[1rem]">
					{props.fieldTitle}
					{props.min > 0 ? "*" : ""}
				</h3>
				<InfoPopup alt="Admin Help Text" iconSize="20px">
					{props.adminHelpText}
				</InfoPopup>
				<InfoPopup icon="info2" alt="Entrant Help Text" iconSize="21px">
					{props.entrantHelpText}
				</InfoPopup>
			</div>
			<div className="flex flex-col gap-[1rem]">
				{entryId &&
					propsMediaItems.map((media, i) => {
						return (
							<ThumbnailCard
								fieldId={props.fieldId}
								entryId={entryId}
								media={media}
								setMediaModal={setMediaModal}
								toggleApproveFlagMedia={toggleApproveFlagMedia}
							/>
						);
					})}
			</div>
			{(minError || maxError) && <ErrorText>{minError || maxError}</ErrorText>}
		</div>
	);
};

export const CampaignFieldDisplay = (props: {
	onSave(): Promise<AxiosResponse<any, any>>;
	onSaveSuccessCallback?(response: AxiosResponse): void;
	onDiscard(): void;
	value?: string | boolean;
	name?: string;
	id?: string;
	subText?: React.ReactNode;
	//   label: string;
	display: React.ReactNode;
	form: React.ReactNode;
	readOnly?: boolean;
}) => {
	const theme = useTheme();
	const [isEditing, setIsEditing] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { addNewAlert } = useAlert();

	return (
		<StyledEntryFieldDisplay>
			{!isEditing && (
				<div className="flex flex-col gap-[.5rem]">
					<div className="flex gap-[.5rem] whitespace-pre-wrap">
						{props.display}
						{(props.readOnly === undefined || props?.readOnly === false) && (
							<Icon
								icon="edit"
								color={theme.colorPrimary}
								width="20px"
								id={`editIcon-${props.name || props.id}`}
								height="20px"
								onClick={() => setIsEditing(!isEditing)}
							/>
						)}
					</div>
					{props.subText}
				</div>
			)}

			{isEditing && (
				<>
					{props.form}
					<div className={`flex gap-[1rem] mt-[1.5rem]`}>
						<Button
							className="button-light w-[150px]"
							icon="close"
							iconSize="18px"
							iconColor={theme.colorCopyDarkDark}
							onClick={() => {
								props.onDiscard();
								setIsEditing(false);
							}}
						>
							Discard
						</Button>
						<Button
							className="w-[150px]"
							icon={isLoading ? "loading" : "check"}
							onClick={() => {
								setIsLoading(true);
								props
									.onSave()
									.then((resp) => {
										if (resp.status === 200) {
											addNewAlert({
												type: "success",
												message: "saved successfully",
											});
											setIsEditing(false);
											setIsLoading(false);

											if (props.onSaveSuccessCallback) {
												props.onSaveSuccessCallback(resp);
											}
										} else {
											setIsLoading(false);
											addNewAlert({
												type: "error",
												message: "Failed to save.",
											});
										}
									})
									.catch((error: any) => {
										console.log("Failed to save Field: ", error);
										setIsLoading(false);
										addNewAlert({
											type: "error",
											message: "Failed to save.",
										});
									});
							}}
							disabled={isLoading}
						>
							Save
						</Button>
					</div>
				</>
			)}
		</StyledEntryFieldDisplay>
	);
};

export const RenderAdminEntryFields = (props: RenderAdminEntryFieldProps) => {
	const { values, setFieldValue } = useFormikContext<any>();
	const { addNewAlert } = useAlert();
	const { wrapText } = useTextWrap();

	const AddToMediaField = (
		uploaded: any,
		mediaField: EntryMediaField,
		name: string,
		libraryChanged: boolean = false
	) => {
		let joins: EntryMediaJoin[] = [];
		// push a new EntryMediaJoin to field for each uploaded media item
		if (Array.isArray(uploaded)) {
			joins = uploaded.map((item) => {
				return {
					fieldId: mediaField.id,
					id: 0,
					mediaId: item.id,
					mediaItem: item,
					weight: mediaField.mediaItems.length,
					entryId: mediaField.entryId,
				};
			});
		} else {
			joins.push({
				fieldId: mediaField.id,
				id: 0,
				mediaId: uploaded.id,
				mediaItem: uploaded,
				weight: mediaField.mediaItems.length,
				entryId: mediaField.entryId,
			});
		}
		const newMediafield = {
			...mediaField,
			mediaItems: [...mediaField.mediaItems, ...joins],
		};
		return updateMediaField(newMediafield, name);
	};

	const updateMediaField = (newMediaField: EntryMediaField, name: string) => {
		return updateEntryField(newMediaField)
			.then((resp) => {
				if (resp.status === 200) {
					addNewAlert({
						type: "success",
						message: "saved successfully",
					});
					setFieldValue(`${name}`, resp.data);
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save.",
					});
				}
			})
			.catch((error: any) => {
				console.log("Failed to save Field: ", error);

				addNewAlert({
					type: "error",
					message: "Failed to save.",
				});
			});
	};

	return (
		<>
			{props.fields &&
				props.fields.length > 0 &&
				props.fields.map((field, index) => {
					if (
						!props.hideMediaFields &&
						isAProgramMediaField(field.programField)
					) {
						const mediaField = field as EntryMediaField;

						if (props.showEditMedia) {
							return (
								<div>
									<div className="flex items-center gap-[.5rem] mb-[1rem]">
										<h3 className="mr-[1rem]">
											{mediaField.programField.title}{" "}
											{mediaField.programField.minQuantity > 0 ? "*" : ""}
											{/* {MediaType[mediaField.programField.mediaType]}) */}
										</h3>
										<InfoPopup alt="Admin Help Text" iconSize="20px">
											{mediaField.programField.adminHelpText}
										</InfoPopup>
										<InfoPopup
											icon="info2"
											alt="Entrant Help Text"
											iconSize="21px"
										>
											{mediaField.programField.entrantHelpText}
										</InfoPopup>
									</div>

									<div className="flex flex-col gap-[1rem]">
										<DraggableMediaList
											name={`${props.name}.${index}`}
											list={
												(mediaField.mediaItems &&
													mediaField.mediaItems.length > 0 &&
													mediaField.mediaItems.map((m) => {
														return {
															...m.mediaItem,
															joinId: m.id,
															hideForWinnersGallery:
																m.mediaItem.hideForWinnersGallery,
														} as DragMediaItem;
													})) ||
												[]
											}
											mediaType={mediaField.programField.mediaType}
											min={mediaField.programField.minQuantity}
											max={mediaField.programField.maxQuantity}
											companyId={values["companyId"]}
											onAdd={(addedMedia) =>
												AddToMediaField(
													addedMedia,
													mediaField,
													`${props.name}.${index}`,
													false
												)
											}
											onRemove={(mediaId) => {
												const newMediaField: EntryMediaField = {
													...mediaField,
													mediaItems: mediaField.mediaItems.filter(
														(value) => value.mediaId !== mediaId
													),
												};
												return updateMediaField(
													newMediaField,
													`${props.name}.${index}`
												);
											}}
											onUploadSuccess={(uploaded) => {
												AddToMediaField(
													uploaded,
													mediaField,
													`${props.name}.${index}`,
													true
												);
											}}
											onReorder={() => {
												addNewAlert({
													type: "success",
													message: "saved successfully",
												});

												return Promise.resolve();
											}}
											showLocalFileUpload
										/>
									</div>
								</div>
							);
						} else {
							return (
								<MediaFieldDisplay
									key={mediaField.id}
									fieldId={String(mediaField.id)}
									fieldTitle={mediaField.programField.title}
									mediaItems={mediaField.mediaItems.map((media) => ({
										...media.mediaItem,
										joinId: media.id,
										hideForWinnersGallery:
											media.mediaItem.hideForWinnersGallery,
									}))}
									min={mediaField.programField.minQuantity}
									max={mediaField.programField.maxQuantity}
									adminHelpText={mediaField.programField.adminHelpText}
									entrantHelpText={mediaField.programField.entrantHelpText}
								/>
							);
						}
					}

					if (props.hideNonMediaFields) {
						return <></>;
					}

					if (isAProgramTextField(field.programField)) {
						const textField = field as EntryTextField;

						return (
							<ErrorWrapper>
								{(error, setError) => (
									<EntryFieldDisplay
										field={textField}
										form={
											<EntryTextFieldForm
												field={textField}
												name={`${props.name}.${index}`}
												isAdmin
												saveOnBlur={false}
												setError={setError}
											/>
										}
										display={
											<>
												{wrapText(
													typeof textField.text === "string"
														? parse(textField.text)
														: textField.text
												)}
												{error && <ErrorText>{error}</ErrorText>}
											</>
										}
										onSave={() => {
											return updateEntryField(textField);
										}}
										onDiscard={() => props.onDiscard()}
										name={`${props.name}.${index}`}
									/>
								)}
							</ErrorWrapper>
						);
					}
					if (isAProgramDateField(field.programField)) {
						const dateField = field as EntryDateField;
						const isDateRange =
							dateField.programField.dateType === DateType.Range &&
							dateField.endDate;
						const displayTime = dateField.programField.displayTime;

						return (
							<ErrorWrapper>
								{(error, setError) => (
									<EntryFieldDisplay
										field={dateField}
										form={
											<EntryDateFieldForm
												field={dateField}
												name={`${props.name}.${index}`}
												isAdmin
												saveOnSelect={false}
												setError={setError}
											/>
										}
										display={
											<div>
												{dateField.startDate ||
													(dateField.startDateTime && (
														<p>
															{isDateRange
																? "Start Date: "
																: "" +
																  getLocalTime(
																		displayTime
																			? dateField.startDateTime!
																			: dateField.startDate!
																  )}
														</p>
													))}
												{dateField.endDate ||
													(dateField.endDateTime && (
														<p>
															{isDateRange &&
																"End Date: " +
																	getLocalTime(
																		displayTime
																			? dateField.endDateTime!
																			: dateField.endDate!
																	)}
														</p>
													))}
												{error && <ErrorText>{error}</ErrorText>}
											</div>
										}
										onSave={() => {
											return updateEntryField(dateField);
										}}
										onDiscard={() => props.onDiscard()}
										name={`${props.name}.${index}`}
									/>
								)}
							</ErrorWrapper>
						);
					}
					if (isAProgramListField(field.programField)) {
						const listField = field as EntryListField;

						return (
							<ErrorWrapper>
								{(error, setError) => (
									<EntryFieldDisplay
										field={listField}
										form={
											<EntryListFieldForm
												field={listField}
												name={`${props.name}.${index}`}
												saveOnChange={false}
												isAdmin
												setError={setError}
											/>
										}
										display={
											<>
												{listField.selectedOption ? (
													<p>{listField.selectedOption.join(", ")}</p>
												) : (
													<> </>
												)}
												{error && <ErrorText>{error}</ErrorText>}
											</>
										}
										onSave={() => {
											return updateEntryField(listField);
										}}
										onDiscard={() => props.onDiscard()}
										name={`${props.name}.${index}`}
									/>
								)}
							</ErrorWrapper>
						);
					}
					if (isAProgramLinkField(field.programField)) {
						const linkField = field as EntryLinkField;
						return (
							<ErrorWrapper>
								{(error, setError) => (
									<EntryFieldDisplay
										field={linkField}
										form={
											<EntryLinkFieldForm
												field={linkField}
												name={`${props.name}.${index}`}
												saveOnChange={false}
												isAdmin
												setError={setError}
											/>
										}
										display={
											<>
												<RenderLinkField
													title=""
													links={linkField.links}
													jurorHelpText=""
												/>
												{error && <ErrorText>{error}</ErrorText>}
											</>
										}
										onSave={() => {
											return updateEntryField(linkField);
										}}
										onDiscard={() => props.onDiscard()}
										name={`${props.name}.${index}`}
									/>
								)}
							</ErrorWrapper>
						);
					}
					if (isAProgramPhysicalComponentField(field.programField)) {
						const physicalField = field as EntryPhysicalComponentField;
						return (
							<ErrorWrapper>
								{(error, setError) => (
									<EntryFieldDisplay
										field={physicalField}
										form={
											<EntryPhysicalComponentFieldForm
												field={physicalField}
												name={`${props.name}.${index}`}
												isAdmin
												saveOnChange={false}
												setError={setError}
											/>
										}
										display={
											<>
												<p>{physicalField.physicalItemDescription}</p>
												{error && <ErrorText>{error}</ErrorText>}
											</>
										}
										onSave={() => {
											return updateEntryField(physicalField);
										}}
										onDiscard={() => props.onDiscard()}
										name={`${props.name}.${index}`}
									/>
								)}
							</ErrorWrapper>
						);
					}
				})}
		</>
	);
};

export default RenderAdminEntryFields;

interface MediaFieldDisplayProps {
	fieldId: string;
	fieldTitle: string;
	min: number;
	max: number;
	mediaItems: MediaItem[];
	adminHelpText: string;
	entrantHelpText: string;
}

interface RenderAdminEntryFieldProps {
	name: string;
	fields: (
		| EntryMediaField
		| EntryTextField
		| EntryDateField
		| EntryListField
		| EntryLinkField
		| EntryPhysicalComponentField
	)[];
	onDiscard(): void;
	showEditMedia?: boolean; // used in admin media edit

	// hide media fields while keeping the index
	hideNonMediaFields?: boolean;
	hideMediaFields?: boolean;
}

interface RenderAdminEntryFieldProps {
	name: string;
	fields: (
		| EntryMediaField
		| EntryTextField
		| EntryDateField
		| EntryListField
		| EntryLinkField
		| EntryPhysicalComponentField
	)[];
	onDiscard(): void;
	showEditMedia?: boolean; // used in admin media edit

	// hide media fields while keeping the index
	hideNonMediaFields?: boolean;
	hideMediaFields?: boolean;
}
