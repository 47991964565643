import styled, { css, useTheme } from "styled-components";
import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import Icon from "../Icon/Icon";
import { defaultBoxShadow } from "../../assetsConfig";

const StyledInfoPopup = styled.div<{ show: boolean; top?: string }>`
	position: absolute;
	display: none;
	flex-direction: column;
	min-width: 200px;
	max-width: 300px;
	width: max-content;
	top: ${({ top }) => top || "30px"};
	z-index: 999;
	box-shadow: ${defaultBoxShadow};
	padding: 3px;
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorBackgroundMedium};
	font-size: ${({ theme }) => theme.xxSmallSize};
	font-weight: ${({ theme }) => theme.fontDefaultWeight};
	white-space: pre-wrap;
	padding: 0.5rem;

	/* caret up  */
	&:after {
		content: "";
		position: absolute;
		left: 5px;
		bottom: 100%;
		width: 0;
		height: 0;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;
		border-bottom: 3px solid ${({ theme }) => theme.colorBackgroundMedium};
		z-index: 999999;
	}

	${(p) =>
		p.show &&
		css`
			display: flex;
		`}
`;

const InfoPopup = (props: InfoPopupProps) => {
	const [showPopup, setShowPopup] = useState(false);
	const theme = useTheme();

	return (
		<div className={`${props.className} relative`}>
			<span
				onMouseEnter={() => setShowPopup(true)}
				onMouseLeave={() => setShowPopup(false)}
			>
				<Icon
					icon={props.icon ? props.icon : "info"}
					color={props.iconColor || theme.colorPrimary}
					width={props.iconSize}
					height={props.iconSize}
					alt={props.alt}
				/>
			</span>

			<CSSTransition in={showPopup} timeout={150} unmountOnExit>
				<StyledInfoPopup show={showPopup}>{props.children}</StyledInfoPopup>
			</CSSTransition>
		</div>
	);
};

export const ThumbnailPopup = (props: InfoPopupProps) => {
	const [showPopup, setShowPopup] = useState(false);

	return (
		<div
			className={`relative ${props.className ? props.className : ""}`}
			onMouseEnter={() => props.alt && setShowPopup(true)}
			onMouseLeave={() => setShowPopup(false)}
		>
			{props.children}

			<CSSTransition in={true} timeout={150} unmountOnExit>
				<StyledInfoPopup top={props.top ? props.top : "0px"} show={showPopup}>
					{props?.alt}
				</StyledInfoPopup>
			</CSSTransition>
		</div>
	);
};

export default InfoPopup;

interface InfoPopupProps {
	children: React.ReactNode;
	icon?: string;
	iconSize?: string;
	iconColor?: string;
	alt?: string;
	className?: string;
	top?: string;
}
