import {
	CardContainer,
	CardHeader,
	CardBody,
} from "../../../components/DashboardCard/DashboardCard";
import Button from "../../../components/Button/Button";
import { useTheme } from "styled-components";
import { useContext, useEffect, useState } from "react";
import TextField from "../../../components/FormFields/TextField";
import DropdownField from "../../../components/FormFields/DropdownField";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import { useFormik, FormikProvider } from "formik";
import { ProgramModel } from "./ProgramInterfaces";
import FileUpload from "../../../components/FormFields/FileUpload";
import StyledForm from "../../../components/Form/Form";
import CheckboxField from "../../../components/FormFields/CheckboxField";
import * as Yup from "yup";
import RichTextField from "../../../components/FormFields/RichTextField";
import {
	getActiveAwards,
	getActiveSeasons,
	PostProgram,
} from "./manageProgram";
import { SeasonModel } from "../Seasons/Seasons";
import { AwardModel } from "../Awards/Awards";
import { useHistory } from "react-router-dom";
import DeadlineFields from "./DeadlineFields";
import { MediaType } from "../../MediaLibrary/mediaLibrary.model.d";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { useAlert } from "../../../components/Alert/Alerts";
import DateTimeField from "../../../components/FormFields/DateTimeField";
import assetsConfig from "../../../assetsConfig";

export const programValidationSchema = {
	name: Yup.string().required("A Program Name is required"),
	seasonId: Yup.number()
		.min(1, "A season is required")
		.required("A season is required"),
	awardId: Yup.number()
		.min(1, "An award is required")
		.required("An award is required"),
	description: Yup.string()
		.min(1, "A long description is required")
		.required("Description is required"),
	deadlines: Yup.array(
		Yup.object({
			name: Yup.string().required("Name is required"),
			date: Yup.number().required("Date is required"),
			price: Yup.number().required("Price is required"),
		})
	),
	entriesOpenDate: Yup.number()
		.typeError("Entries Open Date is required")
		.required("Entries Open Date is required"),
	childLevelDisplayLabel: Yup.string().required(
		"Child Level Display Name is Required"
	),
};

const NewProgram = () => {
	const [activeSeasons, setActiveSeasons] = useState<SeasonModel[]>([]);
	const [activeAwards, setActiveAwards] = useState<AwardModel[]>([]);
	const { addNewAlert } = useAlert();

	let programHistory = useHistory();

	useEffect(() => {
		getActiveSeasons()
			.then((response) => {
				setActiveSeasons(response.data);
			})
			.catch((error) => {
				// console.log("getActiveSeasons ERROR", error);
				// setErrors((old) => [...old, error]);
			});

		getActiveAwards()
			.then((response) => {
				setActiveAwards(response.data);
			})
			.catch((error) => {
				// console.log("getActiveAwards ERROR", error);
				// setErrors((old) => [...old, error]);
			});
	}, []);

	const theme = useTheme();

	async function HandleSubmit(value: ProgramModel) {
		formikProps
			.validateForm()
			.then((response) => {
				PostProgram(formikProps.values).then((res) => {
					// console.log("res", res);
					if (res.status === 201) {
						programHistory.push(`/program/configure/${res?.data.id}`);
					}
					// else {
					//   console.log("error", res);
					// }
				});
			})
			.catch((e) => {
				// console.log("error", e);
				addNewAlert({ type: "error", message: "Validation Failed" });
			});
	}

	const formikProps = useFormik({
		initialValues: {
			name: "",
			description: "",
			image: "",
			imageData: undefined,
			welcomeVideo: "",
			welcomeVideoData: undefined,
			imageDisplaysForEntrants: false,
			imageDisplaysForJurors: false,
			imageDisplaysForWinnersGallery: false,
			isCampaignEligible: false,
			isMixedCampaignEligible: false,
			isEntryLevel: false,
			isExecution: false,
			entriesOpenDate: undefined,
			entriesClosed: false,
			seasonId: undefined,
			awardId: undefined,
			deadlines: [],
			hasChildren: false,
			childLevelDisplayLabel: "",
			rootProgramId: 0,
			depth: 0,
			physicalComponentAllowed: false,
			physicalComponentEntrantHelpText: undefined,
			isCampaignOnly: false,
			createdDate: "",
			createdBy: "",
			modifiedDate: "",
			modifiedBy: "",
		},
		onSubmit: async () => {},
		validationSchema: Yup.object(programValidationSchema),
		validateOnBlur: false,
		validateOnChange: false,
	});

	// if the program is campaign only, by default campaigns are eligible
	useEffect(() => {
		if (formikProps.values.isCampaignOnly) {
			formikProps.setFieldValue("isCampaignEligible", true);
		}
	}, [formikProps.values.isCampaignOnly]);

	return (
		<FormikProvider value={formikProps}>
			<BrowserHeader title="New Program" />
			<CardContainer>
				<CardHeader>
					<h2 className="card-title">New Program</h2>
				</CardHeader>
				<CardBody className="flex flex-col">
					<StyledForm className="twoColumn">
						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">
								Basic Program Information
							</h3>
						</div>

						<TextField
							name="name"
							placeholder="Program Name"
							value={formikProps.values.name}
						/>
						<DropdownField
							name="seasonId"
							placeholder="Select Season"
							options={activeSeasons.map((s) => {
								return { value: s.id, label: s.name };
							})}
						/>

						<DateTimeField
							className="!w-full !max-w-none"
							name="entriesOpenDate"
							placeholder="Entries Open Date"
							value={formikProps.values.entriesOpenDate}
						/>
						<DropdownField
							name="awardId"
							placeholder="Select Award"
							options={activeAwards.map((a) => {
								return { value: a.id, label: a.name };
							})}
						/>

						<div className="col-w-100">
							<RichTextField
								name="description"
								placeholder="Description"
								height="200px"
							/>
						</div>

						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">
								Program Deadlines
							</h3>
						</div>

						<div className="col-w-100 gap-[3rem]">
							<DeadlineFields name="deadlines" />
						</div>

						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">
								Image or Sponsor Logo
							</h3>
						</div>

						<FileUpload
							className="!w-[175px]"
							id="FileUpload-ProgramImage"
							onChange={(files) =>
								formikProps.setFieldValue("imageData", files[0].file)
							}
							allowedMediaType={MediaType.Image}
							remove={() => {
								formikProps.setFieldValue("imageData", undefined);
							}}
						/>

						<div className="col-w-100 flex !flex-row flex-wrap gap-[1.11rem]">
							<p className="font-semibold mr-[.765rem]">Image displays for</p>
							<CheckboxField
								name="imageDisplaysForEntrants"
								placeholder="Displays For Entrants"
								checked={formikProps.values.imageDisplaysForEntrants}
							/>
							<CheckboxField
								name="imageDisplaysForJurors"
								placeholder="Displays For Jurors"
								checked={formikProps.values.imageDisplaysForJurors}
							/>
							<CheckboxField
								name="imageDisplaysForWinnersGallery"
								placeholder="Displays For Winners Gallery"
								checked={formikProps.values.imageDisplaysForWinnersGallery}
							/>
						</div>

						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">Welcome Video</h3>
						</div>

						<FileUpload
							height="173px"
							id="fileUpload-welcomeVideo"
							onChange={(files) => {
								if (!files || files.length === 0) {
									return;
								}
								formikProps.setFieldValue("welcomeVideoData", files[0].file);
							}}
							{...(formikProps.values.welcomeVideo && {
								value: {
									fileName: "Welcome Video",
									fileType: "video",
									src: formikProps.values.welcomeVideo,
									initial: true,
								},
								disabled: false,
							})}
							remove={() => {
								formikProps.setFieldValue("welcomeVideo", undefined);
								formikProps.setFieldValue("welcomeVideoData", undefined);
							}}
							allowedMediaType={MediaType.Video}
						/>

						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">Entry Settings</h3>
						</div>

						<div className="flex gap-[1rem] items-center">
							<p>Is Entry Level?</p>
							<ToggleSwitchField
								id="isEntryLevel"
								name="isEntryLevel"
								checked={formikProps.values.isEntryLevel}
							/>
						</div>

						{formikProps.values.isEntryLevel && (
							<div className="col-w-100">
								<div className="flex gap-[1rem] items-center">
									<p>Are Physical Components allowed?</p>
									<ToggleSwitchField
										id="physicalComponentAllowed"
										name="physicalComponentAllowed"
										checked={formikProps.values.physicalComponentAllowed}
									/>
								</div>
							</div>
						)}

						{formikProps.values.isEntryLevel &&
							formikProps.values.physicalComponentAllowed && (
								<RichTextField
									name="physicalComponentEntrantHelpText"
									placeholder="Physical Component Entrant Help Text"
									value={formikProps.values.physicalComponentEntrantHelpText}
									height="200px"
								/>
							)}

						<div className="col-w-100">
							<div className="flex gap-[1rem] items-center">
								<p>Is {assetsConfig.labels.execution.singular}?</p>
								<ToggleSwitchField
									id="isExecution"
									name="isExecution"
									checked={formikProps.values.isExecution}
								/>
							</div>
						</div>

						<div className="col-w-100 flex !flex-row flex-wrap gap-[3rem]">
							<div className="flex gap-[1rem] items-center">
								<p>Is Campaign Only?</p>
								<ToggleSwitchField
									id="isCampaignOnly"
									name="isCampaignOnly"
									checked={formikProps.values.isCampaignOnly}
								/>
							</div>
							<div className="flex gap-[1rem] items-center">
								<p>Is Campaign Eligible?</p>
								<ToggleSwitchField
									id="isCampaignEligible"
									name="isCampaignEligible"
									checked={formikProps.values.isCampaignEligible}
									disabled={formikProps.values.isCampaignOnly}
								/>
							</div>
							<div className="flex gap-[1rem] items-center">
								<p>Is Mixed Campaign Eligible?</p>
								<ToggleSwitchField
									id="isMixedCampaignEligible"
									name="isMixedCampaignEligible"
									checked={formikProps.values.isMixedCampaignEligible}
								/>
							</div>
						</div>

						<div className="col-w-100">
							<div className="flex gap-[1rem] items-center">
								<p>Entries Closed?</p>
								<ToggleSwitchField
									id="entriesClosed"
									name="entriesClosed"
									checked={formikProps.values.entriesClosed}
								/>
							</div>
						</div>

						<div className="col-w-100">
							<h3 className="subtitle font-semibold !mb-0">
								Additional Settings
							</h3>
						</div>

						<TextField
							name="childLevelDisplayLabel"
							placeholder="Child-level Display Name"
							value={formikProps.values.childLevelDisplayLabel}
						/>

						<div className="col-w-100">
							<Button
								className="ml-auto w-[200px] button-activation"
								iconLeft="plus"
								iconColor={theme.colorCopyLightLight}
								onClick={() => HandleSubmit(formikProps.values)}
								type="button"
							>
								Create
							</Button>
						</div>
					</StyledForm>
				</CardBody>
			</CardContainer>
		</FormikProvider>
	);
};

export default NewProgram;
