import { useTheme } from "styled-components";
import { useAlert } from "../../../components/Alert/Alerts";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { RootState } from "../../../store";
import {
	updateEntryProperty,
	UpdatePropertyRequest,
} from "../../Entries/manageEntry";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/FormFields/TextField";
import Authorized from "../../../components/Auth/Authorized";
import MessagingModal from "./MessagingModal";
import { createPortal } from "react-dom";
import { EntryNoteType } from "../../Judging/EntryDetail/EntryDetail";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { AdminEntryModel } from "../../Entries/EditEntry";
import { useChatHub } from "../../../hooks/useChatHub";
import assetsConfig from "../../../assetsConfig";

const StickyFields = () => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();
	const { user } = useCurrentUser();
	const { values, setValues } = useFormikContext<AdminEntryModel>();

	// UI states for Start Message
	const [showMessageTextbox, setShowMessageTextbox] = useState(false);

	const [showMessageModal, setShowMessageModal] = useState(false);
	const [modalType, setModalType] = useState<null | EntryNoteType>(
		EntryNoteType.ReviewerToSubmitter
	);
	const [showJudgingNoteTextbox, setShowJudgingNoteTextbox] = useState(false);

	const {
		messages,
		draftMessage,
		blankReviewNote,
		setDraftMessage,
		sendDraftMessage,
		updateMessage,
		deleteMessage,
		isConnected,
		error,
	} = useChatHub(values.id, modalType, user);

	const { messages: reviewToSubmitterMessages } = useChatHub(
		values.id,
		EntryNoteType.ReviewerToSubmitter,
		user
	);

	const { messages: reviewerNotes } = useChatHub(
		values.id,
		EntryNoteType.Reviewer,
		user
	);
	const { messages: reviewerFedback } = useChatHub(
		values.id,
		EntryNoteType.Feedback,
		user
	);

	const { settings, loading } = useSelector(
		(state: RootState) => state.settings
	);

	const handleSaveDraftMessage = () => {
		if (
			modalType === EntryNoteType.ReviewerToSubmitter &&
			draftMessage &&
			draftMessage.note &&
			draftMessage.note != ""
		) {
			sendDraftMessage(draftMessage)
				.then((res: any) => {
					addNewAlert({
						type: "success",
						message: "Successfully saved draft",
					});
				})
				.catch((e: any) =>
					addNewAlert({
						type: "error",
						message: e.message,
					})
				);
		}
	};

	const messageCount = reviewToSubmitterMessages.reduce(
		(count, x) => (!x.isPrivate ? count + 1 : 0),
		0
	);

	const notesCount = reviewerNotes.reduce(
		(count, x) => (!x.isPrivate ? count + 1 : 0),
		0
	);

	const feedbackCount = reviewerFedback.reduce(
		(count, x) => (!x.isPrivate ? count + 1 : 0),
		0
	);

	return (
		<div className="flex flex-col gap-[1rem]">
			{messageCount > 0 ? (
				<Button
					className="button-light"
					iconLeft="startMessage"
					iconColor={theme.colorCopyDarkDark}
					iconSize="20px"
					iconClassName="absolute right-[1rem]"
					onClick={() => {
						setShowMessageModal(!showMessageModal);
						setModalType(EntryNoteType.ReviewerToSubmitter);
					}}
				>
					Review Messages ({messageCount})
				</Button>
			) : (
				<Button
					className="button-light"
					iconLeft="startMessage"
					iconColor={theme.colorCopyDarkDark}
					iconSize="20px"
					iconClassName="absolute right-[1rem]"
					onClick={() => {
						setModalType(EntryNoteType.ReviewerToSubmitter);
						setShowMessageTextbox(!showMessageTextbox);
					}}
				>
					Start Message
				</Button>
			)}

			{showMessageTextbox && (
				<>
					<TextField
						className="flex-1"
						name="tempMessage"
						placeholder={`Compose Message`}
						component="textarea"
						height="100px"
						nonFormik
						value={draftMessage.note}
						onChange={(e) => {
							setDraftMessage({
								...draftMessage,
								note: e.target.value,
							});
						}}
						onBlur={handleSaveDraftMessage}
					/>
					<Button
						onClick={() => {
							setShowMessageModal(!showMessageModal);
							setShowMessageTextbox(!showMessageTextbox);
						}}
					>
						Review and Send
					</Button>
				</>
			)}
			<Button
				className="button-light"
				iconLeft="reviewerNote"
				iconColor={theme.colorCopyDarkDark}
				iconSize="20px"
				iconClassName="absolute right-[1rem]"
				onClick={() => {
					setModalType(EntryNoteType.Reviewer);
					setShowMessageModal(!showMessageModal);
				}}
			>
				<div className="flex gap-[4px]">
					Reviewer Note {notesCount > 0 && <>({notesCount})</>}
				</div>
			</Button>

			<Button
				className="button-light"
				iconLeft="judgingNote"
				iconColor={theme.colorCopyDarkDark}
				iconSize="20px"
				iconClassName="absolute right-[1rem]"
				onClick={() => setShowJudgingNoteTextbox(!showJudgingNoteTextbox)}
			>
				Judging Note
			</Button>
			{showJudgingNoteTextbox && (
				<Authorized
					settings={settings}
					loading={loading}
					feature="Judging Fields"
					authorized={
						<TextField
							name="judgingNote"
							placeholder={
								!settings.featureFlags["Judging"]
									? "Creative Review Note"
									: "Add Judging Notes"
							}
							component="textarea"
							value={values.judgingNote}
							height="100px"
							onClickSave={() => {
								const updatePropertyRequest: UpdatePropertyRequest = {
									id: values.id,
									propertyName: "JudgingNote",
									propertyValue: values.judgingNote,
								};

								return updateEntryProperty(updatePropertyRequest)
									.then(() => {
										addNewAlert({
											type: "success",
											message: "Successfully saved entry judging note",
										});
										setValues({
											...values,
											judgingNote: values.judgingNote,
										});
									})
									.catch((e) => {
										addNewAlert({
											type: "error",
											message: e.message,
										});
									});
							}}
						/>
					}
				/>
			)}
			<Button
				className="button-light"
				iconLeft="jurorFeedback"
				iconColor={theme.colorCopyDarkDark}
				iconSize="20px"
				iconClassName="absolute right-[1rem]"
				onClick={() => {
					setModalType(EntryNoteType.Feedback);
					setShowMessageModal(!showMessageModal);
				}}
			>
				<div className="flex gap-[4px]">
					{assetsConfig.labels.jurorFeedback.singular}{" "}
					{feedbackCount > 0 && <>({feedbackCount})</>}
				</div>
			</Button>

			{createPortal(
				<MessagingModal
					showModal={modalType !== null && showMessageModal}
					modalType={modalType}
					// onClose={() => setModalType(EntryNoteType.ReviewerToSubmitter)}
					onClose={() => setModalType(null)}
					setShowModal={setShowMessageModal}
					currentUser={user}
					disabled={
						values.isChatLocked &&
						modalType === EntryNoteType.ReviewerToSubmitter
					}
					messages={messages}
					draftMessage={draftMessage}
					blankReviewNote={blankReviewNote}
					setDraftMessage={setDraftMessage}
					sendDraftMessage={sendDraftMessage}
					updateMessage={updateMessage}
					deleteMessage={deleteMessage}
					isConnected={isConnected}
				/>,
				document.body
			)}

			{/*{createPortal(
        <PreviewMessageModal
          showModal={showMessagePreviewModal}
          setShowModal={(show) => {
            // clear temp message after hiding modal
            if (!show) {
              setTempMessage(undefined);
              setShowMessageTextbox(false);
            }
            setShowMessagePreviewModal(show);
          }}
          tempMessage={tempMessage}
        />,
        document.body
      )}

      {document.querySelector("#dashboard-body") &&
        createPortal(
          <FloatingMessageIcon
            setShowModal={() => {
              setModalType(EntryNoteType.ReviewerToSubmitter);
              setShowMessageModal(true);
            }}
            isThreadResolved={isMessageThreadResolved}
          />,
          document.querySelector("#dashboard-body")!
        )} */}
		</div>
	);
};

export default StickyFields;
