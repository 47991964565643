import * as Yup from "yup";
import { EntryModel } from "./EntryInterfacesComplete";
import {
	DateType,
	EntryCompanyCreditField,
	EntryDateField,
	EntryIndividualCreditField,
	EntryLinkField,
	EntryListField,
	EntryMediaField,
	EntryPhysicalComponentField,
	EntryTextField,
	ListType,
	ProgramDateField,
	ProgramListField,
	ProgramMediaField,
	ProgramPhysicalComponentField,
	ProgramTextField,
} from "../Admin/Program/ProgramInterfaces";
import { fieldRequired } from "../../components/FieldModal/FieldModal";
import { EntryError } from "./DynamicFields";
import { getLocalTime, getLuxonDateTime } from "../../utils/timeUtilities";
import assetsConfig from "../../assetsConfig";
import lowerCase from "../../utils/lowerCase";

const EntryValidation = (
	initialFormValues: EntryModel,
	isDemo: boolean,
	showTermsAndConditions: boolean
): EntryValidationReturnType => {
	const basicInfoErrors: FieldError = {};
	const campaignErrors: FieldError = {};
	const creditErrors: FieldError = {};
	const entryMediaErrors: FieldError = {};
	const executionErrors: FieldError = {};
	const reviewTabErrors: FieldError = {};

	const deleteError = (fieldName: string, errors: EntryError) => {
		const newErrors: EntryError = { ...errors };
		delete newErrors[fieldName]; // delete error
		errors = newErrors; // update errors
	};

	// add basic info validations
	if (!initialFormValues.title) {
		basicInfoErrors["title"] = fieldRequired;
	} else {
		deleteError("title", basicInfoErrors);
	}

	if (!initialFormValues.brand) {
		basicInfoErrors["brand"] = fieldRequired;
	} else {
		deleteError("brand", basicInfoErrors);
	}

	if (
		initialFormValues.hasPhysicalComponent &&
		!initialFormValues.physicalItemDescription
	) {
		basicInfoErrors["physicalItemDescription"] = fieldRequired;
	} else {
		deleteError("physicalItemDescription", basicInfoErrors);
	}

	if (!initialFormValues.termsAndConditions && showTermsAndConditions) {
		reviewTabErrors["termsAndConditions"] = fieldRequired;
	} else {
		deleteError("reviewTabErrors", reviewTabErrors);
	}

	// add review tab validations
	if (!initialFormValues.coverImage.mediaItem) {
		basicInfoErrors["coverImage"] = fieldRequired;
	} else {
		deleteError("coverImage", basicInfoErrors);
	}

	// add campaign validations
	initialFormValues.campaignFields.forEach((field, i) => {
		switch (field.classType) {
			case "EntryTextField":
				const textField = field.programField as ProgramTextField;
				const textFieldName = `campaignFields.${i}.text`;
				if (textField.isRequired && !(field as EntryTextField).text) {
					campaignErrors[textFieldName] = fieldRequired;
				} else {
					deleteError(textFieldName, campaignErrors);
				}
				break;
			case "EntryPhysicalComponentField":
				const physicalField =
					field.programField as ProgramPhysicalComponentField;
				const physicalFieldName = `campaignFields.${i}.physicalItemDescription`;
				if (
					physicalField.isRequired &&
					!(field as EntryPhysicalComponentField).physicalItemDescription
				) {
					campaignErrors[physicalFieldName] = fieldRequired;
				} else {
					deleteError(physicalFieldName, campaignErrors);
				}
				break;
			case "EntryDateField":
				const dateField = field.programField as ProgramDateField;
				const dateFieldStartName = `campaignFields.${i}.startDate`;
				const dateFieldEndName = `campaignFields.${i}.endDate`;
				const EntryDateField = field as EntryDateField;

				const minStartDateErr =
					EntryDateField.startDate &&
					dateField.minDate &&
					getLuxonDateTime(EntryDateField.startDate)! <
						getLuxonDateTime(dateField.minDate)!
						? `Start Date cannot be before ${getLocalTime(dateField.minDate)}`
						: undefined;

				const maxStartDateErr =
					EntryDateField.startDate &&
					dateField.maxDate &&
					getLuxonDateTime(EntryDateField.startDate)! >
						getLuxonDateTime(dateField.maxDate)!
						? `Start Date cannot be after ${getLocalTime(dateField.maxDate)}`
						: undefined;

				const minEndDateErr =
					EntryDateField.programField.dateType === DateType.Range &&
					EntryDateField.endDate &&
					dateField.minDate &&
					getLuxonDateTime(EntryDateField.endDate)! <
						getLuxonDateTime(dateField.minDate)!
						? `End Date cannot be before ${getLocalTime(dateField.minDate)}`
						: undefined;

				const maxEndDateErr =
					EntryDateField.programField.dateType === DateType.Range &&
					EntryDateField.endDate &&
					dateField.maxDate &&
					getLuxonDateTime(EntryDateField.endDate)! >
						getLuxonDateTime(dateField.maxDate)!
						? `End Date cannot be after ${getLocalTime(dateField.maxDate)}`
						: undefined;

				const reqStartDateErr =
					dateField.isRequired && !EntryDateField.startDate
						? fieldRequired
						: undefined;

				const reqEndDateErr =
					EntryDateField.programField.dateType === DateType.Range &&
					dateField.isRequired &&
					!EntryDateField.endDate
						? fieldRequired
						: undefined;

				if (minStartDateErr || maxStartDateErr || reqStartDateErr) {
					campaignErrors[dateFieldStartName] =
						minStartDateErr || maxStartDateErr || reqStartDateErr;
				} else {
					deleteError(dateFieldStartName, campaignErrors);
				}

				if (minEndDateErr || maxEndDateErr || reqEndDateErr) {
					campaignErrors[dateFieldEndName] =
						minEndDateErr || maxEndDateErr || reqEndDateErr;
				} else {
					deleteError(dateFieldEndName, campaignErrors);
				}

				break;
			case "EntryListField":
				const listField = field.programField as ProgramListField;
				const entryListField = field as EntryListField;
				const listFieldName = `campaignFields.${i}.selectedOption`;

				const validationMessage =
					listField.listType === ListType.CheckBox ||
					listField.listType === ListType.Radio
						? `Must select at least ${listField.minQuantity} option${
								listField.minQuantity > 1 ? "s" : ""
						  }`
						: fieldRequired;

				const minError =
					listField.minQuantity &&
					entryListField.selectedOption &&
					(entryListField.selectedOption.length < listField.minQuantity ||
						entryListField.selectedOption?.reduce(
							(prev, curr) => `${prev}${curr}`
						) == "");

				const maxError =
					listField.maxQuantity &&
					entryListField.selectedOption &&
					entryListField.selectedOption.length > listField.maxQuantity;

				const noOptionSelected =
					listField.minQuantity && !entryListField.selectedOption;

				if (noOptionSelected || minError || maxError) {
					const validationMessage = noOptionSelected
						? fieldRequired
						: minError
						? `Must select at least ${listField.minQuantity} option${
								listField.minQuantity > 1 ? "s" : ""
						  }`
						: `Can only select a maximum of ${listField.maxQuantity} option${
								listField.maxQuantity > 1 ? "s" : ""
						  }`;
					campaignErrors[listFieldName] = validationMessage;
				} else {
					deleteError(listFieldName, campaignErrors);
				}

				break;
			case "EntryLinkField":
				const linkField = field as EntryLinkField;
				const linkFieldName = `campaignFields.${i}.links`;
				const isMinLinkError =
					(!linkField.links && linkField.programField.minLinks > 0) ||
					(linkField.links &&
						linkField.links.length < linkField.programField.minLinks);

				// check if any links are added
				if (isMinLinkError) {
					campaignErrors[linkFieldName] = fieldRequired;
				} else {
					deleteError(linkFieldName, campaignErrors);
				}

				// check each added link for missing values
				// if (linkField.links && linkField.links.length > 0) {
				//   // set validation for link, username, and password for each set
				//   linkField.links.forEach((linkSet, linkIndex: number) => {
				//     campaignErrors[`campaignFields.${i}.links.${linkIndex}.link`] =
				//       fieldRequired;
				//     campaignErrors[`campaignFields.${i}.links.${linkIndex}.username`] =
				//       fieldRequired;
				//     campaignErrors[`campaignFields.${i}.links.${linkIndex}.password`] =
				//       fieldRequired;
				//   });
				// }
				break;

			// entry level media validation
			case "EntryMediaField":
				const mediaField = field.programField as ProgramMediaField;
				const mediaFieldName = `campaignFields.${i}.mediaItems.${mediaField.id}`;
				const isMinNotDropped =
					mediaField.minQuantity &&
					mediaField.minQuantity > 0 &&
					(!(field as EntryMediaField).mediaItems ||
						(field as EntryMediaField).mediaItems.length <
							mediaField.minQuantity)
						? `At least ${mediaField.minQuantity} file is required.`
						: undefined;

				if (isMinNotDropped) {
					entryMediaErrors[mediaFieldName] = isMinNotDropped;
				} else {
					deleteError(mediaFieldName, entryMediaErrors);
				}
				break;
		}
	});

	// add credits validations
	initialFormValues.creditFields.forEach((field, i) => {
		const creditFieldName = `creditFields.${i}.credits`;

		switch (field.classType) {
			case "EntryCompanyCreditField":
				const companyField = field as EntryCompanyCreditField;
				const isMinCompanyError =
					companyField.programField.minCredits > 0 &&
					(!companyField.credits ||
						companyField.credits.length < companyField.programField.minCredits);
				// user can skip required COMPANY credits if they check the N/A box
				const isCompanyCreditBypassed = companyField.notApplicableAttestation;

				if (isMinCompanyError && !isCompanyCreditBypassed) {
					creditErrors[creditFieldName] = fieldRequired;
				} else {
					deleteError(creditFieldName, creditErrors);
				}
				break;

			case "EntryIndividualCreditField":
				const individualField = field as EntryIndividualCreditField;
				const isMinIndividualError =
					individualField.programField.minCredits > 0 &&
					(!individualField.credits ||
						individualField.credits.length <
							individualField.programField.minCredits);

				// user can skip required INDIVIDUAL credits if they check the N/A box
				const isIndCreditBypassed = individualField.notApplicableAttestation;

				if (isMinIndividualError && !isIndCreditBypassed) {
					creditErrors[creditFieldName] = fieldRequired;
				} else {
					deleteError(creditFieldName, creditErrors);
				}
				break;
		}
	});

	// add executions/single entry validations
	// initialFormValues.isCampaign &&
	initialFormValues.executions.length > 0 &&
		initialFormValues.executions.forEach((execution, executionIndex) => {
			// check execution if execution title is empty (only for campaign entries)
			if (
				initialFormValues.isCampaign &&
				(execution.title === "" || !execution.title)
			) {
				executionErrors[`executions.${executionIndex}.title`] = fieldRequired;
			} else {
				delete executionErrors[`executions.${executionIndex}.title`];
			}

			execution.executionFields.forEach((field, fieldIndex) => {
				switch (field.classType) {
					case "EntryTextField":
						const textField = field.programField as ProgramTextField;
						const textFieldName = `executions.${executionIndex}.executionFields.${fieldIndex}.text`;
						if (textField.isRequired && !(field as EntryTextField).text) {
							executionErrors[textFieldName] = fieldRequired;
						} else {
							deleteError(textFieldName, executionErrors);
						}

						break;
					case "EntryPhysicalComponentField":
						const physicalField =
							field.programField as ProgramPhysicalComponentField;
						const physicalFieldName = `executions.${executionIndex}.executionFields.${fieldIndex}.physicalItemDescription`;
						if (
							physicalField.isRequired &&
							!(field as EntryPhysicalComponentField).physicalItemDescription
						) {
							executionErrors[physicalFieldName] = fieldRequired;
						} else {
							deleteError(physicalFieldName, executionErrors);
						}
						break;
					case "EntryDateField":
						const dateField = field.programField as ProgramDateField;
						const dateFieldStartName = `executions.${executionIndex}.executionFields.${fieldIndex}.startDate`;
						const dateFieldEndName = `executions.${executionIndex}.executionFields.${fieldIndex}.endDate`;

						// check start date
						if (dateField.isRequired && !(field as EntryDateField).startDate) {
							executionErrors[dateFieldStartName] = fieldRequired;
						} else {
							deleteError(dateFieldStartName, executionErrors);
						}

						// check end date
						if (dateField.isRequired && !(field as EntryDateField).endDate) {
							executionErrors[dateFieldEndName] = fieldRequired;
						} else {
							deleteError(dateFieldEndName, executionErrors);
						}

						break;
					case "EntryListField":
						const listField = field.programField as ProgramListField;
						const entryListField = field as EntryListField;
						const listFieldName = `executions.${executionIndex}.executionFields.${fieldIndex}.selectedOption`;

						const minError =
							listField.minQuantity &&
							entryListField.selectedOption &&
							entryListField.selectedOption.length < listField.minQuantity;

						const maxError =
							listField.maxQuantity &&
							entryListField.selectedOption &&
							entryListField.selectedOption.length > listField.maxQuantity;

						const noOptionSelected =
							listField.minQuantity && !entryListField.selectedOption;

						if (noOptionSelected || minError || maxError) {
							const validationMessage = noOptionSelected
								? fieldRequired
								: minError
								? `Must select at least ${listField.minQuantity} option${
										listField.minQuantity > 1 ? "s" : ""
								  }`
								: `Can only select a maximum of ${
										listField.maxQuantity
								  } option${listField.maxQuantity > 1 ? "s" : ""}`;
							executionErrors[listFieldName] = validationMessage;
						} else {
							deleteError(listFieldName, executionErrors);
						}

						break;
					case "EntryLinkField":
						const linkField = field as EntryLinkField;
						const linkFieldName = `executions.${executionIndex}.executionFields.${fieldIndex}.links`;
						const isMinLinkError =
							(!linkField.links && linkField.programField.minLinks > 0) ||
							(linkField.links &&
								linkField.links.length < linkField.programField.minLinks);

						// check if any links are added
						if (isMinLinkError) {
							executionErrors[linkFieldName] = fieldRequired;
						} else {
							deleteError(linkFieldName, executionErrors);
						}

						// check each added link for missing values
						if (linkField.links && linkField.links.length > 0) {
							// set validation for link, username, and password for each set
							linkField.links.forEach((linkSet, linkIndex: number) => {
								executionErrors[
									`executions.${executionIndex}.executionFields.${fieldIndex}.links.${linkIndex}.link`
								] = fieldRequired;
								executionErrors[
									`executions.${executionIndex}.executionFields.${fieldIndex}.links.${linkIndex}.username`
								] = fieldRequired;
								executionErrors[
									`executions.${executionIndex}.executionFields.${fieldIndex}.links.${linkIndex}.password`
								] = fieldRequired;
							});
						}
						break;
					case "EntryMediaField":
						const mediaField = field.programField as ProgramMediaField;
						const mediaFieldName = `executions.${executionIndex}.executionFields.${fieldIndex}.mediaItems.${mediaField.id}`;
						const isMinNotDropped =
							mediaField.minQuantity &&
							mediaField.minQuantity > 0 &&
							(!(field as EntryMediaField).mediaItems ||
								(field as EntryMediaField).mediaItems.length <
									mediaField.minQuantity)
								? `At least ${mediaField.minQuantity} file is required.`
								: undefined;

						if (isMinNotDropped) {
							executionErrors[mediaFieldName] = isMinNotDropped;
						} else {
							deleteError(mediaFieldName, executionErrors);
						}
						break;
				}
			});
		});

	if (initialFormValues.isCampaign && !isDemo) {
		if (initialFormValues.executions.length < 2) {
			executionErrors["minExecutionErr"] = `A minimum of 2 ${lowerCase(
				assetsConfig.labels.execution.plural
			)} is required for campaign entries`;
		} else {
			deleteError("minExecutionErr", executionErrors);
		}
	}

	return {
		basicInfoErrors: {
			...basicInfoErrors,
		},
		campaignErrors: {
			...campaignErrors,
		},
		creditErrors: {
			...creditErrors,
		},
		entryMediaErrors: {
			...entryMediaErrors,
		},
		executionErrors: {
			...executionErrors,
		},
		reviewTabErrors: {
			...reviewTabErrors,
		},
	};
};

export default EntryValidation;

interface FieldError {
	[field: string]: any;
}
export interface EntryValidationReturnType {
	basicInfoErrors: FieldError;
	campaignErrors: FieldError;
	creditErrors: FieldError;
	entryMediaErrors: FieldError;
	executionErrors: FieldError;
	reviewTabErrors: FieldError;
}
