import { useFormik, FormikProvider } from "formik";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import Button from "../Button/Button";
import TextField from "../FormFields/TextField";
import Icon from "../Icon/Icon";
import Modal, { ModalCard, ModalContainer } from "../Modal/Modal";
import { createUser } from "../../views/Admin/User/managerUser";
import { NewUserCredentials } from "../Auth/auth.models";
import { useAlert } from "../Alert/Alerts";
import assetsConfig from "../../assetsConfig";

export const NewUserModal = (props: NewUserModalProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();

	const fieldRequired = "This field is required";

	const blankUserModel = {
		email: "",
		fullName: "",
	};

	async function PostAddUser(user: any) {
		try {
			const newUser: NewUserCredentials = {
				email: user.email,
				fullName: user.fullName,
				companyId: props.companyId,
				address1: "",
				city: "",
				jobTitle: "",
				password: "",
				phone: "",
				postalCode: "",
				state: "",
				address2: null,
			};

			const response = await createUser(newUser);
			if (response.data && response.data.id) {
				if (response?.status === 201) {
					window.open(`/edit-user/${response.data.id}`);
				}
				props.onClickHide();
				formikProps.resetForm();
			}
		} catch (error: any) {
			addNewAlert({ type: "error", message: error.response.data });
		}
	}

	const formikProps = useFormik({
		initialValues: blankUserModel,
		enableReinitialize: true,
		onSubmit: (values) => {
			PostAddUser(values);
		},
		validationSchema: Yup.object({
			email: Yup.string()
				.email("Invalid email address")
				.required(fieldRequired),
			fullName: Yup.string().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	return (
		<Modal show={props.show}>
			<ModalCard
				className="!min-h-[250px] !w-[500px]"
				title="Add New User"
				headerIcons={
					<Icon
						icon="close"
						color={theme.colorCopyLightLight}
						onClick={props.onClickHide}
					/>
				}
				iconColor={theme.colorCopyLightLight}
			>
				<div className="flex flex-col py-[1.5625rem] px-[1.5625rem] gap-[1.5625rem]">
					<p>{assetsConfig.copy.newUserText}</p>
					<FormikProvider value={formikProps}>
						<TextField name="email" placeholder="Email" />
						<TextField name="fullName" placeholder="Full Name" />
						<Button
							className="w-auto ml-auto"
							iconLeft="plus"
							iconColor={theme.colorCopyLightLight}
							onClick={formikProps.submitForm}
							type="submit"
						>
							Add
						</Button>
					</FormikProvider>
				</div>
			</ModalCard>
		</Modal>
	);
};

interface NewUserModalProps {
	show: boolean;
	onClickHide(): void;
	companyId?: number;
}
