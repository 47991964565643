import { useContext, useEffect, useRef, useState } from "react";
import { EntrySetConfig } from "../../views/Admin/Judging/JudgingInterfaces";
import { ReactSortable, Sortable, Store } from "react-sortablejs";
import { sortableOptions } from "./Draggable";
import { useTheme } from "styled-components";
import { updateEntrySetProperty } from "../../views/Admin/Judging/manageJudging";
import { FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import Icon from "../Icon/Icon";
import TextField from "../FormFields/TextField";
import { FocusBorder } from "../FormFields/FieldTemplate";
import { StyledEntrySetCard } from "../../views/Admin/Judging/JudgingConfig/EntrySetTab";
import useTaskHandler, {
	TASKS_COMPLETED,
	TaskHandlerReturnType,
} from "../../hooks/useTaskHandler";
import { UpdatePropertyRequest } from "../../views/Entries/manageEntry";
import { fieldRequired } from "../FieldModal/FieldModal";
import { useAlert } from "../Alert/Alerts";
import assetsConfig from "../../assetsConfig";
import lowerCase from "../../utils/lowerCase";

const DragEntrySet = (props: DragEntrySetProps) => {
	const [isEditing, setIsEditing] = useState(false);
	const theme = useTheme();

	const formikProps = useFormik({
		initialValues: props.entrySet,
		enableReinitialize: true,
		onSubmit: async () => {},
		validationSchema: Yup.object({
			name: Yup.string().required(fieldRequired),
		}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	// discard changes if card isn't selected
	useEffect(() => {
		if (!props.isSelected) {
			setIsEditing(false);
			formikProps.resetForm();
		}
	}, [props.isSelected]);

	return (
		<FormikProvider value={formikProps}>
			<StyledEntrySetCard
				className="draggable"
				onClick={props.onClick}
				isSelected={props.isSelected || false}
			>
				<FocusBorder />
				{isEditing ? (
					<>
						<TextField
							className="entry-set-name mr-auto"
							name="name"
							placeholder="Entry Set Name"
							value={formikProps.values.name}
							hideShadow
						/>
						<Icon
							className="self-center"
							icon="check"
							onClick={() => {
								props.onEdit({
									entrySetId: formikProps.values.id,
									name: formikProps.values.name,
								});
								setIsEditing(false);
							}}
							color={theme.colorActivation}
							width="18px"
							height="18px"
						/>
						<Icon
							className="self-center"
							icon="close"
							onClick={() => {
								formikProps.resetForm();
								setIsEditing(false);
							}}
							color={theme.colorPrimary}
							width="25px"
							height="25px"
						/>
					</>
				) : (
					<>
						<p className="entry-set-display">{formikProps.values.name}</p>

						<Icon
							className="self-center"
							icon="edit"
							color={theme.colorPrimary}
							onClick={() => {
								setIsEditing(true);
								props.onClick && props.onClick();
							}}
							width="18px"
							height="18px"
						/>
					</>
				)}

				<Icon
					className="self-center"
					icon="trash"
					color={theme.colorPrimary}
					onClick={() => props.onDelete(formikProps.values.id)}
					width="18px"
					height="18px"
				/>
			</StyledEntrySetCard>
		</FormikProvider>
	);
};

const DragEntrySetList = (props: DragEntrySetListProps) => {
	const { addNewAlert } = useAlert();
	const ref = useRef<EntrySetConfig[] | null>(null);
	const [entrySets, setEntrySets] = useState<EntrySetConfig[]>([]);
	const [minHeight, setMinHeight] = useState(194);
	const prevEntrySets = ref.current;

	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<UpdatePropertyRequest> =
		useTaskHandler<UpdatePropertyRequest>();

	const handleEntrySetList = (
		newEntrySets: EntrySetConfig[],
		sortable: Sortable | null,
		store: Store
	) => {
		// only run setEntrySets once on drag end
		// https://github.com/SortableJS/react-sortablejs/issues/210
		if (
			store.dragging &&
			store.dragging.props &&
			JSON.stringify(store.dragging.props.list) !== JSON.stringify(newEntrySets)
		) {
			if (prevEntrySets !== null) {
				// on reorder
				if (prevEntrySets.length === newEntrySets.length) {
					// set media list immediately in the front-end
					setEntrySets(newEntrySets);

					const list = newEntrySets.map(
						(entrySet: EntrySetConfig, i: number) => {
							return {
								id: entrySet.id,
								propertyName: "order",
								propertyValue: i,
							};
						}
					);

					onSubmit(updateEntrySetProperty, list);
				}
			}
		}

		ref.current = newEntrySets;
	};

	useEffect(() => {
		if (state.status === TASKS_COMPLETED) {
			if (state.errored.length) {
				addNewAlert({
					type: "error",
					message: `Failed to update ${lowerCase(
						assetsConfig.labels.execution.singular
					)} order.`,
				});
			} else {
				addNewAlert({
					type: "success",
					message: `Successfully updated ${lowerCase(
						assetsConfig.labels.execution.singular
					)} order.`,
				});
			}
			reset();
		}
	}, [state.status, state.errored]);

	useEffect(() => {
		if (props.entrySets) {
			ref.current = props.entrySets;
			setEntrySets(props.entrySets);
		}
	}, [props.entrySets]);

	return (
		<ReactSortable
			className="h-full"
			list={entrySets}
			setList={(newEntrySets, sortabble, store) =>
				handleEntrySetList(newEntrySets, sortabble, store)
			}
			{...sortableOptions({
				group: "entry-sets",
				name: "entry-sets",
				drop: false,
				sortable: true,
				allowMultiSelect: false,
			})}
		>
			{entrySets.length > 0 ? (
				entrySets.map((item, i) => (
					<DragEntrySet
						key={item.id}
						index={i}
						onClick={() => {
							props.setSelectedEntrySet(item);

							const selectedEntrySets = entrySets.map((eSet) =>
								eSet.name === item.name
									? { ...eSet, isSelected: true }
									: { ...eSet, isSelected: false }
							);

							// set clicked entry set to "isSelected"
							setEntrySets(selectedEntrySets);
						}}
						onEdit={props.onEdit}
						onDelete={props.onDelete}
						isSelected={item.isSelected}
						entrySet={item}
					/>
				))
			) : (
				<p>No Entry Sets Available</p>
			)}
		</ReactSortable>
	);
};

export default DragEntrySetList;

interface DragEntrySetListProps extends HandleActions {
	className?: string;
	entrySets?: EntrySetConfig[];
	onReorder?(newOrder: EntrySetConfig[]): void;
	setSelectedEntrySet(eSet: EntrySetConfig): void;
}

interface DragEntrySetProps extends HandleActions {
	entrySet: EntrySetConfig;
	isSelected?: boolean;
	index: number;
	// setEntrySets: React.Dispatch<React.SetStateAction<EntrySetConfig[]>>;
}

// pass props up to edit/delete entry sets
interface HandleActions {
	onClick?(): void;
	onEdit(editedEntrySet: eSet): void;
	onDelete(id: number): void;
}

export type eSet = { entrySetId: number; name: string };
