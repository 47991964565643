import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import assetsConfig from "../../assetsConfig";
import PlaySpinningIcon from "./AnimatedIcons/PlaySpinningIcon";
import PauseSpinningIcon from "./AnimatedIcons/PauseSpinningIcon";
import StopSpinningIcon from "./AnimatedIcons/StopSpinningIcon";
import React, { useEffect, useRef } from "react";

const ColoredSVG = (props: { color: string; children: React.ReactNode }) => {
	const { color, children } = props;
	const svgRef = useRef<SVGSVGElement | null>(null);

	const overrideStyles = (element: Element): void => {
		if (element instanceof SVGElement) {
			// Check if the element has inline styles
			const style = (element as SVGElement).style;

			// Fill color
			if (
				element.getAttribute("fill") &&
				element.getAttribute("fill")?.toLowerCase() !== "none" &&
				!element.getAttribute("fill")?.includes("url")
			) {
				// Override fill and stroke properties
				style.fill = color;
			}

			// Stroke color
			if (
				element.getAttribute("stroke") &&
				element.getAttribute("stroke")?.toLowerCase() !== "none" &&
				!element.getAttribute("fill")?.includes("url")
			) {
				style.stroke = color;
			}

			if (element.style && element.style.fill) {
				style.fill = color;
			}

			if (element.style && element.style.stroke) {
				style.stroke = color;
			}

			// Recursively override styles for child elements
			if (element.children) {
				for (let i = 0; i < element.children.length; i++) {
					overrideStyles(element.children[i]);
				}
			}
		}
	};

	useEffect(() => {
		if (svgRef.current) {
			overrideStyles(svgRef.current);
		}
	}, [color]);

	return React.cloneElement(
		React.Children.only(children) as React.ReactElement<any>,
		{ ref: svgRef }
	);
};

export const IconWrapper = styled.span<IconWrapperProps>`
	cursor: pointer;

	${(p) =>
		p.defaultCursor &&
		css`
			cursor: default;
		`};

	${(p) =>
		p.readonly &&
		css`
			cursor: not-allowed;
		`};

	svg {
		width: ${(p) => p.width};
		height: ${(p) => p.height};

		${(p) =>
			p.rotation &&
			css`
				transform: ${`rotate(${p.rotation})`};
				transition: all 150ms ease;
			`};
	}
`;

const getIcon = (icon: string, alt: string | undefined) => {
	switch (icon) {
		case "logo":
			return assetsConfig.logos.local;
		case "plus":
			return assetsConfig.icons.plus;
		case "close":
			return assetsConfig.icons.close;
		case "closeLarge":
			return assetsConfig.icons.closeLarge;
		case "caret":
			return assetsConfig.icons.caret;
		case "expandCaret":
			return assetsConfig.icons.expand;
		case "alert-blue":
			return assetsConfig.icons.alertBlue;
		case "alert-black":
			return assetsConfig.icons.alertBlack;
		case "alert-gold":
			return assetsConfig.icons.alertGold;
		case "alert-white":
			return assetsConfig.icons.alertWhite;
		case "alert-gray":
			return assetsConfig.icons.alertGray;
		case "home":
			return assetsConfig.icons.home;
		case "media-library":
			return assetsConfig.icons.mediaLibrary;
		case "credit-db":
			return assetsConfig.icons.creditLibrary;
		case "entries":
			return assetsConfig.icons.entries;
		case "edit":
			return assetsConfig.icons.editPencil;
		case "check":
			return assetsConfig.icons.check;
		case "user":
			return assetsConfig.icons.user;
		case "clock":
			return assetsConfig.icons.clock;
		case "download":
			return assetsConfig.icons.download;
		case "calendar":
			return assetsConfig.icons.calendar;
		case "eye":
			return assetsConfig.icons.eye;
		case "triangle":
			return assetsConfig.icons.triangle;
		case "search":
			return assetsConfig.icons.search;
		case "expand-arrows":
			return assetsConfig.icons.expandArrows;
		case "drag-arrows":
			return assetsConfig.icons.dragArrows;
		case "trash":
			return assetsConfig.icons.trash;
		case "invalid":
			return assetsConfig.icons.invalid;
		case "info":
			return assetsConfig.icons.info;
		case "info2":
			return assetsConfig.icons.info2;
		case "crop":
			return assetsConfig.icons.crop;
		case "copy":
			return assetsConfig.icons.copy;
		case "upload":
			return assetsConfig.icons.upload;
		case "play":
			return assetsConfig.icons.play;
		case "pause":
			return assetsConfig.icons.pause;
		case "pause-circle":
			return assetsConfig.icons.pauseCircle;
		case "pause-spinning":
			return <PauseSpinningIcon />;
		case "play-spinning":
			return <PlaySpinningIcon />;
		case "stop":
			return assetsConfig.icons.stop;
		case "stop-spinning":
			return <StopSpinningIcon />;
		case "lock":
			return assetsConfig.icons.lock;
		case "unlocked":
			return assetsConfig.icons.unlocked;
		case "gear":
			return assetsConfig.icons.gear;
		case "logout":
			return assetsConfig.icons.logout;
		case "sidebarlibrary":
			return assetsConfig.icons.sidebarLibrary;
		case "reports":
			return assetsConfig.icons.reports;
		case "judge":
			return assetsConfig.icons.judge;
		case "library":
			return assetsConfig.icons.library;
		case "statue":
			return assetsConfig.icons.statue;
		case "help":
			return assetsConfig.icons.help;
		case "notes":
			return assetsConfig.icons.notes;
		case "link":
			return assetsConfig.icons.link;
		case "document":
			return assetsConfig.icons.document;
		case "doc-play":
			return assetsConfig.icons.docPlay;
		case "audio-play":
			return assetsConfig.icons.audioPlay;
		case "video-play":
			return assetsConfig.icons.videoPlay;
		case "media":
			return assetsConfig.icons.media;
		case "filter":
			return assetsConfig.icons.filter;
		case "player":
			return assetsConfig.icons.player;
		case "star":
			return assetsConfig.icons.star;
		case "list":
			return assetsConfig.icons.list;
		case "grid":
			return assetsConfig.icons.grid;
		case "present":
			return assetsConfig.icons.present;
		case "stop-present":
			return assetsConfig.icons.stopPresent;
		case "zoom":
			return assetsConfig.icons.zoom;
		case "hide":
			return assetsConfig.icons.hide;
		case "tag":
			return assetsConfig.icons.tag;
		case "deselect":
			return assetsConfig.icons.deselect;
		case "flag":
			return assetsConfig.icons.flag;
		case "flagged":
			return assetsConfig.icons.flagged;
		case "like":
			return assetsConfig.icons.like;
		case "liked":
			return assetsConfig.icons.liked;
		case "edit-user":
			return assetsConfig.icons.editUser;
		case "entry-review":
			return assetsConfig.icons.entryReview;
		case "cube":
			return assetsConfig.icons.cube;
		case "judgingNote":
			return assetsConfig.icons.judgingNote;
		case "jurorFeedback":
			return assetsConfig.icons.jurorFeedback;
		case "reviewerNote":
			return assetsConfig.icons.reviewerNote;
		case "startMessage":
			return assetsConfig.icons.startMessage;
		case "bell":
			return assetsConfig.icons.bell;
		case "loading":
			return <img src={assetsConfig.loading.primary} alt="loading" />;
		default:
			return <></>;
	}
};

const Icon = (props: IconProps) => {
	const history = useHistory();

	const handleClick = () => {
		props.onClick && props.onClick();
		props.to && history.push(props.to);
	};

	return (
		<div className={`${props.className ? props.className : ""}`}>
			<IconWrapper
				id={props.id}
				color={props.color}
				{...(props.width && {
					width: props.width,
				})}
				{...(props.height && {
					height: props.height,
				})}
				// temp set close icon to 20px
				{...(props.icon === "close" &&
					!props.width && {
						width: "20px",
					})}
				{...(props.icon === "close" &&
					!props.height && {
						height: "20px",
					})}
				{...(props.rotation && {
					rotation: props.rotation,
				})}
				{...(props.alt && {
					alt: props.alt,
				})}
				onClick={() => (props.readonly ? {} : handleClick())}
				readonly={props.readonly}
				defaultCursor={props.defaultCursor}
			>
				<ColoredSVG color={props.color}>
					{getIcon(props.icon, props.alt)}
				</ColoredSVG>
			</IconWrapper>
		</div>
	);
};

export default Icon;

interface IconWrapperProps {
	color: string;
	width?: string;
	height?: string;
	onClick?(): void;
	rotation?: string;
	isCollapsed?: boolean;
	readonly?: boolean;
	defaultCursor?: boolean;
}
interface IconProps {
	icon: string;
	// iconLeft?: string;
	// iconRight?: boolean;
	color: string;
	id?: string;
	to?: string;
	className?: string;
	width?: string;
	height?: string;
	// children?: React.ReactNode;
	onClick?(): void;
	rotation?: string;
	//isCollapsed?: boolean;
	readonly?: boolean;
	alt?: string;
	defaultCursor?: boolean;
}
