import {
	GalleryEntryCard,
	GalleryQuickVote,
	VoteOption,
} from "../JudgingInterfaces";
import styled, { css, useTheme, keyframes } from "styled-components";
import { useState, useEffect } from "react";
import { voteButtonColor } from "../../../Judging/GalleryCard";
import SlotCounter from "react-slot-counter";

const voteBoxBgAnimation = (background: string) => keyframes`
    from {
        background: transparent;
    }

    to {
        background: ${background};
    }
`;

const StyledVoteBox = styled.div<{
	background?: string;
	large?: boolean;
	length: number;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	padding: 0.5rem;
	gap: 0.5rem;
	background: transparent;

	span[aria-hidden="true"] {
		font-size: 22px !important;
		font-weight: 600;
	}

	& > * {
		font-size: 10px;
		line-height: 10px;
	}

	.score {
		/* font-size: ${({ theme }) => theme.xSmallSize};
    line-height: ${({ theme }) => theme.xSmallSize}; */
		font-size: 24px;
		line-height: 24px;
		font-weight: 700;
	}

	/* animate bg color */
	${(p) =>
		p.background
			? css`
					font-weight: ${p.theme.fontSemiBold};
					animation: ${voteBoxBgAnimation(p.background)} 750ms ease forwards;
			  `
			: `
        border: 1px solid ${p.theme.colorBorderLight};
    `}

	${(p) =>
		p.background &&
		(p.background === p.theme.colorAwardGold ||
			p.background === p.theme.colorAwardSilver ||
			p.background === p.theme.colorAwardBronze) &&
		css`
			color: ${p.theme.colorCopyDarkDark};
		`}

     ${(p) =>
		p.length >= 5 &&
		css`
			width: 80px;
		`}

    ${(p) =>
		p.length >= 6 &&
		css`
			width: 100px;
		`}

  ${(p) =>
		p.large &&
		css`
			width: 80px;
			height: 80px;
			.score {
				font-size: 2rem;
				line-height: 2rem;
				font-weight: ${p.theme.fontSemiBold};
			}

			.award {
				font-size: 0.75rem;
				line-height: 0.75rem;
			}

			${p.length >= 5 &&
			css`
				width: 100px;
			`}

			${p.length >= 6 &&
			css`
				width: 135px;
			`}
		`}
`;

export function initializeGroups(
	roundType: string,
	voteOptions: VoteOption[],
	awardOnly: boolean,
	votedOnly: boolean,
	includeAbstain: boolean,
	includeUnvoted?: boolean,
	scoreAndAbstainOnly?: boolean
): AwardGroup[] {
	let groups: AwardGroup[] = [];
	if (votedOnly) {
		groups.push({ name: "Voted", count: 0, value: 1, multiplier: 1 });
	} else {
		groups = [
			...voteOptions
				.filter((x) =>
					awardOnly
						? x.isAward
						: x.value || x.value === 0
						? true
						: includeAbstain
				)
				.map((option) => ({
					name: option.name,
					count: 0,
					value: option.value,
					multiplier: getRoundMultiplier(roundType),
				})),
		];
	}

	if (scoreAndAbstainOnly) {
		groups = groups.filter((x) => x.name === "Abstain");
	}

	if (includeUnvoted) {
		groups.push({ name: "Unvoted", count: 0, multiplier: 0 });
	}

	return groups;
}

export function getRoundMultiplier(roundType: string) {
	switch (roundType) {
		case "In/Out":
			return 50;
		case "1-10":
			return 10;
		default:
			return 1;
	}
}

export function calculateEntryAwardIndicator(
	roundEntry: GalleryEntryCard,
	roundType: string,
	voteOptions: VoteOption[],
	awardOnly: boolean,
	votedOnly: boolean
): string | undefined {
	// if not every vote has been cast
	// return "Unvoted"
	if (!roundEntry.votes.every((x) => x.voteOptionId)) {
		return "Unvoted";
	}

	if (votedOnly) {
		return "Voted";
	}

	// if the entry has already been awarded, return award
	if (roundEntry.awardLevel) {
		return roundEntry.awardLevel;
	}

	const roundMultiplier = getRoundMultiplier(roundType);

	return voteOptions
		.filter((x) => (awardOnly ? x.isAward : true))
		.find((x) =>
			x.value || x.value === 0
				? roundEntry.score! >= x.value * roundMultiplier
				: true
		)?.name;
}

export function calculateEntryAwardGroups({
	roundEntries,
	roundType,
	voteOptions,
	awardOnly,
	votedOnly,
	includeAbstain,
	includeUnvoted,
	scoreAndAbstainOnly,
}: AwardSummaryBoxProps): AwardGroup[] {
	let groups: AwardGroup[] = initializeGroups(
		roundType,
		voteOptions,
		awardOnly,
		votedOnly,
		includeAbstain,
		includeUnvoted,
		scoreAndAbstainOnly
	);

	roundEntries?.forEach((entry) => {
		const award = calculateEntryAwardIndicator(
			entry,
			roundType,
			voteOptions,
			awardOnly,
			votedOnly
		);
		if (award) {
			const group = groups.find((group) => group.name === award);
			if (group) {
				group.count += 1;
			}
		}
	});

	return groups;
}

export function calculateVoteAwardIndicator(
	vote: GalleryQuickVote,
	awardOnly: boolean,
	votedOnly: boolean
) {
	// if vote has not been cast
	// return "Unvoted"
	if (!vote.voteOption) {
		return "Unvoted";
	}

	if (votedOnly) {
		return "Voted";
	}

	if (awardOnly) {
		return vote.voteOption.isAward ? vote.voteOption.name : undefined;
	}

	return vote.voteOption.name;
}

export function calculateVoteAwardGroups({
	roundEntry,
	roundType,
	voteOptions,
	awardOnly,
	votedOnly,
	includeAbstain,
	includeUnvoted,
	scoreAndAbstainOnly,
}: AwardSummaryBoxProps): AwardGroup[] {
	let groups: AwardGroup[] = initializeGroups(
		roundType,
		voteOptions,
		awardOnly,
		votedOnly,
		includeAbstain,
		includeUnvoted,
		scoreAndAbstainOnly
	);

	roundEntry?.votes?.forEach((vote) => {
		const award = calculateVoteAwardIndicator(vote, awardOnly, votedOnly);
		if (award) {
			const group = groups.find((group) => group.name === award);
			if (group) {
				group.count += 1;
			}
		}
	});

	return groups;
}

export const VoteBox = (props: {
	score: number;
	scoreLength: number;
	award: string;
	background?: string;
	revealResults: boolean;
	large?: boolean;
}) => {
	return (
		<StyledVoteBox
			background={props.background}
			large={props.large}
			length={props.scoreLength}
		>
			<span className="score">
				{props.revealResults ? (
					<SlotCounter
						value={props.score}
						valueClassName="text-h2Size font-semibold"
						dummyCharacterCount={3}
					/>
				) : (
					"-"
				)}
			</span>
			<span className="award text-center">{props.award}</span>
		</StyledVoteBox>
	);
};

const AwardSummaryBox = (props: AwardSummaryBoxProps) => {
	const theme = useTheme();
	const [awardSummary, setAwardSummary] = useState<AwardGroup[]>([]);

	useEffect(() => {
		if (props.roundEntry && props.roundEntry.votes) {
			setAwardSummary(calculateVoteAwardGroups(props));
		} else if (props.roundEntries) {
			setAwardSummary(calculateEntryAwardGroups(props));
		} else {
			setAwardSummary([]);
		}
	}, [props]);

	const renderVoteBoxes = () => {
		let voteBoxes = <></>;

		voteBoxes = (
			<>
				{props.scoreAndAbstainOnly && props.roundEntry && (
					<VoteBox
						key={`${props.roundEntry?.id}.Score`}
						score={props.roundEntry.score!}
						scoreLength={String(props.roundEntry.score!).length}
						award="Score"
						revealResults={props.revealResults}
						large={props.large}
					/>
				)}
				{awardSummary.flatMap((award, i) => (
					<VoteBox
						key={`${props.roundEntry?.id}.${award.name}`}
						score={award.count}
						scoreLength={String(award.count).length}
						award={award.name}
						background={
							props.revealResults &&
							props.roundEntry &&
							(props.roundEntry.awardLevel === award.name ||
								award.name ===
									calculateEntryAwardIndicator(
										props.roundEntry,
										props.roundType,
										props.voteOptions,
										props.awardOnly,
										props.votedOnly
									))
								? voteButtonColor(award.name, theme)
								: undefined
						}
						revealResults={props.revealResults}
						large={props.large}
					/>
				))}
			</>
		);
		return voteBoxes;
	};

	return renderVoteBoxes();
};

export default AwardSummaryBox;

export interface AwardGroup {
	name: string;
	count: number;
	value?: number;
	multiplier: number;
}

export interface AwardSummaryBoxProps {
	roundEntry?: GalleryEntryCard;
	roundEntries?: GalleryEntryCard[];
	roundType: string;
	voteOptions: VoteOption[];
	awardOnly: boolean;
	votedOnly: boolean;
	includeAbstain: boolean;
	revealResults: boolean;
	includeUnvoted?: boolean;
	scoreAndAbstainOnly?: boolean;
	large?: boolean;
}
