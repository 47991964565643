import { useLocation } from "react-router-dom";
import { useCurrentUser } from "./useCurrentUser";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useEffect } from "react";

const useHubspotTracking = () => {
	const location = useLocation();
	const { user } = useCurrentUser();
	const { setPathPageView, setIdentity, setTrackPageView, setContentType } =
		useTrackingCode();
	const currentPath = location.pathname;

	useEffect(() => {
		if (user) {
			setIdentity(user.email);
		}
	}, [user]);

	useEffect(() => {
		if (currentPath) {
			setPathPageView(currentPath);
			setTrackPageView();
		}
	}, [currentPath]);

	return { currentPath };
};
export default useHubspotTracking;
