import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import Button from "../../../../components/Button/Button";
import { Fragment, useContext, useEffect, useState } from "react";
import { useFormik, FormikProvider, FieldArray } from "formik";
import * as Yup from "yup";
import TextField from "../../../../components/FormFields/TextField";
import { ProgramHierarchyContainer } from "../../Program/ProgramHierarchyNav";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import Icon from "../../../../components/Icon/Icon";
import styled, { useTheme, css } from "styled-components";
import { EntrySetContainer } from "./EntrySetTab";
import { ButtonContainer, JuryConfigTabProps } from "./JudgingConfigJury";
import {
	finalizeRoundAssignment,
	getJuryForRoundAssigments,
	saveRoundAssignments,
} from "../manageJudging";
import {
	JuryCardModel,
	JuryConfig,
	JurorRoundJoin,
	JurorCardConfig,
} from "../JudgingInterfaces";

import React from "react";
import { WarningModal } from "../../../../components/Modal/Modal";
import Loading from "../../../../components/Loading/Loading";
import config from "../../../../config";
import { useAlert } from "../../../../components/Alert/Alerts";
import Table from "../../../../components/Table/Table";

const StyledTable = styled(Table)`
	// center the column labels, except for the first four columns
	& > .sort:not(:nth-child(-n + 4)) {
		justify-content: center;
	}
`;

const CenterLabel = css`
	display: flex;
	justify-content: center;
	/* width: 8%; */
`;

const Filters = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding: 1rem 19px;
	color: ${({ theme }) => theme.colorCopyLightLight};
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	p {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.round {
			${CenterLabel};
		}
	}
`;

const LoadingSpinnder = styled.img`
	width: 11%;
	display: inline-flex;
`;

const Test = styled(ProgramHierarchyContainer)`
	.round {
		${CenterLabel};
	}
`;

const RoundAssignmentTab = (props: JuryConfigTabProps) => {
	const theme = useTheme();
	const [isPageLoading, setIsPageLoading] = useState(true);
	const [loading, setLoading] = useState(false);
	const [confirmFinalizeJury, setConfirmedFinalizeJury] = useState(false);
	const [jury, setJury] = useState<JuryConfig>({
		id: 0,
		isEntrySetFinal: false,
		isJuryPoolFinal: false,
		isRoundAssignmentFinal: false,
		isRoundConfigurationFinal: false,
		juryPool: [],
		name: "",
		numberOfJuryGroups: 1,
		programId: 0,
		roundStructures: [],
	});
	const { addNewAlert } = useAlert();

	const handleSave = () => {
		saveRoundAssignments(formikProps.values)
			.then((res) => {
				if (res.status == 200) {
					fetchJury();
					addNewAlert({
						type: "success",
						message: "Saved Round Assignments",
					});
				}
			})
			.catch((err) => {
				//console.log("jurors error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem saving Round Assignments",
				});
			});
	};

	const fetchJury = () => {
		getJuryForRoundAssigments(Number(props.juryId))
			.then((res) => {
				if (res.status == 200) {
					setJury({ ...res.data });
					setIsPageLoading(false);
				}
			})
			.catch((err) => {
				//console.log("jury error: ", err);
				addNewAlert({
					type: "error",
					message: "Problem getting jury card",
				});
			});
	};

	const formikProps = useFormik({
		initialValues: jury,
		enableReinitialize: true,
		onSubmit: async (value) => {},
		validationSchema: Yup.object({}),
	});

	const handleAddAssignmentFormik = (
		jurorId: number,
		roundStructureIndex: number,
		roundStructureId: number
	) => {
		const roundPool =
			(formikProps.values.roundStructures[roundStructureIndex]
				.juryRoundPool && [
				...formikProps.values.roundStructures[roundStructureIndex]
					.juryRoundPool!,
			]) ||
			[];

		const newJoin: JurorRoundJoin = {
			id: 0,
			jurorId: jurorId,
			roundStructureId: roundStructureId,
		};

		const newRoundPool = [...roundPool, newJoin];

		formikProps.setValues({
			...formikProps.values,
			roundStructures: formikProps.values.roundStructures.map(
				(structure, i) => {
					if (i === roundStructureIndex) {
						return {
							...structure,
							juryRoundPool: newRoundPool,
						};
					} else {
						return structure;
					}
				}
			),
		});
	};

	const handleRemoveAssignmentFormik = (
		jurorId: number,
		roundStructureIndex: number
	) => {
		const roundPool =
			formikProps.values.roundStructures[roundStructureIndex].juryRoundPool;
		if (!roundPool) return;

		const newRoundPool = roundPool.filter((join) => join.jurorId !== jurorId);

		formikProps.setValues({
			...formikProps.values,
			roundStructures: formikProps.values.roundStructures.map(
				(structure, i) => {
					if (i === roundStructureIndex) {
						return {
							...structure,
							juryRoundPool: newRoundPool,
						};
					} else {
						return structure;
					}
				}
			),
		});
	};

	const randomizeJurors = (juryPool: JurorCardConfig[], numGroups: number) => {
		const totalJurors = juryPool.length;
		const targetJurorsPerGroup = Math.ceil(totalJurors / numGroups);
		let groups = new Array(numGroups).fill(0);

		// shuffle the jury pool
		for (let i = totalJurors - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[juryPool[i], juryPool[j]] = [juryPool[j], juryPool[i]];
		}

		// assign groups in order
		for (let i = 0; i < totalJurors; i++) {
			const groupIndex = i % numGroups;
			juryPool[i].juryGroup = groupIndex + 1;
			groups[groupIndex]++;
		}

		// return the updated jury pool sorted by id
		return juryPool.sort((a, b) => a.id - b.id);
	};

	useEffect(() => {
		fetchJury();
	}, [props.juryId]);

	// useEffect(() => {
	//   console.log("Values have changed:", formikProps.values);
	// }, [formikProps.values]);

	return (
		<FormikProvider value={formikProps}>
			<WarningModal
				show={confirmFinalizeJury}
				title="Finalize Jury"
				message="Finalizing a Jury cannot be undone. Do you want to finalize the jury?"
				close={() => setConfirmedFinalizeJury(false)}
			>
				<Button
					disabled={loading}
					className="button-light"
					onClick={() => setConfirmedFinalizeJury(false)}
				>
					No
				</Button>
				<Button
					disabled={loading}
					onClick={() => {
						setLoading(true);
						finalizeRoundAssignment(Number(props.juryId)).then((res) => {
							if (res.status == 204) {
								props.setJuryCard((prevState) => {
									return {
										...(prevState as JuryCardModel),
										isRoundAssignmentFinal: true,
									};
								});
								setConfirmedFinalizeJury(false);
							}
							setLoading(false);
						});
					}}
				>
					{loading ? (
						<LoadingSpinnder src={config.assets.loading.primary} />
					) : (
						"Yes"
					)}
				</Button>
			</WarningModal>
			{isPageLoading ? (
				<Loading fullScreen={false} showLogo={false} />
			) : (
				<>
					<EntrySetContainer>
						<h2>Split Jury Into Groups</h2>
						<div className="flex">
							<TextField
								className="w-[150px]"
								name="numberOfJuryGroups"
								type="number"
								max={formikProps.values.juryPool.length}
								nonFormik
								placeholder="Groups"
								onChange={(e) => {
									const newValue = parseInt(e.target.value, 10);
									if (newValue === 0 || isNaN(newValue)) {
										formikProps.setFieldValue("numberOfJuryGroups", 1);
									} else {
										formikProps.setFieldValue("numberOfJuryGroups", newValue);
									}
								}}
								value={formikProps.values.numberOfJuryGroups}
							/>

							<Button
								onClick={() => {
									if (
										formikProps.values.juryPool.length <
										formikProps.values.numberOfJuryGroups
									) {
										return addNewAlert({
											type: "error",
											message: "Groups cannot exceed jurors",
										});
									}
									formikProps.setValues({
										...formikProps.values,
										juryPool: randomizeJurors(
											formikProps.values.juryPool,
											formikProps.values.numberOfJuryGroups
										),
									});
								}}
							>
								Split
							</Button>
						</div>
					</EntrySetContainer>
					<CardBody className="flex flex-col gap-[1rem]">
						<h2>Jurors/Rounds Assignment</h2>

						<StyledTable
							columnLabels={[
								"Juror Name",
								"Group",
								"Company Name",
								"Company City",
								...formikProps.values.roundStructures.map(
									(roundStructure) => roundStructure.name
								),
							]}
							isActive={true}
							dualScroll={true}
						>
							<FieldArray name="juryPool" validateOnChange={false}>
								{() =>
									formikProps.values.juryPool &&
									formikProps.values.juryPool.length > 0 &&
									formikProps.values.juryPool.map((juror, i) => (
										<Fragment key={juror.id}>
											<div className="cell">
												<p>{juror.fullName}</p>
											</div>
											<div className="cell">
												<TextField
													className="min-w-[80px]"
													type="number"
													name={`juryPool.${i}.juryGroup`}
													value={juror.juryGroup}
													onChange={(e) =>
														formikProps.setFieldValue(
															`juryPool.${i}.juryGroup`,
															e.target.value
														)
													}
													nonFormik
													hiddenlabel
													height="40px"
												/>
											</div>
											<div className="cell">
												<p>{juror.companyName}</p>
											</div>
											<div className="cell">
												<p>{juror.companyCity}</p>
											</div>

											{formikProps.values.roundStructures &&
												formikProps.values.roundStructures.length > 0 &&
												formikProps.values.roundStructures.map(
													(round, index) => {
														return (
															<div className="cell justify-center">
																<CheckboxField
																	key={index}
																	className="round"
																	label=""
																	nonFormik
																	onChange={(checked) => {
																		if (checked) {
																			handleAddAssignmentFormik(
																				juror.id,
																				index,
																				round.id
																			);
																		} else {
																			handleRemoveAssignmentFormik(
																				juror.id,
																				index
																			);
																		}
																	}}
																	name="roundAssignmentTab"
																	checked={
																		round.juryRoundPool &&
																		round.juryRoundPool.findIndex(
																			(x) => x.jurorId == juror.id
																		) > -1
																	}
																/>
															</div>
														);
													}
												)}
										</Fragment>
									))
								}
							</FieldArray>
						</StyledTable>

						{/* <div className="flex flex-col">
							<Filters>
								<p>Juror Name</p>
								<p>Group</p>
								<p>Company Name</p>
								<p>Company City</p>
								{formikProps.values.roundStructures &&
									formikProps.values.roundStructures.length > 0 &&
									formikProps.values.roundStructures.map(
										(roundStructure, index) => (
											<React.Fragment key={index}>
												<p className=" !whitespace-pre-wrap text-center">
													{roundStructure.name}
												</p>
											</React.Fragment>
										)
									)}
								<span className="w-[5%]"></span>
							</Filters>
							<FieldArray name="juryPool" validateOnChange={false}>
								{() =>
									formikProps.values.juryPool &&
									formikProps.values.juryPool.length > 0 &&
									formikProps.values.juryPool.map((juror, i) => (
										<Test key={juror.id}>
											<p>{juror.fullName}</p>
											<TextField
												name={`juryPool.${i}.juryGroup`}
												value={juror.juryGroup}
											/>
											<p>{juror.companyName}</p>
											<p>{juror.companyCity}</p>
											{formikProps.values.roundStructures &&
												formikProps.values.roundStructures.length > 0 &&
												formikProps.values.roundStructures.map(
													(round, index) => {
														return (
															<CheckboxField
																key={index}
																className="round"
																label=""
																nonFormik
																onChange={(checked) => {
																	if (checked) {
																		handleAddAssignmentFormik(
																			juror.id,
																			index,
																			round.id
																		);
																	} else {
																		handleRemoveAssignmentFormik(
																			juror.id,
																			index
																		);
																	}
																}}
																name="roundAssignmentTab"
																checked={
																	round.juryRoundPool &&
																	round.juryRoundPool.findIndex(
																		(x) => x.jurorId == juror.id
																	) > -1
																}
															/>
														);
													}
												)}
										</Test>
									))
								}
							</FieldArray>
						</div> */}

						<ButtonContainer>
							<Button className="button-light" onClick={handleSave}>
								Save
							</Button>

							<Button
								icon="check"
								disabled={
									!props.juryCard || props.juryCard.isRoundAssignmentFinal
								}
								onClick={() => setConfirmedFinalizeJury(true)}
							>
								{props.juryCard && props.juryCard.isRoundAssignmentFinal
									? "Jury Finalized"
									: "Finalize Jury"}
							</Button>
						</ButtonContainer>
					</CardBody>
				</>
			)}
		</FormikProvider>
	);
};

export default RoundAssignmentTab;

enum Rounds {
	InOut = "inOut",
	OneToTen = "oneToTen",
	Statue = "statue",
	GrandNomination = "grandNomination",
	Grand = "grand",
}

interface RoundAssignmentModel {
	id: number;
	jurorName: string;
	group: number;
	companyName: string;
	companyCity: string;
	inOut: boolean;
	oneToTen: boolean;
	statue: boolean;
	grandNomination: boolean;
	grand: boolean;
}
