import { useFormik } from "formik";
import FileUpload from "../../../../components/FormFields/FileUpload";
import * as Yup from "yup";
import { MediaType } from "../../../MediaLibrary/mediaLibrary.model.d";
import { useEffect, useState } from "react";
import {
	updateJudgingResource,
	updateJuryWelcomeVideo,
	updateJuryWelcomeImage,
} from "../manageJudging";
import { CardBody } from "../../../../components/DashboardCard/DashboardCard";
import Button from "../../../../components/Button/Button";
import { useTheme } from "styled-components";
import { useAlert } from "../../../../components/Alert/Alerts";
import {
	getThumbnailSrc,
	ThumbnailSize,
} from "../../../MediaLibrary/manageMediaLibrary";

const WelcomeVideoTab = (props: {
	juryId: string;
	welcomeVideo?: string;
	juryResource?: string;
	welcomeImage?: string;
}) => {
	const { addNewAlert } = useAlert();
	const [isUploadingMedia, setIsUploadingMedia] = useState(false);
	const [isUploadingJudgingResource, setIsUploadingJudgingResource] =
		useState(false);

	const formikProps = useFormik<{
		welcomeVideo?: string;
		welcomeVideoData?: File;
		welcomeImage?: string;
		welcomeImageData?: File;
		juryResource?: string;
		juryResourceData?: File;
	}>({
		initialValues: {
			welcomeVideo: props.welcomeVideo,
			welcomeImage: props.welcomeImage,
			juryResource: props.juryResource,
		},
		onSubmit: async () => {},
		enableReinitialize: true,
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
	});

	const uploadWelcomeVideo = async () => {
		const formData = new FormData();
		formData.append("FormFile", formikProps.values.welcomeVideoData || "");
		formData.append(
			"FileName",
			formikProps.values.welcomeVideoData
				? formikProps.values.welcomeVideoData.name
				: ""
		);

		try {
			const res = await updateJuryWelcomeVideo(formData, Number(props.juryId));

			if (res.status === 200) {
				return Promise.resolve("Sucessfully updated welcome video");
			}
		} catch (err) {
			return Promise.reject("Failed to update welcome video");
		}
	};

	const uploadWelcomeImage = async () => {
		const formData = new FormData();

		formData.append("FormFile", formikProps.values.welcomeImageData || "");
		formData.append(
			"FileName",
			formikProps.values.welcomeImageData?.name || ""
		);
		formData.append(
			"Size",
			formikProps.values.welcomeImageData?.size.toString() || ""
		);

		try {
			const res = await updateJuryWelcomeImage(formData, Number(props.juryId));

			if (res.status === 200) {
				return Promise.resolve("Sucessfully updated welcome image");
			}
		} catch {
			return Promise.reject("Failed to update welcome image");
		}
	};

	// only allow user to upload video or image
	const handleUpload = () => {
		setIsUploadingMedia(true);

		if (
			(formikProps.values.welcomeVideo ||
				formikProps.values.welcomeVideoData) &&
			(formikProps.values.welcomeImage || formikProps.values.welcomeImageData)
		) {
			setIsUploadingMedia(false);
			addNewAlert({
				type: "error",
				message: "Only one media type can be uploaded at a time",
			});
		} else {
			Promise.all([uploadWelcomeVideo(), uploadWelcomeImage()])
				.then(() => {
					addNewAlert({
						type: "success",
						message: "Successfully updated welcome video and image",
					});
				})
				.catch((err) => {
					addNewAlert({
						type: "error",
						message: err,
					});
				})
				.finally(() => {
					setIsUploadingMedia(false);
				});
		}
	};

	const uploadJugingAsset = () => {
		setIsUploadingJudgingResource(true);
		const formData = new FormData();
		formData.append("FormFile", formikProps.values.juryResourceData || "");
		formData.append(
			"FileName",
			formikProps.values.juryResourceData
				? formikProps.values.juryResourceData.name
				: ""
		);

		updateJudgingResource(formData, Number(props.juryId))
			.then((res) => {
				if (res.status === 200) {
					addNewAlert({
						type: "success",
						message: "Sucessfully updated judging resource",
					});
				}
				setIsUploadingJudgingResource(false);
			})
			.catch((err) => {
				addNewAlert({
					type: "error",
					message: err.message,
				});
				setIsUploadingJudgingResource(false);
			});
	};

	return (
		<CardBody className="flex flex-col gap-[1rem]">
			<h2>Welcome Media</h2>
			<p>Only one type of welcome media is allowed.</p>
			<div className="flex gap-[2rem] flex-wrap lg:flex-nowrap">
				<FileUpload
					height="173px"
					id="fileUpload-welcomeVideo"
					onChange={(files) => {
						if (!files || files.length === 0) {
							return;
						}
						formikProps.setFieldValue("welcomeVideoData", files[0].file);
					}}
					{...(formikProps.values.welcomeVideo && {
						value: {
							fileName: "Welcome Video",
							fileType: "video",
							src: formikProps.values.welcomeVideo,
							initial: true,
						},
						disabled: false,
					})}
					remove={() => {
						formikProps.setFieldValue("welcomeVideo", undefined);
						formikProps.setFieldValue("welcomeVideoData", undefined);
					}}
					allowedMediaType={MediaType.Video}
					disabled={isUploadingMedia}
					fileTypeName="Welcome Video"
				/>
				<FileUpload
					height="173px"
					id="fileUpload-welcomeImage"
					onChange={(files) => {
						if (!files || files.length === 0) {
							return;
						}
						formikProps.setFieldValue("welcomeImageData", files[0].file);
					}}
					{...(formikProps.values.welcomeImage && {
						value: {
							fileName: "Welcome Image",
							fileType: "image",
							src: getThumbnailSrc(
								formikProps.values.welcomeImage,
								ThumbnailSize.SMALL
							),
							initial: true,
						},
						disabled: false,
					})}
					remove={() => {
						formikProps.setFieldValue("welcomeImage", undefined);
						formikProps.setFieldValue("welcomeImageData", undefined);
					}}
					allowedMediaType={MediaType.Image}
					disabled={isUploadingMedia}
					fileTypeName="Welcome Image"
				/>
			</div>

			<Button
				className="!w-fit ml-auto"
				icon="check"
				onClick={handleUpload}
				disabled={isUploadingMedia}
				loading={isUploadingMedia}
			>
				Save Changes
			</Button>

			<h2>Judging Resources</h2>

			<FileUpload
				height="173px"
				id="fileUpload-juryResource"
				onChange={(files) => {
					if (!files || files.length === 0) {
						return;
					}
					formikProps.setFieldValue("juryResourceData", files[0].file);
				}}
				{...(formikProps.values.juryResource && {
					value: {
						fileName: "Judging Resource",
						fileType: "application/pdf",
						src:
							process.env.REACT_APP_S3_HOST + formikProps.values.juryResource,
						initial: true,
					},
					disabled: false,
				})}
				remove={() => {
					formikProps.setFieldValue("juryResource", undefined);
					formikProps.setFieldValue("juryResourceData", undefined);
				}}
				allowedMediaType={"application/pdf"}
				disabled={isUploadingJudgingResource}
				fileTypeName="Judging Resource"
			/>

			<Button
				className="!w-fit ml-auto"
				icon="check"
				onClick={uploadJugingAsset}
				disabled={isUploadingJudgingResource}
				loading={isUploadingJudgingResource}
			>
				Save Changes
			</Button>
		</CardBody>
	);
};

export default WelcomeVideoTab;
