import styled, { css, useTheme } from "styled-components";
import Icon from "../Icon/Icon";
import { useEffect, useRef, useState } from "react";
import { Circle } from "../ProgressCircle/ProgressCircle";
import { defaultBoxShadow } from "../../assetsConfig";

const FloatingIcon = styled(Circle)<{ showAnimation?: boolean }>`
	position: fixed;
	display: flex;
	align-items: center;
	gap: 1rem;
	right: 35px;
	bottom: 85px;
	box-shadow: ${defaultBoxShadow};
	z-index: 999999;
	color: ${({ theme }) => theme.colorCopyLightLight};

	${(p) =>
		p.showAnimation
			? css`
					width: 250px;
					animation: ripple 2s linear 3, collapse 150ms linear 6s forwards;

					.label {
						animation: hideText 150ms linear 6s forwards;
					}
			  `
			: css`
					.label {
						display: none;
					}
			  `}

	@keyframes collapse {
		0% {
			width: 250px;
		}

		100% {
			width: 35px;
		}
	}

	@keyframes ripple {
		0% {
			box-shadow: 0 0 0 0rem rgba(1, 85, 151, 0.2),
				0 0 0 0.75rem rgba(1, 85, 151, 0.2), 0 0 0 1.5rem rgba(1, 85, 151, 0.2);
		}
		100% {
			box-shadow: 0 0 0 0.75rem rgba(1, 85, 151, 0.2),
				0 0 0 1.5rem rgba(1, 85, 151, 0.2), 0 0 0 2.5rem rgba(1, 85, 151, 0);
		}
	}

	@keyframes hideText {
		0% {
			display: inline;
		}

		100% {
			display: none;
		}
	}
`;

const FloatingMessageIcon = (props: FloatingMessageIconProps) => {
	const theme = useTheme();
	const iconRef = useRef<HTMLDivElement | null>(null);

	return (
		<>
			<FloatingIcon
				id="messages-floating-icon"
				className="cursor-pointer !border-[35px]"
				color={
					props.isThreadResolved
						? theme.colorFieldDisabledDark
						: theme.colorActivation
				}
				size="35px"
				onClick={() => props.setShowModal(true)}
				ref={iconRef}
				showAnimation={!props.isThreadResolved}
			>
				<span className="label">View Messages</span>
				<Icon
					icon="startMessage"
					color={
						props.isThreadResolved
							? theme.colorCopyDarkDark
							: theme.colorCopyLightLight
					}
					width="30px"
					height="30px"
				/>
			</FloatingIcon>
		</>
	);
};

export default FloatingMessageIcon;

interface FloatingMessageIconProps {
	setShowModal(show: boolean): void;
	isThreadResolved?: boolean;
}
