import { DragCreditAccordion } from "../../../components/DragAccordion/DragCreditAccordion";
import {
	isACompanyCreditField,
	isAIndividualCreditField,
} from "../../Entries/CreditFields";
import { CompanyCredit } from "../Credits/CreditInterfaces";
import {
	CreditType,
	EntryCompanyCreditField,
	EntryIndividualCreditField,
} from "../Program/ProgramInterfaces";
import styled from "styled-components";
import { ErrorText } from "./RenderAdminEntryFields";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";

const Divider = styled.div`
	width: 100%;
	border-top: 2px solid ${({ theme }) => theme.colorBorderLight};
	padding: 0 2rem;
`;

const CreditsContainer = styled.div`
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const RenderCreditFields = (props: RenderCreditFieldsProps) => {
	// if no ind credits, return "No Credit" text
	const noIndCredits = props.creditFields.every((field: any) => {
		if (isAIndividualCreditField(field.programField)) {
			const indCredits = (field as EntryIndividualCreditField).credits;
			return indCredits.length === 0;
		} else return true;
	});

	const requiredErr =
		"This field is required. Please add applicable credits or check the 'N/A' box.";

	const maxErr = "This field is not valid";

	return (
		<>
			{props.creditFields.some(
				(creditField) => creditField.classType === "EntryCompanyCreditField"
			) && (
				<CreditsContainer>
					<h2 className="mb-[1rem] font-normal">Company Credits</h2>
					{props.creditFields.length > 0 &&
						props.creditFields.flatMap((field: any) => {
							if (isACompanyCreditField(field.programField)) {
								const companyCredits = (field as EntryCompanyCreditField)
									.credits;

								// Sort company credits by weight
								const sortedCompanyCredits = companyCredits.sort(
									(a, b) => a.weight! - b.weight!
								);

								const creditRequired =
									(field as EntryCompanyCreditField).programField.minCredits >
									0;

								const isMissingRequiredCredits =
									sortedCompanyCredits.length === 0 &&
									!field.notApplicableAttestation &&
									creditRequired;

								const maxExceeded =
									(field as EntryCompanyCreditField).programField.maxCredits >
										0 &&
									sortedCompanyCredits.length >
										(field as EntryCompanyCreditField).programField.maxCredits;

								return (
									<div>
										<h3 className="mb-[.5rem]">
											{field.programField.title}
											{creditRequired ? "*" : ""}
										</h3>
										{sortedCompanyCredits.length > 0 ? (
											sortedCompanyCredits.map((creditJoin) => (
												<DragCreditAccordion
													className="dropped"
													key={creditJoin.credit.id}
													dropTarget=""
													creditObj={creditJoin.credit}
													creditType={CreditType.Company}
													readOnly
												/>
											))
										) : field.notApplicableAttestation ? (
											<p className="ml-[1rem]">Selected N/A</p>
										) : (
											!isMissingRequiredCredits && (
												<p className="ml-[1rem]">No Credits</p>
											)
										)}
										{isMissingRequiredCredits ? (
											<ErrorText className="ml-[1rem]">{requiredErr}</ErrorText>
										) : (
											maxExceeded && (
												<ErrorText className="ml-[1rem]">{maxErr}</ErrorText>
											)
										)}
									</div>
								);
							} else return [];
						})}
				</CreditsContainer>
			)}

			{props.creditFields.some(
				(creditField) => creditField.classType === "EntryIndividualCreditField"
			) && (
				<>
					<Divider />

					<CreditsContainer>
						<h2 className="mb-[1rem] font-normal">Individual Credits</h2>
						{props.creditFields.length > 0 &&
							props.creditFields.flatMap((field: any, i: number) => {
								if (isAIndividualCreditField(field.programField)) {
									const indCredits = (field as EntryIndividualCreditField)
										.credits;

									// Sort individual credits by weight
									const sortedIndCredits = indCredits.sort(
										(a, b) => a.weight! - b.weight!
									);

									const creditRequired =
										(field as EntryIndividualCreditField).programField
											.minCredits > 0;

									const isMissingRequiredCredits =
										sortedIndCredits.length === 0 &&
										!field.notApplicableAttestation &&
										creditRequired;

									const maxExceeded =
										(field as EntryIndividualCreditField).programField
											.maxCredits > 0 &&
										sortedIndCredits.length >
											(field as EntryIndividualCreditField).programField
												.maxCredits;

									return (
										<div>
											<h3 className="mb-[.5rem]">
												{field.programField.title}
												{creditRequired ? "*" : ""}
											</h3>
											{sortedIndCredits.length > 0
												? sortedIndCredits.map((creditJoin) => (
														<DragCreditAccordion
															className="dropped"
															key={creditJoin.credit.id}
															dropTarget=""
															creditObj={creditJoin.credit}
															creditType={CreditType.Individual}
															readOnly
														/>
												  ))
												: !isMissingRequiredCredits && (
														<p className="ml-[1rem]">No Credits</p>
												  )}
											{isMissingRequiredCredits ? (
												<ErrorText className="ml-[1rem]">
													{requiredErr}
												</ErrorText>
											) : (
												maxExceeded && (
													<ErrorText className="ml-[1rem]">{maxErr}</ErrorText>
												)
											)}
										</div>
									);
								} else return [];
							})}
					</CreditsContainer>
				</>
			)}
		</>
	);
};

export default RenderCreditFields;

interface RenderCreditFieldsProps {
	name: string;
	creditFields: (EntryCompanyCreditField | EntryIndividualCreditField)[];
}
