import { useState } from "react";

const ErrorWrapper = (props: ErrorWrapperProps) => {
	const [error, setError] = useState<string | null | undefined>();

	return <>{props.children(error, setError)}</>;
};

export default ErrorWrapper;

interface ErrorWrapperProps {
	children: (
		error: string | null | undefined,
		setError: React.Dispatch<React.SetStateAction<string | null | undefined>>
	) => React.ReactNode;
}
