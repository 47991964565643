import styled, { useTheme } from "styled-components";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import { EntryNoteType, PreviousNextEntry } from "./EntryDetail";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import { NavLink } from "react-router-dom";
import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import Loading from "../../../components/Loading/Loading";
import assetsConfig from "../../../assetsConfig";
import Image from "../../../components/Image/Image";
import { Circle } from "../../../components/ProgressCircle/ProgressCircle";
import { getEntryNotesByType } from "../../Entries/manageEntry";

const StyledEntryDetailNav = styled.div`
	display: flex;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	justify-content: center;
	/* fix to the bottom */
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 9999;
	height: auto;
	flex-wrap: wrap;

	@media (min-width: ${({ theme }) => theme.lg}) {
		height: 101px;
		flex-wrap: nowrap;
	}
	/* margin-top: 5rem; */

	.icon-left {
		margin: 0;
	}

	.entryNavContainer {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.button-transparent {
		border: 1px solid ${({ theme }) => theme.colorPrimary};
		flex: 1;

		@media only screen and (max-width: 500px) {
			min-width: 95px;
		}
	}

	.first {
		border-left: 0;
	}

	.last {
		border-right: 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.lg}) {
		.button-transparent {
			border: 0;
			flex: unset;
		}

		& > *:not(:last-child) {
			border-right: 5px solid ${({ theme }) => theme.colorBorderDark};
		}

		.first {
			flex: unset;
		}

		.last {
			flex: unset;
		}

		.entryNavContainer {
			margin-left: auto;
			width: fit-content;

			& > *:not(:last-child) {
				border-right: 5px solid ${({ theme }) => theme.colorBorderDark};
			}
		}
	}
`;

const EntryNavigation = styled(NavLink)`
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 2rem;
	color: ${({ theme }) => theme.colorCopyLightLight};
	cursor: pointer;
	@media (max-width: ${({ theme }) => theme.lg}) {
		padding: 1rem;
	}

	img {
		height: 100%;
		width: 100%;
	}
`;

const EntryDetailNav = (props: EntryDetailNavProps) => {
	const theme = useTheme();

	const [jurorNoteCount, setJurorNoteCount] = useState(0);

	const [baseLink, setBaseLink] = useState(
		`/judge/${props.juryId}/${props.entrySetId}`
	);

	const [previousImage, setPreviousImage] = useState(
		(props.previous &&
			props.previous.coverImage &&
			getThumbnailSrc(props.previous.coverImage)) ||
			ImagePlaceholder
	);
	const [previousLabel, setPreviousLabel] = useState(
		props.previous
			? props.previous.batchId === Number(props.batchId)
				? "Previous Entry"
				: "Previous Batch"
			: "Return to Gallery"
	);
	const [previousLink, setPreviousLink] = useState(
		props.previous
			? `/${props.previous.batchId}/${props.previous.entryId}/${props.previous.voteId}`
			: ""
	);

	const [nextImage, setNextImage] = useState(
		(props.next &&
			props.next.coverImage &&
			getThumbnailSrc(props.next.coverImage)) ||
			ImagePlaceholder
	);
	const [nextLabel, setNextLabel] = useState(
		props.next
			? props.next.batchId === Number(props.batchId)
				? "Next Entry"
				: "Next Batch"
			: "Return to Gallery"
	);
	const [nextLink, setNextLink] = useState(
		props.next
			? `/${props.next.batchId}/${props.next.entryId}/${props.next.voteId}`
			: ""
	);

	useEffect(() => {
		setBaseLink(`/judge/${props.juryId}/${props.entrySetId}`);

		setPreviousImage(
			(props.previous &&
				props.previous.coverImage &&
				getThumbnailSrc(props.previous.coverImage)) ||
				ImagePlaceholder
		);
		setPreviousLabel(
			props.previous
				? props.previous.batchId === Number(props.batchId)
					? "Previous Entry"
					: "Previous Batch"
				: "Return to Gallery"
		);
		setPreviousLink(
			props.previous
				? `/${props.previous.batchId}/${props.previous.entryId}/${props.previous.voteId}`
				: ""
		);

		setNextImage(
			(props.next &&
				props.next.coverImage &&
				getThumbnailSrc(props.next.coverImage)) ||
				ImagePlaceholder
		);
		setNextLabel(
			props.next
				? props.next.batchId === Number(props.batchId)
					? "Next Entry"
					: "Next Batch"
				: "Return to Gallery"
		);
		setNextLink(
			props.next
				? `/${props.next.batchId}/${props.next.entryId}/${props.next.voteId}`
				: ""
		);
	}, [
		props.batchId,
		props.juryId,
		props.entrySetId,
		props.previous,
		props.next,
	]);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (previousLabel && nextLabel) {
			if (
				(previousLabel === "Return to Gallery" ||
					(previousLabel !== "Return to Gallery" && previousImage)) &&
				(nextLabel === "Return to Gallery" ||
					(nextLabel !== "Return to Gallery" && nextImage))
			) {
				setLoading(true);
				setTimeout(() => {
					setLoading(false);
				}, 750);
			}
		}
	}, [previousLabel, nextLabel]);

	useEffect(() => {
		getEntryNotesByType(Number(props.entryId), EntryNoteType.Juror).then(
			(data) => {
				if (data) {
					setJurorNoteCount(data.length);
				}
			}
		);
	}, [props.entryId, props.juryId]);

	return (
		<StyledEntryDetailNav>
			{loading ? (
				<img className="h-[100px]" src={assetsConfig.loading.secondary} />
			) : (
				<>
					<Button
						className="button-transparent flex-col w-[175px] first"
						icon="caret"
						iconColor={theme.colorPrimary}
						iconRotation="90deg"
						iconSize="20px"
						to={baseLink}
					>
						Back to Entries
					</Button>
					<Button
						className="button-transparent flex-col w-[175px]"
						icon="info"
						onClick={() => props.showModal(NavModalType.Tip)}
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						Judging Info
					</Button>
					<Button
						className="button-transparent flex-col w-[175px]"
						// icon="notes"
						// iconSize="20px"
						onClick={() => props.showModal(NavModalType.Notes)}
						iconColor={theme.colorPrimary}
					>
						<div className="flex flex-col items-center">
							<div className="relative">
								<Icon
									icon="notes"
									color={theme.colorPrimary}
									width="20px"
									height="46px"
								/>
								{jurorNoteCount > 0 && (
									<Circle
										className="absolute right-[-13px] top-[10px] !border-[4px]"
										color={theme.colorDanger}
										size="4px"
									/>
								)}
							</div>
							Notes {jurorNoteCount > 0 && `(${jurorNoteCount})`}
						</div>
					</Button>
					<Button
						className="button-transparent flex-col w-[175px] last"
						icon="help"
						onClick={() => props.showModal(NavModalType.Help)}
						iconSize="20px"
						iconColor={theme.colorPrimary}
					>
						<span className="hidden lg:inline ">Request Help</span>
						<span className="lg:hidden">Help</span>
					</Button>
					<div className="entryNavContainer">
						<EntryNavigation to={baseLink + previousLink}>
							<Icon
								icon="caret"
								color={theme.colorPrimary}
								rotation="90deg"
								width="20px"
								height="20px"
							/>

							{previousLabel !== "Return to Gallery" && (
								<div className="w-[142px] h-[80px] hidden xl:block">
									{/* <img
										className="object-cover"
										src={previousImage}
										alt="Entry Cover"
									/> */}
									<Image
										className="object-cover"
										key={previousImage}
										src={previousImage}
										placeholderSrc={ImagePlaceholder}
										alt="Entry Cover"
										refetchOnError
									/>
								</div>
							)}

							<p>{previousLabel}</p>
						</EntryNavigation>
						<EntryNavigation to={baseLink + nextLink} className="">
							<p>{nextLabel}</p>

							{nextLabel !== "Return to Gallery" && (
								<div className="w-[142px] h-[80px] hidden xl:block">
									{/* <img
										className="object-cover"
										src={nextImage}
										alt="Next Entry"
									/> */}
									<Image
										className="object-cover"
										key={nextImage}
										src={nextImage}
										placeholderSrc={ImagePlaceholder}
										alt="Entry Cover"
										refetchOnError
									/>
								</div>
							)}

							<Icon
								icon="caret"
								color={theme.colorPrimary}
								rotation="-90deg"
								width="20px"
								height="20px"
							/>
						</EntryNavigation>
					</div>
				</>
			)}
		</StyledEntryDetailNav>
	);
};

export default EntryDetailNav;

interface EntryDetailNavProps {
	showModal(type: NavModalType): void;
	juryId: string;
	entryId: string;
	entrySetId: string;
	batchId: string;
	next?: PreviousNextEntry;
	previous?: PreviousNextEntry;
}

export enum NavModalType {
	Help,
	Tip,
	Notes,
}
