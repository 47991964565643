import { DefaultTheme } from "styled-components";
import Placeholder from "./assets/placeholders/image-landscape.png";
import Spinner from "./assets/spinner-gold.svg";
import SpinnerBlack from "./assets/spinner-black.svg";
import SpinnerGray from "./assets/spinner-gray.svg";
import CheckeredBackgroundImage from "./assets/placeholders/checkered_background.png";
import { AssetsConfig } from "./config";

import { ReactComponent as EchoBg } from "./assets/Clio/echo_background.svg";
import { ReactComponent as Award360White } from "./assets/Clio/Award 360 White.svg";
import { ReactComponent as Award360Black } from "./assets/Clio/Award 360 Black.svg";
import Award360WhitePng from "./assets/Clio/Award 360 White Png.png";
import Award360BlackPng from "./assets/Clio/Award 360 Black Png.png";
import { ReactComponent as NestleWhite } from "./assets/logos/NESTLElogo-with-wordmark-black-vectorial-PMS.svg";
import { ReactComponent as NestleBlack } from "./assets/logos/NESTLElogo-with-wordmark-black-vectorial-PMS-wide-centered.svg";
import { ReactComponent as NestleOak } from "./assets/logos/NESTLElogo-with-wordmark-oak-vectorial-PMS-wide-centered.svg";
import NestleWhitePng from "./assets/logos/NESTLElogo-with-wordmark-white-781x800px-RGB.png";
import NestleBlackPng from "./assets/logos/NestleLogoWordmarkOak.png";

// Clio specific icons
import { ReactComponent as ClioLogo } from "./assets/Clio/clios-horizontal-black.svg";
import ClioLogoPng from "./assets/Clio/CLIOS-Horizontal-Black.png";
import ClioLogoPngWhite from "./assets/Clio/CLIOS-Horizontal-White.png";
import ClioLogoPngSecondaryWhite from "./assets/Clio/CLIOS-Vertical-White.png";
import { ReactComponent as ClioBG } from "./assets/Clio/clio_background.svg";
import SpinnerLoading from "./assets/Clio/clio-loading.svg";
import { ReactComponent as ClioCreditLibrary } from "./assets/Clio/ClioCreditLibrary.svg";
import { ReactComponent as ClioStatue } from "./assets/Clio/ClioStatue.svg";

// global icons
import { ReactComponent as Statue } from "./assets/icons/Statue.svg";
import { ReactComponent as PlusIcon } from "./assets/icons/plus.svg";
import { ReactComponent as CaretDown } from "./assets/icons/Down.svg";
import { ReactComponent as HomeIcon } from "./assets/icons/Programs.svg";
import { ReactComponent as MediaLibIcon } from "./assets/icons/Media Library 2.svg";
import { ReactComponent as EntriesIcon } from "./assets/icons/Media Library.svg";
import { ReactComponent as CheckIcon } from "./assets/icons/Check.svg";
import { ReactComponent as UserIcon } from "./assets/icons/Person.svg";
import { ReactComponent as DownloadIcon } from "./assets/icons/Download.svg";
import { ReactComponent as ClockIcon } from "./assets/icons/Clock.svg";
import { ReactComponent as EditPencilIcon } from "./assets/icons/Write.svg";
import { ReactComponent as CalendarIcon } from "./assets/icons/calendar.svg";
import { ReactComponent as DragArrowsIcon } from "./assets/icons/DragDrop.svg";
import { ReactComponent as ExpandArrowsIcon } from "./assets/icons/Expand.svg";
import { ReactComponent as SearchIcon } from "./assets/icons/Search.svg";
import { ReactComponent as EyeIcon } from "./assets/icons/Preview.svg";
import { ReactComponent as TriangleIcon } from "./assets/icons/Triangle.svg";
import { ReactComponent as CloseIcon } from "./assets/icons/Close.svg";
import { ReactComponent as CloseLarge } from "./assets/icons/CloseLarge.svg";
import { ReactComponent as TrashIcon } from "./assets/icons/Trash.svg";
import { ReactComponent as InvalidIcon } from "./assets/icons/invalid.svg";
import { ReactComponent as InfoIcon } from "./assets/icons/Info.svg";
import { ReactComponent as InfoIcon2 } from "./assets/icons/Info2.svg";
import { ReactComponent as CropIcon } from "./assets/icons/Crop.svg";
import { ReactComponent as CopyIcon } from "./assets/icons/Copy.svg";
import { ReactComponent as UploadIcon } from "./assets/icons/Upload.svg";
import { ReactComponent as ExpandCaret } from "./assets/icons/ExpandCaret.svg";
import { ReactComponent as PlayIcon } from "./assets/icons/Play.svg";
import { ReactComponent as PauseCircleIcon } from "./assets/icons/PauseCircle.svg";
import { ReactComponent as PauseIcon } from "./assets/icons/Pause.svg";
import { ReactComponent as StopIcon } from "./assets/icons/Stop.svg";
import { ReactComponent as LockIcon } from "./assets/icons/Lock.svg";
import { ReactComponent as UnlockedIcon } from "./assets/icons/Unlocked.svg";
import { ReactComponent as SidebarSettingsIcon } from "./assets/icons/SideBarSettings.svg";
import { ReactComponent as SidebarLogoutIcon } from "./assets/icons/SideBarLogout.svg";
import { ReactComponent as SidebarLibraryIcon } from "./assets/icons/SideBarLibrary.svg";
import { ReactComponent as SidebarReportsIcon } from "./assets/icons/SideBarReports.svg";
import { ReactComponent as SidebarJudgeIcon } from "./assets/icons/SideBarJudge.svg";
import { ReactComponent as LibraryIcon } from "./assets/icons/Library.svg";
import { ReactComponent as HelpIcon } from "./assets/icons/Help.svg";
import { ReactComponent as NotesIcon } from "./assets/icons/Notes.svg";
import { ReactComponent as LinkIcon } from "./assets/icons/link.svg";
import { ReactComponent as MediaIcon } from "./assets/icons/Media.svg";
import { ReactComponent as PlayerIcon } from "./assets/icons/Player.svg";
import { ReactComponent as FilterIcon } from "./assets/icons/Filter.svg";
import { ReactComponent as StarIcon } from "./assets/icons/Star.svg";
import { ReactComponent as ListIcon } from "./assets/icons/List.svg";
import { ReactComponent as GridIcon } from "./assets/icons/Grid.svg";
import { ReactComponent as PresentIcon } from "./assets/icons/Present.svg";
import { ReactComponent as StopPresentIcon } from "./assets/icons/StopPresenting.svg";
import { ReactComponent as ZoomIcon } from "./assets/icons/Unzoom.svg";
import { ReactComponent as HideIcon } from "./assets/icons/Hide.svg";
import { ReactComponent as DeselectIcon } from "./assets/icons/Deselect.svg";
import { ReactComponent as TagIcon } from "./assets/icons/Tag.svg";
import { ReactComponent as LikeIcon } from "./assets/icons/Like.svg";
import { ReactComponent as LikedIcon } from "./assets/icons/Liked.svg";
import { ReactComponent as FlagIcon } from "./assets/icons/Flag.svg";
import { ReactComponent as FlaggedIcon } from "./assets/icons/Flagged.svg";
import { ReactComponent as EditUserIcon } from "./assets/icons/EditUser.svg";
import { ReactComponent as DocumentIcon } from "./assets/icons/Doc.svg";
import { ReactComponent as EntryReview } from "./assets/icons/EntryReview.svg";
import { ReactComponent as Cube } from "./assets/icons/Cube.svg";
import { ReactComponent as DocPlay } from "./assets/placeholders/DocPlay.svg";
import { ReactComponent as VideoPlay } from "./assets/placeholders/VideoPlay.svg";
import { ReactComponent as AudioPlay } from "./assets/placeholders/AudioPlay.svg";

import { ReactComponent as JudgingNote } from "./assets/icons/JudgingNote.svg";
import { ReactComponent as JurorFeedback } from "./assets/icons/JurorFeedback.svg";
import { ReactComponent as ReviewerNote } from "./assets/icons/ReviewerNote.svg";
import { ReactComponent as StartMessage } from "./assets/icons/StartMessage.svg";
import { ReactComponent as Bell } from "./assets/icons/Bell.svg";

// alerts
import AlertBlue from "./assets/icons/alert-blue.png";
import AlertBlack from "./assets/icons/alert-black.png";
import AlertGray from "./assets/icons/alert-gray.png";
import AlertWhite from "./assets/icons/alert-white.png";
import AlertGold from "./assets/icons/alert-gold.png";

// animated SVGs
import { ReactComponent as PauseSpinning } from "./assets/icons/Pause-spinning.svg";
import { ReactComponent as PlaySpinning } from "./assets/icons/Play-spinning.svg";
import { ReactComponent as StopSpinning } from "./assets/icons/Stop-spinning.svg";
import lowerCase from "./utils/lowerCase";

export const defaultColors = {
	colorPrimaryDark: "#30261d", // Dark oak
	colorPrimary: "#64513d", // oak
	colorPrimaryTint: "rgba(100, 81, 61, 0.75)", // oak tint
	colorPrimaryAccent: "#492F38", // burgundy

	//colorActivation: "#8b7c6d", // Oak 75
	colorActivation: "#006937",
	colorActivationDark: "#30261d", // Dark oak

	colorButtonPrimary: "rgb(100, 81, 61)", // oak
	colorToggleBlue: "#008DC3", // baby blue
	colorButtonNavy: "#0B131D", // navy

	colorGrand: "#6E3788",
	colorNoGrand: "#363636",
	colorAwardGold: "#FBCA0E",
	colorAwardSilver: "#ACB6BA",
	colorAwardBronze: "#CD7F32",
	colorShortlist: "#000000",

	colorAwardOne: "#EBF0F6",
	colorAwardTwo: "#D7E2ED",
	colorAwardThree: "#C3D4E4",
	colorAwardFour: "#AFC5DB",
	colorAwardFive: "#9BB7D1",
	colorAwardSix: "#88A9C8",
	colorAwardSeven: "#739ABF",
	colorAwardEight: "#608CB6",
	colorAwardNine: "#4C7DAD",
	colorAwardTen: "#386FA4", // cornflower blue

	// vote button colors
	colorIn: "#014F86", // baby blue
	colorOut: "#A20202", // out red
	colorAbstain: "#666666", // dark gray

	colorDanger: "#ab0033", // PANTONE 207C Red
	colorSuccess: "#61a60e", // PANTONE 369C green
	colorWarning: "#fae345", // PANTONE 106C yellow

	colorProgressBackground: "#e8e5e2", // oak 15
	colorProgressBackgroundDark: "#30261d", // oak 75
	colorSelected: "#d1cbc5", // selected credits and media

	colorLogoLightLight: "#FFFFFF",
	colorLogoDarkDark: "#30261d",
};

// specific components colors for light theme toggle
const lightComponentColors = {
	colorTextField: "#FFFFFF", // white
};

// specific components colors for dark theme toggle
const darkComponentColors = {
	colorTextField: "rgba(68, 69, 70, 0.63)", // transparent gray
};

export const lightThemeColors = {
	...lightComponentColors,

	colorBackgroundDark: "#151413", // off black
	colorBackgroundDarkDark: "#000000", // black
	colorBackgroundLight: "#F6F6F5", // off white
	colorBackgroundLightLight: "#FFFFFF", // white
	colorBackgroundMedium: "#1F1F1F", // dark gray

	colorCopyDarkDark: "#000000", // black
	colorCopyDark: "#5F5F5F", // dark gray text
	colorCopyLight: "#a2968a", // oak 60
	colorCopyLightLight: "#FFFFFF", // white

	colorLinkLight: "#686868", // light gray

	colorButtonDark: "rgb(19, 19, 19)", // off black
	colorButtonLight: "#FFFFFF", // white

	colorBorder: "#979797",
	colorBorderLight: "#e8e5e2", // oak 15
	colorBorderDark: "#121212",
	colorFormText: "#686868",

	colorFieldDisabled: "#CCCCCC",
	colorFieldDisabledDark: "#bbb",
	colorTextDisabled: "#000000",
	colorLabelDisabled: "rgba(0,0,0,0.75)",
	colorFieldReadOnly: "#F0F0F0",

	colorBoxShadow: "rgb(0 0 0 / 15%)", // !!!!!!!!!! NEED TO UPDATE
	gradientBackgroundLight: "rgba(232, 229, 226, 1)", //.9
	gradientBackgroundDark: "rgba(232, 229, 226, 1)", //.9
	colorBackdropDark: "rgba(0, 0, 0, 0.85)",
	colorPatternBGDark: "rgba(29, 19, 11, 0.9)",
};

export const darkThemeColors = {
	...darkComponentColors,

	colorBackgroundDarkDark: "#ffffff",
	colorBackgroundDark: "#e8e5e2",
	colorBackgroundMedium: "#b9b1a8",
	colorBackgroundLight: "#64513d",
	colorBackgroundLightLight: "#30261d",

	colorCopyDarkDark:  "#ffffff",
	colorCopyDark: "#e8e5e2",
	colorCopyLight:  "#64513d", //"#a2968a", // oak 75
	colorCopyLightLight: "#30261d",

	colorLinkLight: "#99d9d9", // light gray

	colorButtonDark: "rgb(255,255,255)",
	colorButtonLight: "rgb(21, 20, 19)",

	colorBorder: "#979797", // !!!!!!!!!! NEED TO UPDATE
	colorBorderLight: "#e5e7eb", // !!!!!!!!!! NEED TO UPDATE
	colorBorderDark: "#121212", // !!!!!!!!!! NEED TO UPDATE
	colorFormText: "#686868", // !!!!!!!!!! NEED TO UPDATE

	colorFieldDisabled: "#CCCCCC",
	colorFieldDisabledDark: "#bbb",
	colorTextDisabled: "#000000",
	colorLabelDisabled: "rgba(0,0,0,0.75)",
	colorFieldReadOnly: "#F0F0F0",

	colorBoxShadow: "rgb(0 0 0 / 15%)", // !!!!!!!!!! NEED TO UPDATE
	gradientBackgroundLight: "rgba(48, 38, 29, 1)", //.9
	gradientBackgroundDark: "rgba(48, 38, 29, 1)", //.9
	colorBackdropDark: "rgba(0, 0, 0, 0.85)",
	colorPatternBGDark: "rgba(29, 19, 11, 0.9)",
};

export const defaultBoxShadow =
	"0 2px 8px 0 " + lightThemeColors.colorBoxShadow;

export const lightBoxShadow =
	"rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px";

export const gradients = {
	gradientBlackGray: "linear-gradient(180deg, #22211e 0%, #000000 100%)",
	gradientBlackSlate:
		"linear-gradient(90deg, rgba(255, 255, 255, 0.5) 16.82%, black 100%)",
	mobileMenu: "linear-gradient(180deg, #21201e, #000000 20%, #000000)",
};


export const nestleGradients = {
	gradientBlackGray: "linear-gradient(180deg, #e8e5e2 0%, #e8e5e2 100%)",
	gradientBlackSlate:
		"linear-gradient(90deg, rgba(255, 255, 255, 0.5) 16.82%, black 100%)",
	mobileMenu: "linear-gradient(180deg, #e8e5e2, #000000 20%, #e8e5e2)",
};

export const hoverColors = {
	colorButtonHover: "#013f6e", // darker blue
	colorButtonDarkHover: "#212121", //dark gray
	colorButtonLightHover: "#DADADA", //slate gray
};

export const pdfColors = {
	textHighlight: "#30261d",
	textLight: "#8e8e8e",
};

export const graphColors = {
	bar: "#131415",
	text: "#666",
	statusColors: {
		a: "#97caeb",
		b: "#99d9d9",
		c: "#a2d45e",
		d: "#fae345",
		e: "#ff8300",
		f: "#ff595a",
		g: "#ee6fbb",
		h: "#cb8bda",
		i: "#8b7c6d",
		j: "#d1cbc5",
	},
};

const breakpoints = {
	// LOWest TESTED RESOLUTION IS 360
	xxs: "360px",
	xs: "480px",
	sm: "576px",
	md: "768px",
	lg: "992px",
	xl: "1200px",
	xxl: "1400px",
};

const fontSizes = {
	fontBrand: `'Open Sans', sans-serif`,
	fontHeading: `'Open Sans', sans-serif`,
	fontCopy: `'Open Sans', sans-serif`,
	fontBrandSrc:
		"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
	fontHeadingSrc:
		"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
	fontCopySrc:
		"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap",
	fontDefaultWeight: "400",
	fontMediumWeight: "500",
	fontSemiBold: "600",

	h1Size: "30px",
	h2Size: "22px",
	h3Size: "18px",
	h4Size: "18px",
	h5Size: "16px",
	h6Size: "14px",
	pSize: "16px",
	aSize: "16px",
	spanSize: "16px",
	xSmallSize: "14px",
	xxSmallSize: "13px",
	xxxSmallSize: "12px",
};

export const lightTheme: DefaultTheme = {
	...defaultColors,
	...lightThemeColors,
	...gradients,
	...hoverColors,
	...fontSizes,
	...breakpoints,
};

export const darkTheme: DefaultTheme = {
	...defaultColors,
	...darkThemeColors,
	...nestleGradients,
	...hoverColors,
	...fontSizes,
	...breakpoints,
};

const labels = {
	// capitalize labels by default
	entry: {
		singular: "Submission",
		plural: "Submissions",
	},
	entryReview: {
		singular: "Admin Review",
		plural: "Admin Review",
	},
	company: {
		singular: "Group",
		plural: "Groups",
	},
	allPrograms: {
		singular: "Home",
		plural: "Home",
	},
	review: {
		singular: "Admin Approval",
		plural: "Admin Approvals",
	},
	reviewer: {
		singular: "Admin Approver",
		plural: "Admin Approvers",
	},
	execution: {
		singular: "Execution",
		plural: "Executions",
	},
	jurorFeedback: {
		singular: "Reviewer Feedback",
		plural: "Reviewer Feedback",
	},
};

const copy = {
	loginMessage:
		'Welcome! If you do not have an account, please click "Create Account" below.',
	registerMessage:
		// `To create new ${lowerCase(labels.entry.plural)}, you must fill in your contact details on this page. On the following page, you will be asked for your ${lowerCase(labels.company.singular)} contact information.`,
		'',
	registerAddressMessage: "Please provide your professional address.",
	registerPasswordMessage:
		"Your password must have eight or more characters, must have at least one uppercase and one lowercase letter, must include at least one number and one special character (such as !?^*$ etc).",
	companyEntryCreationMessage: "THIS IS A PLACEHOLDER, IT IS NOT YET USED",
	userInfoAddressMessage: "Please provide your professional address.",
	companyPointOfContactMessage:
		`If you will not be the main contact for any finance or ${lowerCase(labels.entry.singular)} questions, please unselect the boxes below and identify who we should reach out to for any financial or ${lowerCase(labels.entry.singular)}-related questions.`,
	companyEntryCreationInformation: `To create new ${lowerCase(labels.entry.plural)}, please provide information about your  ${lowerCase(labels.company.singular)}.`,
	entryFormTitleDescription: `<p>The title is a short name for this ${lowerCase(labels.entry.singular)}. Keep it memorable, descriptive and short.</p>`,
	entryFormBrandDescription: `<p>Select your brand from the dropdown. If you do not see your brand, select other.</p>`,
	entryFormCoverImageDescription:
		`Please provide a cover image for your ${lowerCase(labels.entry.singular)}. This image will display wherever your ${lowerCase(labels.entry.singular)} appears in a list or gallery with other ${lowerCase(labels.entry.plural)}.`,
	entryFormCampaignOrSingleDescription:
		`Do you want to submit a single piece of work on its own, or a collection of "execuctions" that make up a larger campaign?`,
	entryFormCampaignExecutionsDescription:
		`<p>In this area, you can gather your ${lowerCase(labels.entry.singular)} assets into "executions". Executions reflect a part of your ${lowerCase(labels.entry.singular)} that you would like reviewed as a cohesive unit.</p>
		<br />
		<p>You can add more than one of these executions by clicking "Add Execution" toward the bottom of this page.</p>
		<br />
		<p>Give each execution a short, descriptive title, and add media to each execution.</p>
		<br />
		<p>You can change the order of your executions at the bottom of the page.</p>`,
	entryFormDisplayOrderDescription:
		`In this section, you may change the order your executions display in your ${lowerCase(labels.entry.singular)}.`,
	entryFormReviewDescription:
	`You may review your ${lowerCase(labels.entry.singular)} here for any typos or other issues before you submit it. While you can edit your work after it is submitted, you shouldn't submit until the ${lowerCase(labels.entry.singular)} is complete.`,
	entryFormReviewTOS: `<p className="mb-[1.25rem]">I'm ready to submit</p>`,
	draftEntryDescription:
		`Your unsubmitted ${lowerCase(labels.entry.plural)} appear here. Click "Edit ${labels.entry.singular}" to complete any required fields. From here, you will be able to submit your completed ${lowerCase(labels.entry.plural)}.`,
	cartEntryDescription: `The ${lowerCase(labels.entry.plural)} on this page are ready for submission. You may send any ${lowerCase(labels.entry.plural)} back to drafts by clicking "Send to Drafts". Once you are ready, you can click "Submit ${labels.entry.plural}" below to let an Admin know your projects are ready for review.`,
	ordersTabDescription:
		"Your complete " +
		lowerCase(labels.entry.plural) +
		" appear on this page for your reference. You may perform any available actions on your " +
		lowerCase(labels.entry.plural) +
		" here.",
	mediaLibraryInstructions:
		"Left-click on a card to view or edit a single piece of media. Hold down SHIFT and left-click on one or more cards to select multiple pieces. You can then tag them, delete them, or mark them as inactive with the toolbar at the bottom of the screen.",
	creditLibraryInstructions:
		"Hold down SHIFT and left-click on one or more credit to select multiple pieces. You can then tag them, delete them, or mark them as inactive with the toolbar at the bottom of the screen.",
	tagLibraryInstructions:
		"Left-click on a card to view or edit a single tag. Hold down SHIFT and left-click on one or more cards to select multiple tags. You can then edit them or delete them with the toolbar at the bottom of the screen.",
	creditUploadInstructions: `<ol>
  <li>
    Download the appropriate Credit Template File:
    <ul>
      <li onClick={downloadIndividualCreditSampleCsvFile}>
        <a href="javascript:void(0)">Individual</a>
      </li>
      <li onClick={downloadCompanyCreditSampleCsvFile}>
        <a href="javascript:void(0)">Companies</a>
      </li>
    </ul>
  </li>
  <li class="list">
    Open the file in Excel, Google Sheets, or your editor of choice
  </li>
  <li class="list">
    Fill in your credit information; required information will be
    marked with an asterisk (*). Do not change the column headers
  </li>
  <li class="list">Save the file as a CSV</li>
  <li class="list">
    Upload the file with your credits on the Individual Credit or
    Company Credit pages, as appropriate
  </li>
</ol>
<p>
  After you finish your upload, your credits will appear in your
  library, and you will see messages about any errors that occurred
  during the upload. You can then add any credits you have uploaded
  to your ${lowerCase(labels.entry.plural)}.
</p>
<p class="boldedHeader">Important Notes</p>
<ul>
  <li>
    If you want to add more than one tag to a credit, separate the
    tags with a semicolon (;)
  </li>
  <li>
    If a credit you are adding already exists, it will not be added
    again
  </li>
  <li>
    You can add tags to an existing credit by including it in the
    upload with text in the "Tags" column
  </li>
  <li>
    Do not change the column headers in the template. If you do,
    your upload will fail
  </li>
</ul>`,
	judgingWelcomeText: `<p class="text-center">Welcome, and thank you for participating in [JURY NAME] review.</p> <p class="text-center">To begin, select an option below.</p>`,
	mediaUploadText: `
          <p>
						Click on the box below or drag files from your desktop into the box
						below to upload files to your media library. You may upload images,
						video files, mp3 files or PDFs.
					</p>
					<p>
						Once you add them here, click the "Add Media" button. This will save
						your files to your library. Once saved, you will be able to drag
						them from your library into any entry.
					</p>`,
	disabledMessageModalText: `<p>
	Please contact support at <a href="mailto:contact@awards-360.com">contact@awards-360.com</a> for any
	additional questions or concerns.
</p>`,
	newUserText: `Please enter the name and email address of the user you're adding. If the user exists and does not have a ${lowerCase(
		labels.company.singular
	)}, they will be added to this ${lowerCase(
		labels.company.singular
	)}. Otherwise a new user will be created.`,
					};

const assetsConfig: AssetsConfig = {
	companyName: "Nestlé",
	companyNameShort: "nestlé",
	companyNameLong: "Nestle",
	holdingCompanyName: "Scope -",
	agencyNetworkCompanyName: "Scope",
	logos: {
		local: <NestleOak title="Nestlé Logo" />,
		localPng: NestleBlackPng,
		primary: NestleBlackPng,
		primaryWhite: NestleWhitePng,
		secondary: NestleBlackPng,
	},
	icons: {
		// clio icons
		creditLibrary: <ClioCreditLibrary title="Credit Library" />,
		statue: <Statue title="Trophy" />,

		// global icons
		plus: <PlusIcon title="Plus" />,
		close: <CloseIcon title="Close" />,
		closeLarge: <CloseLarge title="Close" />,
		caret: <CaretDown title="Caret Down" />,
		expand: <ExpandCaret title="Expand" />,
		alertBlue: <img src={AlertBlue} alt="Alert" />,
		alertBlack: <img src={AlertBlack} alt="Alert" />,
		alertGold: <img src={AlertGold} alt="Alert" />,
		alertWhite: <img src={AlertWhite} alt="Alert" />,
		alertGray: <img src={AlertGray} alt="Alert" />,
		home: <HomeIcon title="Home" />,
		mediaLibrary: <MediaLibIcon title="Media Library" />,
		entries: <EntriesIcon title="Entries" />,
		editPencil: <EditPencilIcon title="Edit" />,
		check: <CheckIcon title="Check" />,
		user: <UserIcon title="User" />,
		clock: <ClockIcon title="Clock" />,
		download: <DownloadIcon title="Download" />,
		calendar: <CalendarIcon title="Calendar" />,
		eye: <EyeIcon title="Eye" />,
		triangle: <TriangleIcon title="Triangle" />,
		search: <SearchIcon title="Search" />,
		expandArrows: <ExpandArrowsIcon title="Expand" />,
		dragArrows: <DragArrowsIcon title="Drag" />,
		trash: <TrashIcon title="Trash" />,
		invalid: <InvalidIcon title="Invalid" />,
		info: <InfoIcon title="Info" />,
		info2: <InfoIcon2 title="Info" />,
		crop: <CropIcon title="Crop" />,
		copy: <CopyIcon title="Copy" />,
		upload: <UploadIcon title="Upload" />,
		play: <PlayIcon title="Play" />,
		pause: <PauseIcon title="Pause" />,
		pauseCircle: <PauseCircleIcon title="Pause" />,
		pauseSpinning: <PauseSpinning title="Pause Loading" />,
		playSpinning: <PlaySpinning title="Play Loading" />,
		stop: <StopIcon title="Stop" />,
		stopSpinning: <StopSpinning title="Stop Loading" />,
		lock: <LockIcon title="Lock" />,
		unlocked: <UnlockedIcon title="Unlocked" />,
		gear: <SidebarSettingsIcon title="Settings" />,
		logout: <SidebarLogoutIcon title="Logout" />,
		sidebarLibrary: <SidebarLibraryIcon title="Library" />,
		reports: <SidebarReportsIcon title="Reports" />,
		judge: <SidebarJudgeIcon title="Judge" />,
		library: <LibraryIcon title="Library" />,
		help: <HelpIcon title="Help" />,
		notes: <NotesIcon title="Notes" />,
		link: <LinkIcon title="Link" />,
		document: <DocumentIcon title="Documennt" />,
		docPlay: <DocPlay title="Show Document" />,
		audioPlay: <AudioPlay title="Play Audio" />,
		videoPlay: <VideoPlay title="Play Video" />,
		media: <MediaIcon title="Media" />,
		filter: <FilterIcon title="Filter" />,
		player: <PlayerIcon title="Media Player" />,
		star: <StarIcon title="Star" />,
		list: <ListIcon title="List" />,
		grid: <GridIcon title="Grid" />,
		present: <PresentIcon title="Present Media" />,
		stopPresent: <StopPresentIcon title="Stop Presenting Media" />,
		zoom: <ZoomIcon title="Zoom" />,
		hide: <HideIcon title="Hide" />,
		tag: <TagIcon title="Tag" />,
		deselect: <DeselectIcon title="Deselect" />,
		flag: <FlagIcon title="Flag" />,
		flagged: <FlaggedIcon title="Flagged" />,
		like: <LikeIcon title="Like" />,
		liked: <LikedIcon title="Liked" />,
		editUser: <EditUserIcon title="Edit User" />,
		entryReview: <EntryReview title="Entry Review" />,
		cube: <Cube title="Physical Component" />,
		judgingNote: <JudgingNote title="Judging Note" />,
		jurorFeedback: <JurorFeedback title="Juror Feedback" />,
		reviewerNote: <ReviewerNote title="Reviewer Note" />,
		startMessage: <StartMessage title="Start Message" />,
		bell: <Bell title="Bell" />,
	},
	loading: {
		primary: SpinnerLoading,
		secondary: Spinner,
		tertiary: SpinnerBlack,
		quaternary: SpinnerGray,
	},
	backgrounds: {
		primary: EchoBg,
	},
	placeholders: {
		image: Placeholder,
		imageBackground: CheckeredBackgroundImage,
	},
	fonts: {
		pdf: {
			fontFamily: "Open Sans",
			fonts: [
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
					fontWeight: 400,
				},
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
					fontWeight: 600,
				},
				{
					src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf",
					fontWeight: 400,
					fontStyle: "italic",
				},
			],
		},
	},
	urls: {
		contactEmail: "contact@awards-360.com",
		judgingContactEmail: "contact@awards-360.com",
	},
	copy: copy,
	labels: labels,
	colors: darkTheme,
	settings: {
		showHelpWidget: false,
	},
};

export default assetsConfig;
