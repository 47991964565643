import styled, { useTheme } from "styled-components";
import Icon from "../../../../components/Icon/Icon";
import { useState, useEffect, useContext, Fragment, useRef } from "react";
import { DynamicTransitionDuration } from "../../../../globalStyles";
import CheckboxField from "../../../../components/FormFields/CheckboxField";
import { FormikProvider, useFormik } from "formik";
import { useJurorHub } from "../../../../hooks/useJurorHub";
import { useJuryHub } from "../../../../hooks/useJuryHub";
import {
	EntryDetailsModel,
	EntryNote,
	MediaField,
	RenderDateField,
} from "../../../Judging/EntryDetail/EntryDetail";
import { getEntryDetailsForJuror } from "../../../Entries/manageEntry";
import MediaCarousel from "../../../../components/MediaCarousel/MediaCarousel";
import { EntryField, LinkSet, TextType } from "../../Program/ProgramInterfaces";
import {
	MediaItem,
	MediaType,
} from "../../../MediaLibrary/mediaLibrary.model.d";
// import { MediaType } from "../../Program/FieldTemplateForms/CustomFieldTypes";
import Button from "../../../../components/Button/Button";
import EntryDetailMediaSelector from "../../../Judging/EntryDetail/EntryDetailMediaSelector";
import { RenderMediaThumbnail } from "../../../Judging/EntryDetail/MediaLightbox";
import Loading from "../../../../components/Loading/Loading";
import classNames from "classnames";
import {
	GalleryEntryCard,
	GalleryQuickVote,
	JuryCardAdmin,
	LiveToolsMediaType,
	LiveToolsSettings,
	VoteOption,
} from "../JudgingInterfaces";
import { JurorNotes } from "../../../Judging/EntryDetail/EntryNotesForm";
import DropdownField from "../../../../components/FormFields/DropdownField";
import MediaViewer from "./MediaControl";
import AwardSummaryBox from "./AwardSummaryBox";
import SearchBar from "../../../../components/SearchBar/SearchBar";
import TextField from "../../../../components/FormFields/TextField";
import InfoPopup from "../../../../components/InfoPopup/InfoPopup";
import ClipboardText from "../../../../components/ClipboardText/ClipboardText";
import MediaControl from "./MediaControl";
import { getEntryAlsoIn, getVoteOptions } from "../manageJudging";
import Modal, { ModalCard } from "../../../../components/Modal/Modal";
import { Link, useHistory } from "react-router-dom";
import {
	voteButtonColor,
	showNoGrandForAbstain,
} from "../../../Judging/GalleryCard";
import {
	BatchHierarchyDefinition,
	LiveToolsMediaItem,
} from "../JudgingInterfaces";

import { StyledScoreIndicator } from "./MediaViewer";
import { StyledVerticalDivider } from "../../../Judging/JudgingGallery";
import { VerticalDivider } from "../../../../components/Breadcrumbs/Breadcrumbs";
import { Execution } from "../../../Judging/EntryDetail/EntryDetail";
import SlotCounter from "react-slot-counter";
import config from "../../../../config";
import { defaultBoxShadow } from "../../../../assetsConfig";
import {
	ProgressFill,
	ProgressIndicator,
} from "../../../Judging/JudgingGalleryNav";
import { useAlert } from "../../../../components/Alert/Alerts";
import useTextWrap from "../../../../hooks/useTextWrap";
import parse from "html-react-parser";

const displayPercentage = (num: number) => {
	return String(Math.round(num * 100)) + "%";
};

const StyledStar = styled(Icon)<{ outline: string }>`
	path {
		stroke: ${(p) => (p.outline ? p.outline : "transparent")};
	}
`;

const VoteList = styled.div`
	display: grid;
	grid-template-columns: 4fr 4fr 2fr 2fr;
	column-gap: 2rem;
	align-items: center;
	padding: 0.5rem 1.5rem;
	border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};
`;

const StyledEntryDetailPane = styled.div<{ isExpanded?: Boolean }>`
	position: fixed;
	bottom: 0;
	display: flex;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};
	justify-content: center;
	width: 100%;
	right: 0px;
	/* color: ${({ theme }) => theme.colorCopyLightLight}; */

	.collapsible {
		max-height: ${({ isExpanded }) => (isExpanded ? "2000px" : "0")};
		transition: all 500ms ease;
	}

	flex-wrap: wrap;
	z-index: 9999;

	.borderStyle {
		border: 1px solid ${({ theme }) => theme.colorBackgroundMedium};
		border-bottom-width: 3px;
		margin-top: 1em;
		width: 100%;
	}

	.centerCaret {
		position: absolute;
		height: 55px;
		width: 70px;
		left: 50%;
		transform: translate(-50%, -10%);
		border-radius: 50%;
		padding-bottom: 5px;
		background: ${({ theme }) => theme.colorBackgroundDarkDark};
		z-index: 0;
		cursor: pointer;
	}
	.caretTransform {
		transform: ${({ isExpanded }) => (isExpanded ? "" : "rotate(180deg)")};
		transition: transform ${DynamicTransitionDuration} ease;
		padding-top: ${({ isExpanded }) => (isExpanded ? "" : "10px")};
	}
	.checkboxstyling {
		/* input {
      border-width: 20px;
      height: 30px;
      width: 30px;
      border-color: ${({ theme }) => theme.colorPrimary} !important;
    } */
		label {
			color: white;
		}
	}

	.button-gold {
		color: ${({ theme }) => theme.colorCopyLightLight};
		width: 150px;
	}

	.EntryToolBarStyling {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 1rem;
		width: 100%;
		padding: 1rem 2rem;
		background-color: ${({ theme }) => theme.colorBackgroundDark};
	}
`;

const AlsoList = styled.div`
	display: flex;
	gap: 2rem;
	align-items: center;
	padding: 0.5rem 1rem;
	border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};

	@media only screen and (max-width: ${({ theme }) => theme.sm}) {
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.5rem;
	}
`;

const ModalBody = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.5rem;

	.entry-details-grid {
		max-height: calc(100vh - (2.75rem * 2) - (1.5rem * 2) - 131px);
		overflow-y: auto;
		background-color: ${({ theme }) => theme.colorBoxShadow};
		padding: 1rem;
	}
`;

const FieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colorBackgroundLight};
	height: fit-content;
`;

const LinkFieldWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: ${({ theme }) => theme.colorBackgroundLight};
	height: fit-content;
	gap: 0.5rem;
`;

const FieldContainer = styled.div`
	padding: 1rem 1.25rem;
	background: ${({ theme }) => theme.colorFieldReadOnly};
	margin-top: 0.5rem;
	box-shadow: ${defaultBoxShadow};
`;

// const ScoreIndicator = styled.div<{ background: string }>`
//   width: 35px;
//   height: 35px;
//   font-weight: ${({ theme }) => theme.fontSemiBold};
//   cursor: default;
//   background: ${(p) => p.background};

//   &:hover {
//     background: ${(p) => p.background};
//   }
//   border-radius: 50%;
//   text-align: center;
//   padding-top: 7px;
// `;

const RenderTextField = (props: RenderTextFieldProps) => {
	const theme = useTheme();
	const { wrapText } = useTextWrap();
	/* const FieldWrapper = (fieldWrapperProps: { children: React.ReactNode }) => (
    <span className="flex items-center gap-[.5rem] flex-wrap lg:flex-nowrap">
      {fieldWrapperProps.children}
      {props.jurorHelpText && props.jurorHelpText !== " " && (
        <InfoPopup>{props.jurorHelpText}</InfoPopup>
      )}
    </span>
  ); */
	if (props.text && props.text !== " ") {
		if (props.textType === TextType.Paragraph) {
			return (
				<div className="flex flex-col gap-[.5rem]">
					<span className="flex items-center gap-[.5rem]">
						<p className={`text-[1.125rem] font-medium lg:whitespace-nowrap"`}>
							{props.title}
						</p>
					</span>
					<FieldContainer>
						<p className="whitespace-pre-wrap">
							{wrapText(
								typeof props.text === "string" ? parse(props.text) : props.text
							)}
						</p>
					</FieldContainer>
					{/* <TextField
            className="flex-1 mt-2"
            name=""
            readOnly={true}
            hiddenlabel={true}
            value={props.text}
            component="textarea"
          /> */}
				</div>
			);
		} else
			return (
				<FieldWrapper>
					<p className={`text-[1.125rem] font-medium lg:whitespace-nowrap"`}>
						{props.title}:
					</p>
					<p className="leading-[1.125rem]">{props.text}</p>
				</FieldWrapper>
			);
	} else return <></>;
};
const RenderLinkField = (props: LinkField) => {
	const theme = useTheme();
	const getHTTPLink = (url: string) => {
		const trimmedUrl = url.replace(/\t/g, "").trim();
		if (
			trimmedUrl.indexOf("https://") === 0 ||
			trimmedUrl.indexOf("http://") === 0
		) {
			return trimmedUrl;
		} else {
			return `https://${trimmedUrl}`;
		}
	};
	if (props.links && props.links.length > 0) {
		return (
			<LinkFieldWrapper className="flex flex-col gap-[.5rem]">
				<span className="flex items-center gap-[.5rem] flex-wrap">
					<p className="text-[1.125rem] font-medium">{props.title}</p>
					{/* {props.jurorHelpText && props.jurorHelpText !== " " && (
            <InfoPopup>{props.jurorHelpText}</InfoPopup>
          )} */}
				</span>
				{props.links.map((linkSet, i) => {
					return (
						<div key={linkSet.id} className="flex flex-col">
							<a
								className="text-colorActivation font-semibold mr-[1rem] break-all"
								href={getHTTPLink(linkSet.link)}
								target="_blank"
								rel="noreferrer"
							>
								{linkSet.link}
							</a>
							<div className="flex items-center gap-x-[2rem] flex-wrap">
								{linkSet.username && (
									<ClipboardText
										displayText={linkSet.username}
										copyText={linkSet.username}
										toolTip="Copy username"
										copiedToolTip="Username copied!"
										icon={
											<Icon
												icon="user"
												width="15px"
												height="15px"
												color={theme.colorCopyLight}
											/>
										}
									/>
								)}
								{linkSet.password && (
									<ClipboardText
										displayText={linkSet.password}
										copyText={linkSet.password}
										toolTip="Copy password"
										copiedToolTip="Password copied!"
										icon={
											<Icon
												icon="lock"
												width="17px"
												height="17px"
												color={theme.colorCopyLight}
											/>
										}
									/>
								)}
							</div>
							<Icon
								icon="present"
								color={
									props.mediaState.filePath === getHTTPLink(linkSet.link)
										? theme.colorPrimary
										: theme.colorCopyLight
								}
								width="25px"
								height="25px"
								onClick={() => {
									if (props.mediaState.filePath === getHTTPLink(linkSet.link)) {
										props.updateMedia({
											...props.mediaState,
											filePath: undefined,
											mediaType: LiveToolsMediaType.PauseSlide,
											awardLevel: undefined,
											brand: undefined,
											title: undefined,
											text: undefined,
											timestamp: 0,
											xLocation: undefined,
											yLocation: undefined,
											zoomLevel: undefined,
											isPlaying: undefined,
										});
									} else {
										props.updateMedia({
											...props.mediaState,
											filePath: getHTTPLink(linkSet.link),
											mediaType: LiveToolsMediaType.Link,
											awardLevel: undefined,
											brand: linkSet.username ? linkSet.username : undefined,
											title: linkSet.password ? linkSet.password : undefined,
											text: undefined,
											timestamp: 0,
											xLocation: undefined,
											yLocation: undefined,
											zoomLevel: undefined,
											isPlaying: undefined,
										});
									}
								}}
							/>
						</div>
					);
				})}
			</LinkFieldWrapper>
		);
	} else return <></>;
};

const RenderBatchDef = (props: {
	def: BatchHierarchyDefinition[] | undefined;
	setShowModal(): void;
	setModalContent(level: BatchHierarchyDefinition): void;
	dividerSize?: string;
	executions?: number;
}) => {
	const [batchHierarchyDefinitions, setBatchHierarchyDefinitions] = useState<
		BatchHierarchyDefinition[]
	>([]);

	useEffect(() => {
		if (props.def) {
			setBatchHierarchyDefinitions(props.def);
		}
	}, [props.def]);

	return batchHierarchyDefinitions.length > 1 ? (
		<>
			{batchHierarchyDefinitions.flatMap((level, i) =>
				i === 0 ? (
					[]
				) : (
					<div className="flex items-center" key={`hierarchy.${level.name}`}>
						<b
							key={`hierarchy.label.${level.name}`}
							className={level.definition ? "underline cursor-pointer" : ""}
							{...(level.definition && {
								onClick: () => {
									props.setModalContent(level);
									props.setShowModal();
								},
							})}
						>
							{level.name}
						</b>
						{i + 1 !== batchHierarchyDefinitions.length && (
							<VerticalDivider
								className="!ml-[.5rem] !mr-[.75rem]"
								size={props.dividerSize ? props.dividerSize : ".75rem"}
								key={`hierarchy.divider.${level.name}`}
							/>
						)}
					</div>
				)
			)}
			{props.executions! > 1 && <div>&nbsp;({props.executions})</div>}
		</>
	) : (
		<></>
	);
};

const EntryDetailPane = (props: EntryDetailPaneProps) => {
	const theme = useTheme();
	const { addNewAlert } = useAlert();

	const [isExpanded, setExpanded] = useState<Boolean>(false);
	const [activeMediaPreview, setActiveMediaPreview] =
		useState<MediaItem | null>(null);

	//   const [activeMediaViewerState, setActiveMediaViewerState] =
	//     useState<LiveToolsMediaItem | null>(null);
	const [showSpinner, setShowSpinner] = useState(false);
	const [showVotePanel, setShowVotePanel] = useState(false);
	const [showNotesModal, setShowNotesModal] = useState(false);
	const [showJudgingNoteModal, setShowJudgingNoteModal] = useState(false);
	const [showVotePanelAfterNoteClose, setShowVotePanelAfterNoteClose] =
		useState(false);
	// used to display notes written by the selected juror
	const [selectedJuror, setSelectedJuror] = useState<undefined | string>();
	// allows admin to change vote directly
	const [allowVoteChange, setAllowVoteChange] = useState(false);

	const [entryAlsoIn, setEntryAlsoIn] = useState<GalleryEntryCard[]>([]);
	const [showEntryDetails, setShowEntryDetails] = useState(false);
	const [showAlsoInModal, setShowAlsoInModal] = useState(false);
	const [showBatchDef, setShowBatchDef] = useState(false);
	const [batchDef, setBatchDef] = useState<null | BatchHierarchyDefinition>(
		null
	);
	const [title, setTitle] = useState<string | undefined>();
	const linksCount = props.entryDetails?.linkFields.reduce(
		(count, field) => count + field.links.length,
		0
	);
	//   const [mediaCount, setMediaCount] = useState<MediaCount>({
	//     audio: 0,
	//     documents: 0,
	//     images: 0,
	//     videos: 0,
	//   });

	useEffect(() => {
		if (props.roundEntry && props.roundEntry.id) {
			getEntryAlsoIn(
				props.roundEntry.juryId,
				props.roundEntry.roundStructureId,
				props.roundEntry.entryId,
				props.roundEntry.brand,
				props.roundEntry.title
			).then((response) => {
				if (response.status === 200) {
					setEntryAlsoIn(response.data);
				}
			});
		}
	}, [props.roundEntry?.id]);

	// play first media when switching execution tabs
	const handleExecutionTab = (tabIndex: number) => {
		// const firstMedia =
		//   props.entryDetails.executions &&
		//   props.values.executions[tabIndex].mediaFields[0].media[0];
		// firstMedia && setActiveMediaPreview(firstMedia);
	};

	const flattenExecution = (executionArr?: Execution[]) => {
		return executionArr
			? executionArr
					.map((execution) =>
						execution.mediaFields.flatMap((field) =>
							field.media.length > 0
								? field.media.map((media) => ({
										...media,
										title: field?.title,
								  }))
								: []
						)
					)
					.flat()
			: [];
	};

	const flattenEntryMedia = (entryMediaFields: MediaField[]) => {
		return entryMediaFields.length > 0
			? entryMediaFields
					.flatMap((field) =>
						field.media.length > 0
							? field.media.map((media) => ({
									...media,
									title: field?.title,
							  }))
							: []
					)

					.flat()
			: [];
	};

	const formikProps = useFormik({
		initialValues: {
			lock: props.roundEntry?.isLocked || false,
		},
		enableReinitialize: true,
		onSubmit: () => {},
	});

	const executions = props.entryDetails?.executions;
	const entryLevelMedia = props.entryDetails?.mediaFields
		? props.entryDetails?.mediaFields
		: [];

	const hasEntryLevelMedia = props.entryDetails?.mediaFields.some(
		(field) => field.media.length > 0
	);

	// treat entry level media as an execution
	const entryMediaExecution: Execution[] = hasEntryLevelMedia
		? [
				{
					index: 0,
					programLabel: "",
					programDescription: "",
					id: 1,
					title: "Entry Level Media",
					programName: "",
					listFields: [],
					shortTextFields: [],
					longTextFields: [],
					dateFields: [],
					linkFields: [],
					mediaFields: entryLevelMedia,
				},
		  ]
		: [];

	const entryMediaWithExecution = executions
		? entryMediaExecution.concat(executions)
		: [];

	const exeWithoutDuplicateMedia = () => {
		let unqiueMediaIdArr: string[] = [];

		const filteredMediaExecutions = entryMediaWithExecution.map(
			(execution) => ({
				...execution,
				mediaFields: execution.mediaFields.map((mediaField) => ({
					...mediaField,
					media: mediaField.media.flatMap((media) => {
						// only return media items if it doesn't exist in another field or execution
						if (media.id && unqiueMediaIdArr.includes(media.id)) {
							return [];
						} else {
							media.id && unqiueMediaIdArr.push(media.id);
							return media;
						}
					}),
				})),
			})
		);

		return filteredMediaExecutions;
	};

	const carouselItems = exeWithoutDuplicateMedia().flatMap(
		(execution, executionIndex: number) => {
			const mediaItems = execution.mediaFields.flatMap(
				(field: MediaField, mediaFieldIndex: number) =>
					field.media.map((media: MediaItem, i: number) => {
						// return field/media index that is the last media item in the last media field
						const lastFieldMediaIndex = () => {
							for (let x = execution.mediaFields.length - 1; x >= 0; x--) {
								const mediaLength = execution.mediaFields[x].media.length;
								const hasMedia = mediaLength > 0;

								if (hasMedia) {
									return {
										fieldIndex: x,
										mediaIndex: mediaLength - 1,
									};
								}
							}
						};

						const firstFieldMediaIndex = () => {
							for (let x = 0; x <= execution.mediaFields.length - 1; x++) {
								const mediaLength = execution.mediaFields[x].media.length;
								const hasMedia = mediaLength > 0;

								if (hasMedia) {
									return {
										fieldIndex: x,
										mediaIndex: mediaLength - 1,
									};
								}
							}
						};

						const firstItemInExecution =
							mediaFieldIndex === firstFieldMediaIndex()?.fieldIndex &&
							i === firstFieldMediaIndex()?.mediaIndex
								? true
								: false;

						const lastItemInExecution =
							exeWithoutDuplicateMedia().length > 1
								? // should not be the last execution
								  executionIndex !== exeWithoutDuplicateMedia().length - 1 &&
								  mediaFieldIndex === lastFieldMediaIndex()?.fieldIndex &&
								  i === lastFieldMediaIndex()?.mediaIndex
								: false;

						return (
							<div
								className={classNames(" flex-col relative inline-flex", {
									// divider is not a child of the carousel but we still want to show it in between executions
									"w-[176px]": !lastItemInExecution,
									"w-[211px]": lastItemInExecution,
								})}
								key={`${executionIndex}-${mediaFieldIndex}-${i}`}
							>
								{exeWithoutDuplicateMedia().length > 1 &&
									execution.title &&
									firstItemInExecution !== false && (
										<b className="mb-[.5rem] text-[0.875rem] text-colorCopyLightLight w-[160px]">
											{execution.title}
										</b>
									)}
								<RenderMediaThumbnail
									key={media.id}
									media={media}
									onClick={(media) => {
										setActiveMediaPreview(media);
										setTitle(field.title);
									}}
									selected={
										activeMediaPreview
											? media.id === activeMediaPreview.id
											: false
									}
									showIcons={true}
								/>
								{lastItemInExecution && (
									// render divider on the right side of the last item in the execution
									<span className="text-colorCopyLightLight w-[2px] h-full border-colorCopyLight border-l-[2px] mx-[.5rem] absolute right-[17px] top-0"></span>
								)}

								<b className="mt-[.5rem] ml-[.5rem] text-[0.875rem] text-colorCopyLightLight">
									{field.title}
								</b>
							</div>
						);
					})
			);

			return mediaItems;
		}
	);

	// remove duplicate media for <MediaControl>
	const flattenedMedia = flattenEntryMedia(entryLevelMedia)
		.concat(flattenExecution(executions))
		.filter(
			(obj1, i, arr) => arr.findIndex((obj2) => obj2.id === obj1.id) === i
		);

	const countMedia = () => {
		let mediaCount = {
			audio: 0,
			documents: 0,
			images: 0,
			videos: 0,
		};

		let unqiueMediaIdArr: string[] = [];

		if (executions) {
			executions.forEach((execution) =>
				execution.mediaFields.forEach((mediaField) => {
					mediaField.media.forEach((mediaItem) => {
						// only count non-duplicate media
						if (mediaItem.id && !unqiueMediaIdArr.includes(mediaItem.id)) {
							unqiueMediaIdArr.push(mediaItem.id);

							switch (mediaItem.type) {
								case MediaType.Audio:
									mediaCount.audio = mediaCount.audio + 1;
									break;
								case MediaType.Document:
									mediaCount.documents = mediaCount.documents + 1;
									break;
								case MediaType.Image:
									mediaCount.images = mediaCount.images + 1;
									break;
								case MediaType.Video:
									mediaCount.videos = mediaCount.videos + 1;
									break;
							}
						}
					});
				})
			);
		}

		if (entryLevelMedia.length > 0) {
			entryLevelMedia.forEach((mediaField) => {
				mediaField.media.forEach((mediaItem) => {
					// only count non-duplicate media
					if (mediaItem.id && !unqiueMediaIdArr.includes(mediaItem.id)) {
						unqiueMediaIdArr.push(mediaItem.id);

						switch (mediaItem.type) {
							case MediaType.Audio:
								mediaCount.audio = mediaCount.audio + 1;
								break;
							case MediaType.Document:
								mediaCount.documents = mediaCount.documents + 1;
								break;
							case MediaType.Image:
								mediaCount.images = mediaCount.images + 1;
								break;
							case MediaType.Video:
								mediaCount.videos = mediaCount.videos + 1;
								break;
						}
					}
				});
			});
		}
		return mediaCount;
	};

	const showTitleSlide = () => {
		if (props.roundEntry) {
			props.updateMedia({
				...props.mediaState,
				awardLevel: props.calculateScoreIndicator(props.roundEntry),
				brand: props.roundEntry.brand,
				title: props.roundEntry.title,
				filePath: props.roundEntry.coverImage,
				mediaType: LiveToolsMediaType.EntryTitleSlide,
				text: props.roundEntry.batchHierarchyDefinitions
					?.map((x) => x.name)
					?.slice(1)
					?.join(" > "),
				audioCount: countMedia().audio,
				documentCount: countMedia().documents,
				imageCount: countMedia().images,
				videoCount: countMedia().videos,
				entryId: props.roundEntry.entryId,
				isPlaying: undefined,
			});
		}
	};

	// show loading spinner while entry detail pane loads
	useEffect(() => {
		if (!props.entryDetails && props.clickedEntry) {
			setShowSpinner(true);
		} else {
			setShowSpinner(false);
			props.resetClickedEntry();
		}
	}, [props.clickedEntry]);

	// update title slide as votes come in
	useEffect(() => {
		if (
			props.mediaState.mediaType === LiveToolsMediaType.EntryTitleSlide &&
			props.mediaState.entryId === props.roundEntry?.entryId
		) {
			showTitleSlide();
		}
	}, [props.roundEntry]);

	return (
		<StyledEntryDetailPane isExpanded={isExpanded}>
			{props.entryDetails && props.roundEntry ? (
				<>
					{/*juror notes modal */}
					<Modal show={showNotesModal}>
						<ModalCard
							className="!max-h-[80vh] !w-[80vw] overflow-hidden"
							title="Juror Notes"
							headerIcons={[
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => {
										setShowNotesModal(false);

										if (showVotePanelAfterNoteClose) {
											setShowVotePanel(true);
											setShowVotePanelAfterNoteClose(false);
										}
									}}
									width="35px"
									height="35px"
								/>,
							]}
							iconColor={theme.colorPrimary}
						>
							<div className="flex flex-col gap-[2rem] p-[1rem_2rem]">
								<JurorNotes
									notes={props.entryNotes}
									selectedJuror={selectedJuror}
								/>
							</div>
						</ModalCard>
					</Modal>

					{/*entry judging modal */}
					<Modal show={showJudgingNoteModal}>
						<ModalCard
							className="!max-h-[80vh] !w-[80vw] overflow-hidden"
							title="Judging Note"
							headerIcons={[
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => setShowJudgingNoteModal(false)}
									width="35px"
									height="35px"
								/>,
							]}
							iconColor={theme.colorPrimary}
						>
							<div className="p-[2rem]">
								<p
									className="whitespace-pre-wrap"
									dangerouslySetInnerHTML={{
										__html: [props.roundEntry.judgingNote].join(""),
									}}
								></p>
							</div>
						</ModalCard>
					</Modal>

					{/* vote panel */}
					{props.roundEntry && props.roundEntry.votes && (
						<Modal show={showVotePanel}>
							<ModalCard
								className="!max-h-[80vh] !w-[90vw] overflow-hidden"
								title="Voting Details"
								headerIcons={[
									<Icon
										icon="edit"
										color={theme.colorPrimary}
										onClick={() => {
											if (props.isActiveRound) {
												setAllowVoteChange(!allowVoteChange);
											} else {
												addNewAlert({
													type: "error",
													message:
														"Not all Entry Sets are active in this round.",
												});
											}
										}}
										width="20px"
										height="20px"
									/>,
									<Icon
										icon="close"
										color={theme.colorPrimary}
										onClick={() => setShowVotePanel(false)}
										width="35px"
										height="35px"
									/>,
								]}
								iconColor={theme.colorPrimary}
							>
								<div className="h-full overflow-auto">
									{props.roundEntry.votes.map((vote) => (
										<VoteList key={vote.id}>
											<div className="flex gap-[1rem] items-center">
												{/* only show check if juror has voted */}
												{vote.voteOptionId && (
													<Icon
														icon="check"
														color={theme.colorPrimary}
														width="15px"
														height="15px"
													/>
												)}
												<p>{vote.jurorName}</p>
											</div>

											<p>{vote.jurorCompany || ""}</p>
											<div className="flex items-center gap-[1rem] w-[180px]">
												{props.isActiveRound && allowVoteChange ? (
													<DropdownField
														className="w-[110px]"
														name={`vote.${vote.id}`}
														value={vote.voteOptionId}
														options={props.voteOptions.map((o) => {
															return { label: o.name, value: o.id };
														})}
														hiddenLabel
														height="30px"
														// placeholder="Vote"
														onChange={(event) => {
															props.updateVote(
																vote.id,
																vote.entrySetId,
																vote.batchId,
																vote.roundId,
																Number(event.currentTarget.value),
																props.settings.roundStructure?.name
															);
														}}
													/>
												) : (
													<b className="italic">
														{vote.voteOption ? vote.voteOption.name : "-"}
													</b>
												)}

												{/* only show notes button if the juror has left a note */}
												{props.entryNotes.find(
													(note) => note.nameCreatedBy === vote.jurorName
												) && (
													<Icon
														className="ml-auto"
														icon="notes"
														color={theme.colorPrimary}
														onClick={() => {
															setSelectedJuror(vote.jurorName);
															setShowNotesModal(true);
															setShowVotePanel(false);
															setShowVotePanelAfterNoteClose(true);
														}}
													/>
												)}
											</div>
										</VoteList>
									))}
								</div>
							</ModalCard>
						</Modal>
					)}

					{/* entry details modal */}
					<Modal show={showEntryDetails}>
						<ModalCard
							className="!min-w-[90vw] !max-h-[90vh]"
							title="Entry Details"
							headerIcons={
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => setShowEntryDetails(!showEntryDetails)}
									width="35px"
									height="35px"
								/>
							}
							iconColor={theme.colorPrimary}
						>
							<FormikProvider value={formikProps}>
								<ModalBody>
									<Link
										to={`/admin/edit-entry?entryId=${props.entryDetails.id}`}
										target="_blank"
										rel="noreferrer"
									>
										{props.entryDetails.title} (id: {props.entryDetails.id})
									</Link>
									<div className="grid grid-cols-2 gap-4 mt-[1rem]">
										<div className="grid gap-4 entry-details-grid">
											{props.entryDetails?.longTextFields?.map((field, i) => (
												<RenderTextField
													{...field}
													key={`execution.${i}.longTextField.${field.title}`}
												/>
											))}
											{props.entryDetails?.executions?.map((execution) => (
												<>
													{execution.longTextFields.length > 0 &&
														execution.longTextFields.map((field, i) => (
															<RenderTextField
																{...field}
																key={`execution.${i}.longTextField.${field.title}`}
															/>
														))}
													{execution.shortTextFields.length > 0 &&
														execution.shortTextFields.map((field, i) => (
															<RenderTextField
																{...field}
																key={`execution.${i}.shortTextField.${field.title}`}
															/>
														))}
												</>
											))}
										</div>
										<div className="grid gap-4 entry-details-grid">
											{props.entryDetails?.dateFields?.map((field, i) => (
												<RenderDateField
													{...field}
													key={`dateField.${i}.${field.title}`}
												/>
											))}
											{props.entryDetails?.listFields?.map((field, i) => (
												<RenderTextField
													{...field}
													key={`listField.${i}.${field.title}`}
												/>
											))}
											{props.entryDetails?.shortTextFields?.map((field, i) => (
												<RenderTextField
													{...field}
													key={`shortTextField.${i}.${field.title}`}
												/>
											))}
											{props.entryDetails?.linkFields?.map((field, i) => (
												<RenderLinkField
													{...field}
													updateMedia={props.updateMedia}
													mediaState={props.mediaState}
													key={`linkField.${i}.${field.title}`}
												/>
											))}
											{props.entryDetails?.executions?.map(
												(execution, executionIndex) => {
													execution.dateFields.length > 0 &&
														execution.dateFields.map((field, i) => (
															<RenderDateField
																{...field}
																key={`execution.${executionIndex}.dateField.${i}.${field.title}`}
															/>
														));
													execution.listFields.length > 0 &&
														execution.listFields.map((field, i) => (
															<RenderTextField
																{...field}
																key={`execution.${executionIndex}.listField.${i}.${field.title}`}
															/>
														));
													execution.shortTextFields.length > 0 &&
														execution.shortTextFields.map((field, i) => (
															<RenderTextField
																{...field}
																key={`execution.${executionIndex}.shortTextField.${i}.${field.title}`}
															/>
														));
													execution.linkFields.length > 0 &&
														execution.linkFields.map((field, i) => (
															<RenderLinkField
																{...field}
																updateMedia={props.updateMedia}
																mediaState={props.mediaState}
																key={`execution.${executionIndex}.linkField.${i}.${field.title}`}
															/>
														));
												}
											)}
										</div>
									</div>
								</ModalBody>
							</FormikProvider>
						</ModalCard>
					</Modal>

					{/* also in modal */}
					<Modal show={showAlsoInModal}>
						<ModalCard
							className="!min-w-[70vw] h-full"
							title={`${props.entryDetails?.title}: also appears in`}
							headerIcons={
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => setShowAlsoInModal(!showAlsoInModal)}
									width="35px"
									height="35px"
								/>
							}
							iconColor={theme.colorPrimary}
						>
							<FormikProvider value={formikProps}>
								<ModalBody className="h-full overflow-auto !p-0">
									{entryAlsoIn.length &&
										entryAlsoIn.map((other) => {
											const award = props.calculateScoreIndicator(other);
											const def = other.batchHierarchyDefinitions;
											return (
												<AlsoList key={other.entryId}>
													<b>{other.entryId}</b>

													<div className="flex items-center flex-wrap">
														<RenderBatchDef
															def={def}
															executions={props.entryDetails?.executions.length}
															setModalContent={(level) => setBatchDef(level)}
															setShowModal={() => setShowBatchDef(true)}
														/>
													</div>

													<StyledScoreIndicator
														className="!relative !w-[55px] !h-[55px] p-[.5rem] ml-auto"
														background={voteButtonColor(award || "", theme)}
													>
														<span className="text-[.7rem]">{award}</span>
													</StyledScoreIndicator>
													<Icon
														icon="eye"
														color={theme.colorPrimary}
														onClick={() => {
															window.open(
																`/admin/edit-entry?entryId=${other.entryId}`,
																"_blank"
															);
														}}
														width="25px"
														height="25px"
													/>
												</AlsoList>
											);
										})}
								</ModalBody>
							</FormikProvider>
						</ModalCard>
					</Modal>

					{/* batch definition modal */}
					<Modal show={showBatchDef}>
						<ModalCard
							title={`${batchDef?.label} : ${batchDef?.name}`}
							headerIcons={[
								<Icon
									icon="close"
									color={theme.colorPrimary}
									onClick={() => setShowBatchDef(false)}
									width="35px"
									height="35px"
								/>,
							]}
							iconColor={theme.colorPrimary}
						>
							<div className="p-[2rem]">
								<p
									className="whitespace-pre-wrap"
									dangerouslySetInnerHTML={{
										__html: [batchDef?.definition].join(""),
									}}
								></p>
							</div>

							<Button
								className="w-full mt-auto"
								icon="check"
								onClick={() => {
									if (props.roundEntry) {
										props.updateMedia({
											...props.mediaState,
											awardLevel: undefined,
											brand: undefined,
											title: `${batchDef?.label} : ${batchDef?.name}`,
											filePath: undefined,
											mediaType: LiveToolsMediaType.DefinitionSlide,
											text: batchDef?.definition
												?.replaceAll("<p>", "")
												.replaceAll("</p>", ""),
										});
									}
								}}
							>
								Show on Media Player
							</Button>
						</ModalCard>
					</Modal>

					<MediaControl
						media={
							activeMediaPreview
								? {
										...activeMediaPreview,
										juryId: props.roundEntry.juryId,
										companyId: activeMediaPreview.companyId,
								  }
								: null
						}
						show={activeMediaPreview !== null}
						setActiveMediaPreview={setActiveMediaPreview}
						// setActiveMediaViewerState={setActiveMediaViewerState}
						updateMedia={props.updateMedia}
						mediaViewerState={props.mediaState}
						coverImage={props.entryDetails.coverImage}
						flattenedMediaArr={flattenedMedia}
						showTitleSlide={() => showTitleSlide()}
						currentEntryId={props.entryDetails.id}
					/>
					<div className="w-full">
						<div className="w-full flex justify-center items-center h-[20px]">
							<div
								className="flex items-center justify-center centerCaret"
								onClick={() => setExpanded(!isExpanded)}
							>
								<Icon
									icon="caret"
									className="caretTransform pb-[3px]"
									color={theme.colorButtonLight}
									width="25px"
									height="25px"
								/>
							</div>
						</div>
						<div className={`overflow-hidden relative z-10`}>
							<div className="flex flex-wrap justify-between gap-[2rem] xxl:grid-cols-2 w-full px-[2rem] items-center text-colorCopyLightLight">
								<div className="flex col-span-1 flex-col gap-2">
									<div className="flex gap-2 flex-wrap items-end">
										<StyledStar
											icon="star"
											color={
												props.settings.highlightedRoundEntryId ===
												props.roundEntry.id
													? theme.colorPrimary
													: "transparent"
											}
											width="25px"
											height="25px"
											onClick={() =>
												props.roundEntry &&
												props.roundEntry.id !==
													props.settings.highlightedRoundEntryId
													? props.updateHighlightedRoundEntryId(
															props.roundEntry.id
													  )
													: props.updateHighlightedRoundEntryId(undefined)
											}
											outline={
												props.settings.highlightedRoundEntryId ===
												props.roundEntry.id
													? theme.colorPrimary
													: theme.colorCopyLight
											}
										/>
										<p className="mx-[1rem] leading-[16px]">
											{props.entryDetails?.brand}
										</p>

										<h2 className="playfair-500 leading-[20px]">
											{props.entryDetails?.title}
										</h2>
										<p className="ml-[3em] leading-[12px] text-xxxSmallSize">
											{props.entryDetails?.id}
										</p>
									</div>
									<div className="flex mt-[1rem] items-center flex-wrap">
										<RenderBatchDef
											def={props.roundEntry.batchHierarchyDefinitions}
											executions={props.entryDetails.executions.length}
											setModalContent={(level) => setBatchDef(level)}
											setShowModal={() => setShowBatchDef(true)}
										/>
									</div>
								</div>
								<div className="flex ml-auto gap-[2rem] items-center justify-end flex-wrap">
									<Button onClick={() => setShowVotePanel(true)}>
										<span>
											Votes:{" "}
											{props.roundEntry && props.roundEntry.votes && (
												<SlotCounter
													value={props.roundEntry.votes.reduce(
														(isVoted, vote) =>
															vote.voteOption ? (isVoted += 1) : isVoted,
														0
													)}
													dummyCharacterCount={3}
												/>
											)}
											/{props.roundEntry?.votes.length}
										</span>
									</Button>
									<div className="flex flex-wrap md:col-span-2">
										{props.roundEntry && props.roundEntry.votes.length > 0 && (
											<div className="flex flex-wrap md:flex-nowrap ml-auto gap-[.25rem]">
												<AwardSummaryBox
													roundEntry={props.roundEntry}
													roundType={props.settings.roundStructure?.name || ""}
													voteOptions={props.voteOptions}
													awardOnly={false}
													includeAbstain={true}
													revealResults={true}
													votedOnly={false}
													includeUnvoted={
														// !props.roundEntry.votes.every((x) => x.voteOptionId)
														false
													}
													scoreAndAbstainOnly={
														props.settings.roundStructure?.name === "1-10" ||
														false
													}
												/>
											</div>
										)}
									</div>

									{props.isActiveRound && props.roundEntry ? (
										<FormikProvider value={formikProps}>
											<CheckboxField
												className="checkboxstyling"
												name="lock"
												placeholder="Lock"
												checked={formikProps.values.lock}
												onChange={() => {
													props.updateEntryLocked(
														props.roundEntry!.juryId,
														props.roundEntry!.entrySetId,
														props.roundEntry!.roundId,
														props.roundEntry!.id,
														props.roundEntry!.batchId,
														!props.roundEntry!.isLocked
													);
												}}
											/>
										</FormikProvider>
									) : (
										<p className="text-colorCopyLight">Inactive Round</p>
									)}
								</div>
							</div>

							<div className="collapsible">
								<hr className="borderStyle" />
								<div className="flex py-[1rem] mx-[2rem]">
									<div className="flex-col">
										<Button
											className="button-gold mb-[1rem]"
											icon={props.entryNotes.length ? "notes" : "close"}
											iconSize="20px"
											iconColor={theme.colorPrimary}
											onClick={() => {
												props.entryNotes.length
													? setShowNotesModal(true)
													: addNewAlert({
															type: "error",
															message: "No notes found.",
													  });
											}}
										>
											Notes
										</Button>
										<Button
											className="button-gold"
											icon={props.roundEntry.judgingNote ? "notes" : "close"}
											iconSize="20px"
											iconColor={theme.colorPrimary}
											onClick={() => {
												props.roundEntry!.judgingNote!
													? setShowJudgingNoteModal(true)
													: addNewAlert({
															type: "error",
															message: "No note found.",
													  });
											}}
										>
											Judging Note
										</Button>
									</div>
									{/* {activeExecution && (
                    // <EntryDetailMediaSelector
                    //   activeExecution={activeExecution}
                    //   className="!py-0"
                    //   activeMediaPreview={activeMediaPreview}
                    //   hideHeading={true}
                    //   onMediaSelect={(mediaItem) => {
                    //     setIsZoomed(false);
                    //     setActiveMediaPreview(mediaItem);
                    //   }}
                    // />
                    <div className="mx-auto w-[75%]">
                      <MediaCarousel isVisible>{carouselItems}</MediaCarousel>
                    </div>
                  )} */}

									<div className="mx-auto w-[75%]">
										<MediaCarousel isVisible>{carouselItems}</MediaCarousel>
									</div>
								</div>
								<div className="EntryToolBarStyling">
									<Button
										className="button-gold"
										onClick={() => setShowEntryDetails(!showEntryDetails)}
									>
										Entry Details
										{linksCount ? ` (${linksCount})` : ""}
									</Button>
									<Button
										className="button-gold"
										icon={entryAlsoIn.length ? "eye" : "close"}
										iconColor={theme.colorPrimary}
										iconSize="20px"
										onClick={() => {
											entryAlsoIn.length
												? setShowAlsoInModal(!showAlsoInModal)
												: addNewAlert({
														type: "error",
														message: "No other entries found.",
												  });
										}}
									>
										Also In
									</Button>

									<Button
										onClick={props.scrollToTop}
										className="button-gold"
										icon="caret"
										iconRotation="180deg"
										iconColor={theme.colorPrimary}
									>
										Scroll to Top
									</Button>

									<div className="flex flex-col gap-[.5rem] mb-[1rem] text-colorCopyLightLight">
										<p className="text-sm ml-[40px]">Overall Progress</p>
										<div className="flex items-center">
											<p className="w-[40px] text-sm">{`${displayPercentage(
												props.juryCard.votesCast / props.juryCard.votesPossible
											)}`}</p>
											<ProgressIndicator>
												<ProgressFill
													size={Math.round(
														(props.juryCard.votesCast /
															props.juryCard.votesPossible) *
															100
													)}
												/>
											</ProgressIndicator>
										</div>
									</div>

									{props.roundEntry && (
										<Button
											onClick={() => showTitleSlide()}
											className="button-gold"
										>
											Title Slide
										</Button>
									)}
									<SearchBar
										showBorder
										transparentBG
										label="Search"
										onKeyUp={(e) => {
											props.onSearch(e.currentTarget.value);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="w-full flex justify-center items-center h-[20px]">
						<div className="flex items-center justify-center centerCaret">
							<Icon
								icon="caret"
								className="caretTransform pb-[3px]"
								color={theme.colorButtonLight}
								width="25px"
								height="25px"
							/>
						</div>
					</div>
					<div className="w-full flex justify-between items-center px-[3em] pb-[2em]">
						<div className="flex items-center gap-[1rem]">
							<h2 className="w-full text-colorCopyLightLight">
								No entry selected
							</h2>
							{showSpinner && (
								<img
									className="w-[50px]"
									src={config.assets.loading.quaternary}
									alt="loading..."
								/>
							)}
						</div>

						<SearchBar
							showBorder
							transparentBG
							label="Search"
							onKeyUp={(e) => props.onSearch(e.currentTarget.value)}
						/>

						{/* <Icon
              icon="search"
              className="pt-[1em]"
              color={theme.colorButtonLight}
              width="25px"
              height="25px"
            /> */}
					</div>
				</>
			)}
		</StyledEntryDetailPane>
	);
};

export default EntryDetailPane;

interface EntryDetailPaneProps {
	entryDetails: EntryDetailsModel | null;
	roundEntry: GalleryEntryCard | null;
	isActiveRound: boolean;
	// states for toggling loading spinner
	clickedEntry: boolean;
	resetClickedEntry(): void;
	settings: LiveToolsSettings;
	entryNotes: Array<EntryNote>;
	voteOptions: Array<VoteOption>;
	mediaState: LiveToolsMediaItem;
	juryCard: JuryCardAdmin;
	updateVote: (
		voteId: number,
		entrySetId: number,
		batchId: number,
		roundId: number,
		voteOptionId?: number,
		roundType?: string
	) => void;
	updateEntryLocked: (
		juryId: number,
		entrySetId: number,
		activeRoundId: number,
		roundEntryId: number,
		batchId: number,
		isLocked: boolean
	) => void;
	updateHighlightedRoundEntryId: (roundEntryId?: number) => void;
	onSearch: (searchString: string) => void;
	calculateScoreIndicator: (roundEntry: GalleryEntryCard) => string | undefined;
	updateMedia: (mediaState: LiveToolsMediaItem) => void;
	scrollToTop: () => void;
}

interface RenderTextFieldProps extends DisplayTextField {
	smallTitle?: boolean;
}

interface DisplayTextField {
	title: string;
	text: string;
	jurorHelpText: string;
	textType: TextType;
}

interface LinkField {
	title: string;
	links: LinkSet[];
	jurorHelpText: string;
	updateMedia: (mediaState: LiveToolsMediaItem) => void;
	mediaState: LiveToolsMediaItem;
}
