import DropdownField, {
	DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import React, {
	useState,
	useEffect,
	useCallback,
	useContext,
	Fragment,
	useMemo,
} from "react";
import * as Yup from "yup";
import TextField from "../../../components/FormFields/TextField";
import Button, {
	CustomNavLink,
	SquareButton,
} from "../../../components/Button/Button";
import {
	Card,
	StickyCardBody,
	StickyHeaderBar,
} from "../../../components/StickyCard/StickyCard";
import styled, { useTheme } from "styled-components";
import { ReactComponent as CaretDown } from "../../../assets/icons/Down.svg";
import { VerticalDivider } from "../../../components/Breadcrumbs/Breadcrumbs";
import ExecutionDragCard from "../../../components/ExecutionDragCard/ExecutionDragCard";
import update from "immutability-helper";
import { IndividualCredit, CompanyCredit } from "../Credits/CreditInterfaces";
import { useFormik, FormikProvider } from "formik";
import {
	Ancestor,
	CreditType,
	DateType,
	EntryDateField,
	EntryLinkField,
	EntryListField,
	EntryMediaField,
	EntryPhysicalComponentField,
	EntryTextField,
	ProgramModel,
} from "../Program/ProgramInterfaces";
import {
	Audit,
	ExecutionModel,
	PhysicalAssetStatus,
	PhysicalAssetStatusOptions,
} from "../../Entries/EntryInterfacesComplete";
import { AdminEntryModel } from "../../Entries/EditEntry";
import {
	MediaItem,
	MediaType,
	Tag,
} from "../../MediaLibrary/mediaLibrary.model.d";
import { getMedia, getMediaSrc } from "../../MediaLibrary/manageMediaLibrary";
import {
	getActiveCompanyCredits,
	getActiveIndividualCredits,
} from "../Credits/manageCreditLibrary";
import axios, { AxiosResponse } from "axios";

import {
	addRevisionNote,
	changePaymentStatus,
	changeReviewStatus,
	createExecution,
	updateEntry,
	removeParentRelationship,
	updateExecutionProperty,
	UpdatePropertyRequest,
	updateEntryProperty,
	deleteExecution,
	setIsCampaign,
	changeExecutionProgram,
	sortEntryFields,
} from "../../Entries/manageEntry";
import SelectExecutionModal from "../../Entries/SelectExecutionModal";
import ChildEntryModal from "../../Entries/ChildEntryModal";
import { ChangeWarning } from "../../Entries/EntryWarnings";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { Link, useHistory } from "react-router-dom";
import AuthenticationContext from "../../../components/Auth/AuthenticationContext";
import EntryValidation from "../../Entries/EntryValidation";
// import { isValidTimeStamp } from "../../../utils/timeUtilities";
import {
	PaymentStatus,
	PaymentStatusOptions,
	ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import { useAwardableAwards } from "../../../hooks/useAwardableAwards";
import { TablePlaceholder } from "../../../components/Table/Table";
import { EntryMediaJoin } from "../Program/ProgramInterfaces";
import Icon from "../../../components/Icon/Icon";
import HierarchyDropDownModal from "../../../components/HierarchyDropDownModal/HierarchyDropDownModal";
import { EntryAction } from "../../../components/HierarchyDropDownModal/HierarchyDropDown";
import AddCreditModal from "../Credits/AddCreditModal";
import { ValidateContext, createTag } from "../../Entries/EntryForm";
import { CreditCheckContext } from "../../Entries/EntryForm";
import { MediaCheckContext } from "../../Entries/EntryForm";
import { SelectedVoteButton, voteButtonColor } from "../../Judging/GalleryCard";
import ImagePlaceholder from "../../../assets/placeholders/image-landscape.png";
import RenderAdminEntryFields, {
	CampaignFieldDisplay,
	ErrorText,
} from "./RenderAdminEntryFields";
import Table from "../../../components/Table/Table";
import RenderCreditFields from "./RenderCreditFields";
import useTaskHandler, {
	TASKS_COMPLETED,
	TaskHandlerReturnType,
} from "../../../hooks/useTaskHandler";
import Collapsible, {
	CollapsibleContainer,
} from "../../../components/Collapsible/Collapsible";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import { getEntryReviewers } from "../User/managerUser";
import assetsConfig, { defaultBoxShadow } from "../../../assetsConfig";
import MediaEnlargeModal, {
	ImgEnlargeModal,
} from "../../../components/MediaEnlargeModal/MediaEnlargeModal";
import BrowserHeader from "../../../components/BrowserHeader/BrowserHeader";
import { isAProgramMediaField } from "../../Entries/DynamicFields";
import { useAlert } from "../../../components/Alert/Alerts";
import { useSettings } from "../../../hooks/UseSettings";
import lowerCase from "../../../utils/lowerCase";
import { getLocalTime } from "../../../utils/timeUtilities";
import Authorized from "../../../components/Auth/Authorized";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import StickyFields from "./StickyFields";
import { urlEntries } from "../../../endpoints";
import Loading from "../../../components/Loading/Loading";
import Pagination from "../../../utils/Pagination";
import { AdminPageControls } from "../Search/AdminSearch";

export const EntryMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	padding: 2rem;
	border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};
`;

const WrapAnywhere = styled.div`
	width: 350px;
	overflow-wrap: anywhere;
`;

const Section = styled(Card)`
	position: relative;
	top: 0 !important;
	min-height: 0 !important;
	max-height: unset;
`;

const EntryDetailPanel = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
	padding: 1rem 2rem;
	background: ${({ theme }) => theme.colorBackgroundDarkDark};

	position: sticky;
	top: 0;
	z-index: 50;

	.left-container {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		gap: 1rem;
		color: ${({ theme }) => theme.colorCopyLightLight};
	}

	.cover-img-container {
		height: 87px;
		aspect-ratio: 16/9;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.details {
		display: flex;
		flex-direction: column;
	}
`;

const FormContainer = styled.div`
	padding: 2rem;

	&,
	.admin-form {
		display: flex;
		flex-direction: column;
		gap: 2rem;
	}

	.side-sticky {
		position: sticky;
		top: calc(163px + 2rem) !important;
		/* min-width: 450px; */
	}

	@media only screen and (max-width: 1180px) {
		.admin-form {
			order: 1;
		}
		.side-sticky {
			position: relative;
			width: auto;
			order: 0;
			top: 0 !important;
		}
	}

	@media only screen and (min-width: 1181px) {
		display: grid;
		grid-template-columns: 9fr auto;
	}
`;

const RevisionNoteContainer = styled.div`
	display: flex;
	padding: 2rem;
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
`;

export const SectionBody = styled(StickyCardBody)`
	padding: 2rem;
`;

export const ExecutionContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	padding: 2rem 0;
	border-bottom: 2px solid ${({ theme }) => theme.colorBorderLight};
	padding: 2rem;
`;

const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colorCopyLightLight};
`;

export const AdminEditPageContainer = (props: {
	children: React.ReactNode;
	entryDetails: {
		eid: number;
		coverImgSrc: string;
		title: string;
		brand: string;
		author: string;
		awardLevel?: string;
		ancestry: string[];
		reviewStatusId: number;
		paymentStatusId: number;
	};
}) => {
	const theme = useTheme();
	const { settings, loading } = useSelector(
		(state: RootState) => state.settings
	);
	return (
		<div className="w-full">
			{/* <UserMenu /> */}
			<EntryDetailPanel id="entryDetailPanel">
				<div className="left-container">
					<div className="flex gap-[1rem]">
						<div className="cover-img-container">
							<ImgEnlargeModal
								path={props.entryDetails.coverImgSrc}
								fileName="Cover Image"
							/>
						</div>

						<div className="details">
							<p className="text-xxSmallSize">{props.entryDetails.brand}</p>
							<p className="playfair-500">{props.entryDetails.title}</p>
							<p className="text-xxSmallSize">
								Author: {props.entryDetails.author}
							</p>

							<div className="flex flex-wrap items-center">
								<p className="text-xxSmallSize"> Eid: </p>
								<StyledLink
									className="ml-[0.5rem]"
									to={`/edit-entry?entryId=${props.entryDetails.eid}`}
									target="_blank"
								>
									{props.entryDetails.eid}
								</StyledLink>
								<Icon
									className="ml-[0.5rem]"
									icon="eye"
									color={theme.colorPrimary}
									onClick={() => {
										window.open(
											`/edit-entry?entryId=${props.entryDetails.eid}`,
											"_blank"
										);
									}}
									width="25px"
									height="25px"
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-wrap items-center">
						{props.entryDetails.ancestry?.map((level, i) => (
							<p key={i} className="flex items-center text-xxSmallSize">
								{i > 0 && (
									<VerticalDivider className="ml-[.25rem]" size="7px" />
								)}
								{level}
							</p>
						))}
					</div>
				</div>
				{(props.entryDetails.reviewStatusId == 7 ||
					props.entryDetails.reviewStatusId == 8) && (
					<Authorized
						settings={settings}
						loading={loading}
						feature="Judging Fields"
						authorized={
							<SelectedVoteButton
								background={
									props.entryDetails.awardLevel
										? voteButtonColor(props.entryDetails.awardLevel, theme)
										: theme.colorBackgroundMedium
								}
							>
								{props.entryDetails.awardLevel || "No Award"}
							</SelectedVoteButton>
						}
						notAuthorized={<></>}
					/>
				)}
				{props.entryDetails.reviewStatusId != 7 &&
					props.entryDetails.reviewStatusId != 8 &&
					props.entryDetails.paymentStatusId !== PaymentStatus.Deleted &&
					props.entryDetails.paymentStatusId !== PaymentStatus.Abandoned && (
						<SelectedVoteButton
							className="button-gold"
							background={theme.colorBackgroundLight + " !important"}
						>
							{ReviewStatusOptions[props.entryDetails.reviewStatusId].label}
						</SelectedVoteButton>
					)}
				{(props.entryDetails.paymentStatusId === PaymentStatus.Deleted ||
					props.entryDetails.paymentStatusId === PaymentStatus.Abandoned) && (
					<Button className="button-danger !cursor-default">
						{props.entryDetails.paymentStatusId === PaymentStatus.Deleted
							? "Deleted"
							: "Abandoned"}
					</Button>
				)}
			</EntryDetailPanel>
			<FormContainer>{props.children}</FormContainer>
		</div>
	);
};

const MetadataContainer = styled.div`
	display: flex;
	gap: 2rem;

	.metadata {
		p {
			display: flex;
			flex-direction: column;

			& > *:not(:first-child) {
				font-weight: ${({ theme }) => theme.fontSemiBold};
			}
		}
	}
`;

export const EntryField = (props: EntryFieldProps) => {
	const val =
		props.value instanceof Date
			? getLocalTime(props.value, false)
			: props.value;

	return (
		<>
			<b className="entry-label">{props.label}</b>
			{props.value ? (
				props.hardLink ? (
					<p className="entry-info">
						<Link to={props.hardLink} target="_blank" rel="noopener noreferrer">
							{props.linkText || val}
						</Link>
					</p>
				) : (
					<p className="entry-info">{val}</p>
				)
			) : props.isRequired ? (
				<p className="missing-info">
					<span>Missing Required Information*</span>
				</p>
			) : (
				<p className="missing-info"></p>
			)}
		</>
	);
};

export const EntryReviewContainer = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	row-gap: 1rem;

	&.col-3-entry-info {
		grid-template-columns: max-content max-content 1fr;
	}

	&.col-4-entry-info {
		grid-template-columns: max-content max-content max-content 1fr;
	}

	b.entry-label {
		display: flex;
		align-items: center;
	}

	p.entry-info,
	p.missing-info,
	b.entry-label {
		/* padding-bottom: 1.3125rem; */
		padding-bottom: 1rem;
		line-height: 23px;
		border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
	}

	p.entry-info,
	p.missing-info {
		padding-left: 5.375rem;
	}

	p.missing-info {
		font-style: italic;
		color: ${({ theme }) => theme.colorDanger};
		font-size: 0.8rem;
	}
`;

export const StyledCaretDown = styled(CaretDown)<{ isCollapsed: boolean }>`
	${(p) =>
		p.isCollapsed &&
		`
  transform: rotate(180deg);
`}
`;

const ExecutionForm = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-rows: min-content;
	grid-row-gap: 3rem;
	padding: 3.125rem;
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: ${defaultBoxShadow};

	@media only screen and (max-width: ${({ theme }) => theme.sm}) {
		padding: 1.5rem;
	}
`;

const PreviewCampaignHeader = styled(StickyCardBody)`
	background: ${({ theme }) => theme.colorBackgroundLight};
	box-shadow: ${defaultBoxShadow};
	padding-bottom: 1.5625rem;
`;

const SaveDraftLink = (props: {
	onClick(): void;
	disabled?: boolean;
	label?: string;
}) => {
	return (
		<CustomNavLink
			className="ml-auto"
			label={props.label ? props.label : "Save as Draft"}
			{...(!props.disabled && {
				onClick: () => props.onClick(),
			})}
			disabled={props.disabled ? true : false}
		/>
	);
};

export function getAdminHierarchy(
	executions: ExecutionModel[],
	ancestry: Ancestor[],
	isCampaign: boolean
) {
	let hierarchy: string[] = [];
	let sameTypeEx = true;
	const firstExecutionProgramId =
		(executions && executions.length > 0 && executions[0].programId) || 0;

	executions?.forEach((execution) => {
		if (
			firstExecutionProgramId &&
			execution.programId != firstExecutionProgramId
		) {
			sameTypeEx = false;
		}
	});
	ancestry?.forEach((ancestor, i) => {
		const childLevelDisplayLabel =
			i === 0
				? "Program"
				: ancestry![i - 1]?.childLevelDisplayLabel ?? "Program";

		hierarchy.push(childLevelDisplayLabel + ": " + ancestor.name);
	});

	if (isCampaign && !sameTypeEx) {
		hierarchy.push(
			ancestry[ancestry.length - 1].childLevelDisplayLabel + ": Mixed Campaign"
		);
	} else if (executions && executions.length > 0) {
		hierarchy.push(
			ancestry[ancestry.length - 1].childLevelDisplayLabel +
				": " +
				executions[0].program.name
		);
	}

	return hierarchy;
}

const AdminEntryEdit = (props: AdminEntryModel) => {
	const theme = useTheme();
	let history = useHistory();
	const { settings, loading } = useSelector(
		(state: RootState) => state.settings
	);
	const { companyId } = props;
	const { claims } = useContext(AuthenticationContext);

	const [tabIndex, setTabIndex] = useState(0);
	const [tabErrors, setTabErrors] = useState({
		basicInfoErrors: false,
		campaignErrors: false,
		creditErrors: false,
		executionErrors: false,
		reviewTabErrors: false,
	});

	const [page, setPage] = useState(1);
	const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [totalRecords, setTotalRecords] = useState(0);
	const [revisionHistory, setRevisionHistory] = useState<Audit[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const getRevisionHistory = async () => {
		const response = await axios.get<Audit[]>(
			`${urlEntries}/getRevisionHistory/${props.id}`,
			{
				params: {
					page,
					recordsPerPage,
				},
			}
		);

		if (response.status === 200) {
			setRevisionHistory([]);
			setRevisionHistory(response.data);
			const total = parseInt(response.headers["totalamountofrecords"]);
			setTotalRecords(total);
			settotalAmountOfPages(Math.ceil(total / recordsPerPage));
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (revisionHistory.length > 1) {
			getRevisionHistory();
		}
	}, [page, recordsPerPage]);

	useEffect(() => {
		getRevisionHistory();
	}, [props]);

	const [creditTabIndex, setCreditTabIndex] = useState(0);

	const [entryToMove, setEntryToMove] = useState<number | null>(null);

	// states for execution types
	const [selectedExecutionProgramId, setSelectedExecutionProgramId] = useState<
		number | undefined
	>();
	const [showExecutionSelection, setShowExecutionSelection] = useState(false);
	const [isExecutionTypeChanged, setIsExecutionTypeChanged] = useState(false);
	const [executionTypeOptions, setExecutionTypeOptions] = useState<
		DropdownOptions[]
	>([]);
	// END states for execution types

	const isEntryClosed = props.program.entriesClosed || false; // disables all fields except for credit fields with a program that "isUnlocked"
	const [firstValidation, setFirstValidation] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [resetCreditCheck, setResetCreditCheck] = useState(false);
	const [resetMediaCheck, setResetMediaCheck] = useState(false);
	const [hierarchy, setHierarchy] = useState<string[]>([]);
	const awardOptions = useAwardableAwards();

	// initial entry values
	const [initialValues, setInitialValues] = useState<AdminEntryModel>(props);
	// const { entryErrors, setEntryErrors } = useContext(EntryErrorContext);

	const formikProps = useFormik({
		initialValues: initialValues,
		onSubmit: () => {},
		validationSchema: Yup.object({}),
		validateOnBlur: false,
		validateOnChange: false,
		enableReinitialize: true,
	});

	// media from DB
	const [media, setMedia] = useState<MediaItem[]>([]);

	const [companyCreditsArray, setCompanyCreditsArray] = useState<
		CompanyCredit[]
	>([]);

	const [individualCreditsArray, setIndividualCreditsArray] = useState<
		IndividualCredit[]
	>([]);
	const [creditSearchVal, setCreditSearchval] = useState("");

	// modal states
	const [showModal, setShowModal] = useState<{
		[modalType: string]: string | boolean | number;
	}>({
		addCredit: false,
		addMedia: false,
		addExecution: false,
		markAsChild: false,
	});

	// execution forms
	const [executionForms, setExecutionForms] = useState<ExecutionModel[]>(
		initialValues.executions
	);
	const [draggedCard, setDraggedCard] = useState(false);
	const [reviewStatus, setReviewStatus] = useState(props.reviewStatusId);
	const [isExecutionFormDirty, setIsExecutionFormDirty] = useState(false);

	const {
		reset,
		loadItems,
		onSubmit,
		handleSelectItem,
		...state
	}: TaskHandlerReturnType<UpdatePropertyRequest> =
		useTaskHandler<UpdatePropertyRequest>();

	// update execution index prop with its actual order
	const updateExecutionOrderWithIndex = (executionForms: ExecutionModel[]) => {
		return executionForms.map((execution, i) => {
			return {
				...execution,
				index: i,
			};
		});
	};

	const [reviewerOptions, setReviewerOptions] = useState<DropdownOptions[]>([]);

	useEffect(() => {
		getEntryReviewers()
			.then((resp) => {
				if (resp.status === 200) {
					setReviewerOptions(resp.data);
				}
			})
			.catch((error) => {
				console.log("getEntryReviewers error", error);
			});
	}, []);

	// const updateExecutionOrder = () => {
	//   onSubmit(updateExecutionProperty),
	//     executionForms.map((execution) => {
	//       return {
	//         id: execution.id,
	//         propertyName: "index",
	//         propertyValue: execution.index,
	//       };
	//     })
	//   );
	// };

	useEffect(() => {
		setHierarchy(
			getAdminHierarchy(
				props.executions!,
				props.program.ancestry!,
				props.isCampaign!
			)
		);
	}, []);

	// update execution form field values
	useEffect(() => {
		setExecutionForms(formikProps.values.executions);
		loadItems(
			formikProps.values.executions.map((execution) => {
				return {
					id: execution.id,
					propertyName: "index",
					propertyValue: execution.index,
				};
			})
		);
	}, [formikProps.values.executions]);

	// updates execution order when card is dragged
	useEffect(() => {
		// console.log("dragged card", draggedCard);
		if (draggedCard) {
			// update index value of each execution
			const updatedOrder = updateExecutionOrderWithIndex(executionForms);
			draggedCard && formikProps.setFieldValue("executions", updatedOrder);
			addNewAlert({
				type: "success",
				message: `Successfully updated ${lowerCase(
					assetsConfig.labels.execution.singular
				)} order.`,
			});
			setDraggedCard(false);
		}
	}, [draggedCard]);

	useEffect(() => {
		if (state.status === TASKS_COMPLETED) {
			if (state.errored.length) {
				addNewAlert({
					type: "error",
					message: `Failed to update ${lowerCase(
						assetsConfig.labels.execution.singular
					)} order.`,
				});
			} else {
				addNewAlert({
					type: "success",
					message: `Successfully updated ${lowerCase(
						assetsConfig.labels.execution.singular
					)} order.`,
				});
				const updatedOrder = updateExecutionOrderWithIndex(executionForms);
				formikProps.setFieldValue("executions", updatedOrder);
				setIsExecutionFormDirty(false);
			}
			reset();
		}
	}, [state.status, state.errored]);

	const moveExecutionForm = useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setExecutionForms((prevCards: ExecutionModel[]) => {
				return update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex] as ExecutionModel],
					],
				});
			});
			setIsExecutionFormDirty(true);
		},
		[]
	);

	const handleDeleteExecution = (index: number | null) => {
		if (index !== null) {
			deleteExecution(executionForms[index].id, companyId)
				.then((resp) => {
					const executionsAfterDelete = executionForms.filter(
						(obj, currentCardIndex) => {
							return currentCardIndex !== index;
						}
					);

					formikProps.setFieldValue("executions", executionsAfterDelete);

					if (executionsAfterDelete?.length <= 0) {
						setSelectedExecutionProgramId(undefined);
					}

					addNewAlert({
						type: "success",
						message: `Successfully deleted ${lowerCase(
							assetsConfig.labels.execution.singular
						)}.`,
					});
				})
				.catch(() => {});
		}
	};

	const renderCard = (
		card: ExecutionModel,
		index: number,
		disabled?: boolean
	) => {
		return (
			<ExecutionDragCard
				key={card.id}
				index={index}
				id={card.id}
				title={card.title}
				executionFields={card.executionFields}
				moveExecutionForm={moveExecutionForm}
				onClickDelete={() => {
					handleDeleteExecution(index);
					//   setShowDeleteExecution(true);
					//   setDeleExecutionIndex(index);
				}}
				disabled={disabled}
			/>
		);
	};

	// const validateEntry = () => {
	//   const validationErrors = { ...EntryValidation(formikProps.values) };

	//   // set info icon for tabs with errors
	//   setTabErrors({
	//     basicInfoErrors:
	//       Object.values(validationErrors.basicInfoErrors).length > 0,
	//     campaignErrors: Object.values(validationErrors.campaignErrors).length > 0,
	//     creditErrors: Object.values(validationErrors.creditErrors).length > 0,
	//     executionErrors:
	//       Object.values(validationErrors.executionErrors).length > 0,
	//     reviewTabErrors:
	//       Object.values(validationErrors.reviewTabErrors).length > 0,
	//   });

	//   // if form is validated and there are no errors
	//   if (
	//     !Object.values(validationErrors).some(
	//       (tabErr) => Object.values(tabErr).length > 0
	//     )
	//   ) {
	//     formikProps.setFieldValue("isValid", true);
	//     //   console.log("BEFORE SAVE formikProps.values", formikProps.values);
	//     saveEntry({ ...formikProps.values, isValid: true });
	//     //console.log("Formik Values: ", formikProps.values);
	//   } else {
	//     // save entry and set isValid to false
	//     formikProps.setFieldValue("isValid", false);
	//     saveEntry({ ...formikProps.values, isValid: false });
	//     //console.log("Formik Values: ", formikProps.values);
	//   }
	// };

	const addExecution = (programId: number) => {
		return createExecution(formikProps.values.id, programId).then(
			(response) => {
				if (response.status === 200) {
					formikProps.setFieldValue("executions", [
						...formikProps.values.executions,
						{
							...response.data,
							index: formikProps.values.executions
								? formikProps.values.executions.length
								: 0,
						},
					]);
				}
			}
		);
	};

	const clearParent = (childEntryId: number, entryType: "child" | "parent") => {
		removeParentRelationship(childEntryId).then((response) => {
			if (response.status === 200) {
				// update front-end
				if (entryType === "parent") {
					formikProps.setFieldValue(
						"childEntries",
						formikProps.values.childEntries?.flatMap((child) =>
							child.id !== childEntryId ? child : []
						)
					);
				} else {
					formikProps.setFieldValue("parentEntry", undefined);
				}

				addNewAlert({
					type: "success",
					message: "Successfully cleared child/parent relationship",
				});
			} else {
				addNewAlert({
					type: "error",
					message: response.message,
				});
			}
		});
	};

	const getCompanyMedia = () => {
		getMedia(companyId)
			.then((response) => {
				// console.log("res media", response);
				if (response.status === 200) {
					setMedia(response.data);
				}
			})
			.catch(() => {});
	};

	// show/hide credit modal
	useEffect(() => {
		if (showModal.addCredit) {
			document.body.style.overflow = "hidden";
		}
		return () => {
			document.body.style.overflow = "unset";
		};
	}, [showModal.addCredit]);

	const shouldShowExecutionSelection =
		(!props.program.isCampaignEligible &&
			props.program.children &&
			props.program.children?.length > 1) ||
		(props.program.isCampaignEligible &&
			!props.program.isMixedCampaignEligible &&
			props.program.children &&
			props.program.children?.length > 1);

	useEffect(() => {
		// If the Entry’s Program is not Campaign Eligible
		//  AND the Entry’s Program has more than one child Program object
		// OR is Campaign Eligible AND NOT Mixed Campaign Eligible
		//  AND the Entry’s Program has more than one child Program object
		// show execution level selection on basic info tab
		if (
			shouldShowExecutionSelection ||
			(props.program.isCampaignEligible &&
				props.program.isMixedCampaignEligible &&
				!formikProps.values.isCampaign)
		) {
			setShowExecutionSelection(true);

			// if there is already an execution,
			// set selected execution to that execution's program Id
			if (props.executions && props.executions.length > 0) {
				setSelectedExecutionProgramId(props.executions[0].programId);
			}
		} else {
			setShowExecutionSelection(false);
		}
		setExecutionTypeOptions([]);
		// set execution options;
		props.program.children &&
			props.program.children?.length > 1 &&
			props.program.children.forEach((program: ProgramModel) => {
				setExecutionTypeOptions((prev) => [
					...prev,
					{
						label: program.name,
						value: program.id,
					},
				]);
			});

		// if there is only one child on the program, select that execution
		if (props.program.children && props.program.children?.length === 1) {
			setSelectedExecutionProgramId(props.program.children[0].id);
		}

		if (
			!formikProps.values.isCampaign &&
			props.executions &&
			props.executions.length === 1
		) {
			setSelectedExecutionProgramId(props.executions[0].programId);
		}
	}, [formikProps.values.isCampaign]);

	const [entryType, setEntryType] = useState<string | undefined>(undefined);

	const [brandOptions, setBrandOptions] = useState<
		DropdownOptions[] | undefined
	>(undefined);

	useEffect(() => {
		const options = props.program.brandOptions
			?.split(";")
			.map((o) => {
				const option = o.trim();
				// If the option is valid, return it as a DropdownOption
				if (option) {
					return { value: option } as DropdownOptions; // Ensure it matches DropdownOptions type
				}
				return null; // Explicitly return null to filter it out later
			})
			.filter((option): option is DropdownOptions => option !== null); // Type guard to ensure the final type

		setBrandOptions(options);
	}, [props.program.brandOptions]);

	// warning modals
	const [showDeleteExecution, setShowDeleteExecution] = useState(false);
	const [showEntryType, setShowEntryType] = useState(false);
	const [showExecutionTypeWarning, setShowExecutionTypeWarning] =
		useState(false);
	const [showDeleteEntryWarning, setShowDeleteEntryWarning] = useState(false);
	const [tempExecutionType, setTempExecutionType] = useState<
		number | undefined
	>(undefined);
	const { addNewAlert } = useAlert();
	const [validateSelectExecution, setValidateSelectExecution] = useState(false);

	const [collapsible, setCollapsible] = useState({
		metadata: false,
		basicInfo: false,
		entryFields: false,
		executions: false,
		media: false,
		credits: false,
		revisionHistory: false,
	});

	// individual field errors
	const titleErr =
		validateSelectExecution && !formikProps.values.title
			? fieldRequired
			: undefined;
	const brandErr =
		validateSelectExecution && !formikProps.values.brand
			? fieldRequired
			: undefined;
	const selectedExecutionErr =
		validateSelectExecution && !selectedExecutionProgramId
			? fieldRequired
			: undefined;
	const termsAndCondErr =
		firstValidation && !formikProps.values.termsAndConditions
			? fieldRequired
			: undefined;

	// show remaining tabs once first page is filled out
	const showTabs =
		formikProps.values.title &&
		formikProps.values.brand &&
		(formikProps.values.isCampaign && props.program.isMixedCampaignEligible
			? true
			: formikProps.values.executions.length > 0
			? true
			: false);

	const isOrderComplete =
		formikProps.values.paymentStatusId === 2 ||
		formikProps.values.paymentStatusId === 3;

	const saveEntry = (formValues: AdminEntryModel) => {
		// save entry
		updateEntry(formValues.id, formValues)
			.then((response) => {
				if (response.status === 200) {
					formikProps.setValues(response.data);
					// console.log("saved entry as draft", formValues);
					addNewAlert({
						type: "success",
						message: `${assetsConfig.labels.entry.singular} saved as draft`,
					});
					setFirstValidation(true);
				} else {
					addNewAlert({
						type: "error",
						message: "Failed to save entry as draft. Please try again later.",
					});
				}
			})
			.catch(() => {
				addNewAlert({
					type: "error",
					message: "Failed to save entry as draft. Please try again later.",
				});
			});
	};

	// on check for errors, save entry as draft. Set isValid = true if no errors
	// useEffect(() => {
	//   console.log("entry errors", entryErrors);
	//   // if form is validated and there are no errors
	//   if (firstValidation) {
	//     if (Object.values(entryErrors).length === 0) {
	//       console.log("form is valid and ready for cart");
	//       formikProps.setFieldValue("isValid", true);
	//       saveEntry({ ...formikProps.values, isValid: true });
	//     } else {
	//       // just save entry w/o setting isValid to TRUE
	//       saveEntry(formikProps.values);
	//     }
	//   }
	// }, [entryErrors, firstValidation]);

	// Entry Review Tab: Campaign Fields
	const ReviewCampaignFields = () => {
		const sortedFields = sortEntryFields(formikProps.values.campaignFields);
		return (
			<EntryReviewContainer className="col-w-100">
				{sortedFields.map((field: any) => {
					let fieldVal = "";

					// set review tab values depending on field type
					switch (field.classType) {
						case "EntryTextField":
							fieldVal = (field as EntryTextField).text;
							break;
						case "EntryPhysicalComponentField":
							fieldVal = (field as EntryPhysicalComponentField)
								.physicalItemDescription;
							break;
						case "EntryDateField":
							const EntryDateField = field as EntryDateField;
							const startDate = EntryDateField.startDate
								? getLocalTime(EntryDateField.startDate)
								: "";
							const endDate = EntryDateField.endDate
								? getLocalTime(EntryDateField.endDate)
								: "";
							if (EntryDateField.programField.dateType === DateType.Range) {
								if (startDate && endDate)
									fieldVal =
										"Start Date: " + startDate + ", End Date: " + endDate;
							} else {
								fieldVal = startDate ? startDate : "";
							}
							break;
						case "EntryListField":
							const EntryListField = field as EntryListField;
							if (
								EntryListField.programField.title === "Entry Type" &&
								EntryListField.selectedOption &&
								Array.isArray(EntryListField.selectedOption)
							) {
								setEntryType(EntryListField.selectedOption[0]);
							}

							if (EntryListField.selectedOption) {
								fieldVal = EntryListField.selectedOption.join(", ");
							}

							break;
						case "EntryLinkField":
							const EntryLinkField = field as EntryLinkField;
							if (EntryLinkField.links && EntryLinkField.links.length > 0) {
								fieldVal = EntryLinkField.links
									.map((link) => link.link)
									.join(", ");
							}

							break;
						case "EntryMediaField":
							if (field.mediaItems && field.mediaItems.length > 0) {
								const mediaFilenames = field.mediaItems.map(
									(mediaItem: EntryMediaJoin) => mediaItem.mediaItem.fileName
								);
								fieldVal = mediaFilenames.join(", ");
							}
							break;
					}

					return (
						<EntryField
							label={field.programField.title}
							value={fieldVal}
							isRequired={
								field.programField.isRequired ||
								field.programField.minQuantity > 0
							}
						/>
					);
				})}
			</EntryReviewContainer>
		);
	};

	const handleChangeExecutionProgram = (): Promise<AxiosResponse<any, any>> => {
		return new Promise<any>((resolve, reject) => {
			const id = selectedExecutionProgramId!;
			//if no executions, add one
			if (
				!formikProps.values.executions ||
				formikProps.values.executions.length < 1
			) {
				resolve(createExecution(formikProps.values.id, id));
			}
			// if there are executions, remove executions
			else if (
				formikProps.values.executions &&
				formikProps.values.executions.length > 0
			) {
				if (formikProps.values.executions[0].programId !== id) {
					resolve(
						changeExecutionProgram(
							formikProps.values.id,
							formikProps.values.companyId,
							id,
							formikProps.values.executions
						)
					);
				}
			}
		});
	};

	// show validation errors by default when editing entry
	useEffect(() => {
		if (history.location.pathname === "/admin/edit-entry")
			setFirstValidation(true);
	}, [history]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [tabIndex]);

	const entryTags = useMemo(() => {
		const tags: Tag[] = [];
		if (formikProps.values.title && formikProps.values.companyId) {
			tags.push(
				createTag(formikProps.values.title, formikProps.values.companyId)
			);
		}

		if (formikProps.values.brand && formikProps.values.companyId) {
			tags.push(
				createTag(formikProps.values.brand, formikProps.values.companyId)
			);
		}
		return tags;
	}, [
		formikProps.values.companyId,
		formikProps.values.title,
		formikProps.values.brand,
	]);

	// run on page render
	useEffect(() => {
		getCompanyMedia();
		getActiveCompanyCredits(companyId)
			.then((response: AxiosResponse<CompanyCredit[], any>) => {
				if (response.status === 200) {
					setCompanyCreditsArray(response.data);
				}
			})
			.catch((e) => {});

		getActiveIndividualCredits(companyId)
			.then((response: AxiosResponse<IndividualCredit[], any>) => {
				if (response.status === 200) {
					setIndividualCreditsArray(response.data);
				}
			})
			.catch((e) => {});

		const index = claims.findIndex(
			(claim) => claim.name === "role" && claim.value === "admin"
		);
		if (index > -1) {
			setIsAdmin(true);
		}

		const userCollapsibleSetting = localStorage.getItem("collapsible_state");

		// save user collapsible preferences in local storage
		if (userCollapsibleSetting) {
			setCollapsible(JSON.parse(userCollapsibleSetting));
		}
	}, []);

	useEffect(() => {
		localStorage.setItem("collapsible_state", JSON.stringify(collapsible));
	}, [collapsible]);

	const hasEntryLevelMediaFields = formikProps.values.campaignFields.some(
		(field) => isAProgramMediaField(field.programField)
	);

	return (
		<ValidateContext.Provider value={firstValidation}>
			<BrowserHeader
				title={`Edit: [${props.id}] ${props.title ? props.title : ""}`}
			/>
			<CreditCheckContext.Provider
				value={{ resetCreditCheck, setResetCreditCheck }}
			>
				<MediaCheckContext.Provider
					value={{ resetMediaCheck, setResetMediaCheck }}
				>
					<AddCreditModal
						companyId={companyId}
						show={showModal.addCredit !== false}
						onClickHide={() => setShowModal({ ...showModal, addCredit: false })}
						setCompanyCreditsArray={setCompanyCreditsArray}
						setIndividualCreditsArray={setIndividualCreditsArray}
						defaultCreditTab={
							showModal.addCredit === CreditType.Company ||
							showModal.addCredit === CreditType.Individual
								? showModal.addCredit
								: 0
						}
					/>
					<SelectExecutionModal
						show={showModal.addExecution !== false}
						childLevelDisplayLabel={
							formikProps.values.program.childLevelDisplayLabel
						}
						onClickHide={() =>
							setShowModal({ ...showModal, addExecution: false })
						}
						options={executionTypeOptions}
						addExecution={addExecution}
					/>
					<HierarchyDropDownModal
						entryId={entryToMove}
						setEntryId={setEntryToMove}
						action={EntryAction.Move}
					/>

					<ChildEntryModal
						show={showModal.markAsChild !== false}
						onClickHide={() =>
							setShowModal({ ...showModal, markAsChild: false })
						}
						childId={formikProps.values.id}
						onSuccess={(parentEntry) =>
							formikProps.setFieldValue("parentEntry", parentEntry)
						}
					/>

					{/* <ChangeWarning
            show={showDeleteExecution}
            title="Delete Execution"
            message="Do you want to delete this execution? This cannot be undone."
            yesMessage="Yes, Delete"
            onHideModal={() => setShowDeleteExecution(false)}
            onConfirm={() => {
              handleDeleteExecution(deleteExecutionIndex!);
              setIsExecutionFormDirty(true);
            }}
          /> */}
					<ChangeWarning
						show={showEntryType}
						title="Change Campaign Status"
						yesMessage="Yes, Change"
						message={`Your entry will become a ${
							formikProps.values.isCampaign ? "Single Entry" : "Campaign"
						}. Anything you have already added to the ${
							formikProps.values.isCampaign
								? assetsConfig.labels.execution.plural
								: "Media"
						} tab may be discarded with this change. Your media will still be in your media library for you to add when you revisit that tab.`}
						onConfirm={() => {
							// if changing to single and more than one execution
							// , remove all but first execution
							if (
								formikProps.values.isCampaign &&
								formikProps.values.executions &&
								formikProps.values.executions.length > 1
							) {
								formikProps.setFieldValue("executions", [
									formikProps.values.executions[0],
								]);
							}

							formikProps.setFieldValue(
								"isCampaign",
								!formikProps.values.isCampaign
							);
						}}
						onHideModal={() => setShowEntryType(false)}
					/>

					<ChangeWarning
						show={showDeleteEntryWarning}
						title={`Deleting ${assetsConfig.labels.entry.singular}`}
						yesMessage="Yes, Delete"
						message={`Do you really want to delete this ${lowerCase(
							assetsConfig.labels.entry.singular
						)}?`}
						onConfirm={() => {
							changePaymentStatus(props.id, 4);
						}}
						onHideModal={() => setShowDeleteEntryWarning(false)}
					/>

					<FormikProvider value={formikProps}>
						<AdminEditPageContainer
							entryDetails={{
								eid: formikProps.values.id,
								coverImgSrc: formikProps.values.coverImage.mediaItem
									? getMediaSrc(formikProps.values.coverImage.mediaItem)
									: ImagePlaceholder,
								title: formikProps.values.title,
								brand: formikProps.values.brand,
								author: formikProps.values.company?.name || "",
								awardLevel: formikProps.values.awardLevel,
								ancestry: hierarchy,
								reviewStatusId: reviewStatus,
								paymentStatusId: formikProps.values.paymentStatusId,
							}}
						>
							<div className="admin-form">
								{/* metadata */}
								<Section>
									<CollapsibleContainer
										title="Metadata"
										isCollapsed={collapsible.metadata}
										onClickCollapse={() =>
											setCollapsible({
												...collapsible,
												metadata: !collapsible.metadata,
											})
										}
									>
										<SectionBody>
											<MetadataContainer className="mb-[1.5rem]">
												<div className="metadata flex flex-col gap-[1.5rem] w-[40%] max-w-[375px]">
													<p>
														<span>Date Created:</span>
														<span>
															{formikProps.values.createdDate
																? getLocalTime(formikProps.values.createdDate)
																: ""}
														</span>
													</p>

													<p>
														<span>
															Date {settings.isDemo ? "Submitter" : "Entrant"}{" "}
															Last Modified:{" "}
														</span>
														<span>
															{formikProps.values.updatedDate
																? getLocalTime(formikProps.values.updatedDate)
																: ""}
														</span>
													</p>
													<p>
														<span>
															Author {assetsConfig.labels.company.singular}:
														</span>
														<a
															href={
																`/edit-company/${formikProps.values.companyId}` ||
																""
															}
															target="_blank"
															rel="noreferrer"
														>
															{formikProps.values.company?.name}
														</a>
													</p>
													<Authorized
														settings={settings}
														loading={loading}
														feature="Company Agency Network"
														authorized={
															<p>
																<span>
																	Author{" "}
																	{assetsConfig.agencyNetworkCompanyName ||
																		"Agency Network"}
																	:
																</span>
																<span>
																	{formikProps.values.company?.agencyNetwork
																		?.name
																		? formikProps.values.company.agencyNetwork
																				.name
																		: "N/A"}
																</span>
															</p>
														}
													/>
													<Authorized
														settings={settings}
														loading={loading}
														feature="Company Holding Company"
														authorized={
															<p>
																<span>Author Holding Company:</span>
																<span>
																	{formikProps.values.company?.holdingCompany
																		?.name
																		? formikProps.values.company.holdingCompany
																				.name
																		: "N/A"}
																</span>
															</p>
														}
													/>

													<Authorized
														settings={settings}
														loading={loading}
														feature="Commerce"
														authorized={
															<p>
																<span>Order ID: </span>
																{(formikProps.values.orderId && (
																	<a
																		href={
																			`/edit-order/${formikProps.values.orderId}` ||
																			""
																		}
																		target="_blank"
																		rel="noreferrer"
																	>
																		{formikProps.values.orderId}
																	</a>
																)) || <span>No Order Id</span>}
															</p>
														}
													/>
													<Authorized
														settings={settings}
														loading={loading}
														feature="Commerce"
														authorized={
															<p>
																<span>Order Date: </span>
																<span>
																	{(formikProps.values.orderDate &&
																		getLocalTime(
																			formikProps.values.orderDate
																		)) ||
																		"No Order Date"}
																</span>
															</p>
														}
													/>
													<Authorized
														settings={settings}
														loading={loading}
														feature="Judging Fields"
														authorized={
															<div
																hidden={
																	formikProps.values.juryGroup == undefined
																}
															>
																<p>
																	<span>Jury Group: </span>
																	<span>{formikProps.values.juryGroup}</span>
																</p>
															</div>
														}
													/>
												</div>

												<div className="metadata flex flex-col gap-[1.5rem]">
													<CampaignFieldDisplay
														onSave={() => {
															setReviewStatus(
																formikProps.values.reviewStatusId
															);
															return changeReviewStatus(
																formikProps.values.id,
																formikProps.values.reviewStatusId
															);
														}}
														onDiscard={() => formikProps.resetForm()}
														name={"reviewStatusId"}
														subText={
															<p className="ml-[1rem]">
																{ReviewStatusOptions[reviewStatus].label}
															</p>
														}
														display={
															<div className="flex items-center gap-[.5rem]">
																<h3 className="mr-[1rem]">Review Status:</h3>
															</div>
														}
														form={
															<DropdownField
																hideEmptyOption={true}
																className="flex-1"
																name="reviewStatusId"
																placeholder="Review Status"
																options={ReviewStatusOptions}
															/>
														}
													/>

													<DropdownField
														className="flex-1"
														name="reviewerId"
														placeholder="Reviewer"
														options={reviewerOptions}
														value={formikProps.values.reviewerId}
														onChange={(event) => {
															const updatePropertyRequest: UpdatePropertyRequest =
																{
																	id: formikProps.values.id,
																	propertyName: "reviewerId",
																	propertyValue: event.currentTarget.value
																		? event.currentTarget.value
																		: null,
																};

															updateEntryProperty(updatePropertyRequest)
																.then((resp) => {
																	if (resp.status === 200) {
																		addNewAlert({
																			type: "success",
																			message: "Successfully Updated Reviewer",
																		});
																	} else {
																		console.log("Reviewer Update Failed", resp);
																		addNewAlert({
																			type: "error",
																			message: "Reviewer Update Failed",
																		});
																	}
																})
																.catch((error) => {
																	console.log("Reviewer Update Failed", error);
																	addNewAlert({
																		type: "error",
																		message: "Reviewer Update Failed",
																	});
																});
														}}
													/>

													<p>
														<span>Last Modified User: </span>
														<span>{props.modifiedByName! || ""}</span>
													</p>

													<p>
														<span>
															{assetsConfig.labels.entry.singular} Contact Name:{" "}
														</span>
														<span>
															{formikProps.values.company?.entryContactName}
														</span>
													</p>

													<p>
														<span>
															{assetsConfig.labels.entry.singular} Contact
															Email:{" "}
														</span>
														<span>
															{formikProps.values.company?.entryContactEmail}
														</span>
													</p>

													<p>
														<span>
															{assetsConfig.labels.entry.singular} Contact
															Phone:{" "}
														</span>
														<span>
															{formikProps.values.company?.entryContactPhone}
														</span>
													</p>
												</div>
											</MetadataContainer>

											{formikProps.values.parentEntry?.id && (
												<Authorized
													settings={settings}
													loading={loading}
													feature="Entry Parent/Child"
													authorized={
														<>
															<p className="mt-[1.5rem]">Child of:</p>

															<div className="flex items-center gap-[.5rem]">
																<a
																	href={`/admin/edit-entry?entryId=${formikProps.values.parentEntry.id}`}
																	target="_blank"
																	rel="noreferrer"
																>
																	{formikProps.values.parentEntry.title} (eid:{" "}
																	{formikProps.values.parentEntry.id})
																</a>
																<Icon
																	icon="close"
																	color={theme.colorPrimary}
																	onClick={() => {
																		clearParent(formikProps.values.id, "child");
																	}}
																/>
															</div>
														</>
													}
												/>
											)}

											{formikProps.values.childEntries &&
												formikProps.values.childEntries.length > 0 && (
													<Authorized
														settings={settings}
														loading={loading}
														feature="Entry Parent/Child"
														authorized={
															<p className="mt-[1.5rem]">
																<span>Parent of: </span> <br />
																{formikProps.values.childEntries.map(
																	(child, i) => (
																		<div
																			key={i}
																			className="flex items-center gap-[.5rem]"
																		>
																			<a
																				href={`/admin/edit-entry?entryId=${child.id}`}
																				target="_blank"
																				rel="noreferrer"
																			>
																				{child.title} (eid: {child.id})
																			</a>
																			<Icon
																				icon="close"
																				color={theme.colorPrimary}
																				onClick={() =>
																					clearParent(child.id, "parent")
																				}
																			/>
																		</div>
																	)
																)}
															</p>
														}
													/>
												)}

											<div className="flex flex-wrap gap-[1rem] mt-[1.5rem]">
												{!settings.isDemo && (
													<Button
														onClick={() => setEntryToMove(props.id)}
														className="button-primary"
													>
														Move {assetsConfig.labels.entry.singular}
													</Button>
												)}

												{!settings.isDemo &&
													(!formikProps.values.childEntries ||
														formikProps.values.childEntries.length === 0) &&
													!formikProps.values.parentEntry && (
														<Authorized
															settings={settings}
															loading={loading}
															feature="Entry Parent/Child"
															authorized={
																<Button
																	className="button-gold mr-auto"
																	onClick={() =>
																		setShowModal({
																			...showModal,
																			markAsChild: true,
																		})
																	}
																>
																	Mark as Child
																</Button>
															}
														/>
													)}

												{!settings.isDemo && (
													<Button
														disabled={reviewStatus === 6}
														onClick={() => {
															setReviewStatus(6);
															changeReviewStatus(props.id, 6);
														}}
														className="button-danger"
													>
														Withdraw
													</Button>
												)}
											</div>
										</SectionBody>
									</CollapsibleContainer>
								</Section>

								{/* basic info  */}
								<Section>
									<CollapsibleContainer
										title="Basic Info"
										isCollapsed={collapsible.basicInfo}
										onClickCollapse={() =>
											setCollapsible({
												...collapsible,
												basicInfo: !collapsible.basicInfo,
											})
										}
									>
										<SectionBody>
											<div className="flex flex-col gap-[2rem]">
												<CampaignFieldDisplay
													value={formikProps.values.title}
													onSave={() => {
														const updatePropertyRequest: UpdatePropertyRequest =
															{
																id: formikProps.values.id,
																propertyName: "title",
																propertyValue: formikProps.values.title,
															};

														return updateEntryProperty(updatePropertyRequest);
													}}
													onDiscard={() => formikProps.resetForm()}
													name={"title"}
													subText={
														<p className="ml-[1rem]">
															{formikProps.values.title
																? formikProps.values.title
																: `No ${assetsConfig.labels.entry.singular} ${
																		props.program.titleNameOverride || "Title"
																  }`}
														</p>
													}
													display={
														<div className="flex items-center gap-[.5rem]">
															<h3 className="mr-[1rem]">
																{props.program.titleNameOverride || "Title"}*
															</h3>
														</div>
													}
													form={
														<TextField
															name="title"
															placeholder={
																props.program.titleNameOverride || "Title"
															}
															value={formikProps.values.title}
														/>
													}
												/>
												<CampaignFieldDisplay
													value={formikProps.values.brand}
													onSave={() => {
														const updatePropertyRequest: UpdatePropertyRequest =
															{
																id: formikProps.values.id,
																propertyName: "brand",
																propertyValue: formikProps.values.brand,
															};

														return updateEntryProperty(updatePropertyRequest);
													}}
													onDiscard={() => formikProps.resetForm()}
													name={"brand"}
													subText={
														<p className="ml-[1rem]">
															{formikProps.values.brand
																? formikProps.values.brand
																: `No ${assetsConfig.labels.entry.singular} ${
																		props.program.brandNameOverride || "Brand"
																  }`}
														</p>
													}
													display={
														<div className="flex items-center gap-[.5rem]">
															<h3 className="mr-[1rem]">
																{props.program.brandNameOverride || "Brand"}*
															</h3>
														</div>
													}
													form={
														<>
															{brandOptions && brandOptions.length > 0 ? (
																<DropdownField
																	className="flex-1"
																	name="brand"
																	placeholder={
																		props.program.brandNameOverride || "Brand"
																	}
																	options={brandOptions}
																/>
															) : (
																<TextField
																	name="brand"
																	placeholder={
																		props.program.brandNameOverride || "Brand"
																	}
																	value={formikProps.values.brand}
																/>
															)}
														</>
													}
												/>
												{!settings.isDemo && (
													<div className="grid grid-cols-2 gap-[1rem]">
														{formikProps.values.program.isCampaignEligible && (
															<CampaignFieldDisplay
																value={formikProps.values.isCampaign}
																onSave={() => {
																	return setIsCampaign(
																		formikProps.values.id,
																		formikProps.values.isCampaign
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"isCampaign"}
																subText={
																	<p className="ml-[1rem]">
																		{formikProps.values.isCampaign
																			? "Yes"
																			: "No"}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">
																			Campaign{" "}
																			{assetsConfig.labels.entry.singular}*
																		</h3>
																	</div>
																}
																form={
																	<ToggleSwitchField
																		id="isCampaign"
																		name="isCampaign"
																		label={`Campaign ${assetsConfig.labels.entry.singular}`}
																		checked={formikProps.values.isCampaign}
																		disabled={props.program.isCampaignOnly}
																		onClick={() => {
																			if (
																				formikProps.values.isCampaign &&
																				formikProps.values.executions &&
																				formikProps.values.executions.length > 1
																			) {
																				formikProps.setFieldValue(
																					"executions",
																					[formikProps.values.executions[0]]
																				);
																			}
																		}}
																	/>
																}
															/>
														)}

														<Authorized
															settings={settings}
															loading={loading}
															feature="Terms and Conditions"
															authorized={
																<CampaignFieldDisplay
																	value={formikProps.values.termsAndConditions}
																	onSave={() => {
																		const updatePropertyRequest: UpdatePropertyRequest =
																			{
																				id: formikProps.values.id,
																				propertyName: "termsAndConditions",
																				propertyValue:
																					formikProps.values.termsAndConditions,
																			};

																		return updateEntryProperty(
																			updatePropertyRequest
																		);
																	}}
																	onDiscard={() => formikProps.resetForm()}
																	name={"termsAndConditions"}
																	subText={
																		formikProps.values.termsAndConditions ? (
																			<p className="ml-[1rem]">Accepted</p>
																		) : (
																			<ErrorText className="ml-[1rem]">
																				Not Accepted
																			</ErrorText>
																		)
																	}
																	display={
																		<div className="flex items-center gap-[.5rem]">
																			<h3 className="mr-[1rem]">
																				Terms and Conditions*
																			</h3>
																		</div>
																	}
																	form={
																		<ToggleSwitchField
																			name="termsAndConditions"
																			label="Terms and Conditions"
																			checked={
																				formikProps.values.termsAndConditions
																			}
																			id={"termsAndConditions"}
																		/>
																	}
																/>
															}
														/>
													</div>
												)}
												<div className="grid grid-cols-2 gap-[1rem]">
													<Authorized
														settings={settings}
														loading={loading}
														feature="Commerce"
														authorized={
															<CampaignFieldDisplay
																onSave={() => {
																	const updatePropertyRequest: UpdatePropertyRequest =
																		{
																			id: formikProps.values.id,
																			propertyName: "paymentStatusId",
																			propertyValue:
																				formikProps.values.paymentStatusId,
																		};

																	return updateEntryProperty(
																		updatePropertyRequest
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"paymentStatusId"}
																subText={
																	<p className="ml-[1rem]">
																		{
																			PaymentStatusOptions[
																				formikProps.values.paymentStatusId
																			].label
																		}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">
																			Payment Status*
																		</h3>
																	</div>
																}
																form={
																	<DropdownField
																		className="flex-1"
																		name="paymentStatusId"
																		placeholder="Payment Status"
																		options={PaymentStatusOptions}
																	/>
																}
															/>
														}
													/>
													<Authorized
														settings={settings}
														loading={loading}
														feature="Judging Fields"
														authorized={
															<CampaignFieldDisplay
																onSave={() => {
																	const updatePropertyRequest: UpdatePropertyRequest =
																		{
																			id: formikProps.values.id,
																			propertyName: "awardLevel",
																			propertyValue: formikProps.values
																				.awardLevel
																				? formikProps.values.awardLevel
																				: "",
																		};

																	return updateEntryProperty(
																		updatePropertyRequest
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"awardLevel"}
																subText={
																	<p className="ml-[1rem]">
																		{formikProps.values.awardLevel}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">Award Level</h3>
																	</div>
																}
																form={
																	<DropdownField
																		className="flex-1"
																		name="awardLevel"
																		placeholder="Award Level"
																		options={awardOptions}
																	/>
																}
															/>
														}
													/>
													{props.program.physicalComponentAllowed && (
														<>
															<CampaignFieldDisplay
																onSave={() => {
																	const updatePropertyRequest: UpdatePropertyRequest =
																		{
																			id: formikProps.values.id,
																			propertyName: "hasPhysicalComponent",
																			propertyValue:
																				formikProps.values.hasPhysicalComponent,
																		};

																	return updateEntryProperty(
																		updatePropertyRequest
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"hasPhysicalComponent"}
																subText={
																	<p className="ml-[1rem]">
																		{formikProps.values.hasPhysicalComponent
																			? "Yes"
																			: "No"}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">
																			Has Physical Component?*
																		</h3>
																	</div>
																}
																form={
																	<ToggleSwitchField
																		id="hasPhysicalComponent"
																		name="hasPhysicalComponent"
																		label="Has Physical Component?"
																		checked={
																			formikProps.values.hasPhysicalComponent
																		}
																		onClick={() => {
																			formikProps.setFieldValue(
																				"physicalAssetStatus",
																				!formikProps.values.hasPhysicalComponent
																					? PhysicalAssetStatus.NotReceived
																					: PhysicalAssetStatus.NoPhysical
																			);
																		}}
																	/>
																}
															/>

															<CampaignFieldDisplay
																onSave={() => {
																	const updatePropertyRequest: UpdatePropertyRequest =
																		{
																			id: formikProps.values.id,
																			propertyName: "physicalAssetStatus",
																			propertyValue:
																				formikProps.values.physicalAssetStatus,
																		};

																	return updateEntryProperty(
																		updatePropertyRequest
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"physicalAssetStatus"}
																subText={
																	<p className="ml-[1rem]">
																		{
																			PhysicalAssetStatusOptions[
																				formikProps.values.physicalAssetStatus
																			].label
																		}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">
																			Physical Asset Status
																			{formikProps.values.hasPhysicalComponent
																				? "*"
																				: ""}
																		</h3>
																	</div>
																}
																form={
																	<DropdownField
																		className="flex-1"
																		name="physicalAssetStatus"
																		placeholder="Physical Asset Status"
																		options={PhysicalAssetStatusOptions}
																		hideEmptyOption={true}
																	/>
																}
															/>

															<CampaignFieldDisplay
																value={
																	formikProps.values.physicalItemDescription
																}
																onSave={() => {
																	const updatePropertyRequest: UpdatePropertyRequest =
																		{
																			id: formikProps.values.id,
																			propertyName: "physicalItemDescription",
																			propertyValue:
																				formikProps.values
																					.physicalItemDescription,
																		};

																	return updateEntryProperty(
																		updatePropertyRequest
																	);
																}}
																onDiscard={() => formikProps.resetForm()}
																name={"physicalItemDescription"}
																subText={
																	<p className="ml-[1rem]">
																		{formikProps.values.physicalItemDescription
																			? formikProps.values
																					.physicalItemDescription
																			: `No Physical Item Description`}
																	</p>
																}
																display={
																	<div className="flex items-center gap-[.5rem]">
																		<h3 className="mr-[1rem]">
																			Physical Item Description
																			{formikProps.values.hasPhysicalComponent
																				? "*"
																				: ""}
																		</h3>
																	</div>
																}
																form={
																	<TextField
																		name="physicalItemDescription"
																		placeholder={"Physical Item Description"}
																		value={
																			formikProps.values.physicalItemDescription
																		}
																		component="textarea"
																	/>
																}
															/>
														</>
													)}
												</div>

												{!settings.isDemo && showExecutionSelection && (
													<div className="grid grid-cols-2 gap-[1rem]">
														<CampaignFieldDisplay
															onDiscard={() => {
																if (tempExecutionType) {
																	setSelectedExecutionProgramId(
																		tempExecutionType
																	);
																	setTempExecutionType(undefined);
																}

																formikProps.resetForm();
															}}
															subText={
																<p className="ml-[1rem]">
																	<p>
																		{
																			executionTypeOptions.find(
																				(e) =>
																					e.value == selectedExecutionProgramId
																			)?.label
																		}
																	</p>
																</p>
															}
															display={
																<div className="flex items-center gap-[.5rem]">
																	<h3 className="mr-[1rem]">
																		{formikProps.values.program
																			?.childLevelDisplayLabel + "*"}
																	</h3>
																</div>
															}
															form={
																<DropdownField
																	className="flex-1"
																	name={`selectedExecutionProgramId`}
																	placeholder="Select Type"
																	value={selectedExecutionProgramId}
																	options={executionTypeOptions}
																	hideEmptyOption={true}
																	readOnly={isEntryClosed}
																	onChange={(e) => {
																		const id = parseInt(e.target.value);
																		setTempExecutionType(
																			selectedExecutionProgramId
																		);
																		setSelectedExecutionProgramId(id);
																	}}
																/>
															}
															onSave={() => handleChangeExecutionProgram()}
															onSaveSuccessCallback={(response) => {
																setTempExecutionType(undefined);

																if (
																	!formikProps.values.executions ||
																	formikProps.values.executions.length < 1
																) {
																	formikProps.setFieldValue("executions", [
																		...formikProps.values.executions,
																		{
																			...response.data,
																			index: formikProps.values.executions
																				? formikProps.values.executions.length
																				: 0,
																		},
																	]);
																} else if (
																	formikProps.values.executions &&
																	formikProps.values.executions.length > 0
																) {
																	formikProps.setFieldValue("executions", [
																		...response.data,
																	]);
																}
															}}
														/>
													</div>
												)}
												<div className="mt-[1rem]">
													{formikProps.values.isCampaign &&
														executionForms &&
														executionForms.length > 0 &&
														executionForms.map((card, i) =>
															renderCard(card, i)
														)}
													{formikProps.values.isCampaign &&
														formikProps.values.executions.length < 10 && (
															<div className="flex flex-wrap gap-[1rem] mt-[1rem]">
																<Button
																	className="w-[250px] ml-auto"
																	iconLeft="plus"
																	iconColor={theme.colorCopyLightLight}
																	onClick={() => {
																		if (
																			!selectedExecutionProgramId ||
																			(props.program.isMixedCampaignEligible &&
																				props.program.children &&
																				props.program.children?.length > 1)
																		) {
																			setShowModal({
																				...showModal,
																				addExecution: true,
																			});
																		} else {
																			addExecution(selectedExecutionProgramId!);
																		}
																	}}
																>
																	Add {assetsConfig.labels.execution.singular}
																</Button>
															</div>
														)}
													{isExecutionFormDirty && (
														<div className="flex gap-[1rem] mt-[1rem]">
															<Button
																className="w-[250px] button-light ml-auto"
																icon="close"
																iconColor={theme.colorCopyDarkDark}
																iconSize="18px"
																onClick={() => {
																	// formikProps.resetForm();
																	setExecutionForms(
																		formikProps.values.executions
																	);
																	setIsExecutionFormDirty(false);
																}}
															>
																Discard Changes
															</Button>

															<Button
																className="w-[250px]"
																icon="check"
																iconColor={theme.colorCopyLightLight}
																onClick={() => {
																	const list = executionForms.map(
																		(execution, i) => {
																			return {
																				id: execution.id,
																				propertyName: "Index",
																				propertyValue: i,
																			};
																		}
																	);
																	onSubmit(updateExecutionProperty, list);
																}}
															>
																Save Changes
															</Button>
														</div>
													)}
												</div>
											</div>
										</SectionBody>
									</CollapsibleContainer>
								</Section>

								{/* entry fields */}
								<Section>
									<CollapsibleContainer
										title={`${assetsConfig.labels.entry.singular} Fields`}
										isCollapsed={collapsible.entryFields}
										onClickCollapse={() =>
											setCollapsible({
												...collapsible,
												entryFields: !collapsible.entryFields,
											})
										}
									>
										<SectionBody className="flex flex-col gap-[2rem]">
											<RenderAdminEntryFields
												name="campaignFields"
												fields={sortEntryFields(
													formikProps.values.campaignFields
												)}
												onDiscard={() => formikProps.resetForm()}
												hideMediaFields
											/>
										</SectionBody>
									</CollapsibleContainer>
								</Section>

								{/* media/executions */}
								<Section>
									<CollapsibleContainer
										title={
											formikProps.values.isCampaign
												? assetsConfig.labels.execution.plural
												: "Media"
										}
										isCollapsed={collapsible.executions}
										onClickCollapse={() =>
											setCollapsible({
												...collapsible,
												executions: !collapsible.executions,
											})
										}
										icons={[
											<Icon
												icon="edit"
												color={theme.colorCopyLightLight}
												height="21px"
												width="21px"
												onClick={() =>
													window.open(
														`/admin/edit-entry/media?entryId=${props.id}`
													)
												}
											/>,
										]}
									>
										<SectionBody className="flex flex-col !p-0">
											{hasEntryLevelMediaFields && (
												<EntryMediaContainer>
													<h1 className="playfair-500">
														{assetsConfig.labels.entry.singular} Level Media
													</h1>
													<RenderAdminEntryFields
														name="campaignFields"
														fields={sortEntryFields(
															formikProps.values.campaignFields
														)}
														onDiscard={() => formikProps.resetForm()}
														hideNonMediaFields
													/>
												</EntryMediaContainer>
											)}

											{formikProps.values.executions &&
												formikProps.values.executions.map((execution, i) => (
													<ExecutionContainer key={i} id={String(execution.id)}>
														{formikProps.values.isCampaign &&
															(formikProps.values.executions.length > 1 ||
																formikProps.values.program
																	.isMixedCampaignEligible) && (
																<>
																	<CampaignFieldDisplay
																		value={execution.title}
																		onSave={() => {
																			const updatePropertyRequest: UpdatePropertyRequest =
																				{
																					id: execution.id,
																					propertyName: "title",
																					propertyValue: execution.title,
																				};

																			return updateExecutionProperty(
																				updatePropertyRequest
																			);
																		}}
																		onDiscard={() => formikProps.resetForm()}
																		name={`executions.${i}.title`}
																		display={
																			<h1 className="playfair-500">
																				{execution.title
																					? execution.title
																					: `No ${assetsConfig.labels.execution.singular} Title`}
																			</h1>
																		}
																		form={
																			<TextField
																				name={`executions.${i}.title`}
																				value={execution.title}
																				hiddenlabel
																				readOnly={false}
																			/>
																		}
																	/>
																	<div className="flex flex-col gap-[.5rem]">
																		<CampaignFieldDisplay
																			onDiscard={() => formikProps.resetForm()}
																			readOnly={
																				!props.program.isMixedCampaignEligible
																			}
																			subText={
																				<p className="ml-[1rem]">
																					<p>
																						{
																							executionTypeOptions.find(
																								(e) =>
																									e.value == execution.programId
																							)?.label
																						}
																					</p>
																				</p>
																			}
																			display={
																				<div className="flex items-center gap-[.5rem]">
																					<h3 className="mr-[1rem]">
																						{execution.program
																							.childLevelDisplayLabel + "*"}
																					</h3>
																				</div>
																			}
																			form={
																				<DropdownField
																					className="flex-1"
																					name={`executions.${i}.programId`}
																					placeholder="Select Type"
																					value={execution.programId}
																					options={executionTypeOptions}
																					hideEmptyOption={true}
																				/>
																			}
																			onSave={() => {
																				return changeExecutionProgram(
																					formikProps.values.id,
																					formikProps.values.companyId,
																					execution.programId,
																					[execution]
																				);
																			}}
																			onSaveSuccessCallback={(response) => {
																				const updatedExecution = response
																					.data[0] as ExecutionModel;
																				const executions =
																					formikProps.values.executions.map(
																						(e) =>
																							e.id == execution.id
																								? updatedExecution
																								: e
																					);

																				setInitialValues({
																					...formikProps.values,
																					executions: executions,
																				});
																			}}
																		/>
																	</div>
																</>
															)}

														<RenderAdminEntryFields
															name={`executions.${i}.executionFields`}
															fields={sortEntryFields(
																execution.executionFields
															)}
															onDiscard={() => formikProps.resetForm()}
														/>
													</ExecutionContainer>
												))}
										</SectionBody>
									</CollapsibleContainer>
								</Section>

								{/* credits */}
								{formikProps.values.creditFields.length > 0 && (
									<Section>
										<CollapsibleContainer
											title="Credits"
											isCollapsed={collapsible.credits}
											onClickCollapse={() =>
												setCollapsible({
													...collapsible,
													credits: !collapsible.credits,
												})
											}
											icons={[
												<Icon
													icon="edit"
													color={theme.colorCopyLightLight}
													height="21px"
													width="21px"
													onClick={() =>
														window.open(
															`/admin/edit-entry/credits?entryId=${props.id}`
														)
													}
												/>,
											]}
										>
											<SectionBody className="!p-0">
												{/* <CreditFields
                         name={"creditFields"}
                         fieldArray={formikProps.values.creditFields}
                       /> */}
												<RenderCreditFields
													name={"creditFields"}
													creditFields={sortEntryFields(
														formikProps.values.creditFields
													)}
												/>
											</SectionBody>
										</CollapsibleContainer>
									</Section>
								)}
							</div>

							{/* passing sticky */}
							<Section className="side-sticky space-y-5 !shadow-none">
								<div className="grid gap-4 grid-cols-3">
									<Button
										disabled={reviewStatus === 5}
										onClick={() => {
											setReviewStatus(5);
											changeReviewStatus(props.id, 5);
										}}
									>
										{reviewStatus === 5 ? "Passed" : "Pass"}
									</Button>
									<Button
										className="button-light"
										disabled={reviewStatus === 2}
										onClick={() => {
											setReviewStatus(2);
											changeReviewStatus(props.id, 2);
										}}
									>
										{reviewStatus === 2
											? settings.isDemo
												? "Pending Failure"
												: "On Hold"
											: "Hold"}
									</Button>
									<Button
										disabled={reviewStatus === 3}
										onClick={() => {
											setReviewStatus(3);
											changeReviewStatus(props.id, 3);
										}}
										className="button-danger"
									>
										{reviewStatus === 3
											? "Failed"
											: settings.isDemo
											? "Send Failure"
											: "Fail"}
									</Button>
								</div>

								<StickyFields />
							</Section>
						</AdminEditPageContainer>
						<div className="w-full p-[2rem] pt-0">
							{/* revision history */}
							<Section>
								<StickyHeaderBar className="justify-between">
									<h2 className="text-colorCopyLightLight !font-normal">
										Revision History
									</h2>
									<Icon
										icon="caret"
										color={theme.colorCopyLightLight}
										height="21px"
										width="21px"
										className="caret"
										rotation={collapsible.revisionHistory ? "180deg" : "0"}
										onClick={() =>
											setCollapsible({
												...collapsible,
												revisionHistory: !collapsible.revisionHistory,
											})
										}
									/>
								</StickyHeaderBar>
								<RevisionNoteContainer>
									<TextField
										className="flex-1"
										name="revisionNote"
										// component="textarea"
										placeholder="Add Revision Note"
										value={formikProps.values.revisionNote}
									/>
									<Button
										//   className="button-light w-[150px] ml-auto mt-[1rem]"
										icon="plus"
										onClick={() =>
											formikProps.values.revisionNote &&
											addRevisionNote(
												formikProps.values.id,
												formikProps.values.revisionNote
											)
												.then((res) => {
													addNewAlert({
														type: "success",
														message: "Revision note saved",
													});

													getRevisionHistory();
												})
												.catch((err) =>
													addNewAlert({
														type: "error",
														message: "Problem saving revision note",
													})
												)
										}
									>
										Add
									</Button>
								</RevisionNoteContainer>
								<Collapsible isCollapsed={collapsible.revisionHistory}>
									<SectionBody className="flex flex-col gap-[1rem]">
										{isLoading && (
											<TablePlaceholder active={false}>
												<Loading fullScreen={false} showLogo={false} />
											</TablePlaceholder>
										)}

										{/* top pagination controls */}
										{revisionHistory.length > 0 && (
											<AdminPageControls
												totalRecords={totalRecords}
												totalAmountOfPages={totalAmountOfPages}
												currentPage={page}
												setPage={(newPage) => setPage(newPage)}
												setIsLoading={(isLoading) => setIsLoading(isLoading)}
											/>
										)}

										{!isLoading &&
										revisionHistory &&
										revisionHistory.length > 0 ? (
											<Table
												isActive
												dualScroll
												columnLabels={[
													"Field Changed",
													"Action",
													"Old Value",
													"New Value",
													"Revised by",
													"Date",
												]}
												fontSize={theme.xxxSmallSize}
											>
												{revisionHistory.map((record, index) => {
													return (
														<Fragment key={index}>
															<div className="cell">{record.fieldChanged}</div>
															<div className="cell">{record.action}</div>
															<WrapAnywhere className="cell">
																{/* {record.oldValues &&
																	isValidTimeStamp(record.oldValues)
																		? getLocalTime(record.oldValues, true)
																		: record.oldValues} */}
																{record.oldValues && record.oldValues}
															</WrapAnywhere>
															<WrapAnywhere className="cell">
																{/* {record.newValues &&
																	isValidTimeStamp(record.newValues)
																		? getLocalTime(record.newValues, true)
																		: record.newValues} */}
																{record.newValues && record.newValues}
															</WrapAnywhere>
															<div className="cell">{record.nameRevisedBy}</div>
															<div className="cell">
																{getLocalTime(record.createdDate, true, "long")}
															</div>
														</Fragment>
													);
												})}
											</Table>
										) : (
											<TablePlaceholder active={true}>
												Revision History Not Available
											</TablePlaceholder>
										)}

										{/* bottom pagination controls */}
										{revisionHistory.length > 0 && (
											<AdminPageControls
												totalRecords={totalRecords}
												totalAmountOfPages={totalAmountOfPages}
												currentPage={page}
												setPage={(newPage) => setPage(newPage)}
												setIsLoading={(isLoading) => setIsLoading(isLoading)}
											/>
										)}
									</SectionBody>
								</Collapsible>
							</Section>

							<Button
								className="w-fit ml-auto mt-[2rem] button-danger"
								icon="trash"
								onClick={() => setShowDeleteEntryWarning(true)}
							>
								Delete {assetsConfig.labels.entry.singular}
							</Button>
						</div>

						<SquareButton
							className="button-light fixed right-[1rem] bottom-[1rem] w-[35px] h-[35px]"
							icon="caret"
							iconRotation="180deg"
							iconSize="20px"
							onClick={() => window.scrollTo(0, 0)}
						/>
					</FormikProvider>
				</MediaCheckContext.Provider>
			</CreditCheckContext.Provider>
		</ValidateContext.Provider>
	);
};

export default AdminEntryEdit;

interface EntryFieldProps {
	label: string;
	value?: string | number | Date | Boolean | React.ReactNode;
	isRequired: boolean; // shows red required text if value is empty and is required
	hardLink?: string; // optional absolute path for hyperlink
	linkText?: string; // optional text for hyperlink
}

// indexed objects for credits
export interface CompanyCreditObj {
	[id: string]: CompanyCredit;
}

export interface IndividualCreditObj {
	[id: string]: IndividualCredit;
}

export interface Item {
	id: number;
	text: string;
}

export interface ContainerState {
	cards: Item[];
}
