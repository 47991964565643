import assetsConfig from "../../../../assetsConfig";

export const JuryVoteHeaders: { [key: string]: string } = {
	entryId: "Entry ID",
	isCampaign: "Campaign",
	entrySet: "Entry Set",
	entryLevelProgram: "Entry Level Program",
	entryType: "Entry Type",
	executionProgram: `${assetsConfig.labels.execution.singular} Program`,
	entrantCompanies: "Entrant Companies",
	brand: "Brand",
	title: "Title",
	numericScore: "Numeric Score",
	roundScore: "Round Score",
	previousRoundScore: "Previous Round Score",
	alsoIn: "Also In",
	abstains: "Abstains",
	chairVote: "Chair Vote",
	batchLabel: "Batch Label",
};

export function getStandardDate() {
	const dateNow: Date = new Date();

	let month: string = (dateNow.getMonth() + 1).toString();
	month = month.length === 1 && parseInt(month) < 10 ? "0" + month : month;

	let day: string = dateNow.getDate().toString();
	day = day.length === 1 && parseInt(day) < 10 ? "0" + day : day;

	let date: string = month + day + dateNow.getFullYear().toString();

	return date;
}
