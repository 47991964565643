import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Sort from "../../../components/Sort/Sort";
import TextField from "../../../components/FormFields/TextField";
import { useFormik, FormikProvider } from "formik";
import DropdownField, {
	DropdownOptions,
} from "../../../components/FormFields/DropdownField";
import Button from "../../../components/Button/Button";
import { TablePlaceholder } from "../../../components/Table/Table";
import axios from "axios";
import { urlEntries, urlPrograms } from "../../../endpoints";
import { Ancestor } from "../Program/ProgramInterfaces";
import {
	PaymentStatus,
	PaymentStatusOptions,
	ReviewStatusOptions,
} from "../../Checkout/OrderInterfaces";
import { ExecutionCardModel } from "../../../components/CheckoutEntryCard/CheckoutEntryCard";
import { getHierarchy } from "../../Checkout/manageCheckout";
import { getThumbnailSrc } from "../../MediaLibrary/manageMediaLibrary";
import { useHistory } from "react-router-dom";
import Pagination from "../../../utils/Pagination";
import StyledForm from "../../../components/Form/Form";
import { changePaymentStatus } from "../../Entries/manageEntry";
import { AdminPageControls, SearchFieldsContainer } from "./AdminSearch";
import Placeholder from "../../../assets/placeholders/placeholder.png";
import { CSVDownload, CSVLink } from "react-csv";
import {
	getStoredValue,
	setStoredValue,
} from "../../../components/LocalStorageStorage/LocalStorageStore";
import { useAwardableAwards } from "../../../hooks/useAwardableAwards";
import MultiDropdown from "../../../components/MultiDropdown/MultiDropdown";
import { Label } from "../../../components/FormFields/FieldTemplate";
import Table from "../../../components/Table/Table";
import Loading from "../../../components/Loading/Loading";
import EntryExportColumnsModal, {
	EntryExportColumn,
} from "./EntryExportColumnsModal";
import { SortDirection } from "../../../components/Table/Table";
import { getEntryReviewers } from "../User/managerUser";
import config from "../../../config";
import {
	getAgencyNetworks,
	getHoldingCompanies,
} from "../../Company/manageCompany";
import assetsConfig from "../../../assetsConfig";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { getLocalTime, getTimezoneOffset } from "../../../utils/timeUtilities";
import Authorized from "../../../components/Auth/Authorized";
import { NonFormikToggleSwitch } from "../../../components/FormFields/ToggleSwitchField";
import AdvancedSearchFields, {
	SearchObj,
} from "./AdvancedSearch/AdvancedSearchFields";
import CheckboxField from "../../../components/FormFields/CheckboxField";

export const StyledLink = styled(Link)`
	color: ${({ theme }) => theme.colorActivation};
`;

const CsvExport = styled(CSVLink)<{ disabled?: boolean }>`
	${(p) =>
		p.disabled &&
		`
      pointer-events: none;
    `};
`;

const initialSearch = {
	brand: undefined,
	companyName: undefined,
	contactEmail: undefined,
	paymentStatus: [],
	reviewStatus: [],
	awardLevel: [],
	title: undefined,
	id: undefined,
	entryProgram: undefined,
	RootPrograms: [],
	parentEntry: undefined,
	keyword: undefined,
	entryReviewNote: undefined,
	advancedHierarchySearchRows: [],
	includeDeleted: false,
};

const getScrubbedRequest = (
	request: EntrySearchRequest
): EntrySearchRequest => {
	let result = {
		id: request.id ? request.id : undefined,
		entryProgram: request.entryProgram ? request.entryProgram : undefined,
		paymentStatus: request.paymentStatus ? request.paymentStatus : undefined,
		reviewStatus: request.reviewStatus ? request.reviewStatus : undefined,
		RootPrograms: request.RootPrograms ? request.RootPrograms : undefined,
		companyName: request.companyName ? request.companyName : undefined,
		contactEmail: request.contactEmail ? request.contactEmail : undefined,
		title: request.title ? request.title : undefined,
		brand: request.brand ? request.brand : undefined,
		awardLevel: request.awardLevel ? request.awardLevel : undefined,
		parentEntry: request.parentEntry
			? JSON.parse(request.parentEntry)
			: undefined,
		keyword: request.keyword ? request.keyword : undefined,
		entryType: request.entryType ? request.entryType : undefined,
		isCampaign: request.isCampaign ? JSON.parse(request.isCampaign) : undefined,
		reviewers: request.reviewers ? request.reviewers : undefined,
		agencyNetworks: request.agencyNetworks ? request.agencyNetworks : undefined,
		holdingCompanies: request.holdingCompanies
			? request.holdingCompanies
			: undefined,
		entryReviewNote: request.entryReviewNote
			? request.entryReviewNote
			: undefined,
		advancedHierarchySearchRows: request.advancedHierarchySearchRows
			? request.advancedHierarchySearchRows
			: undefined,
		includeDeleted: request.includeDeleted ? true : false,
	};

	return result;
};

const EntrySearchTab = () => {
	const { settings, loading } = useSelector(
		(state: RootState) => state.settings
	);
	const theme = useTheme();
	const [searchVal, setSearchVal] = useState<EntrySearchRequest>(
		getStoredValue("entrySearch") || initialSearch
	);
	const [isActive, setActive] = useState(false);
	const [entries, setEntries] = useState<EntrySearchResult[]>([]);
	const [page, setPage] = useState(1);
	const [totalAmountOfPages, settotalAmountOfPages] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [totalRecords, setTotalRecords] = useState(0);
	const [errors, setErrors] = useState<string[]>([]);
	const history = useHistory();
	const [csvEntries, setCsvEntries] = useState<any[]>([]);
	const [csvLoading, setCsvLoading] = useState(false);
	const [showExportColumnsModal, setShowExportColumnsModal] = useState(false);
	const [selectedExportColumns, setSelectedExportColumns] = useState<
		EntryExportColumn[]
	>(getStoredValue("selectedExportColumns") || []);
	const [programOptions, setProgramOptions] = useState([]);
	const awardOptions = useAwardableAwards();
	const parentChildOptions: DropdownOptions[] = [
		{ value: true, label: "Parent" },
		{ value: false, label: "Child" },
	];
	const isCampaignOptions: DropdownOptions[] = [
		{ value: true, label: "Campaign" },
		{ value: false, label: "Single" },
	];
	const [isLoading, setIsLoading] = useState(false);
	const [activeSort, setActiveSort] = useState<number | null>(null);
	const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(
		SortDirection.Asc
	);
	const [agencyNetworksOptions, setAgencyNetworksDropdownOptions] = useState<
		DropdownOptions[]
	>([]);
	const [holdingCompaniesOptions, setHoldingCompaniesDropdownOptions] =
		useState<DropdownOptions[]>([]);
	const [reviewerOptions, setReviewerOptions] = useState<DropdownOptions[]>([]);
	const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
	const [resetSearch, setResetSearch] = useState(false);

	useEffect(() => {
		getAgencyNetworks().then((data) => {
			var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
			setAgencyNetworksDropdownOptions(dropdownOptions);
		});
	}, []);

	useEffect(() => {
		getHoldingCompanies().then((data) => {
			var dropdownOptions = data!.map((r) => ({ value: r.id, label: r.name }));
			setHoldingCompaniesDropdownOptions(dropdownOptions);
		});
	}, []);

	useEffect(() => {
		getEntryReviewers()
			.then((resp) => {
				if (resp.status === 200) {
					setReviewerOptions([
						...resp.data,
						{ label: "Unassigned", value: null },
					]);
				}
			})
			.catch((error) => {
				console.log("getEntryReviewers error", error);
			});
	}, []);

	useEffect(() => {
		if (entries.length > 1) {
			searchEntries(
				formikProps.values,
				activeSort !== null ? activeSort : undefined,
				activeSort !== null ? activeSortDirection : undefined
			);
		}
	}, [page, recordsPerPage]);

	useEffect(() => {
		async function fetchOptions() {
			const response = await axios.get(`${urlPrograms}/GetRootProgramOptions`);
			setProgramOptions(response.data);
		}

		fetchOptions();
	}, []);

	function handleSelectedColumns(columns: EntryExportColumn[]) {
		setCsvLoading(true);

		setSelectedExportColumns(columns);

		setStoredValue("selectedExportColumns", columns);

		const newRequest = getScrubbedRequest(formikProps.values);

		const queryParams = columns.map((p) => "columns=" + p.column).join("&");

		// Get the timezone offset and append it to the query parameters
		const timezoneOffset = getTimezoneOffset();
		const queryParamsWithOffset = `${queryParams}&timezoneOffset=${timezoneOffset}`;

		axios
			.post(
				`${urlEntries}/search/export?${queryParamsWithOffset}`,
				newRequest,
				{
					responseType: "blob",
				}
			)
			.then((response) => {
				const temp = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");

				link.href = temp;
				link.setAttribute(
					"download",
					`Entry Search Export ${getLocalTime(new Date())}.csv`
				);
				document.body.appendChild(link);
				link.click();

				setCsvLoading(false);
			})
			.catch((error: any) => {
				return error;
			});
	}

	const searchEntries = async (
		request: EntrySearchRequest,
		activeSort?: number,
		sortDirection?: SortDirection
	) => {
		const newRequest = getScrubbedRequest(request);
		const sortField =
			activeSort !== undefined && activeSort !== null
				? EntrySearchSortEnum[activeSort]
				: undefined;
		const response = await axios.post(`${urlEntries}/search`, newRequest, {
			params: {
				page,
				recordsPerPage,
				sortField,
				sortDirection,
			},
		});

		if (response.status === 200) {
			setEntries([]); // This cleans the results and forces a React re-render in cases of sorting.
			setEntries(response.data);
			const total = parseInt(response.headers["totalamountofrecords"]);
			setTotalRecords(total);
			settotalAmountOfPages(Math.ceil(total / recordsPerPage));
			setIsLoading(false);
		}
	};

	async function PostSearch(request: EntrySearchRequest) {
		//console.log("pre-request ", request);
		try {
			const newRequest = getScrubbedRequest(request);

			//console.log("request ", newRequest);
			setErrors([]);
			setActive(false);
			const response = await axios.post(`${urlEntries}/search`, newRequest, {
				params: { page, recordsPerPage },
			});

			if (response.status === 200) {
				setEntries(response.data);
				const total = parseInt(response.headers["totalamountofrecords"]);
				setTotalRecords(total);
				settotalAmountOfPages(Math.ceil(total / recordsPerPage));
				setActive(true);
				setIsLoading(false);
			}
		} catch (error: any) {
			// console.log(error);
			//setErrors(error.response.data);
			setIsLoading(false);
		}
	}

	const fieldRequired = "This field is required";
	const formikProps = useFormik({
		initialValues: searchVal,
		onSubmit: async (value) => {
			setStoredValue("entrySearch", getScrubbedRequest(value));
			setPage(1);
			await PostSearch(value);
			// console.log(value);
		},
	});

	// clear non-applicable search fields
	useEffect(() => {
		if (isAdvancedSearch) {
			formikProps.setFieldValue("RootPrograms", []);
			formikProps.setFieldValue("entryProgram", undefined);
		}
	}, [isAdvancedSearch]);

	// for the sort options
	// backend uses an enum with index, front-end using an enum with string label
	// need to map label to index
	const mapEnum = (sortField: string) => {
		const sortEnumIndex = Object.values(EntrySearchSortOptions).indexOf(
			sortField as EntrySearchSortOptions
		);

		return sortEnumIndex !== -1 ? (sortEnumIndex as any) : null;
	};

	useEffect(() => {
		if (activeSort !== null) {
			searchEntries(formikProps.values, activeSort, activeSortDirection);
		}
	}, [activeSort, activeSortDirection]);

	const fieldLayout = settings.isDemo ? (
		<>
			<TextField
				name="id"
				placeholder={assetsConfig.labels.entry.singular + " Id"}
				value={formikProps.values.id}
			/>
			<TextField
				name="keyword"
				placeholder="Keyword"
				value={formikProps.values.keyword}
			/>
			<MultiDropdown
				placeholder={assetsConfig.labels.review.singular + " Status"}
				options={ReviewStatusOptions}
				selectedValues={(formikProps.values.reviewStatus || []).flatMap(
					(value: number) => {
						const correspondingOption = ReviewStatusOptions.find(
							(option: DropdownOptions) => option.value === value
						);
						return correspondingOption ? [correspondingOption] : [];
					}
				)}
				onChange={(selectedList) => {
					formikProps.setFieldValue(
						"reviewStatus",
						selectedList.map((x) => Number(x.value))
					);
				}}
			/>
			<TextField
				name="title"
				placeholder="Title"
				value={formikProps.values.title}
			/>
			<TextField
				name="companyName"
				placeholder={assetsConfig.labels.company.singular + " Name"}
				value={formikProps.values.companyName}
			/>
			<TextField
				name="entryReviewNote"
				placeholder={assetsConfig.labels.review.singular + " Note"}
				value={formikProps.values.entryReviewNote}
			/>
			<TextField
				name="brand"
				placeholder="Brand"
				value={formikProps.values.brand}
			/>
			<TextField
				name="contactEmail"
				placeholder="Author Email"
				value={formikProps.values.contactEmail}
			/>
			<MultiDropdown
				placeholder={assetsConfig.labels.reviewer.plural}
				options={reviewerOptions}
				selectedValues={
					Array.isArray(formikProps.values.reviewers)
						? formikProps.values.reviewers.flatMap((value: string) => {
								const correspondingOption = reviewerOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
						  })
						: []
				}
				onChange={(selectedList) => {
					formikProps.setFieldValue(
						"reviewers",
						selectedList.map((x: DropdownOptions) => x.value)
					);
				}}
			/>
			<CheckboxField
				name="includeDeleted"
				placeholder="Include Deleted"
				checked={formikProps.values.includeDeleted}
			/>
		</>
	) : (
		<>
			<TextField
				name="id"
				placeholder={assetsConfig.labels.entry.singular + " Id"}
				value={formikProps.values.id}
			/>

			<MultiDropdown
				placeholder="Root Programs"
				options={programOptions}
				selectedValues={
					Array.isArray(formikProps.values.RootPrograms)
						? formikProps.values.RootPrograms.flatMap((value: number) => {
								const correspondingOption = programOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
						  })
						: []
				}
				onChange={(selectedList) => {
					formikProps.setFieldValue(
						"RootPrograms",
						selectedList.map((x: DropdownOptions) => Number(x.value))
					);
				}}
				disabled={isAdvancedSearch}
			/>

			<TextField
				name="keyword"
				placeholder="Keyword"
				value={formikProps.values.keyword}
			/>
			<TextField
				name="title"
				placeholder="Title"
				value={formikProps.values.title}
			/>

			<TextField
				name="entryProgram"
				placeholder={assetsConfig.labels.entry.singular + " Program"}
				value={formikProps.values.entryProgram}
				disabled={isAdvancedSearch}
			/>

			<Authorized
				settings={settings}
				loading={loading}
				feature="Commerce"
				authorized={
					<MultiDropdown
						placeholder="Payment Status"
						options={PaymentStatusOptions}
						selectedValues={(formikProps.values.paymentStatus || []).flatMap(
							(value: number) => {
								const correspondingOption = PaymentStatusOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
							}
						)}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"paymentStatus",
								selectedList.map((x) => Number(x.value))
							);
						}}
					/>
				}
			/>

			<TextField
				name="brand"
				placeholder="Brand"
				value={formikProps.values.brand}
			/>

			<TextField
				name="entryType"
				placeholder={assetsConfig.labels.entry.singular + " Type"}
				value={formikProps.values.entryType}
			/>

			<MultiDropdown
				placeholder={assetsConfig.labels.review.singular + " Status"}
				options={ReviewStatusOptions}
				selectedValues={(formikProps.values.reviewStatus || []).flatMap(
					(value: number) => {
						const correspondingOption = ReviewStatusOptions.find(
							(option: DropdownOptions) => option.value === value
						);
						return correspondingOption ? [correspondingOption] : [];
					}
				)}
				onChange={(selectedList) => {
					formikProps.setFieldValue(
						"reviewStatus",
						selectedList.map((x) => Number(x.value))
					);
				}}
			/>
			<TextField
				name="companyName"
				placeholder={assetsConfig.labels.company.singular + " Name"}
				value={formikProps.values.companyName}
			/>

			<DropdownField
				name="isCampaign"
				placeholder="Campaign/Single"
				options={isCampaignOptions}
				value={formikProps.values.isCampaign}
			/>

			<TextField
				name="entryReviewNote"
				placeholder={assetsConfig.labels.review.singular + " Note"}
				value={formikProps.values.entryReviewNote}
			/>
			<TextField
				name="contactEmail"
				placeholder="Author Email"
				value={formikProps.values.contactEmail}
			/>

			<Authorized
				settings={settings}
				loading={loading}
				feature="Entry Parent/Child"
				authorized={
					<DropdownField
						name="parentEntry"
						options={parentChildOptions}
						placeholder="Parent/Child"
						value={formikProps.values.parentEntry}
					/>
				}
			/>

			<MultiDropdown
				placeholder={assetsConfig.labels.reviewer.plural}
				options={reviewerOptions}
				selectedValues={
					Array.isArray(formikProps.values.reviewers)
						? formikProps.values.reviewers.flatMap((value: string) => {
								const correspondingOption = reviewerOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
						  })
						: []
				}
				onChange={(selectedList) => {
					formikProps.setFieldValue(
						"reviewers",
						selectedList.map((x: DropdownOptions) => x.value)
					);
				}}
			/>

			<Authorized
				settings={settings}
				loading={loading}
				feature="Judging Fields"
				authorized={
					<MultiDropdown
						placeholder="Award Level"
						options={awardOptions}
						selectedValues={
							Array.isArray(formikProps.values.awardLevel)
								? formikProps.values.awardLevel.map((value) => ({
										value,
										label: value,
								  }))
								: []
						}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"awardLevel",
								selectedList.map((x) => x.label)
							);
						}}
					/>
				}
			/>
			<Authorized
				settings={settings}
				loading={loading}
				feature="Company Agency Network"
				authorized={
					<MultiDropdown
						placeholder={`Author ${assetsConfig.agencyNetworkCompanyName}`}
						options={agencyNetworksOptions}
						selectedValues={(formikProps.values.agencyNetworks || []).flatMap(
							(value: number) => {
								const correspondingOption = agencyNetworksOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
							}
						)}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"agencyNetworks",
								selectedList.map((x: DropdownOptions) => Number(x.value))
							);
						}}
					/>
				}
			/>
			<Authorized
				settings={settings}
				loading={loading}
				feature="Company Holding Company"
				authorized={
					<MultiDropdown
						placeholder={`Author ${assetsConfig.holdingCompanyName}`}
						options={holdingCompaniesOptions}
						selectedValues={(formikProps.values.holdingCompanies || []).flatMap(
							(value: number) => {
								const correspondingOption = holdingCompaniesOptions.find(
									(option: DropdownOptions) => option.value === value
								);
								return correspondingOption ? [correspondingOption] : [];
							}
						)}
						onChange={(selectedList) => {
							formikProps.setFieldValue(
								"holdingCompanies",
								selectedList.map((x: DropdownOptions) => Number(x.value))
							);
						}}
					/>
				}
			/>

			<CheckboxField
				name="includeDeleted"
				placeholder="Include Deleted"
				checked={formikProps.values.includeDeleted}
			/>
		</>
	);

	const tableLabels = settings.isDemo
		? [
				"Cover Image",
				EntrySearchSortOptions.EntryId,
				EntrySearchSortOptions.Title,
				// EntrySearchSortOptions.Level1,
				// EntrySearchSortOptions.Level2,
				// EntrySearchSortOptions.Level3,
				// EntrySearchSortOptions.Level4,
				// EntrySearchSortOptions.Level5,
				// EntrySearchSortOptions.EntryType,
				EntrySearchSortOptions.Brand,
				EntrySearchSortOptions.Author,
				EntrySearchSortOptions.CompanyContactName,
				// EntrySearchSortOptions.PaymentStatus,
				EntrySearchSortOptions.ReviewStatus,
				EntrySearchSortOptions.ExecutionCount,
				// EntrySearchSortOptions.AwardLevel,
		  ]
		: [
				"Cover Image",
				EntrySearchSortOptions.EntryId,
				EntrySearchSortOptions.Title,
				EntrySearchSortOptions.Level1,
				EntrySearchSortOptions.Level2,
				EntrySearchSortOptions.Level3,
				EntrySearchSortOptions.Level4,
				EntrySearchSortOptions.Level5,
				EntrySearchSortOptions.EntryType,
				EntrySearchSortOptions.Brand,
				EntrySearchSortOptions.Author,
				settings.featureFlags.Commerce
					? EntrySearchSortOptions.PaymentStatus
					: null,
				EntrySearchSortOptions.ReviewStatus,
				EntrySearchSortOptions.ExecutionCount,
				settings.featureFlags["Judging Fields"]
					? EntrySearchSortOptions.AwardLevel
					: null,
		  ].filter((x): x is string => !!x);

	const tableAlias = settings.isDemo
		? [
				"Cover Image",
				assetsConfig.labels.entry.singular + " Id",
				EntrySearchSortOptions.Title,
				// EntrySearchSortOptions.Level1,
				// EntrySearchSortOptions.Level2,
				// EntrySearchSortOptions.Level3,
				// EntrySearchSortOptions.Level4,
				// EntrySearchSortOptions.Level5,
				// assetsConfig.labels.entry.singular + " Type",
				EntrySearchSortOptions.Brand,
				"Group",
				"Author",
				// EntrySearchSortOptions.PaymentStatus,
				assetsConfig.labels.review.singular + " Status",
				`${config.assets.labels.execution.singular} Count`,
				// EntrySearchSortOptions.AwardLevel,
		  ]
		: [
				"Cover Image",
				assetsConfig.labels.entry.singular + " Id",
				EntrySearchSortOptions.Title,
				EntrySearchSortOptions.Level1,
				EntrySearchSortOptions.Level2,
				EntrySearchSortOptions.Level3,
				EntrySearchSortOptions.Level4,
				EntrySearchSortOptions.Level5,
				assetsConfig.labels.entry.singular + " Type",
				EntrySearchSortOptions.Brand,
				EntrySearchSortOptions.Author,
				settings.featureFlags.Commerce
					? EntrySearchSortOptions.PaymentStatus
					: null,
				EntrySearchSortOptions.ReviewStatus,
				`${config.assets.labels.execution.singular} Count`,
				settings.featureFlags["Judging Fields"]
					? EntrySearchSortOptions.AwardLevel
					: null,
		  ].filter((x): x is string => !!x);

	return (
		<FormikProvider value={formikProps}>
			<SearchFieldsContainer>{fieldLayout}</SearchFieldsContainer>

			<div className="my-[1rem] flex gap-[1rem] flex-wrap">
				<NonFormikToggleSwitch
					className="w-full"
					id="isAdvancedSearch"
					checked={isAdvancedSearch}
					onClick={() => setIsAdvancedSearch(!isAdvancedSearch)}
					label="Enable Advanced Hierarchy Search"
					small
				/>
				{isAdvancedSearch && (
					<AdvancedSearchFields
						setSearchVal={(val) => {
							//console.log("search values", JSON.stringify(val, null, 2), val);
							formikProps.setFieldValue("advancedHierarchySearchRows", val);
						}}
						onResetSearch={resetSearch}
					/>
				)}
			</div>

			<div className="my-[1rem] flex gap-[1rem] justify-end flex-wrap">
				<Button
					className="button-light w-[150px]"
					type="button"
					onClick={() => {
						setResetSearch(!resetSearch);
						formikProps.setValues(initialSearch);
						setSearchVal(initialSearch);
						setEntries([]);
						setActive(false);
						setStoredValue("entrySearch", initialSearch);
						setActiveSort(null);
						setActiveSortDirection(SortDirection.Asc);
						setPage(1);
					}}
				>
					Reset Search
				</Button>
				<EntryExportColumnsModal
					show={showExportColumnsModal}
					selectedExportColumns={selectedExportColumns}
					onClickHide={() => setShowExportColumnsModal(false)}
					onSelectionChange={(columns) => handleSelectedColumns(columns)}
					localStorageKey="selectedExportColumns"
					getExportGroupsEndpoint={`${urlEntries}/search/export/columns`}
				/>
				<Button
					className="button-light w-[150px]"
					disabled={entries.length < 1}
					onClick={() => setShowExportColumnsModal(true)}
				>
					<div className="flex items-center gap-[.5rem]">
						Export
						{csvLoading && (
							<img className="w-[16px]" src={config.assets.loading.primary} />
						)}
					</div>
				</Button>

				<Button
					className="w-[150px]"
					type="submit"
					onClick={() => {
						setIsLoading(true);
						formikProps.submitForm();
					}}
				>
					Search
				</Button>
			</div>

			{/* top pagination controls */}
			{isActive && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}
			{!isLoading &&
				(entries && entries.length > 0 ? (
					<Table
						isActive={isActive}
						dualScroll
						columnLabels={tableLabels}
						labelAlias={tableAlias}
						firstColumnWidth="125px"
						onClickSort={(sortLabel, sortDirection) => {
							setActiveSort(mapEnum(sortLabel));
							setActiveSortDirection(sortDirection);
						}}
						sortTriangleSize="14px"
						{...(activeSort !== null && {
							activeSort: {
								label: Object.values(EntrySearchSortOptions)[activeSort],
								direction: activeSortDirection,
							},
						})}
					>
						{entries.map((entry: EntrySearchResult, index) => {
							const hierarchy =
								(entry.batchLabel && entry.batchLabel.split(" > ")) || [];
							const deletedOrAbandoned =
								entry.paymentStatusId === PaymentStatus.Deleted ||
								entry.paymentStatusId === PaymentStatus.Abandoned;

							return (
								<Fragment key={entry.id}>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<div className="img-container">
											<img
												src={
													(entry.coverImage &&
														getThumbnailSrc(entry.coverImage!)) ||
													Placeholder
												}
												alt="Entry Cover Image"
											/>
										</div>
									</div>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>
											<StyledLink
												to={`/admin/edit-entry?entryId=${entry.id}`}
												target="_blank"
											>
												{entry.id}
											</StyledLink>
										</p>
									</div>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>
											<StyledLink
												to={`/admin/edit-entry?entryId=${entry.id}`}
												target="_blank"
											>
												{entry.title}
											</StyledLink>
										</p>
									</div>
									{!settings.isDemo && (
										<>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.ancestryNames.at(0)}</p>
											</div>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.ancestryNames.at(1)}</p>
											</div>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.ancestryNames.at(2)}</p>
											</div>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.ancestryNames.at(3)}</p>
											</div>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.ancestryNames.at(4)}</p>
											</div>
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.entryType}</p>
											</div>
										</>
									)}

									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>{entry.brand}</p>
									</div>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>{entry.companyName}</p>
									</div>
									{settings.isDemo && (
										<div
											className={`cell ${
												deletedOrAbandoned ? "highlighted" : ""
											}`}
										>
											<p>{entry.companyContactName}</p>
										</div>
									)}
									<Authorized
										settings={settings}
										loading={loading}
										feature="Commerce"
										authorized={
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{PaymentStatus[entry.paymentStatusId]}</p>
											</div>
										}
									/>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>{ReviewStatusOptions[entry.reviewStatusId].label}</p>
									</div>
									<div
										className={`cell ${
											deletedOrAbandoned ? "highlighted" : ""
										}`}
									>
										<p>{entry.executions && entry.executions.length}</p>
									</div>
									<Authorized
										settings={settings}
										loading={loading}
										feature="Judging Fields"
										authorized={
											<div
												className={`cell ${
													deletedOrAbandoned ? "highlighted" : ""
												}`}
											>
												<p>{entry.awardLevel}</p>
											</div>
										}
									/>
								</Fragment>
							);
						})}
					</Table>
				) : (
					<TablePlaceholder active={isActive}>
						Please add a search to see result table.
					</TablePlaceholder>
				))}

			{isLoading && (
				<TablePlaceholder active={false}>
					<Loading fullScreen={false} showLogo={false} />
				</TablePlaceholder>
			)}

			{/* bottom pagination controls */}
			{isActive && !isLoading && (
				<AdminPageControls
					totalRecords={totalRecords}
					totalAmountOfPages={totalAmountOfPages}
					currentPage={page}
					setPage={(newPage) => setPage(newPage)}
					setIsLoading={(isLoading) => setIsLoading(isLoading)}
				/>
			)}
		</FormikProvider>
	);
};

export default EntrySearchTab;

interface EntrySearchRequest {
	id?: number;
	title?: string;
	brand?: string;
	companyName?: string;
	contactEmail?: string;
	awardLevel?: string[];
	paymentStatus?: number[];
	reviewStatus?: number[];
	RootPrograms?: number[];
	entryProgram?: string;
	parentEntry?: string;
	keyword?: string;
	entryType?: string;
	isCampaign?: string;
	reviewers?: string[];
	agencyNetworks?: number[];
	holdingCompanies?: number[];
	entryReviewNote?: string;
	sort?: {
		direction: SortDirection;
		label: string;
	};
	advancedHierarchySearchRows?: SearchObj[];
	includeDeleted?: boolean;
}

export interface EntrySearchResult {
	id: number;
	userId: string;
	companyId: number;
	companyName: string;
	programId: number;
	paymentStatusId: number;
	reviewStatusId: number;
	title: string;
	brand: string;
	coverImage: string;
	awardLevel?: string;
	isCampaign: boolean;
	executions: ExecutionCardModel[];
	companyType: string;
	companyCountry: string;
	companyContactEmail: string;
	companyContactName: string;
	ancestry: Ancestor[];
	batchLabel: string;
	ancestryNames: string[];
	entryType: string;
}

enum EntrySearchSortOptions {
	EntryId = "Entry Id",
	Title = "Title",
	Level1 = "Level 1",
	Level2 = "Level 2",
	Level3 = "Level 3",
	Level4 = "Level 4",
	Level5 = "Level 5",
	EntryType = "Entry Type",
	Brand = "Brand",
	Author = "Author",
	PaymentStatus = "Payment Status",
	ReviewStatus = "Review Status",
	ExecutionCount = "Execution Count",
	AwardLevel = "Award Level",
	CompanyContactName = "Company Contact Name",
}

enum EntrySearchSortEnum {
	EntryId,
	Title,
	Level1,
	Level2,
	Level3,
	Level4,
	Level5,
	EntryType,
	Brand,
	Author,
	PaymentStatus,
	ReviewStatus,
	ExecutionCount,
	AwardLevel,
	CompanyContactName,
}

interface EntrySearchDirection {
	[sortField: string]: null | SortDirection;
}
