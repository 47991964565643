import { useState, useEffect } from "react";
import {
	PageContainer,
	ProgramCardsContainer,
} from "../EntrantProgram/EntrantProgram";
import {
	InnerProgramDeadlines,
	ProgramCardContainer,
	ProgramDeadlines,
	ProgramDescription,
	ProgramHeader,
} from "../../components/ProgramCard/ProgramCard";
import Icon from "../../components/Icon/Icon";
import { roundTime } from "../../utils/getCountdownTimer";
import parse from "html-react-parser";
import {
	JurorCardConfig,
	JurorEntrySetSelectionCard,
	JurorJuryCard,
	RoundType,
} from "../Admin/Judging/JudgingInterfaces";
import { DisplayDate } from "../Admin/Program/ProgramInterfaces";
import styled, { useTheme } from "styled-components";
import Button from "../../components/Button/Button";
import ProgressCircle from "../../components/ProgressCircle/ProgressCircle";
import {
	getJurorEntrySetSelectionCards,
	getJurors,
} from "../Admin/User/managerUser";
import { useParams } from "react-router-dom";
import JudgingGalleryNav from "./JudgingGalleryNav";
import { useJurorHub } from "../../hooks/useJurorHub";
import BrowserHeader from "../../components/BrowserHeader/BrowserHeader";
import assetsConfig, { defaultBoxShadow } from "../../assetsConfig";
import { unixToJS } from "../../utils/timeUtilities";
import {
	getResizedVideoSrcAsync,
	getThumbnailSrc,
} from "../MediaLibrary/manageMediaLibrary";

const StatusCard = styled(InnerProgramDeadlines)`
	background: ${({ theme }) => theme.colorBackgroundLightLight};
	box-shadow: 0 2px 8px 0 ${({ theme }) => theme.colorBoxShadow};
	padding: 2rem;
`;

const HelpTextContainer = styled.div`
	margin: 0 15%;
	margin-bottom: 2.4375rem;
	padding: 1rem 2rem;
	border: 1px solid ${({ theme }) => theme.colorBorderDark};
	font-style: italic;
`;

const WelcomeBanner = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 1px solid ${({ theme }) => theme.colorCopyLight}; */
	box-shadow: 0 4px 4px -4px ${({ theme }) => theme.colorBoxShadow};
	padding: 1rem;
	background-color: ${({ theme }) => theme.colorBackgroundDark};
	color: ${({ theme }) => theme.colorCopyLightLight};
`;

const WelcomeVideoContainer = styled.div`
	background-color: ${({ theme }) => theme.colorBackgroundDark};
	box-shadow: ${defaultBoxShadow};
	color: ${({ theme }) => theme.colorCopyLightLight};

	video {
		width: 100%;
		max-height: 50vh;
	}

	img {
		width: 100%;
		max-width: 600px;
		margin: 0 auto;
		padding: 1rem 2rem;
	}
`;

const JudgingEntrySetCard = (props: JudgingEntrySetCardProps) => {
	const theme = useTheme();
	const isActive = props.status === JudgingRoundStatus.Started;
	const isJurorAssigned = props.votesPossible > 0;
	// find closest display date
	const deadline =
		props.deadline &&
		unixToJS(props.deadline) &&
		roundTime(unixToJS(props.deadline)!);
	const entriesPercentComplete = (props.votesCast / props.votesPossible) * 100;

	const getHelpText = () => {
		// if (isActive) {
		//   if (isJurorAssigned) {
		//     return "";
		//   } else {
		//     return "Coming Soon: You will be notified when this round of judging begins";
		//   }
		// } else {
		//   if (isJurorAssigned) {
		//     return "Coming Soon: You will be notified when this round of judging begins";
		//   } else {
		//     return "Coming Soon: You will be notified when this round of judging begins";
		//   }
		//   }

		if (isJurorAssigned) {
			if (props.status === JudgingRoundStatus.Started) {
				return "";
			} else if (
				props.status === JudgingRoundStatus.Ready ||
				props.status === JudgingRoundStatus.Pending
			) {
				return "Coming Soon: You will be notified when this round of judging begins";
			} else if (props.status === JudgingRoundStatus.Pause) {
				return "Judging Paused: You will be notified when this round of judging resumes";
			} else if (props.status === JudgingRoundStatus.Completed) {
				return "Congratulations: Thank you for completing this Judging Set";
			}
		} else return "Coming Soon: You will be notified when judging begins";
	};

	return isJurorAssigned && props.votesPossible > 0 ? (
		<ProgramCardContainer
			className={`${props.className} ${isActive ? "active" : ""}`}
		>
			<ProgramHeader>
				<div className="flex items-center">
					<h3 className="text-colorCopyLightLight">{props.roundType} Round</h3>
				</div>

				{deadline && (
					<div className="flex items-center">
						<Icon
							icon="clock"
							color={theme.colorPrimary}
							className="mr-[.5rem]"
						/>
						<p>{deadline} left</p>
					</div>
				)}
			</ProgramHeader>
			<ProgramDescription>
				<h1 className="playfair mx-auto text-center max-w-full break-words">
					{props.name}
				</h1>
				<div className="program-description mt-auto !mb-0">
					{parse(props.description || "")}
				</div>
			</ProgramDescription>
			{isActive ? (
				<ProgramDeadlines className="mb-[2.4375rem]">
					<InnerProgramDeadlines className="!flex flex-row items-center">
						<ProgressCircle
							value={entriesPercentComplete}
							text={`${Math.round(entriesPercentComplete)}%`}
							size="55px"
						/>
						<div className="flex flex-col">
							<p className="font-semibold">Entries</p>
							<p>
								{props.votesCast}/{props.votesPossible} Entries
							</p>
						</div>

						{/* <ProgressCircle
              value={videosPercentComplete}
              text={`${Math.round(videosPercentComplete)}%`}
              size="55px"
            /> */}

						{/* <div className="flex flex-col">
              <p className="font-semibold">Videos</p>
              <p>
                {props.videoMinutesWatched}/{props.videoMinutesCount} Minutes
                Watched
              </p>
            </div> */}
					</InnerProgramDeadlines>
				</ProgramDeadlines>
			) : (
				<ProgramDeadlines className="mb-[2.4375rem]">
					<InnerProgramDeadlines>
						<p className="deadline normal">Coming Soon</p>
					</InnerProgramDeadlines>
				</ProgramDeadlines>
			)}

			{getHelpText() && <HelpTextContainer>{getHelpText()}</HelpTextContainer>}

			{props.status === JudgingRoundStatus.Started && (
				<Button
					className="mt-auto font-semibold"
					to={`/judge/${props.juryId}/${props.id}`}
				>
					{/* {props.votesCast > 0 ? "Change Vote" : "Vote Now"} */}
					Vote Now
				</Button>
			)}
		</ProgramCardContainer>
	) : (
		<></>
	);
};

const getJudgingWelcomeText = (jurors: JurorCardConfig[], juryId: number) => {
	const jury = jurors.find((j) => j.juryId === juryId);
	return assetsConfig.copy.judgingWelcomeText.replace(
		"[JURY NAME]",
		jury?.juryName || ""
	);
};

const JudgeEntrySets = () => {
	const { juryId } = useParams<{
		juryId: string;
	}>();
	const parsedJuryId = juryId ? parseInt(juryId) : 0;
	const { jurorJuryCard, settings, highlightedEntryUrl, connection } =
		useJurorHub();

	const [cards, setCards] = useState<JurorEntrySetSelectionCard[]>([]);
	const [jurors, setJurors] = useState<JurorCardConfig[]>([]);
	const [liveJudgingJurorActive, setLiveJudgingJurorActive] = useState(false);
	const [welcomeVideo, setWelcomeVideo] = useState<string | null>(null);
	const [welcomeImage, setWelcomeImage] = useState<string | null>(null);

	useEffect(() => {
		handleGetJurorEntrySetSelectionCards(parsedJuryId);
	}, [juryId]);

	useEffect(() => {
		getJurors().then((response) => {
			if (response.status === 200) {
				setJurors(response.data);
			}
		});
	}, []);

	useEffect(() => {
		if (settings) {
			if (settings.isActive === true && settings.jurorControl === true) {
				setLiveJudgingJurorActive(true);
			} else {
				setLiveJudgingJurorActive(false);
			}
		}
	}, [settings.isActive, settings.jurorControl]);

	useEffect(() => {
		connection?.on(`juryEntrySetsUpdated-${parsedJuryId}`, () => {
			handleGetJurorEntrySetSelectionCards(parsedJuryId);
		});
	}, [juryId, connection]);

	const handleGetJurorEntrySetSelectionCards = (parsedJuryId: number) =>
		getJurorEntrySetSelectionCards(parsedJuryId).then((response) => {
			if (response.status == 200) {
				setCards(response.data);
			}
		});

	useEffect(() => {
		const fetchResizedVideo = async () => {
			if (jurorJuryCard?.welcomeVideo) {
				const videoSrc = await getResizedVideoSrcAsync(
					jurorJuryCard.welcomeVideo
				);
				setWelcomeVideo(videoSrc);
			}
		};
		fetchResizedVideo();
	}, [jurorJuryCard?.welcomeVideo]);

	useEffect(() => {
		if (jurorJuryCard?.welcomeImage) {
			const resziedImage = getThumbnailSrc(jurorJuryCard?.welcomeImage);
			setWelcomeImage(resziedImage);
		}
	}, [jurorJuryCard?.welcomeImage]);

	return (
		<PageContainer>
			<BrowserHeader
				title={`Judge Now: ${
					jurors?.find((j) => j.juryId === Number(juryId))?.juryName || ""
				}`}
			/>
			<JudgingGalleryNav
				jurorJuryCard={jurorJuryCard}
				juryId={juryId}
				highlightedEntryUrl={highlightedEntryUrl}
				liveJudgingJurorActive={liveJudgingJurorActive}
				award={settings.award}
			/>

			{jurorJuryCard?.juryResource && (
				<Button
					className="fixed z-10 bottom-[1.5rem] right-[1.5rem] !p-[1rem_1.5rem] "
					onClick={() =>
						window.open(
							process.env.REACT_APP_S3_HOST + jurorJuryCard.juryResource!,
							"_blank"
						)
					}
					icon="document"
					iconSize="18px"
				>
					View Resources
				</Button>
			)}

			<WelcomeBanner
				dangerouslySetInnerHTML={{
					__html: getJudgingWelcomeText(jurors, parsedJuryId),
				}}
			></WelcomeBanner>

			{welcomeVideo ? (
				<WelcomeVideoContainer>
					<video controls>
						<source src={welcomeVideo} type="video/mp4" />
						<source src={welcomeVideo} type="video/quicktime" />
						Your browser does not support the video tag.
					</video>
				</WelcomeVideoContainer>
			) : welcomeImage ? (
				<WelcomeVideoContainer>
					<img src={welcomeImage} alt="Welcome Image" />
				</WelcomeVideoContainer>
			) : (
				<></>
			)}

			<ProgramCardsContainer>
				{/* ROOT LEVEL PROGRAMS */}
				{cards &&
					cards.length > 0 &&
					cards.map((eSet: any, i: number) => (
						<JudgingEntrySetCard key={i} {...eSet} />
					))}
			</ProgramCardsContainer>
		</PageContainer>
	);
};

export default JudgeEntrySets;

interface JudgingEntrySetCardProps extends JurorEntrySetSelectionCard {
	className?: string;
}

enum JudgingRoundStatus {
	Pending,
	Ready,
	Started,
	Pause,
	Completed,
}
