import {
	DateType,
	EntryDateField,
} from "../../Admin/Program/ProgramInterfaces";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { useEffect, useState, useContext } from "react";
import { ValidateContext } from "../EntryForm";
import { AUTOSAVE_MESSAGE, DynamicEntryField } from "../DynamicFields";
import { useFormikContext } from "formik";
import { updateEntryField } from "../manageEntry";
import { SUCCESS_MESSAGE_DURATION } from "../../../components/FormFields/FieldTemplate";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";
import DateTimeField from "../../../components/FormFields/DateTimeField";
import DateField from "../../../components/FormFields/DateField";
import { getLocalTime, getLuxonDateTime } from "../../../utils/timeUtilities";

const EntryDateFieldForm = (props: EntryDateFieldProps) => {
	const validate = useContext(ValidateContext);
	const asterisk = props.field.programField.isRequired ? "*" : "";
	const startDateError =
		validate && !props.field.startDate && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	// check if START date is before minimum date
	const minStartDateErr =
		props.field.startDate &&
		props.field.programField.minDate &&
		getLuxonDateTime(props.field.startDate)! <
			getLuxonDateTime(props.field.programField.minDate)!
			? `
        Date cannot be before ${getLocalTime(props.field.programField.minDate)}
        `
			: undefined;

	// check if START date is after max date
	const maxStartDateErr =
		props.field.startDate &&
		props.field.programField.maxDate &&
		getLuxonDateTime(props.field.startDate)! >
			getLuxonDateTime(props.field.programField.maxDate)!
			? `Date cannot be after ${getLocalTime(props.field.programField.maxDate)}`
			: undefined;

	const endDateError =
		validate && !props.field.endDate && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	// check if END date is before minimum date
	const minEndDateErr =
		props.field.endDate &&
		props.field.programField.minDate &&
		getLuxonDateTime(props.field.endDate)! <
			getLuxonDateTime(props.field.programField.minDate)!
			? `Date cannot be before ${getLocalTime(
					props.field.programField.minDate
			  )}`
			: undefined;

	// check if END date is after max date
	const maxEndDateErr =
		props.field.endDate &&
		props.field.programField.maxDate &&
		getLuxonDateTime(props.field.endDate)! >
			getLuxonDateTime(props.field.programField.maxDate)!
			? `Date cannot be after 
       ${getLocalTime(props.field.programField.maxDate)}
       `
			: undefined;

	const handleDateChange = (
		fieldName: string,
		selectedDate: string | number | null
	) => {
		// Update only the changed field (startDate or endDate) and keep the other field intact
		const updatedField = {
			...props.field,
			[fieldName]: selectedDate,
		};

		return updateEntryField(updatedField);
	};

	return (
		<div className="col-w-100" id={`wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			<div className="flex gap-[1rem]">
				<SuccessMessageWrapper>
					{(success, setSuccess) =>
						props.field.programField.displayTime ? (
							<DateTimeField
								name={`${props.name}.startDate`}
								value={props.field.startDateTime}
								isRequired={props.field.programField.isRequired}
								min={props.field.programField.minDateTime}
								max={props.field.programField.maxDateTime}
								success={success}
								placeholder={
									props.field.programField.dateType === DateType.Range
										? "Start Date"
										: undefined
								}
								readOnly={props.readOnly}
								onChange={(selectedDate) =>
									props.saveOnSelect &&
									handleDateChange("startDate", selectedDate).then(() =>
										setSuccess(AUTOSAVE_MESSAGE)
									)
								}
								setError={props.setError}
							/>
						) : (
							<DateField
								name={`${props.name}.startDate`}
								value={props.field.startDate}
								isRequired={props.field.programField.isRequired}
								min={props.field.programField.minDate}
								max={props.field.programField.maxDate}
								success={success}
								placeholder={
									props.field.programField.dateType === DateType.Range
										? "Start Date"
										: undefined
								}
								readOnly={props.readOnly}
								onChange={(selectedDate) =>
									props.saveOnSelect &&
									handleDateChange("startDate", selectedDate).then(() =>
										setSuccess(AUTOSAVE_MESSAGE)
									)
								}
								setError={props.setError}
							/>
						)
					}
				</SuccessMessageWrapper>

				{props.field.programField.dateType === DateType.Range && (
					<SuccessMessageWrapper>
						{(success, setSuccess) =>
							props.field.programField.displayTime ? (
								<DateTimeField
									name={`${props.name}.endDate`}
									value={props.field.endDateTime}
									isRequired={props.field.programField.isRequired}
									min={props.field.programField.minDateTime}
									max={props.field.programField.maxDateTime}
									success={success}
									placeholder="End Date"
									readOnly={props.readOnly}
									onChange={(selectedDate) =>
										props.saveOnSelect &&
										handleDateChange("endDate", selectedDate).then(() =>
											setSuccess(AUTOSAVE_MESSAGE)
										)
									}
									setError={props.setError}
								/>
							) : (
								<DateField
									name={`${props.name}.endDate`}
									value={props.field.endDate}
									isRequired={props.field.programField.isRequired}
									min={props.field.programField.minDate}
									max={props.field.programField.maxDate}
									success={success}
									placeholder="End Date"
									readOnly={props.readOnly}
									onChange={(selectedDate) =>
										props.saveOnSelect &&
										handleDateChange("endDate", selectedDate).then(() =>
											setSuccess(AUTOSAVE_MESSAGE)
										)
									}
									setError={props.setError}
								/>
							)
						}
					</SuccessMessageWrapper>
				)}
			</div>
		</div>
	);
};

export default EntryDateFieldForm;

interface EntryDateFieldProps extends DynamicEntryField {
	field: EntryDateField;
	name: string;
	saveOnSelect?: boolean;
	setError?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}
