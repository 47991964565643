import { ReactElement, useContext, useMemo } from "react";
import Company from "../../views/Company/Company";
import AuthenticationContext from "./AuthenticationContext";
import { SettingsState } from "../Settings/settingsReducer";
import UserInfo from "../../views/Admin/User/UserInfo";

export default function Authorized(props: AuthorizedProps) {
  const { claims } = useContext(AuthenticationContext);

  // Use the passed props instead of accessing the Redux store directly
  const { settings, loading } = props;

  // Wait until settings are fully loaded before calculating authorization
  const isAuthorized = useMemo(() => {
    if (claims.length === 0) return false;

    let authorized = props.role
      ? claims.some(
          (claim) => claim.name === "role" && claim.value === props.role
        )
      : claims.length > 0;

    if (authorized && props.feature) {
      authorized = settings?.featureFlags?.[props.feature] ?? false;
    }

    return authorized;
  }, [claims, props.role, props.feature, settings]);

  // Memoized incomplete registration check
  const isIncompleteRegistration = useMemo(
    () => claims.some((claim) => claim.name === "incomplete"),
    [claims]
  );

  // If still loading, show nothing or a loading spinner (optional)
  if (loading) {
    //console.log("Loading", settings, loading, claims);
    return <></>; // or a loading spinner component
  }

  // If not authorized after loading, show the notAuthorized component or null
  if (!isAuthorized) {
    //console.log("Not Authorized", settings, loading, claims);
    return props.notAuthorized || null;
  }
  //console.log("Authorized", settings, loading, claims);
  const isCompanyCreated = claims.some((claim) => claim.name === "company");

  return isCompanyCreated ? (
    isIncompleteRegistration ? (
      <UserInfo />
    ) : (
      props.authorized
    )
  ) : (
    <Company />
  );
}

interface AuthorizedProps {
  authorized: ReactElement;
  notAuthorized?: ReactElement;
  role?: string;
  feature?: string; // Optional feature flag name
  settings: SettingsState; // Passed from parent
  loading: boolean; // Passed from parent
}
