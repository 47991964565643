import styled, { useTheme, css } from "styled-components";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import Sort from "../Sort/Sort";
import { FormikProvider, useFormik, useFormikContext } from "formik";
import CheckboxField from "../FormFields/CheckboxField";

const StyledDualScroll = styled.div<{ width: number }>`
	.top-scroll,
	.scroll-view {
		overflow-x: auto;
		width: 100%;
	}
	.top-scroll {
		height: 17px;
	}

	.scroll-div1 {
		width: ${(p) => p.width + "px"};
		overflow-x: auto;
		height: 17px;
	}
`;

const DualScroll = (props: { children: React.ReactNode; show?: boolean }) => {
	const topScrollRef = useRef<any>(null);
	const viewScrollRef = useRef<any>(null);

	const table = viewScrollRef.current
		? viewScrollRef.current.querySelector(".search-table")
		: null;

	useEffect(() => {
		if (table) {
			table.addEventListener("scroll", (e: React.UIEvent<HTMLElement>) => {
				if (topScrollRef.current) {
					topScrollRef.current.scrollTo({
						left: e.currentTarget.scrollLeft,
						behavior: "instant",
					});
				}
			});
		}
	}, [table]);

	return (
		<>
			{props.show ? (
				<StyledDualScroll width={table ? table.scrollWidth : table}>
					<div
						className="top-scroll"
						ref={topScrollRef}
						onScroll={(e) => {
							if (table) {
								table.scrollTo({
									left: e.currentTarget.scrollLeft,
									behavior: "instant",
								});
							}
						}}
					>
						<div className="scroll-div1"></div>
					</div>

					<div className="scroll-view" ref={viewScrollRef}>
						{props.children}
					</div>
				</StyledDualScroll>
			) : (
				props.children
			)}
		</>
	);
};

const ResultsTable = styled.div<{
	columns: number;
	active: boolean;
	firstColumnWidth?: string;
	fontSize?: string;
}>`
	display: ${(p) => (p.active ? "grid" : "none")};
	align-items: stretch;
	grid-template-columns: ${(p) =>
			p.firstColumnWidth ? p.firstColumnWidth : "1fr"} repeat(
			${(p) => p.columns - 1},
			1fr
		);
	overflow: auto;
	width: 100%;
	/* height: 68vh; */

	& > .cell,
	& > .sort {
		padding: 1rem;
		border-bottom: 1px solid ${({ theme }) => theme.colorBorderLight};
	}

	& > .sort {
		background: ${({ theme }) => theme.colorBackgroundLight};
		height: 70px;
		font-size: ${({ theme }) => theme.xSmallSize};
		font-weight: ${({ theme }) => theme.fontSemiBold};
	}

	& > .cell {
		display: flex;
		align-items: center;
		white-space: pre-wrap;
		/* max-width: 10vw; */
		.img-container {
			width: 93px;
			height: 52px;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
		p,
		a {
			overflow: hidden;
			white-space: pre-wrap;
			text-overflow: ellipsis;
			font-size: ${({ theme }) => theme.xxSmallSize};
			line-height: 15px;
		}

		&.highlighted {
			background: ${({ theme }) => theme.colorFieldReadOnly};
		}
	}

	${(p) =>
		p.fontSize &&
		`
    *:not(.sort) {
  font-size: ${p.fontSize}
    }
  `};

	/* @media (max-width: ${({ theme }) => theme.xxl}) {
    height: 45vh;
  } */
`;

export const TablePlaceholder = styled.div<{ active: boolean }>`
	position: relative;
	display: ${(p) => (p.active !== true ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	height: 200px;
	background-color: ${({ theme }) => theme.colorBackgroundLight};
`;

const Table = (props: TableProps) => {
	// state to control triangle asc/desc
	const defaultSortState = props.columnLabels.reduce((obj, label) => {
		obj[label] =
			props.activeSort && props.activeSort.label === label
				? props.activeSort.direction
				: SortDirection.Asc;
		return obj;
	}, {} as SortState);

	const [sortTriangle, setSortTriangle] = useState<SortState>(defaultSortState);

	const handleSort = (columnLabel: string) => {
		const oppositeDirection =
			sortTriangle[columnLabel] === SortDirection.Asc
				? SortDirection.Desc
				: SortDirection.Asc;

		props.onClickSort && props.onClickSort(columnLabel, oppositeDirection);

		setSortTriangle({
			...defaultSortState,
			[columnLabel]: oppositeDirection,
		});
	};

	const multiselectProps = useFormik({
		initialValues: {
			multiselect: false,
		},
		onSubmit: () => {},
	});

	// const fieldName = "checkBoxArr";

	const checkBoxProps = useFormik<TableCheckBox>({
		initialValues: {
			// [fieldName]: [],
		},
		onSubmit: () => {},
	});

	// selects or deselects all checkboxes
	const handleAllCheckBox = (checked: boolean) => {
		if (Object.values(checkBoxProps.values).length > 0) {
			Object.keys(checkBoxProps.values).forEach((key) => {
				checkBoxProps.setFieldValue(key, checked);
			});
		}
	};

	useEffect(() => {
		handleAllCheckBox(multiselectProps.values.multiselect);
	}, [multiselectProps.values.multiselect]);

	useEffect(() => {
		multiselectProps.setFieldValue("multiselect", false);
	}, [props.unCheckMultiselect]);

	return (
		<DualScroll show={props.dualScroll}>
			<ResultsTable
				className={`search-table ${props.className ? props.className : ""}`}
				columns={props.columnLabels.length + (props.allowMultiselect ? 1 : 0)}
				active={props.isActive}
				firstColumnWidth={props.firstColumnWidth}
				fontSize={props.fontSize}
			>
				{props.allowMultiselect && (
					<div className="flex items-center justify-center sort">
						<FormikProvider value={multiselectProps}>
							<CheckboxField
								name="multiselect"
								placeholder="MultiSelect"
								partialCheck={
									Object.values(checkBoxProps.values).length > 0
										? Object.values(checkBoxProps.values).includes(true) &&
										  Object.values(checkBoxProps.values).includes(false)
										: undefined
								}
							/>
						</FormikProvider>
					</div>
				)}
				{props.columnLabels.map((columnLabel, i) => {
					const aliasIndicesMatch =
						props.labelAlias !== undefined &&
						props.labelAlias.length === props.columnLabels.length;

					return (
						<Sort
							className="sort"
							label={aliasIndicesMatch ? props.labelAlias![i] : columnLabel}
							{...(!props.onClickSort && {
								noSortTriangle: true,
							})}
							{...(props.sortTriangleSize && {
								triangleSize: props.sortTriangleSize,
							})}
							{...(props.onClickSort && {
								onClick: () => handleSort(columnLabel),
							})}
							ascending={sortTriangle[columnLabel] === SortDirection.Asc}
						/>
					);
				})}
				<FormikProvider value={checkBoxProps}>
					{/* {typeof props.children === "function"
						? props.children(checkBoxProps.values, () =>
								handleAllCheckBox(false)
						  )
						: // .map((cell) => cell)
						  props.children.map((cell) => cell)} */}
					{props.children}
				</FormikProvider>
			</ResultsTable>
		</DualScroll>
	);
};

export default Table;

interface TableProps {
	className?: string;
	isActive: boolean;
	dualScroll?: boolean;
	columnLabels: string[];
	labelAlias?: string[]; /// display only aliases, indices must match with column labels
	children: React.ReactNode | React.ReactNode[];
	// | ((
	// 		formikValues: TableCheckBox,
	// 		deselectAll: () => void
	//   ) => React.ReactNode);
	firstColumnWidth?: string;
	fontSize?: string;
	onClickSort?(sortLabel: string, sortDirection: SortDirection): void;
	sortTriangleSize?: string;
	activeSort?: {
		label: string;
		direction: SortDirection;
	};
	allowMultiselect?: boolean;
	unCheckMultiselect?: boolean;
}

interface SortState {
	[columnLabel: string]: SortDirection;
}

export enum SortDirection {
	Asc,
	Desc,
}

export interface TableCheckBox {
	// id: number;
	// isChecked: boolean;

	[id: string]: boolean;
}
