import { useContext, useState, useEffect, useMemo } from "react";
import AddIndividualCreditForm, {
	AddIndividualCreditFormProps,
} from "../AddIndividualCreditForm";
import { AddCreditModalProps, TabModalCard } from "../AddCreditModal";
import Modal, { ModalCard } from "../../../../components/Modal/Modal";
import Icon from "../../../../components/Icon/Icon";
import { NavTabs } from "../../../../components/NavTabs/NavTabs";
import styled, { useTheme } from "styled-components";
import { FormikProvider, useFormik } from "formik";
import TextField from "../../../../components/FormFields/TextField";
import DropdownField from "../../../../components/FormFields/DropdownField";
import Button from "../../../../components/Button/Button";
import * as Yup from "yup";

import {
	addIndividualCredit,
	deleteCompanyCredit,
	deleteIndividualCredit,
	getLinkedEntriesByCompanyCreditId,
	getLinkedEntriesByIndividualCreditId,
} from "../manageCreditLibrary";
import { JobTitleOptions } from "../../../../data/JobTitleData";
import { CountryOptions } from "../../../../data/CountryData";
import TagFilter from "../../Media/TagFilter";
import Loading from "../../../../components/Loading/Loading";
import { MediaCreditEntryInfoModel } from "../../../MediaLibrary/mediaLibrary.model";
import { Link, useParams } from "react-router-dom";
import {
	CompanyTypeOptions,
	CreditType,
} from "../../Program/ProgramInterfaces";
import { updateCompanyCredit } from "../manageCreditLibrary";
import { updateIndividualCredit } from "../manageCreditLibrary";
import { CompanyCredit, IndividualCredit } from "../CreditInterfaces";
import TagSelection from "../../../../components/Tags/TagSelection";
import ToggleSwitchField from "../../../../components/FormFields/ToggleSwitchField";
import {
	ButtonContainer,
	EditModalContainer,
	EntryDetailList,
	InnerContainer,
	PageType,
} from "../../../../components/Library/Library";
import { useAlert } from "../../../../components/Alert/Alerts";
import lowerCase from "../../../../utils/lowerCase";
import assetsConfig from "../../../../assetsConfig";

export const EntryDetailCard = styled.div`
	display: flex;
	margin-top: 1rem;
	flex-direction: column;
	gap: 1rem;
	/* justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderLight}; */

	.hierarchy {
		width: 70%;
	}
`;

const CreditDetails = (props: CreditDetailsProps) => {
	const theme = useTheme();

	const { addNewAlert } = useAlert();
	const [firstValidation, setFirstValidation] = useState(false);
	const [isFetchingLinkedEntries, setIsFetchingLinkedEntries] = useState(false);
	const [linkedEntries, setLinkedEntries] = useState<
		MediaCreditEntryInfoModel[]
	>([]);
	const isCompanyCredit = props.creditType === CreditType.Company;

	let fieldRequired = "This field is required";

	const formikProps = useFormik({
		initialValues: { ...props.credit },
		onSubmit: async (values, { resetForm }) => {
			isCompanyCredit
				? updateCompanyCredit(values.id!, values as CompanyCredit)
						.then((response) => {
							console.log(response);

							props.onClickHide();
							props.refreshCredits();
							addNewAlert({
								type: "success",
								message: "Successfully saved credit",
							});
						})
						.catch((e) => {
							addNewAlert({
								type: "error",
								message: e.message,
							});
						})
				: updateIndividualCredit(values.id!, values as IndividualCredit)
						.then(() => {
							props.onClickHide();
							props.refreshCredits();
							addNewAlert({
								type: "success",
								message: "Successfully saved credit",
							});
						})
						.catch((e) => {
							addNewAlert({
								type: "error",
								message: e.message,
							});
						});
		},
		validationSchema: Yup.object({
			// individual credits
			fullName: Yup.string().when([], {
				is: () => !isCompanyCredit,
				then: Yup.string().required(fieldRequired),
			}),
			jobTitle: Yup.string().when([], {
				is: () => !isCompanyCredit,
				then: Yup.string().required(fieldRequired),
			}),
			otherJobTitle: Yup.string()
				.nullable()
				.when("jobTitle", {
					is: "Other",
					then: Yup.string().required(fieldRequired),
				}),
			email: Yup.string().email().nullable(),
			// company credits
			name: Yup.string().when([], {
				is: () => isCompanyCredit,
				then: Yup.string().required(fieldRequired),
			}),
			companyType: Yup.string().when([], {
				is: () => isCompanyCredit,
				then: Yup.string().required(fieldRequired),
			}),
			otherCompanyType: Yup.string().when("companyType", {
				is: "Other",
				then: Yup.string().required(fieldRequired),
			}),
			city: Yup.string().when([], {
				is: () => isCompanyCredit,
				then: Yup.string().required(fieldRequired),
			}),
			country: Yup.string()
				.nullable()
				.when([], {
					is: () => isCompanyCredit,
					then: Yup.string().required(fieldRequired),
				}),
		}),
		validateOnBlur: false,
		validateOnChange: firstValidation,
	});

	const handleDeleteIndividualCredit = (credit: IndividualCredit) => {
		getLinkedEntriesByIndividualCreditId(props.companyId, credit.id!).then(
			(res) => {
				if (res.status === 200) {
					deleteIndividualCredit(credit, res.data)
						.then(() => {
							addNewAlert({
								type: "success",
								message: "Successfully deleted individual credit",
							});
							props.refreshCredits();
							props.onClickHide();
						})
						.catch((e) =>
							addNewAlert({
								type: "error",
								message: e.message,
							})
						);
				}
			}
		);
	};

	const handleDeleteCompanyCredit = (credit: CompanyCredit) => {
		getLinkedEntriesByCompanyCreditId(props.companyId, credit.id!).then(
			(res) => {
				if (res.status === 200) {
					deleteCompanyCredit(credit, res.data)
						.then(() => {
							addNewAlert({
								type: "success",
								message: "Successfully deleted company credit",
							});
							props.refreshCredits();
							props.onClickHide();
						})
						.catch((e) =>
							addNewAlert({
								type: "error",
								message: e.message,
							})
						);
				}
			}
		);
	};

	useEffect(() => {
		setIsFetchingLinkedEntries(true);
		if (props.companyId) {
			if (isCompanyCredit) {
				getLinkedEntriesByCompanyCreditId(
					props.companyId,
					props.credit.id!
				).then((res) => {
					if (res.status === 200) {
						setLinkedEntries(res.data);
						setIsFetchingLinkedEntries(false);
					}
				});
			} else {
				getLinkedEntriesByIndividualCreditId(
					props.companyId,
					props.credit.id!
				).then((res) => {
					if (res.status === 200) {
						setLinkedEntries(res.data);
						setIsFetchingLinkedEntries(false);
					}
				});
			}
		}
	}, [props.credit, props.companyId]);

	const formDisabled = useMemo(() => {
		if (props.isAdmin) {
			return false;
		}
		return isFetchingLinkedEntries || linkedEntries.length > 0;
	}, [isFetchingLinkedEntries, linkedEntries.length, props.isAdmin]);

	return (
		<FormikProvider value={formikProps}>
			<Modal show>
				<ModalCard
					title={`Edit ${isCompanyCredit ? "Company" : "Individual"} Credit`}
					headerIcons={
						<Icon
							icon="close"
							color={theme.colorPrimary}
							onClick={props.onClickHide}
							width="35px"
							height="35px"
						/>
					}
					iconColor={theme.colorPrimary}
				>
					<EditModalContainer>
						<InnerContainer>
							<p>
								If this credit is attached to an{" "}
								{lowerCase(assetsConfig.labels.entry.singular)} (see{" "}
								{assetsConfig.labels.entry.singular} Details below), you will
								not be able to edit this credit. Be sure to Save after you make
								any changes.
							</p>
							<div className="flex items-center justify-between">
								<h3>Credit Details</h3>
								<ToggleSwitchField
									id={`${formikProps.values.id}.isActive`}
									name="isActive"
									checked={formikProps.values.isActive}
									small
									label={formikProps.values.isActive ? "Active" : "Inactive"}
								/>
							</div>

							{!isCompanyCredit && (
								<TextField
									name="fullName"
									disabled={formDisabled}
									placeholder="Name*"
									value={(formikProps.values as IndividualCredit).fullName}
								/>
							)}

							{isCompanyCredit && (
								<TextField
									name="name"
									disabled={formDisabled}
									placeholder="Company Name*"
									value={(formikProps.values as CompanyCredit).name}
								/>
							)}

							{isCompanyCredit && (
								<DropdownField
									name="companyType"
									disabled={formDisabled}
									placeholder="Company Type*"
									options={CompanyTypeOptions}
									value={(formikProps.values as CompanyCredit).companyType}
								/>
							)}

							{isCompanyCredit &&
								(formikProps.values as CompanyCredit).companyType ===
									"Other" && (
									<TextField
										name="otherCompanyType"
										disabled={formDisabled}
										placeholder="Other Company Type*"
										value={
											(formikProps.values as CompanyCredit).otherCompanyType
										}
									/>
								)}

							{!isCompanyCredit && (
								<DropdownField
									name="jobTitle"
									disabled={formDisabled}
									placeholder="Job Title*"
									options={JobTitleOptions}
									value={(formikProps.values as IndividualCredit).jobTitle}
								/>
							)}

							{!isCompanyCredit &&
								(formikProps.values as IndividualCredit).jobTitle ===
									"Other" && (
									<TextField
										name="otherJobTitle"
										disabled={formDisabled}
										placeholder="Preferred Title*"
										value={
											(formikProps.values as IndividualCredit).otherJobTitle
										}
									/>
								)}

							{!isCompanyCredit && (
								<TextField
									name="companyName"
									disabled={formDisabled}
									placeholder="Company Name"
									value={(formikProps.values as IndividualCredit).companyName}
								/>
							)}

							<div className="grid md:grid-cols-2 gap-[1.5rem]">
								{isCompanyCredit ? (
									<TextField
										name="city"
										disabled={formDisabled}
										placeholder="Company City*"
										value={(formikProps.values as CompanyCredit).city}
									/>
								) : (
									<TextField
										name="companyCity"
										disabled={formDisabled}
										placeholder="Company City"
										value={(formikProps.values as IndividualCredit).companyCity}
									/>
								)}

								<DropdownField
									name="country"
									disabled={formDisabled}
									placeholder={`Company Country${isCompanyCredit ? "*" : ""}`}
									options={CountryOptions}
									value={formikProps.values.country}
								/>
							</div>

							{!isCompanyCredit && (
								<TextField
									name="email"
									disabled={formDisabled}
									placeholder="Email Address"
									value={(formikProps.values as IndividualCredit).email}
								/>
							)}

							<TagSelection
								companyId={formikProps.values.companyId}
								selectedTags={formikProps.values.tags || []}
								setSelectedTags={(outputTags) => {
									formikProps.setFieldValue("tags", outputTags);
								}}
							/>
							<Link
								className="text-colorActivation !font-bold"
								to={
									props.isAdmin
										? "/admin/edit-entry/tag-library/" + props.companyId
										: "/tags"
								}
								target="_blank"
							>
								Manage Tags
							</Link>
							{/* <TagFilter onSearch={() => {}} placeholder={"Add Tags"} /> */}

							<EntryDetailList
								linkedEntries={linkedEntries}
								isFetchingLinkedEntries={isFetchingLinkedEntries}
								pageType={PageType.Credits}
							/>
						</InnerContainer>
						<ButtonContainer>
							<Button
								className="button-light mr-auto"
								icon="trash"
								iconSize="15px"
								iconColor={theme.colorActivation}
								onClick={() =>
									isCompanyCredit
										? handleDeleteCompanyCredit(props.credit as CompanyCredit)
										: handleDeleteIndividualCredit(
												props.credit as IndividualCredit
										  )
								}
							>
								Delete
							</Button>
							<Button
								className="button-gold"
								icon="close"
								iconSize="18px"
								iconColor={theme.colorCopyDarkDark}
								onClick={() => {
									props.onClickHide();
								}}
							>
								Cancel
							</Button>
							<Button
								icon="check"
								iconColor={theme.colorCopyLightLight}
								onClick={() => {
									formikProps.submitForm();
									setFirstValidation(true);
								}}
								type="submit"
							>
								Save
							</Button>
						</ButtonContainer>
					</EditModalContainer>
				</ModalCard>
			</Modal>
		</FormikProvider>
	);
};

export default CreditDetails;

interface CreditDetailsProps {
	companyId: number;
	credit: CompanyCredit | IndividualCredit;
	creditType: CreditType;
	onClickHide(): void;
	refreshCredits(): void;
	isAdmin: boolean;
}
