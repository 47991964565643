import axios from "axios";
import { urlEntries, urlJudgingAdmin, urlJuryAdmin } from "../../../endpoints";
import { ProgramCardAdmin } from "./Administration/JudgingAdminProgram";
import {
	EntrySetAdminCard,
	GalleryBatchCard,
	GalleryEntryCard,
	VoteOption,
} from "./JudgingInterfaces";
import {
	BatchLabelGroup,
	BatchLabelJury,
	CreateEntrySetModel,
	CreateJuryModel,
	EntrySetAdminEntrySetCard,
	EntrySetConfig,
	EntrySetDefinitionConfig,
	JurorCardConfig,
	JurorViewCard,
	JuryCardModel,
	JuryConfig,
	RootProgramWithJuryCards,
	RoundStructureConfig,
	RoundType,
} from "./JudgingInterfaces";
import { EntrySetAdminRoundSelection } from "./Administration/EntrySetAdministration";
import { UpdatePropertyRequest } from "../../Entries/manageEntry";

export async function getJuriesByProgramId(programId: number) {
	return await axios.get<RootProgramWithJuryCards>(
		`${urlJuryAdmin}/getJuriesByProgramId/${programId}`
	);
}

export async function getJuryCardById(juryId: number) {
	return await axios.get<JuryCardModel>(
		`${urlJuryAdmin}/getJuryCard/${juryId}`
	);
}

export async function createJury(value: CreateJuryModel) {
	return await axios.post<JuryCardModel>(`${urlJuryAdmin}/`, value);
}

export async function getEntrySetDefinitionsByRootProgramId(programId: number) {
	return await axios.get<EntrySetDefinitionConfig[]>(
		`${urlJuryAdmin}/getEntrySetDefinitionsByRootProgramId/${programId}`
	);
}

export async function getEntrySetsByJuryId(juryId: number) {
	return await axios.get<EntrySetConfig[]>(
		`${urlJuryAdmin}/getEntrySetsByJuryId/${juryId}`
	);
}

export async function createEntrySet(value: CreateEntrySetModel) {
	return await axios.post<EntrySetConfig>(
		`${urlJuryAdmin}/createEntrySet`,
		value
	);
}

export async function deleteEntrySet(entrySetId: number) {
	return await axios.delete(`${urlJuryAdmin}/deleteEntrySet/${entrySetId}`);
}

export async function saveEntrySetName(values: {
	entrySetId: number;
	name: string;
}) {
	return await axios.put(`${urlJuryAdmin}/saveEntrySetName`, values);
}

// const formData = new FormData();
// formData.append("FormFile", files[0].file);
// formData.append("FileName", files[0].file.name);
export async function updateJuryWelcomeVideo(value: FormData, juryId: number) {
	const headers = {
		"Content-Type": "multipart/form-data",
	};

	return axios.post<string>(
		`${urlJuryAdmin}/updateJuryWelcomeVideo?juryId=${juryId}`,
		value,
		{
			headers: headers,
		}
	);
}

export async function updateJuryWelcomeImage(value: FormData, juryId: number) {
	const headers = {
		"Content-Type": "multipart/form-data",
	};

	return axios.post<string>(
		`${urlJuryAdmin}/updateJuryWelcomeImage?juryId=${juryId}`,
		value,
		{
			headers: headers,
		}
	);
}

export async function updateJudgingResource(value: FormData, juryId: number) {
	const headers = {
		"Content-Type": "multipart/form-data",
	};

	return axios.post<string>(
		`${urlJuryAdmin}/updateJuryResource?juryId=${juryId}`,

		value,
		{
			headers: headers,
		}
	);
}

export async function updateEntrySetProperty(request: UpdatePropertyRequest) {
	const response = axios.put(`${urlJuryAdmin}/entryset/property`, request);
	return response;
}

export async function saveEntrySetDefinitions(
	values: EntrySetDefinitionConfig[]
) {
	return await axios.post(`${urlJuryAdmin}/saveEntrySetDefinitions`, values);
}

export async function getJuryPool(juryId: number) {
	return await axios.get<JurorCardConfig[]>(
		`${urlJuryAdmin}/getJuryPool/${juryId}`
	);
}

export async function saveJurors(values: JurorCardConfig[]) {
	return await axios.post(`${urlJuryAdmin}/saveJurors`, values);
}

export async function addJurorToFinalizedJury(value: JurorCardConfig) {
	return await axios.post<JurorCardConfig>(
		`${urlJudgingAdmin}/AddJurorToFinalizedJury`,
		value
	);
}

export async function removeJurorFromFinalizedJury(jurorId: number) {
	return await axios.delete<JurorCardConfig>(
		`${urlJudgingAdmin}/RemoveJurorFromFinalizedJury/${jurorId}`
	);
}

export async function getRoundTypes() {
	return await axios.get<RoundType[]>(`${urlJuryAdmin}/getRoundsTypes`);
}

export async function getRoundStructures(juryId: number) {
	return await axios.get<RoundStructureConfig[]>(
		`${urlJuryAdmin}/getRoundStructures/${juryId}`
	);
}

export async function getVoteOptions(roundStructureId: number) {
	return await axios.get<VoteOption[]>(
		`${urlJudgingAdmin}/getVoteOptions/${roundStructureId}`
	);
}

export async function getEntryAlsoIn(
	juryId: number,
	roundStructureId: number,
	entryId: number,
	brand?: string,
	title?: string
) {
	const params = { juryId, roundStructureId, entryId, brand, title };
	return await axios.post<GalleryEntryCard[]>(
		`${urlJudgingAdmin}/GetEntryAlsoIn`,
		{
			...params,
		}
	);
}

export async function saveRoundStructures(values: RoundStructureConfig[]) {
	return await axios.post(`${urlJuryAdmin}/saveRoundStructures`, values);
}

export async function getJuryForRoundAssigments(juryId: number) {
	return await axios.get<JuryConfig>(
		`${urlJuryAdmin}/getJuryForRoundAssigments/${juryId}`
	);
}

export async function saveRoundAssignments(values: JuryConfig) {
	return await axios.post(`${urlJuryAdmin}/saveRoundAssignments`, values);
}

export async function finalizeEntrySet(juryId: number) {
	return await axios.put(`${urlJuryAdmin}/finalizeEntrySet/${juryId}`, {});
}

export async function finalizeJuryPool(juryId: number) {
	return await axios.put(`${urlJuryAdmin}/finalizeJuryPool/${juryId}`, {});
}

export async function finalizeRoundConfiguration(juryId: number) {
	return await axios.put(
		`${urlJuryAdmin}/finalizeRoundConfiguration/${juryId}`,
		{}
	);
}

export async function finalizeRoundAssignment(juryId: number) {
	return await axios.put(
		`${urlJuryAdmin}/finalizeRoundAssignment/${juryId}`,
		{}
	);
}

export async function getRootJudgingAdminPrograms() {
	return await axios.get(`${urlJudgingAdmin}/getJudgingAdminPrograms`, {});
}

export async function getProgramJudgingList(id: number) {
	return await axios.get<ProgramCardAdmin>(
		`${urlJudgingAdmin}/GetProgramJudgingList/${id}`,
		{}
	);
}

export async function getBatchLabelGroups(entrySetid: number) {
	return await axios.get<BatchLabelGroup[]>(
		`${urlJudgingAdmin}/GetBatchLabelGroups/${entrySetid}`,
		{}
	);
}

export async function getBatchLabelJurors(juryId: number) {
	return await axios.get<BatchLabelJury[]>(
		`${urlJudgingAdmin}/GetBatchLabelJurors/${juryId}`,
		{}
	);
}

export async function updateJuryGroup(entryId: number, juryGroup: number) {
	return await axios.put(
		`${urlJudgingAdmin}/UpdateJuryGroup?entryId=${entryId}&juryGroup=${juryGroup}`,
		{}
	);
}

export async function getJurorViewJurors(juryId: number) {
	return await axios.get<JurorViewCard[]>(
		`${urlJudgingAdmin}/GetJurorViewJurors/${juryId}`,
		{}
	);
}

export async function castAbstainForUncastVotes(jurorId: number) {
	return await axios.get(
		`${urlJudgingAdmin}/castAbstainForUncastVotes/${jurorId}`,
		{}
	);
}

export async function getEntrySetAdminCard(
	entrySetId: number,
	roundId: number
) {
	return await axios.get<EntrySetAdminCard>(
		`${urlJudgingAdmin}/GetEntrySetAdminCard/${entrySetId}/${roundId}`,
		{}
	);
}

export async function getEntrySetAdminBatches(
	entrySetId: number,
	roundId: number
) {
	return await axios.get<Array<GalleryBatchCard>>(
		`${urlJudgingAdmin}/GetEntrySetAdminBatches/${entrySetId}/${roundId}`,
		{}
	);
}

export async function getEntrySetAdminRounds(entrySetId: number) {
	return await axios.get<Array<EntrySetAdminRoundSelection>>(
		`${urlJudgingAdmin}/GetEntrySetAdminRounds/${entrySetId}`,
		{}
	);
}

export async function advanceEntryInJudging(entryId: number) {
	return await axios.get(`${urlEntries}/advanceEntryInJudging/${entryId}`, {});
}

export async function renameJury(juryId: number, newJuryName: string) {
	return await axios.put(`${urlJuryAdmin}/rename/${juryId}`, {
		newJuryName: newJuryName,
	});
}
