import { EntryPhysicalComponentField } from "../../Admin/Program/ProgramInterfaces";
import ToggleSwitchField from "../../../components/FormFields/ToggleSwitchField";
import TextField from "../../../components/FormFields/TextField";
import { fieldRequired } from "../../../components/FieldModal/FieldModal";
import { useContext, useEffect, useState } from "react";
import { getIn, setIn, useFormikContext } from "formik";
import { ValidateContext } from "../EntryForm";
import {
	AUTOSAVE_MESSAGE,
	DynamicEntryField,
	EntryError,
} from "../DynamicFields";
import { updateEntryField } from "../manageEntry";
import { SuccessMessageWrapper } from "../../../components/FormFields/FieldWrapper";

const EntryPhysicalComponentFieldForm = (
	props: EntryPhysicalComponentFieldProps
) => {
	const validate = useContext(ValidateContext);
	const asterisk = props.field.programField.isRequired ? "*" : "";

	const error =
		!props.field.physicalItemDescription && props.field.programField.isRequired
			? fieldRequired
			: undefined;

	const handleToggleSwitchChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		// Perform the save operation here
		return updateEntryField({
			...props.field,
			hasPhysicalComponent: event.target.checked,
		});
	};

	// Handler to save when TextField is unfocused
	const handleTextFieldBlur = () => {
		// Perform the save operation here,
		// using the current value of props.field.physicalItemDescription
		return updateEntryField({
			...props.field,
			physicalItemDescription: props.field.physicalItemDescription,
		});
	};

	useEffect(() => {
		props.setError && props.setError(error);
	}, [error]);

	return (
		<div className="col-w-100" id={`wrapper-div-${props.name}`}>
			{!props.isAdmin && (
				<>
					<h3 className="subtitle font-semibold">
						{props.field.programField.title}
						{asterisk}
					</h3>
					<p className="whitespace-pre-wrap">
						{props.field.programField.entrantHelpText}
					</p>
				</>
			)}

			{!props.field.programField.isRequired && (
				<div className="col-w-100">
					<p className="mb-[1rem]">Contains a Physical Component?</p>

					<SuccessMessageWrapper>
						{(success, setSuccess) => (
							<ToggleSwitchField
								name={`${props.name}.hasPhysicalComponent`}
								checked={props.field.hasPhysicalComponent}
								id={"hasPhysicalComponent"}
								disabled={props.readOnly}
								onChange={(event) =>
									props.saveOnChange &&
									handleToggleSwitchChange(event).then(() =>
										setSuccess(AUTOSAVE_MESSAGE)
									)
								}
								success={success}
							/>
						)}
					</SuccessMessageWrapper>
				</div>
			)}

			{(props.field.programField.isRequired ||
				props.field.hasPhysicalComponent) && (
				<SuccessMessageWrapper>
					{(success, setSuccess) => (
						<TextField
							name={`${props.name}.physicalItemDescription`}
							component="textarea"
							value={props.field.physicalItemDescription}
							hiddenlabel
							success={success}
							error={validate && error && error}
							readOnly={props.readOnly}
							onBlur={() =>
								props.saveOnChange &&
								handleTextFieldBlur().then(() => setSuccess(AUTOSAVE_MESSAGE))
							}
						/>
					)}
				</SuccessMessageWrapper>
			)}
		</div>
	);
};

export default EntryPhysicalComponentFieldForm;

interface EntryPhysicalComponentFieldProps extends DynamicEntryField {
	field: EntryPhysicalComponentField;
	name: string;
	saveOnChange?: boolean;
	setError?: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}
